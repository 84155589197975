import React from 'react';
import styled, { useTheme } from 'styled-components';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import StyledDot from '../../../app/components/styledComponents/StyledDot';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import { getAdvertRedirectUrl, getCurrencySign, useWindowSize } from '../../../utils/helper';
import { STATUSES } from '../../../utils/Statuses';
import OccyLogoDefault from '../../../app/components/svgIcons/OccyLogoDefault.png';
import StyledRadioButton from '../../../app/components/radio/StyledRadioButton';
import moment from 'moment';
import { useState } from 'react';
import StyledCheckbox from '../../../app/components/checkbox/StyledCheckbox';
// import OfferAcceptedModal from './OfferAcceptedModal';
import Contactus from '../../ReInterviews/components/Contactus';
import { Link } from 'react-router-dom';

const JobOffer = ({ setOfferLetter, jobOffer, responceLoading, rejectOffer, isLoading,
  token, }) => {
  const isSmallScreen = useWindowSize();
  const [state, setState] = useState({
    selectDate: '',
    confirm: false,
  });
  const [pickDate, setPickDate] = useState('');
  const [pickDateError, setPickDateError] = useState('');
  const [checkboxError, setCheckboxError] = useState('');
  const [selectCustomDate, setSelectCustomDate] = useState(false);
  const [contactUs, setContactUs] = useState(false);
  const theme = useTheme();
  const handleAcceptOfferLetter = (isAccept = undefined) => {
    if (!state?.selectDate) {
      setPickDateError('Required');
    } else if (!state?.confirm) {
      setCheckboxError('Required');
    } else {
      const values = {};
      values.startDate = state?.selectDate?.offerDate ?? undefined;
      values.selectedJHPId = state?.selectDate?.jobHiringPlan?.id?? undefined;
      values.proposedDate = pickDate ? pickDate : undefined;
      values.token = token;
      values.accept = isAccept;
    //  if (selectCustomDate) values.comments = comment;
      rejectOffer(values).then(
        (res) =>
          (res?.data?.message === 'Offer accepted' ||
            res?.data?.message === 'Comment submitted successfully') && setOfferLetter('Continue')
      );
    }
  };
  const filterHtmlRigix = (text) => <div dangerouslySetInnerHTML={{ __html: text }} />


  return contactUs ? (
    <Contactus
      setContactUs={setContactUs}
      token={token}
      rejectOffer={rejectOffer}
      setOfferLetter={setOfferLetter}
      // contactUs={contactUs}
      isLoading={isLoading}
    />
  ) : (
    <Wrap className="d-sm-flex justify-content-sm-center">
      <Wrap className="col-sm col-sm-8 mt-0 mt-sm-5">
        <StyledContainer className="mt-0 mt-md-5 rounded">
          <StyledRoundedBorder className="bg-white">
            <Wrap className="px-5 py-4 rounded-top flex-column d-flex align-items-center align-items-md-start">
              <Wrap className="d-flex justify-content-center w-100 mb-2">
                <img
                  alt="company_logo"
                  src={jobOffer?.jobOfferDetails?.application?.job?.company?.compLOGOSignedUrl || OccyLogoDefault}
                  width="100"
                  height="20%"
                />
              </Wrap>
              <StyledLabel size="24px">Job Offer</StyledLabel>
              <StyledLabel
                weight="500px"
                color={theme.headingColor}
                className="text-center"
              >
                Please find the details of our job offer below. We can’t wait
                for you to join the team
              </StyledLabel>
            </Wrap>
          </StyledRoundedBorder>

          <Wrapped className="p-4">
            <StyledPortlet>
              <Wrap className="">
                <Wrap className="d-flex flex-column flex-sm-row gap-sm-2">
                  <StyledLabel
                    color={theme.headingColor}
                    size={isSmallScreen ? '16px' : '14px'}
                    weight="500px"
                  >
                    Job
                  </StyledLabel>
                  <span className="d-none d-md-block">:</span>
                  <StyledLabel
                    color={theme.primaryDark}
                    className="mt-1 mt-sm-0 cursor-pointer text-decoration-none"
                    as={Link}
                    to={getAdvertRedirectUrl(jobOffer?.jobOfferDetails?.application?.job?.title, jobOffer?.jobOfferDetails?.application?.applicationSourceTracking?.postingId)}
                  >
                    {jobOffer?.jobOfferDetails?.application?.job?.title}
                  </StyledLabel>
                </Wrap>
                <hr className="d-sm-none" />
                <Wrap className="d-flex flex-column flex-sm-row gap-sm-2">
                  <StyledLabel
                    size={isSmallScreen ? '16px' : '14px'}
                    weight="500px"
                    color={theme.headingColor}
                  >
                    Salary
                  </StyledLabel>
                  <span className="d-none d-md-block">:</span>
                  <StyledLabel
                    color={theme.primaryDark}
                    className="mt-1 mt-sm-0"
                  >
                    {getCurrencySign(jobOffer?.jobOfferDetails?.currency)}{' '}
                    {jobOffer?.jobOfferDetails?.salary}/
                    {jobOffer?.jobOfferDetails?.payPer}
                  </StyledLabel>
                </Wrap>
                <hr className="d-sm-none" />
              </Wrap>
              <StyledLabel
                weight="500px"
                color={theme.textColor}
                className=" my-md-3"
              >
                {filterHtmlRigix(jobOffer?.jobOfferDetails?.offerLetter) ??
                  `We are excited to have you onboard. All you need to do now is
                let us know whether you will accept the job and agree a start
                date. We will then let you know the next steps to get you
                started with us.`}
              </StyledLabel>

              {jobOffer?.jobOfferDetails?.statusId !== 16 &&
                jobOffer?.jobOfferDetails?.statusId !== STATUSES.AWAITING_FEEDBACK && (
                  <StyledLabel className="d-flex mt-3 mt-md-0">
                    offer already processed
                  </StyledLabel>)}
            </StyledPortlet>
            {jobOffer?.jobOfferDetails?.statusId === 16
              // jobOffer?.jobOfferDetails?.statusId === STATUSES.AWAITING_FEEDBACK
              && (
                <StyledPortlet className="my-3">
                  <StyledLabel weight="500px" className="d-md-none">
                    Choose a date of joining as per your convenience.{' '}
                  </StyledLabel>
                  <StyledLabel className="d-md-block d-none">
                    Select Start Date
                  </StyledLabel>

                  <Wrap className="mt-3">
                    {jobOffer?.jobOfferDetails?.jobOfferHiringPlans?.map(
                      (option, index, array) => (
                        <div
                          className={`py-2 px-3 rounded-3 ${state?.selectDate?.id ===
                            option?.id
                            ? 'bg-lightPrimary'
                            : ''
                            }`}
                          key={`radio-${index}-${option?.id}`}
                        >
                          <StyledRadioButton
                            className={
                              pickDateError ? 'border-danger text-danger' : ''
                            }
                            label={moment(option?.offerDate).format(
                              'DD MMM YYYY',
                            )}
                            id={`${index}-${option?.id}`}
                            value={
                              state?.selectDate?.id ===
                              option?.id
                            }
                            size={12}
                            disabled={false}
                            onChange={() => {
                              setState({ ...state, selectDate: option });
                              setSelectCustomDate(false);
                              setPickDate('');
                              setPickDateError('');
                            }}
                          />
                        </div>
                      ),
                    )}
                    <Wrap
                      className={`px-3 py-2  rounded ${selectCustomDate ? 'bg-lightPrimary' : ''
                        }`}
                    >
                      <StyledRadioButton
                        label="These dates don’t work for me"
                        value={selectCustomDate}
                        size={12}
                        disabled={false}
                        onChange={() => {
                          setState({ ...state, selectDate: '' });
                          setSelectCustomDate(true);
                          setPickDate(selectCustomDate ? new Date() : '');
                        }}
                      />
                    </Wrap>
                  </Wrap>


                  <hr className="d-none d-sm-block" />

                  <Wrap className="d-flex align-items-start align-items-md-center gap-2 mb-4 mt-3 mt-md-0">
                    <StyledCheckbox
                      className={checkboxError ? 'border-danger text-danger mt-1 mt-md-0' : 'mt-1 mt-md-0'}
                      size={16}
                      value={state?.confirm}
                      label='I confirm I will be accepting this job offer and start date
                      and will await confirmation of the next steps.'
                      onChange={() => {
                        setState({ ...state, confirm: !state?.confirm });
                        setCheckboxError('');
                      }}
                    />
                    {/* <StyledLabel
                      htmlFor='accept'
                      size="14px"
                      id='accept'
                      weight="400px"
                      color={checkboxError ? theme.danger : theme.headingColor}
                      className="pt-1 pt-md-0 cursor-pointer"
                    >
                      I confirm I will be accepting this job offer and start date
                      and will await confirmation of the next steps.
                    </StyledLabel> */}
                  </Wrap>
                  {
                  selectCustomDate ? (
                    <StyledWrap className="px-3 px-md-0 py-4 py-md-0 bg-white d-flex gap-2">
                      <StyledButtonContainer onClick={() => setContactUs(true)}>
                        Contact Us
                      </StyledButtonContainer>
                    </StyledWrap>
                  ) : (
                    !responceLoading && jobOffer ? (
                      <StyledWrap className="px-3 px-md-0 py-4 py-md-0 bg-white d-flex gap-2">
                        <StyledButtonContainer
                          onClick={() => handleAcceptOfferLetter(true)}
                          disabled={isLoading}
                          loading={isLoading}
                        >
                          Accept
                        </StyledButtonContainer>
                        <StyledButtonContainer
                          variant="white"
                          onClick={() => setOfferLetter('Reject')}
                        >
                          Reject
                        </StyledButtonContainer>
                      </StyledWrap>

                    ) : null

                  )
                  
                  }
                </StyledPortlet>)}


            <StyledPortlet className="d-flex flex-column mt-3">
              <StyledLabel className="mb-2">For queries contact</StyledLabel>
              <StyledLabel color={theme.primary} className=" text-capitalize">
                {`${jobOffer?.jobOfferDetails?.application?.job?.account
                  ?.firstName ?? ''
                  }${' '}${jobOffer?.jobOfferDetails?.application?.job?.account
                    ?.lastName ?? ''
                  }`}
              </StyledLabel>
              <Wrap className="d-flex flex-column flex-sm-row align-items-sm-center gap-sm-2">
                <StyledLabel color={theme.lightBoldBlack} weight="500px">
                  {jobOffer?.jobOfferDetails?.application?.job?.account
                    ?.email ?? ''}
                </StyledLabel>
                {!responceLoading && jobOffer ? (
                  <StyledDot className=" d-none d-sm-block" />
                ) : null}
                <StyledLabel color={theme.lightBoldBlack} weight="500px">
                  {jobOffer?.jobOfferDetails?.application?.job?.account
                    ?.phone ?? ''}
                </StyledLabel>
              </Wrap>
            </StyledPortlet>
          </Wrapped>
        </StyledContainer>
      </Wrap>

    </Wrap >
  );
};

const StyledWrap = styled(Wrap)`
  ${(props) => props.theme.sm} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  z-index: 999;
`;

const StyledButtonContainer = styled(StyledButton)`
  ${(props) => props.theme.sm} {
    width: 100%;
  }
`;

const Wrapped = styled(Wrap)`
  ${(props) => props.theme.sm} {
    margin-bottom: 70px;
  }
`;

const StyledContainer = styled(Wrap)`
  ${(props) => props.theme.gtSm} {
    background-color: ${({theme}) => theme.inputBorder};
  }
`;

const StyledPortlet = styled(PortletBody)`
  ${(props) => props.theme.gtSm} {
    border: 1px solid ${({theme}) => theme.borderLight};
  }
`;

const StyledRoundedBorder = styled(Wrap)`
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  ${(props) => props.theme.gtSm} {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export default JobOffer;
