import { createSlice } from '@reduxjs/toolkit';


const slice = createSlice({
    name: 'headerCredits',
    initialState: {
        headerCredits: {
            refetchOccyCredits: '',
        }
    },
    reducers: {
        setHeaderCredits: (state, { payload }) => {
            state.headerCredits = { ...state?.headerCredits, ...payload };
        },
        resetHeaderCredits: (state) => {
            state.headerCredits = {};
        },
    },
});

export const { setHeaderCredits, resetHeaderCredits } = slice?.actions;

export default slice?.reducer;

export const getHeaderCreditsState = (state) => state?.headerCredits?.headerCredits;