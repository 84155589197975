import React from 'react';

const SlotsIcon = ({ size = 17, filled, className='' }) => {
  return (
    <>

      {
        !filled ?
        <svg width={size} height={size} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" className={className}>
        <path d="M9 22H15C20 22 22 20 22 15V9C22 4 20 2 15 2H9C4 2 2 4 2 9V15C2 20 4 22 9 22Z" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M12 2V22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2 12H22" stroke="currentColor" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        </svg>
        
          :
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.1 7.23019C16.29 7.23019 15.55 5.95019 16.45 4.38019C16.97 3.47019 16.66 2.31019 15.75 1.79019L14.02 0.80019C13.23 0.33019 12.21 0.61019 11.74 1.40019L11.63 1.59019C10.73 3.16019 9.25 3.16019 8.34 1.59019L8.23 1.40019C7.78 0.61019 6.76 0.33019 5.97 0.80019L4.24 1.79019C3.33 2.31019 3.02 3.48019 3.54 4.39019C4.45 5.95019 3.71 7.23019 1.9 7.23019C0.86 7.23019 0 8.08019 0 9.13019V10.8902C0 11.9302 0.85 12.7902 1.9 12.7902C3.71 12.7902 4.45 14.0702 3.54 15.6402C3.02 16.5502 3.33 17.7102 4.24 18.2302L5.97 19.2202C6.76 19.6902 7.78 19.4102 8.25 18.6202L8.36 18.4302C9.26 16.8602 10.74 16.8602 11.65 18.4302L11.76 18.6202C12.23 19.4102 13.25 19.6902 14.04 19.2202L15.77 18.2302C16.68 17.7102 16.99 16.5402 16.47 15.6402C15.56 14.0702 16.3 12.7902 18.11 12.7902C19.15 12.7902 20.01 11.9402 20.01 10.8902V9.13019C20 8.09019 19.15 7.23019 18.1 7.23019ZM10 13.2602C8.21 13.2602 6.75 11.8002 6.75 10.0102C6.75 8.22019 8.21 6.76019 10 6.76019C11.79 6.76019 13.25 8.22019 13.25 10.0102C13.25 11.8002 11.79 13.2602 10 13.2602Z" fill="#CCB2C8" />
          </svg>
      }
    </>
  );
};

export default SlotsIcon;


