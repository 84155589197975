import React from 'react';
import Proptypes from 'prop-types';
import StyledCheckbox from '../checkbox/StyledCheckbox';
import { ErrorMessage, useField } from 'formik';
import { formErrorMessage } from './FormErrorMessage';

const FormikCheckBox = ({ name, align, ...props }) => {
  const [, meta, helpers] = useField(name);
  const { value } = meta;
  const { setValue } = helpers;

  const onChange = (event) => {
    setValue(event.target.checked);
  };
  return (
    <>
      <StyledCheckbox value={value} align={align} onChange={onChange} {...props} />
      <ErrorMessage name={name} render={formErrorMessage} />
    </>
  );
};
FormikCheckBox.propTypes = {
  name: Proptypes.string.isRequired,
};

export default React.memo(FormikCheckBox);
