import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import PortletBody from '../../../app/components/portlet/PortletBody';
// import StyledButton from '../../../app/components/styledComponents/StyledButton';
import StyledDot from '../../../app/components/styledComponents/StyledDot';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import OfferAcceptedModal from './OfferAcceptedModal';
import NewAttachmentIcon from '../../../app/components/svgIcons/NewAttachmentIcon';
import StyledToolTip from '../../../app/components/styledComponents/StyledToolTip';
import OccyLogoDefault from '../../../app/components/svgIcons/OccyLogoDefault.png';
import DownloadIcon3 from '../../../app/components/svgIcons/DownloadIcon3';
import ExportIcon from '../../../app/components/svgIcons/ExportIcon';
import { getFileNameByFileDescriptor, getPreviewURL } from '../../../utils/helper';

const DisplayAttachment = (attachment) => {
  const theme = useTheme();
  return (
    <Wrap className="d-flex justify-content-between align-items-center mt-3">
      <Wrap className="d-flex align-items-center gap-2">
        <NewAttachmentIcon />
        <StyledLabel weight="500" color={theme.headingColor}>
          {attachment.fileOriginalName || getFileNameByFileDescriptor(attachment.fileDescriptor)}
        </StyledLabel>
      </Wrap>
      <Wrap className="d-flex gap-2">
        {attachment.signedUrl && (
          <StyledToolTip title="View" childrenClass={'ms-0'}>
            <ExportIcon
              className="mx-2 cursor-pointer" // color={theme?.primary}
              onClick={() => {
                window.open(
                  getPreviewURL(attachment.signedUrl || ''),
                  '_blank',
                );
              }}
            />
          </StyledToolTip>
        )}
        <a
          className="cursor-pointer text-muted"
          href={attachment.signedUrl}
          target="_blank"
          rel="noreferrer"
        >
          <StyledToolTip title="Download" childrenClass={'ms-0'}>
            <DownloadIcon3 />
          </StyledToolTip>
        </a>
      </Wrap>
    </Wrap>
  );
};

const OfferAcception = ({
  jobOffer,
  setOfferLetter,
  responceLoading,
  document,
}) => {
  const [acceptOffer, setAcceptOffer] = useState(false);
  const theme = useTheme();
  return (
    <Wrap className="d-sm-flex justify-content-sm-center">
      <Wrap className="col-sm col-sm-8 mt-0 mt-sm-5">
        <StyledContainer className="mt-0 mt-md-5 rounded">
          <StyledRoundedBorder className="bg-white">
            <Wrap className="px-5 py-4 rounded-top flex-column d-flex align-items-center align-items-md-start">
              <Wrap className="d-flex justify-content-center w-100 mb-2">
                <img
                  alt="company_logo"
                  src={
                    jobOffer?.document?.company?.compLOGOSignedUrl ||
                    OccyLogoDefault
                  }
                  width="100"
                  height="20%"
                />
              </Wrap>
              <StyledLabel size="24px">Job Offer</StyledLabel>
              <StyledLabel
                weight="500"
                color={theme.lightBoldBlack}
                className="text-left"
              >
                Please find the details of our job offer below. We can’t wait
                for you to join the team
              </StyledLabel>
            </Wrap>
          </StyledRoundedBorder>
          <div className="px-4 py-2">
            {jobOffer?.attachments?.length > 0 || jobOffer?.signedDoc ? (
              <StyledPortlet className="my-4">
                <StyledLabel className="w-100">Attached Documents</StyledLabel>

                <StyledLabel
                  className="mt-2"
                  size="12px"
                  color={theme?.borderColor}
                  weight="500"
                >
                  Download the documents below for future reference
                </StyledLabel>
                {jobOffer?.attachments?.map((attachment, index) => (
                  <>
                    {DisplayAttachment(attachment.item?.media)}
                    <StyledHR className="text-light2" />
                  </>
                ))}
                {jobOffer?.signedDoc ? (
                  <>
                    {DisplayAttachment(jobOffer?.signedDoc)}
                    <StyledHR className="text-light2" />
                  </>
                ) : null}
              </StyledPortlet>
            ) : null}

            <StyledPortlet className="d-flex flex-column my-3">
              <StyledLabel className="mb-2">For queries contact</StyledLabel>
              <StyledLabel
                color={theme.primary}
                className="mt-2 text-capitalize"
              >
                {`${jobOffer?.account?.firstName ?? ''}${' '}${
                  jobOffer?.account?.lastName ?? ''
                }`}
              </StyledLabel>
              <Wrap className="d-flex flex-column flex-sm-row align-items-sm-center gap-sm-2">
                <StyledLabel
                  size={'12px'}
                  color={theme.lightBoldBlack}
                  weight="500"
                >
                  {jobOffer?.account?.email ?? ''}
                </StyledLabel>
                {!responceLoading && jobOffer ? (
                  <StyledDot $size={'4px'} className=" d-none d-sm-block" />
                ) : null}
                <StyledLabel
                  size={'12px'}
                  color={theme.lightBoldBlack}
                  weight="500"
                >
                  {jobOffer?.account?.phone ?? ''}
                </StyledLabel>
              </Wrap>
            </StyledPortlet>

            {/* <div className="d-flex">
              <StyledButton
                onClick={() => setAcceptOffer(true)}
                type="button"
                className="ms-auto mb-3 btn"
              >
                Proceed
              </StyledButton>
            </div> */}
          </div>
          <OfferAcceptedModal
            show={acceptOffer}
            onHide={() => setAcceptOffer(false)}
            setOfferLetter={setOfferLetter}
            agreement={document}
          />
        </StyledContainer>
      </Wrap>
    </Wrap>
  );
};

const StyledContainer = styled(Wrap)`
  ${(props) => props.theme.gtSm} {
    background-color: ${({theme}) => theme.inputBorder};
  }
`;

const StyledPortlet = styled(PortletBody)`
  ${(props) => props.theme.gtSm} {
    border: 1px solid ${({theme}) => theme.borderLight};
  }
`;

const StyledRoundedBorder = styled(Wrap)`
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  ${(props) => props.theme.gtSm} {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

const StyledHR = styled.hr`
  border-color: ${({theme}) => theme?.borderColor};
`;

export default OfferAcception;
