import React from 'react';
import Skeleton from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css';
import PortletBody from '../../../app/components/portlet/PortletBody';

const SlotsSkeleton = ({ cols, show, customeColWidth=200 }) => {

  return (
    <>
      <PortletBody>
        {Array(2).fill(null).map((col, ind) =>(<div key={ind} className="flex-center d-flex justify-content-between">

        {Array(2).fill(null).map((col, ind) =>(<div key={ind} className="w-50 flex-grow-1 m-2">

      <Skeleton height={100} width={'100%'} className="me-2 mt-2" />

    </div>  ))}

   

    </div>))}

      </PortletBody>

    </>

  );

};


export default SlotsSkeleton;

