import React from 'react';

const BackArrowIcon = ({ size = 32 ,...props}) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 33 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <rect x="0.533447" width="32" height="32" rx="16" fill="#F8F2F7" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.4763 10.3905C19.997 10.9112 19.997 11.7554 19.4763 12.2761L15.7524 16L19.4763 19.7239C19.997 20.2446 19.997 21.0888 19.4763 21.6095C18.9556 22.1302 18.1113 22.1302 17.5906 21.6095L12.924 16.9428C12.4033 16.4221 12.4033 15.5779 12.924 15.0572L17.5906 10.3905C18.1113 9.86983 18.9556 9.86983 19.4763 10.3905Z"
        fill="#4A154B"
      />
    </svg>
  );
};

export default BackArrowIcon;
