import { createSlice } from '@reduxjs/toolkit';


const slice = createSlice({
    name: 'childCompanyHeader',
    initialState: {
        childCompanyHeader: {
            refecthCompaniesList: '',
        }
    },
    reducers: {
        setChildCompanyHeader: (state, { payload }) => {
            state.childCompanyHeader = { ...state?.childCompanyHeader, ...payload };
        },
        resetChildCompanyHeader: (state) => {
            state.childCompanyHeader = {};
        },
    },
});

export const { setChildCompanyHeader, resetChildCompanyHeader } = slice?.actions;

export default slice?.reducer;

export const getChildCompanyHeaderState = (state) => state?.childCompanyHeader?.childCompanyHeader;