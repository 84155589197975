import React from 'react';
import { useTheme } from 'styled-components';

const BriefCaseColorIcon = ({
  size = 17,
  color = 'currentColor',
  ...props
}) => {
  const theme = useTheme();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M21.59 6.98002C20.74 6.04002 19.32 5.57002 17.26 5.57002H17.02V5.53002C17.02 3.85002 17.02 1.77002 13.26 1.77002H11.74C7.98004 1.77002 7.98004 3.86002 7.98004 5.53002V5.58002H7.74004C5.67004 5.58002 4.26004 6.05002 3.41004 6.99002C2.42004 8.09002 2.45004 9.57002 2.55004 10.58L2.56004 10.65L2.63749 11.4633C2.65176 11.6131 2.73242 11.7484 2.85831 11.8307C3.09812 11.9877 3.49946 12.2464 3.74004 12.38C3.88004 12.47 4.03004 12.55 4.18004 12.63C5.89004 13.57 7.77004 14.2 9.68004 14.51C9.77004 15.45 10.18 16.55 12.37 16.55C14.56 16.55 14.99 15.46 15.06 14.49C17.1 14.16 19.07 13.45 20.85 12.41C20.91 12.38 20.95 12.35 21 12.32C21.3968 12.0958 21.8083 11.8195 22.1835 11.5489C22.2965 11.4673 22.3688 11.3413 22.3842 11.2028L22.4 11.06L22.45 10.59C22.46 10.53 22.46 10.48 22.47 10.41C22.55 9.40002 22.53 8.02002 21.59 6.98002ZM13.59 13.83C13.59 14.89 13.59 15.05 12.36 15.05C11.13 15.05 11.13 14.86 11.13 13.84V12.58H13.59V13.83ZM9.41004 5.57002V5.53002C9.41004 3.83002 9.41004 3.20002 11.74 3.20002H13.26C15.59 3.20002 15.59 3.84002 15.59 5.53002V5.58002H9.41004V5.57002Z"
        fill={theme?.dark ? 'white' : color}
      />
      <path
        d="M21.3733 13.7341C21.7269 13.5659 22.1342 13.8462 22.0988 14.2361L21.7398 18.19C21.5298 20.19 20.7098 22.23 16.3098 22.23H8.68984C4.28984 22.23 3.46984 20.19 3.25984 18.2L2.91913 14.4521C2.88409 14.0667 3.28205 13.7867 3.63468 13.9463C4.7741 14.4618 6.87724 15.3764 8.17641 15.7166C8.34072 15.7597 8.47361 15.8773 8.54556 16.0311C9.15253 17.3292 10.469 18.02 12.3698 18.02C14.252 18.02 15.585 17.3026 16.194 16.0013C16.266 15.8474 16.3991 15.7298 16.5635 15.6865C17.943 15.3235 20.1816 14.3012 21.3733 13.7341Z"
        fill={theme?.dark ? 'white' : color}
      />
    </svg>
  );
};

export default BriefCaseColorIcon;
