import React from 'react';

const OccyColoredIcon = () => {
  return (
    <svg
      width="54"
      height="48"
      viewBox="0 0 54 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1_2383)">
        <path
          d="M22.2711 47.5339C23.6913 47.8156 25.1562 47.9752 26.6544 47.9974C37.0888 48.1416 46.0338 41.7188 49.581 32.6191H50.2454C51.3691 32.6191 52.3769 31.9516 52.8228 30.927C53.398 29.6007 54 27.3873 54 23.9142C54 20.4412 53.4002 18.2278 52.8228 16.9015C52.3769 15.8769 51.3669 15.2094 50.2454 15.2094H49.5832C46.4195 7.08996 38.9594 1.1041 29.9787 0.00850139C29.4726 -0.0535972 28.9754 0.210322 28.7547 0.66719C28.4403 1.31701 27.7737 1.765 27 1.765C26.2263 1.765 25.5597 1.31701 25.2453 0.66719C25.0246 0.210322 24.5274 -0.0535972 24.0213 0.00850139C15.0429 1.1041 7.58274 7.08996 4.41899 15.2071H3.75458C2.63088 15.2071 1.62312 15.8747 1.17721 16.8993C0.601982 18.2256 0 20.4389 0 23.912C0 27.3851 0.599752 29.5985 1.17721 30.9247C1.62312 31.9494 2.63311 32.6169 3.75458 32.6169H4.43683C7.40661 40.1641 14.1064 45.9082 22.2689 47.5317H22.2711V47.5339Z"
          fill="#EC008C"
        />
        <path
          d="M27.0402 42.737C36.8996 42.737 44.8923 36.1835 44.8923 28.0994C44.8923 20.0154 36.8996 13.4619 27.0402 13.4619C17.1807 13.4619 9.18805 20.0154 9.18805 28.0994C9.18805 36.1835 17.1807 42.737 27.0402 42.737Z"
          fill="white"
        />
        <path
          d="M17.9546 24.644C15.5712 24.644 13.6404 26.5647 13.6404 28.9355C13.6404 31.3063 15.5712 33.227 17.9546 33.227C20.338 33.227 22.2688 31.3063 22.2688 28.9355C22.2688 26.5647 20.338 24.644 17.9546 24.644ZM19.415 28.5208C18.7996 28.5208 18.3025 28.024 18.3025 27.4141C18.3025 26.8042 18.8019 26.3074 19.415 26.3074C20.0281 26.3074 20.5276 26.8042 20.5276 27.4141C20.5276 28.024 20.0281 28.5208 19.415 28.5208Z"
          fill="#EC008C"
        />
        <path
          d="M36.4622 24.644C34.0788 24.644 32.148 26.5647 32.148 28.9355C32.148 31.3063 34.0788 33.227 36.4622 33.227C38.8456 33.227 40.7764 31.3063 40.7764 28.9355C40.7764 26.5647 38.8456 24.644 36.4622 24.644ZM38.063 28.5208C37.4477 28.5208 36.9505 28.024 36.9505 27.4141C36.9505 26.8042 37.4499 26.3074 38.063 26.3074C38.6762 26.3074 39.1756 26.8042 39.1756 27.4141C39.1756 28.024 38.6762 28.5208 38.063 28.5208Z"
          fill="#EC008C"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2383">
          <rect width="54" height="48" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default OccyColoredIcon;