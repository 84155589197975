import { Formik } from 'formik';
import React from 'react';
import { Form, Modal } from 'react-bootstrap';
import * as Yup from 'yup';
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import { countryCodes } from '../../../candidates/creation/components/CandidateLIstingUtils';
import { findPhoneNumbersInText } from 'libphonenumber-js'
import { useUpdateChildCompanyMutation, useAddChildCompanyMutation } from '../childCompaniesApiSlice';
import { toast } from 'react-toastify';
import StyledToolTip from '../../../../app/components/styledComponents/StyledToolTip';
import InfoIcon from '../../../../app/components/svgIcons/InfoIcon';
import { getChildCompanyState } from '../ChildCompanyDataSlice';
import { useSelector } from 'react-redux';
import styled, { useTheme } from 'styled-components';

const AddCompanyModal = ({
  title,
  show,
  onHide,
  editChildCompanyData,
  editCompanyModal,
  mobileNumber,
}) => {
  const themes = useTheme();
  // const refecthCompaniesList = useContext(JobContext);
  const { refetchCompanies } = useSelector(getChildCompanyState);
  // const { refecthCompaniesList } = useSelector(getChildCompanyHeaderState);
  const [updateChildCompany, { isLoading: updateLaoding }] = useUpdateChildCompanyMutation();
  const [addChildCompany, { isLoading: addChildCompanyLoading }] = useAddChildCompanyMutation();

  // const extractSubdomain = (obj, address) => {
  //   const regex = new RegExp(`^(\\w+)\\.${obj}$`);
  //   const match = address?.match(regex);
  //   return match ? match[1] : null;
  // }
  const handleSubmit = async (values) => {
    let data = {
      telephone: values.mobileCountryCode + values.telephone,
      companyName: values.companyName,
      id: editChildCompanyData ? editChildCompanyData.id : undefined,
      statusId: editChildCompanyData ? editChildCompanyData.statusId : undefined
    }
if(!editCompanyModal){
      data = {...data, careerSiteSubDomain:values?.careerSiteUrl ? values?.careerSiteUrl : ''};
}
    let apiCall = addChildCompany;
    if (editChildCompanyData) {
      data = { ...data, companyId: editChildCompanyData.id }
      apiCall = updateChildCompany;
    } else {
      apiCall = addChildCompany;
    }
    apiCall(data).unwrap().then((res) => {
      toast.success(res?.message || 'Child company added successfully');
      // refecthCompaniesList();
      refetchCompanies();
      onHide();
    });
  };

  const addCompanySchema = Yup.object().shape({
    companyName: Yup.string().required('Required'),
    telephone: Yup.string().when('mobileCountryCode', (mobileCountryCode, schema, val) => val?.parent?.telephone && schema
      .test('is-valid-phone', 'Invalid Number',
        (value) => findPhoneNumbersInText(`${mobileCountryCode}${value}`)?.length > 0 && !value?.includes('+'))).required('Required'),
    mobileCountryCode: Yup.string().required('Required').nullable(),
  });
  return (
    <Modal show={show} backdrop="static" centered onHide={onHide} size={'lg'}>
      <Formik
        initialValues={{
          companyName: editCompanyModal ? editChildCompanyData?.name : '',
          telephone: editCompanyModal ? mobileNumber[0]?.number?.nationalNumber : '',
          careerSiteUrl: editCompanyModal ?  editChildCompanyData?.cpCompanySetting?.domain : '',
          mobileCountryCode: editCompanyModal && mobileNumber[0]?.number?.countryCallingCode ? `+${mobileNumber[0]?.number?.countryCallingCode}` : '+44'
        }}
        validationSchema={addCompanySchema}
        onSubmit={handleSubmit}
      >
        {({ handleSubmit }) => (
          <Form onSubmit={handleSubmit}>
            <Modal.Header
              closeButton
              className="border-bottom-0 pb-0 align-items-baseline"
            >
              <Modal.Title
                as="h4"
                className="flex-grow-1 px-0 cursor-pointer flex-center pt-md-1 pb-md-3"
              >
                {editCompanyModal ? 'Edit Company':'Add Company'}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="d-flex  flex-column bg-body rounded">
              <PortletBody className="p-2 w-100">
                <PortletBody className={'p-2 w-100'}>
                  <div>
                    <h5>Company name</h5>
                    <FormikInputField
                      id="companyName"
                      name="companyName"
                      placeholder="Enter company name"
                    />
                  </div>
                  <div className="mt-4">
                    <h5>Contact</h5>
                    <div className='row'>
                      <div className="col-3 pe-1">
                        <div className="mb-3">
                          <FormikSelect
                            className="me-0 pe-0"
                            name={`mobileCountryCode`}
                            id={`mobileCountryCode`}
                            placeholder="+0"
                            options={countryCodes}
                          />

                        </div>
                      </div>
                      <div className="col-9 ps-0">
                        <div className="mb-3">
                          <FormikInputField
                            type="tel"
                            name={`telephone`}
                            id={`telephone`}
                            placeholder="Enter telephone number"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <h5 className='d-flex pb-2'>Career site URL
                      <StyledToolTip
                        title={
                          'Choose a name for your career website. This URL can then be used to list jobs and for candidate applications'
                        }
                        className="mt-2"
                        placement="top"
                      >
                        <InfoIcon />
                      </StyledToolTip>
                    </h5>
                    <div className='d-flex align-items-end'><FormikInputField
                      id="careerSiteUrl"
                      name="careerSiteUrl"
                      placeholder="Sub domain name"
                      disabled={editCompanyModal}
                    />
                      <span className='w-50 px-3 fs-5'>{process.env.REACT_APP_CAREER_PAGE_DOMAIN_URL} </span></div>
                  </div>
                  <StyledNote className='py-3'>By creating a new company, you agree to our
                      <a color={themes.primary} href='/policies' target="_blank" className='text-decoration-none'> Terms & Conditions</a> and acknowledge our  <a color={themes.primary} href='/policies' target="_blank" className='text-decoration-none'>Privacy Policy</a>.
                  </StyledNote>
                </PortletBody>
              </PortletBody>
              <div className="d-flex justify-content-end mt-3">
                <div className="d-flex gap-3">
                  <StyledButton
                    variant={'white'}
                    className="mb-2 border-0 fw-bold"
                    onClick={onHide}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton
                    className="mb-2"
                    type="submit"
                    disabled={addChildCompanyLoading ? addChildCompanyLoading : updateLaoding}
                    loading={addChildCompanyLoading ? addChildCompanyLoading : updateLaoding}
                  >
                    {editCompanyModal ? 'Update' : 'Add'}
                  </StyledButton>
                </div>
              </div>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

const StyledNote = styled.div`
font-weight: 500;
font-size: 16px;
`;
export default AddCompanyModal;
