import { createSlice } from '@reduxjs/toolkit';


const slice = createSlice({
    name: 'document',
    initialState: {
        document: {},
    },
    reducers: {
        setDocument: (state, { payload: { values } }) => {
            state.document = { ...state.document, ...values };
        },
    },
});

export const { setDocument } = slice.actions;

export default slice.reducer;

export const getDocument = (state) => state.document.document;