import React from 'react';

const InboxIcon = ({ size = 20, color = 'currentColor',...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M17 2H7C4 2 2 3.5 2 7V14C2 17.5 4 19 7 19H17C20 19 22 17.5 22 14V7C22 3.5 20 2 17 2ZM17.47 8.09L14.34 10.59C13.68 11.12 12.84 11.38 12 11.38C11.16 11.38 10.31 11.12 9.66 10.59L6.53 8.09C6.21 7.83 6.16 7.35 6.41 7.03C6.67 6.71 7.14 6.65 7.46 6.91L10.59 9.41C11.35 10.02 12.64 10.02 13.4 9.41L16.53 6.91C16.85 6.65 17.33 6.7 17.58 7.03C17.84 7.35 17.79 7.83 17.47 8.09Z"
        fill={color}
      />
    </svg>
  );
};

export default InboxIcon;
