import React, { useRef } from 'react';
import { ErrorMessage, FastField } from 'formik';
import Proptypes from 'prop-types';
import { formErrorMessage } from './FormErrorMessage';
import styled from 'styled-components';

const FormikUploadField = ({ icon, name, forms, maxChar, ...props }) => {
  const fileref = useRef(null);
  return (
    <FastField name={name}>
      {({ field, form: { touched, errors, submitCount, ...rest } }) => (
        <>
          <InputStyled {...props}>
            <input
              {...field}
              isInvalid={
                submitCount && touched[field.name] && errors[field.name]
              }
              id={props.id}
              {...props}
              readOnly
            />
            <input ref={fileref} className="inputClass" hidden type="file" />
            <span className="icon" onClick={() => fileref.current.click()}>
              {icon && icon}
            </span>
          </InputStyled>
          {submitCount > 0 && (
            <ErrorMessage name={field.name} render={formErrorMessage} />
          )}
        </>
      )}
    </FastField>
  );
};

FormikUploadField.propTypes = {
  name: Proptypes.string.isRequired,
  placeholder: Proptypes.string,
  type: Proptypes.string,
  as: Proptypes.string,
  rows: Proptypes.string,
};

const InputStyled = styled.div`
  max-width: ${({ $maxWidth }) => ($maxWidth ? $maxWidth : '50em')};
  font-size: 12px;
  width: 100%;
  border-radius: 8px;
  border: 1.5px solid ${({ theme }) => theme.inputBorder};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.inputColor} !important;
  input {
    padding: 8px 14px;
    border: 0;
    width: 100%;
    margin-left: ${({ $marginLeft }) => ($marginLeft ? $marginLeft : '10px')};
    font-weight: 600;
    border-right: 1.5px solid ${({ theme }) => theme.primaryLight2};
    &:valid {
      background-image: none;
    }
    &:focus {
      box-shadow: none;
      outline: none;
      border: 0;
      border-right: 1.5px solid ${({ theme }) => theme.primaryLight2};
    }
    &::placeholder {
      color: ${({ theme }) => theme.inputPlaceHolder};
      opacity: 1;
    }
  }
  .icon {
    margin: 8px 15px;
    cursor: pointer;
  }
`;
export default React.memo(FormikUploadField);
