import { apiSlice } from '../../app/services/apiSlice';
import { shortQuestions } from './assets/formUtils';

const FORM_URL = 'forms/';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addForm: builder.mutation({
      query: (data) => ({
        url: `${FORM_URL}`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    getPublicFormByToken: builder.query({
      query: (params) => ({
        url: `public/${FORM_URL}application-form/?token=${params}`,
      }),
    }),
    getQuestionnaireForms: builder.mutation({
      query: (data) => ({
        url: `jobs/${data?.jobId}/questionnaire-forms/${data?.applicationId}`,
      }),
    }),
    submitQuestionnaireForms: builder.mutation({
      query: (data) => ({
        url: `${FORM_URL}app-questionnaire-form/submit`,
        method: 'POST',
        body: data,
      }),
    }),
    getCandidateQuestionnaireForms: builder.mutation({
      query: (candidateId) => ({
        url: `${FORM_URL}app-questionnaire-forms/candidate/${candidateId}`,
      }),
    }),
    getCandidateTeamQuestionnaireForms: builder.mutation({
      query: (candidateId) => ({
        url: `${FORM_URL}app-questionnaire-forms/candidate/${candidateId}/by-team`,
      }),
    }),
    getApplicationForms: builder.query({
      query: (params) => ({
        url: `${FORM_URL}application-forms`,
        params: { ...params },
      }),
    }),
    getApplicationFormsDetail: builder.mutation({
      query: (params) => ({
        url: `${FORM_URL}application-forms`,
        method: 'GET',
        params: {...params},
      }),
    }),
    getCandidateForm: builder.query({
      query: (params) => ({
        url: `${FORM_URL}application-forms/candidate/${params.id}`,
        params: { ...params },
      }),
    }),
    getCustomFormById: builder.query({
      query: (id) => ({
        url: `${FORM_URL}${id}/custom-form`,
      }),
      transformResponse: (responseData) => {
        return {
          ...responseData,
          formQuestions: responseData?.formQuestions?.map(
            (q) =>
              ({
                ...q,
                customFormId: responseData?.id,
              } || []),
          ),
          compFormSetting: { ...responseData?.compFormSettingId } || {},
        };
      },
    }),
    getDeiForm: builder.query({
      query: () => ({
        url: `${FORM_URL}dei/form`,
      }),
      transformResponse: (responseData) => {
        return {
          ...responseData,
          formQuestions: responseData?.formQuestions?.map(
            (q) =>
              ({
                ...q,

                customFormId: responseData?.id,
                isSensitive: true,
              } || []),
          ),
          options:
            responseData?.formQuestions?.map((d) => ({
              label:
                shortQuestions?.find((sq) => sq.sectionOrder === d.sectionOrder)
                  ?.shortTitle || '',
              value: d.id,
            })) || [],
          compFormSetting: { ...responseData?.compFormSettingId } || {},
        };
      },
    }),
    submitForm: builder.mutation({
      query: (data) => ({
        url: `public/${FORM_URL}application-form/?token=${data.token}`,
        method: 'POST',
        body: data.formData,
      }),
    }),
    editForm: builder.mutation({
      query: (data) => ({
        url: `${FORM_URL}${data?.id}`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),

    deleteForm: builder.mutation({
      query: (id) => ({
        url: `${FORM_URL}${id}`,
        method: 'DELETE',
      }),
    }),

    deleteBanner: builder.mutation({
      query: (params) => ({
        url: `media/${params}`,
        method: 'DELETE',
      }),
    }),


    duplicateForm: builder.mutation({
      query: (data) => ({
        url: `${FORM_URL}duplicate`,
        method: 'POST',
        body: {
          ...data
        },
      }),
    }),

    getCustomFormOverView: builder.query({
      query: (id) => ({
        url: `${FORM_URL}${id}/overview`,
      }),
      transformResponse: (responseData) => {
        responseData = responseData?.data;
        return responseData || {};
      },
    }),
    updateFormData: builder.mutation({
      query: (data) => ({
        url: `${FORM_URL}custom-form/attributes`,
        method: 'PUT',
        body: { ...data },
      }),
    }),
    linkQuestionnaireFormToJob: builder.mutation({
      query: (data) => ({
        url: `${FORM_URL}questionnaire-form`,
        method: 'POST',
        body: { ...data },
      }),
    }),
    getLinkedQuestionnaireForms: builder.query({
      query: (id) => ({
        // url: `${FORM_URL}questionnaire-forms/${id}`,
        url: `jobs/${id}/questionnaire-forms`,
      }),
    }),
    deleteQuestionnaireFormToJob: builder.mutation({
      query: (id) => ({
        url: `${FORM_URL}questionnaire-form/${id}`,
        method: 'DELETE',
      }),
    }),
    getPublicDeiForm: builder.mutation({
      query: (params) => ({
        url: `public/${FORM_URL}dei/application-form/?token=${params}`,
      }),
    }),
    submitDeiForm: builder.mutation({
      query: (data) => ({
        url: `public/${FORM_URL}dei/application-form/?token=${data.token}`,
        method: 'POST',
        body: data.formData,
      }),
    }),
    declineDeiFormSubmission: builder.mutation({
      query: (params) => ({
        url: `public/${FORM_URL}dei/declined/?token=${params}`,
        method: 'POST',
      }),
    }),
  }),
});

export const {
  useDeleteBannerMutation,
  useAddFormMutation,
  useGetPublicFormByTokenQuery,
  useSubmitFormMutation,
  useGetApplicationFormsQuery,
  useGetApplicationFormsDetailMutation,
  useGetCandidateFormQuery,
  useGetCustomFormByIdQuery,
  useEditFormMutation,
  useDeleteFormMutation,
  useDuplicateFormMutation,
  useGetCustomFormOverViewQuery,
  useUpdateFormDataMutation,
  useLinkQuestionnaireFormToJobMutation,
  useGetLinkedQuestionnaireFormsQuery,
  useDeleteQuestionnaireFormToJobMutation,
  useGetQuestionnaireFormsMutation,
  useGetCandidateQuestionnaireFormsMutation,
  useGetCandidateTeamQuestionnaireFormsMutation,
  useSubmitQuestionnaireFormsMutation,
  useGetDeiFormQuery,
  useGetPublicDeiFormMutation,
  useSubmitDeiFormMutation,
  useDeclineDeiFormSubmissionMutation,
} = extendedApiSlice;
