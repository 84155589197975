import AlignIcon from '../../../app/components/svgIcons/AlignIcon';
import CallIcon from '../../../app/components/svgIcons/CallIcon';
import MultipleChoiceIcon from '../../../app/components/svgIcons/MultipleChoiceIcon';
import SectionIcon from '../../../app/components/svgIcons/SectionIcon';
import StarIcon from '../../../app/components/svgIcons/StarIcon';
import TextIcon from '../../../app/components/svgIcons/TextIcon';
import { ControlTypes, uuidv4 } from '../../../utils/helper';
import * as Yup from 'yup';
import { cloneDeep, isArray, maxBy } from 'lodash';
import { STATUSES } from '../../../utils/Statuses';
import FileFormIcon from '../../../app/components/svgIcons/FileFormIcon';
import LongTextIcon from '../../../app/components/svgIcons/LongTextIcon';
import FormRadioButtonIcon from '../../../app/components/svgIcons/FormRadioButtonIcon';
import FormDateButtonIcon from '../../../app/components/svgIcons/FormDateButtonIcon';
import FormEmailIcon from '../../../app/components/svgIcons/FormEmailIcon';

export const formTabs = [
  {
    id: 1,
    title: 'Questions',
    active: true,
  },
  // {
  //   id: 3,
  //   title: 'Responses',
  //   active: false,
  // },
  {
    id: 4,
    title: 'Settings',
    active: false,
  },
];

export const logicTabs = [
  {
    id: 1,
    title: 'Branching',
    active: true,
    tabsDisbaled: true,
  },
  {
    id: 2,
    title: 'Auto Decline',
    active: false,
  },
  {
    id: 3,
    title: 'Scoring',
    active: false,
    tabsDisbaled: true,
  },
];

export const ModalData = [
  {
    id: uuidv4(),
    name: 'Profile',
    fields: [
      { id: uuidv4(), name: 'First Name', icon: <TextIcon /> },
      { id: uuidv4(), name: 'Last Name', icon: <TextIcon /> },
      { id: uuidv4(), name: 'Location', icon: <AlignIcon /> },
      { id: uuidv4(), name: 'Phone', icon: <CallIcon /> },
      { id: uuidv4(), name: 'Phone 2', icon: <CallIcon /> },
      { id: uuidv4(), name: 'Email', icon: <TextIcon /> },
    ],
  },
  {
    id: uuidv4(),
    name: 'Basic',
    fields: [
      { id: uuidv4(), name: 'Multiple Choice', icon: <MultipleChoiceIcon /> },
      { id: uuidv4(), name: 'Short Answer', icon: <AlignIcon /> },
      { id: uuidv4(), name: 'Text Field', icon: <TextIcon /> },
      { id: uuidv4(), name: 'Star Rating', icon: <StarIcon /> },
      { id: uuidv4(), name: 'Radio Button', icon: <MultipleChoiceIcon /> },
      { id: uuidv4(), name: 'Section', icon: <SectionIcon /> },
    ],
  },
  {
    id: uuidv4(),
    name: 'Advanced',
    fields: [
      { id: uuidv4(), name: 'Experience', icon: <AlignIcon /> },
      { id: uuidv4(), name: 'Education Background', icon: <StarIcon /> },
      { id: uuidv4(), name: 'Country', icon: <MultipleChoiceIcon /> },
    ],
  },
];

export const createFormSchema = {
  title: Yup.string().required('Required'),
  type: Yup.number().required('Required'),
};

export const formTypes = {
  CANDIDATE: 1,
  INTERNAL: 4,
};

export const formStatuses = {
  SUBMITTED: 45,
  SENT: 16,
  VIEWED: 13,
  PARTIALLY_COMPLETED: 1,
};

export const renderIcon = (type) => {
  switch (type) {
    case ControlTypes.CHECKBOX:
      return <MultipleChoiceIcon size={24} />;
    case ControlTypes.TEXT_INPUT:
    case ControlTypes.NUMBER_INPUT:
    case ControlTypes.TEXTAREA:
    case ControlTypes.EMAIL:
    case ControlTypes.DATE:
      return <TextIcon size={24} />;
    case ControlTypes.FILE_UPLOAD:  
    return <FileFormIcon size={22} color='#292D32'/>  
    case ControlTypes.DROPDOWN:
      return <AlignIcon size={24} color='currentColor' />;

    default:
      return <MultipleChoiceIcon size={24} />;
  }
};

export const getSectionOrder = (questions = []) =>
  questions?.length ? maxBy(questions, 'sectionOrder')?.sectionOrder + 1 : 1;

export const basicControlsList = [
  {
    sectionLabel: 'Multiple Choice',
    sectionType: ControlTypes.CHECKBOX,
    sectionOptions: {
      allowMultiple: true,
      opts: [{ label: undefined }],
    },
    icon: MultipleChoiceIcon,
  },
  // {
  //   sectionLabel: 'Heading',
  //   sectionType: ControlTypes.HEADING,
  //   icon: HeadingIcon,
  // },
  // {
  //   sectionLabel: 'Sub Heading',
  //   sectionType: ControlTypes.SUB_HEADING,
  //   icon: HeadingIcon,
  // },
  {
    sectionLabel: 'Short Answer',
    sectionType: ControlTypes.TEXT_INPUT,
    icon: TextIcon,
  },
  {
    sectionLabel: 'Long Answer',
    sectionType: ControlTypes.TEXTAREA,
    icon: LongTextIcon,
  },
  {
    sectionLabel: 'Number Input',
    sectionType: ControlTypes.NUMBER_INPUT,
    icon: TextIcon,
  },
  
  // {
  //   title: 'Paragraph',
  //   sectionType: ControlTypes.PARAGRAPH,
  //   icon: ParagraphIcon,
  // },
  {
    sectionLabel: 'Radio Button',
    sectionType: ControlTypes.RADIO,
    sectionOptions: {
      allowMultiple: true,
      opts: [{ label: undefined }],
    },
    icon: FormRadioButtonIcon,
  },
  {
    sectionLabel: 'Dropdown',
    sectionType: ControlTypes.DROPDOWN,
    sectionOptions: {
      opts: [{ label: undefined }],
    },
    icon: MultipleChoiceIcon,
  },
  {
    sectionLabel: 'Email',
    sectionType: ControlTypes.EMAIL,
    icon: FormEmailIcon,
  },
  {
    sectionLabel: 'Date',
    sectionType: ControlTypes.DATE,
    date: new Date(),
    icon: FormDateButtonIcon,
  },
  {
    sectionLabel: 'File Upload',
    sectionType: ControlTypes.FILE_UPLOAD,
    icon: FileFormIcon,
  },
  {
    sectionLabel: 'Yes/No',
    sectionType: ControlTypes.YES_NO,
    sectionOptions: {
      allowMultiple: true,
      opts: [{ label: 'Yes'}, {label: 'No'}],
    },
    icon: MultipleChoiceIcon,
  },
];
export const groups = [
  {plabel: "White"},
  {plabel: "Mixed/Multiple ethnic groups"},
  {plabel: "Asian/Asian British"},
  {plabel: "Black/Black British/Caribbean/African"},
  {plabel: "Other ethnic group"},
  {plabel: "Not Disclosed"},
  ];
export const advancedControlsList = [];

export const getActiveQuestionAndSection = (form, question, section) => {
  let activeQuestion, activeSection;
  if (section) {
    const sectionIndex = form?.formQuestions?.findIndex(
      (q) => q?.index === section?.index,
    );
    if (sectionIndex !== -1) {
      activeSection = section?.index;
      activeQuestion = form?.formQuestions[sectionIndex]?.controls?.find(
        (c) => c.index === question?.index,
      )?.index;
    }
  } else {
    activeQuestion = form?.formQuestions?.find(
      (q) => q?.index === question?.index,
    )?.index;
  }
  return { activeQuestion, activeSection };
};

export const getControlAndSection = (
  formQuestions,
  activeQuestion,
  activeSection,
) => {
  const _activeQuestion = formQuestions?.find(
    (q) => q.index === activeQuestion && q.statusId !== STATUSES.DELETE,
  );
  const _activeSection = formQuestions?.find(
    (s) => s.index === activeSection && s.statusId !== STATUSES.DELETE,
  );
  const control = _activeSection?.controls?.find(
    (c) => c.index === activeQuestion && c.statusId !== STATUSES.DELETE,
  );
  return control
    ? { control, section: _activeSection }
    : { control: _activeQuestion };
};

export const getControlAndSectionIndex = (
  formQuestions,
  activeQuestion,
  activeSection,
) => {
  const _activeQuestionIndex = formQuestions?.findIndex(
    (q) => q.index === activeQuestion && q.statusId !== STATUSES.DELETE,
  );
  const _activeSectionIndex = formQuestions?.findIndex(
    (s) => s.index === activeSection && s.statusId !== STATUSES.DELETE,
  );
  const controlIndex =
    formQuestions[_activeSectionIndex] &&
    formQuestions[_activeSectionIndex]?.controls?.findIndex(
      (c) => c.index === activeQuestion && c.statusId !== STATUSES.DELETE,
    );
  return activeSection
    ? { controlIndex, sectionIndex: _activeSectionIndex }
    : { controlIndex: _activeQuestionIndex };
};

export const handleUpdateControl = (form, updateData, isReset, controlIndex, sectionIndex ) => {
  // const { controlIndex, sectionIndex } = getControlAndSectionIndex(
  //   form?.formQuestions,
  //   form?.activeQuestion,
  //   form?.activeSection,
  // );

  if (sectionIndex === undefined && controlIndex === undefined) {
    console.error('Invalid activeIndex provided.');
    return;
  }
  const updatedForm = cloneDeep(form);

  if (sectionIndex >= 0) {
    const section = updatedForm?.formQuestions[sectionIndex];

    if (!section || !section.controls || !section.controls[controlIndex]) {
      console.error('Invalid controlIndex or sectionIndex provided.');
      return;
    }

    const updatedControls = [...section.controls];
    updatedControls[controlIndex] = {
      ...(!isReset && updatedControls[controlIndex]),
      ...updateData,
    };
    updatedForm.formQuestions[sectionIndex] = {
      ...section,
      controls: updatedControls,
    };
  } else {
    const control = updatedForm?.formQuestions[controlIndex];

    if (!control) {
      console.error('Invalid controlIndex provided.');
      return;
    }

    updatedForm.formQuestions[controlIndex] = {
      ...(!isReset && control),
      ...updateData,
    };
  }

  return updatedForm;
};

export const getNestedControls = (questions) => {
  if (!isArray(questions)) {
    return [];
  }
  const formQuestions = questions?.reduce((acc, curr) => {
    if (curr?.controls) {
      acc = [...acc, ...curr.controls];
    } else {
      acc.push(curr);
    }
    return acc;
  }, []);

  return formQuestions?.filter(
    (question) =>
      question.sectionType !== ControlTypes.SECTION &&
      question.statusId !== STATUSES.DELETE,
  );
};

export const shortQuestions = [
  {
    sectionOrder: 1,
    shortTitle: 'Ethnicity',
  },
  {
    sectionOrder: 2,
    shortTitle: 'Gender',
  },
  {
    sectionOrder: 3,
    shortTitle: 'Disability',
  },
  {
    sectionOrder: 4,
    shortTitle: 'Age',
  },
  {
    sectionOrder: 5,
    shortTitle: 'Nationality',
  },
  {
    sectionOrder: 6,
    shortTitle: 'Sexual Orientation',
  },
  {
    sectionOrder: 7,
    shortTitle: 'Religion',
  },
];



export const getIconBySectionType = (sectionType) => basicControlsList?.find(item=> item?.sectionType===sectionType)?.icon || null;

export const getControlLabelBySectionType = (sectionType) => basicControlsList?.find(item=> item?.sectionType===sectionType)?.sectionLabel || null;