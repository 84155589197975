import React from 'react'
import { WelcomeSidebarMenu } from './welcomeUtils'
import styled, { useTheme } from 'styled-components'
import { StyledLabel } from '../../assets/styledComponents/Global'
import OccyIcon from '../../app/components/svgIcons/OccyIcon'
import CompanySetupIcon from '../../app/components/svgIcons/CompanySetupIcon'
import JobSettingsIcon2 from '../../app/components/svgIcons/JobSettingsIcon2'
import PersonFillIcon from '../../app/components/svgIcons/PersonFillIcon'
import TickCircleIconOpen from '../../app/components/svgIcons/TickCircleIconOpen'
import CommunicationsIcon from '../../app/components/svgIcons/WizardSvg/CommunicationsIcon'
const SideBarWelcome = ({ setTab, tab }) => {
    const theme = useTheme();
    const getIcon = (id) => {
        return (
            id === 1 ?
                <OccyIcon size={20} color={tab === id ? theme?.occyLogo : theme?.textColor} />
                : id === 2 ?
                    <CompanySetupIcon size={20} color={tab === id ? theme?.primary : theme?.textColor} />
                    : id === 3 ?
                        <JobSettingsIcon2 size={20} color={tab === id ? theme?.primary : theme?.textColor} />
                        : id === 4 ?
                            <CommunicationsIcon size={20} color={tab === id ? theme?.primary : theme?.textColor} />
                            :
                            <PersonFillIcon size={20} color={tab === id ? theme?.primary : theme?.textColor} />
        )
    }


    return (
        <div className='d-flex flex-column '>
            {WelcomeSidebarMenu?.map((item, ind) => (
                <StyledDiv className={`d-flex p-3 rounded mb-3 cursor-pointer ${item?.id === tab && 'mainBg'}`} key={item?.id + ind} onClick={() => setTab(item?.id)} >
                    <div className='position-relative'>
                        {item?.tick &&
                            <StyledIcon>
                                <TickCircleIconOpen fill={theme?.interviewsuccess} color='white' />
                            </StyledIcon>}
                    </div>
                    <div className='d-flex align-items-center gap-2'>
                        {getIcon(item?.id)}
                        <StyledLabel font='Inter' weight='600' size="16px" color={item?.id === tab ? theme?.primary : theme?.lightBoldBlack} className={`cursor-pointer hov`} >
                            {item?.name}
                        </StyledLabel>
                    </div>
                </StyledDiv>
            ))}
        </div>
    )
}

export default SideBarWelcome

const StyledDiv = styled.div`
z-index:1;
padding:16px, 24px, 16px, 24px;
max-width:313px;
min-width:200px;
height:56px;
border:1px solid ${({theme}) => theme?.inputBorder};
background-color:${({theme}) => theme?.bodyBg};

&.mainBg{   
    background-color:${({theme}) => theme?.primaryLight};
    border:1px solid ${({theme}) => theme?.primaryLight};
}
&:hover{
    .hov{
        color:${({theme}) => theme?.primary};
     }
    background-color:${({theme}) => theme?.primaryLight};
    border:1px solid ${({theme}) => theme?.primaryLight};
}`;

const StyledIcon = styled.div`
position:absolute;
top:-25px;
left:260px;
z-index:5;
`