import React from 'react';

const FormExpiryIcon = ({ size = 300, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 308 288"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_4502_131850)">
      <path
        d="M284.718 220.329L298.535 222.488C303.212 216.826 306.436 210.109 307.927 202.919C297.824 211.187 282.46 206.793 270.209 211.307C266.398 212.745 262.96 215.021 260.15 217.967C257.339 220.913 255.227 224.453 253.971 228.326L247.219 235.589C253.331 238.009 259.896 239.074 266.46 238.713C273.024 238.352 279.431 236.572 285.241 233.497C288.484 231.739 291.491 229.58 294.194 227.071C289.359 224.028 284.718 220.329 284.718 220.329Z"
        fill="#E4A876"
      />
      <path
        d="M279.756 196.931L293.085 192.703C294.746 185.55 294.635 178.1 292.763 171C287.408 182.905 271.696 185.823 262.745 195.326C259.976 198.311 257.914 201.881 256.712 205.771C255.511 209.661 255.2 213.772 255.804 217.798L253 227.31C259.55 226.75 265.901 224.777 271.615 221.527C277.329 218.276 282.27 213.826 286.098 208.483C288.216 205.465 289.945 202.191 291.245 198.74C285.56 198.172 279.756 196.931 279.756 196.931Z"
        fill="#E4A876"
      />
      <path
        d="M308 239.989C308 240.06 307.986 240.131 307.959 240.196C307.932 240.262 307.892 240.322 307.842 240.373C307.791 240.423 307.731 240.463 307.665 240.49C307.599 240.517 307.529 240.531 307.458 240.531H0.542455C0.398587 240.531 0.26061 240.474 0.15888 240.372C0.0571505 240.27 0 240.132 0 239.989C0 239.845 0.0571505 239.707 0.15888 239.605C0.26061 239.503 0.398587 239.446 0.542455 239.446H307.458C307.529 239.446 307.599 239.46 307.665 239.487C307.731 239.514 307.791 239.554 307.842 239.605C307.892 239.655 307.932 239.715 307.959 239.781C307.986 239.847 308 239.917 308 239.989Z"
        fill="#CCCCCC"
      />
      <path
        d="M127.178 216.868C181.306 216.868 225.185 173 225.185 118.885C225.185 64.7708 181.306 20.9023 127.178 20.9023C73.0508 20.9023 29.1719 64.7708 29.1719 118.885C29.1719 173 73.0508 216.868 127.178 216.868Z"
        fill="#A69DE4"
      />
      <path
        d="M211.51 118.886C211.52 132.042 208.446 145.017 202.535 156.77C196.624 168.524 188.04 178.729 177.472 186.567C176.765 187.096 176.045 187.611 175.32 188.112C161.188 197.933 144.39 203.197 127.179 203.197C109.968 203.197 93.169 197.933 79.0371 188.112C78.3123 187.611 77.592 187.096 76.8855 186.567C66.3247 178.722 57.7463 168.515 51.8361 156.763C45.9258 145.011 42.8477 132.04 42.8477 118.886C42.8477 105.732 45.9258 92.7608 51.8361 81.0088C57.7463 69.2568 66.3247 59.0502 76.8855 51.2049C77.592 50.6763 78.3123 50.1613 79.0371 49.66C93.1691 39.839 109.968 34.5752 127.179 34.5752C144.39 34.5752 161.188 39.839 175.32 49.66C176.045 50.1613 176.765 50.6763 177.472 51.2049C188.04 59.0433 196.624 69.2485 202.535 81.002C208.446 92.7556 211.52 105.73 211.51 118.886Z"
        fill="white"
      />
      <path
        d="M127.178 195.904C169.725 195.904 204.216 161.422 204.216 118.885C204.216 76.3489 169.725 41.8662 127.178 41.8662C84.6315 41.8662 50.1406 76.3489 50.1406 118.885C50.1406 161.422 84.6315 195.904 127.178 195.904Z"
        fill="#535162"
      />
      <path
        opacity="0.2"
        d="M127.379 42.082C126.211 42.082 125.05 42.1126 123.895 42.164C143.366 43.8745 161.458 52.9207 174.507 67.4697C187.555 82.0187 194.584 100.983 194.17 120.519C193.755 140.056 185.927 158.705 172.273 172.687C158.619 186.67 140.159 194.94 120.633 195.823C122.856 196.016 125.105 196.12 127.379 196.12C147.81 196.12 167.405 188.006 181.853 173.562C196.3 159.118 204.416 139.528 204.416 119.101C204.416 98.6744 196.3 79.0843 181.853 64.6404C167.405 50.1965 147.81 42.082 127.379 42.082V42.082Z"
        fill="black"
      />
      <path
        d="M127.406 36.3975C126.741 36.3986 126.104 36.6631 125.634 37.1329C125.164 37.6027 124.9 38.2396 124.898 38.904V49.8416C124.898 50.5064 125.163 51.144 125.633 51.614C126.103 52.0841 126.741 52.3482 127.406 52.3482C128.071 52.3482 128.708 52.0841 129.178 51.614C129.649 51.144 129.913 50.5064 129.913 49.8416V38.904C129.912 38.2396 129.647 37.6027 129.177 37.1329C128.707 36.6631 128.07 36.3986 127.406 36.3975Z"
        fill="white"
      />
      <path
        d="M177.475 51.2045C176.768 50.6759 176.048 50.1609 175.323 49.6596C174.888 49.6313 174.453 49.7168 174.062 49.9078C173.67 50.0989 173.335 50.3887 173.089 50.7488L166.931 59.786C166.557 60.3354 166.416 61.0108 166.54 61.6638C166.664 62.3168 167.042 62.8937 167.592 63.2678C168.141 63.6419 168.817 63.7825 169.47 63.6585C170.123 63.5346 170.7 63.1564 171.074 62.607L177.233 53.5698C177.467 53.227 177.612 52.831 177.654 52.4179C177.696 52.0048 177.635 51.5877 177.475 51.2045Z"
        fill="white"
      />
      <path
        d="M205.451 92.4117C205.327 92.107 205.145 91.8296 204.914 91.5955C204.683 91.3615 204.408 91.1752 204.105 91.0474C203.801 90.9195 203.476 90.8527 203.147 90.8506C202.818 90.8486 202.492 90.9113 202.187 91.0353L192.049 95.1506C191.434 95.4012 190.943 95.8858 190.685 96.498C190.427 97.1101 190.423 97.7996 190.673 98.4151C190.923 99.0306 191.407 99.5216 192.019 99.7802C192.631 100.039 193.321 100.044 193.937 99.7945L204.075 95.6793C204.69 95.4274 205.18 94.9421 205.438 94.3297C205.696 93.7173 205.701 93.0276 205.451 92.4117Z"
        fill="white"
      />
      <path
        d="M87.4316 59.786L81.2732 50.7488C81.0278 50.3887 80.6927 50.0989 80.301 49.9078C79.9092 49.7168 79.4745 49.6313 79.0396 49.6596C78.3148 50.1609 77.5945 50.6759 76.888 51.2045C76.7278 51.5877 76.666 52.0048 76.7082 52.4179C76.7504 52.831 76.8953 53.227 77.1296 53.5698L83.288 62.607C83.6622 63.1564 84.2393 63.5346 84.8925 63.6585C85.5456 63.7825 86.2212 63.6419 86.7707 63.2678C87.3201 62.8937 87.6985 62.3168 87.8224 61.6638C87.9464 61.0108 87.8058 60.3354 87.4316 59.786Z"
        fill="white"
      />
      <path
        d="M62.3068 95.151L52.1688 91.0357C51.5529 90.7857 50.8628 90.7904 50.2504 91.049C49.638 91.3076 49.1533 91.7987 48.9031 92.4145C48.6529 93.0302 48.6575 93.7201 48.9161 94.3324C49.1746 94.9447 49.6658 95.4293 50.2816 95.6796L60.4196 99.7949C61.0354 100.044 61.7251 100.039 62.3372 99.7806C62.9492 99.5219 63.4335 99.0309 63.6836 98.4154C63.9337 97.8 63.9292 97.1104 63.671 96.4983C63.4128 95.8862 62.9221 95.4016 62.3068 95.151V95.151Z"
        fill="white"
      />
      <path
        d="M127.406 185.423C126.741 185.424 126.104 185.688 125.634 186.158C125.164 186.628 124.9 187.265 124.898 187.929V198.867C124.898 199.532 125.163 200.169 125.633 200.639C126.103 201.109 126.741 201.374 127.406 201.374C128.071 201.374 128.708 201.109 129.178 200.639C129.649 200.169 129.913 199.532 129.913 198.867V187.929C129.912 187.265 129.647 186.628 129.177 186.158C128.707 185.688 128.07 185.424 127.406 185.423Z"
        fill="white"
      />
      <path
        d="M177.233 184.201L171.074 175.164C170.889 174.892 170.652 174.659 170.377 174.479C170.102 174.298 169.793 174.174 169.47 174.112C169.147 174.051 168.814 174.054 168.492 174.121C168.17 174.188 167.864 174.318 167.592 174.503C167.32 174.688 167.087 174.925 166.906 175.2C166.726 175.476 166.601 175.784 166.54 176.107C166.479 176.43 166.482 176.763 166.549 177.085C166.616 177.407 166.746 177.713 166.931 177.985L173.089 187.022C173.335 187.382 173.67 187.672 174.062 187.863C174.453 188.054 174.888 188.14 175.323 188.111C176.048 187.61 176.768 187.095 177.475 186.566C177.635 186.183 177.697 185.766 177.654 185.353C177.612 184.94 177.467 184.544 177.233 184.201Z"
        fill="white"
      />
      <path
        d="M204.076 142.091L193.938 137.976C193.322 137.726 192.632 137.731 192.02 137.989C191.408 138.248 190.923 138.739 190.673 139.355C190.422 139.971 190.427 140.661 190.686 141.273C190.944 141.885 191.435 142.37 192.051 142.62L202.189 146.735C202.805 146.985 203.495 146.98 204.107 146.721C204.719 146.462 205.203 145.971 205.453 145.356C205.703 144.74 205.699 144.051 205.441 143.439C205.182 142.827 204.692 142.342 204.076 142.091V142.091Z"
        fill="white"
      />
      <path
        d="M86.7706 174.504C86.2211 174.13 85.5456 173.989 84.8925 174.113C84.2394 174.237 83.6623 174.615 83.288 175.165L77.1296 184.202C76.8952 184.545 76.7504 184.941 76.7082 185.354C76.666 185.767 76.7278 186.184 76.888 186.567C77.5945 187.096 78.3148 187.611 79.0396 188.112C79.4745 188.14 79.9092 188.055 80.301 187.864C80.6927 187.673 81.0278 187.383 81.2732 187.023L87.4316 177.986C87.6169 177.714 87.7468 177.408 87.8139 177.086C87.8809 176.763 87.8839 176.431 87.8225 176.108C87.7611 175.784 87.6366 175.476 87.4562 175.201C87.2757 174.926 87.0427 174.689 86.7706 174.504Z"
        fill="white"
      />
      <path
        d="M63.6856 139.353C63.5618 139.048 63.3791 138.771 63.1481 138.537C62.917 138.303 62.6421 138.117 62.339 137.989C62.0359 137.861 61.7105 137.794 61.3816 137.792C61.0526 137.79 60.7265 137.853 60.4218 137.977L50.2838 142.092C49.6684 142.343 49.1778 142.827 48.9196 143.439C48.6614 144.051 48.6569 144.741 48.907 145.357C49.1571 145.972 49.6414 146.463 50.2534 146.722C50.8655 146.98 51.5552 146.985 52.171 146.736L62.309 142.621C62.9241 142.369 63.4143 141.884 63.6723 141.271C63.9303 140.659 63.9351 139.969 63.6856 139.353Z"
        fill="white"
      />
      <path
        d="M130.661 117.641C130.893 119.012 130.571 120.418 129.767 121.551C128.963 122.684 127.742 123.453 126.372 123.687L81.883 131.253C81.2043 131.368 80.5096 131.349 79.8384 131.196C79.1672 131.043 78.5327 130.759 77.9712 130.361C77.4097 129.963 76.9321 129.458 76.5657 128.875C76.1994 128.293 75.9514 127.643 75.8359 126.965C75.7205 126.286 75.7399 125.592 75.8929 124.921C76.046 124.25 76.3298 123.615 76.728 123.054C77.1263 122.493 77.6312 122.015 78.2141 121.649C78.7969 121.283 79.4462 121.035 80.1249 120.919L124.614 113.354C125.985 113.122 127.391 113.444 128.525 114.247C129.658 115.051 130.427 116.272 130.661 117.641Z"
        fill="#7F7E94"
      />
      <path
        d="M177.486 166.921C176.847 167.564 175.979 167.927 175.072 167.93C174.166 167.934 173.295 167.578 172.651 166.94L124.759 119.435C124.116 118.796 123.753 117.929 123.75 117.023C123.747 116.117 124.104 115.247 124.742 114.603C125.38 113.96 126.248 113.597 127.154 113.593C128.06 113.589 128.931 113.945 129.575 114.582L177.467 162.087C178.11 162.726 178.473 163.594 178.476 164.5C178.48 165.407 178.124 166.277 177.486 166.921Z"
        fill="#7F7E94"
      />
      <path
        d="M127.18 124.81C130.453 124.81 133.106 122.158 133.106 118.885C133.106 115.613 130.453 112.961 127.18 112.961C123.907 112.961 121.254 115.613 121.254 118.885C121.254 122.158 123.907 124.81 127.18 124.81Z"
        fill="#CCCCCC"
      />
      <path
        d="M86.3626 16.1531C77.2663 30.4507 63.3874 39.9392 46.2712 46.1726C45.933 46.4258 45.5482 46.61 45.1388 46.7146C44.7294 46.8191 44.3034 46.8421 43.8851 46.782C43.4668 46.722 43.0644 46.5802 42.701 46.3647C42.3375 46.1492 42.0201 45.8643 41.7668 45.5262C32.4694 33.1154 34.5688 15.3779 46.8393 5.89463C49.8059 3.60588 53.1977 1.92907 56.8178 0.961558C60.4378 -0.0059557 64.2141 -0.24491 67.9273 0.258576C71.6405 0.762061 75.2167 1.99795 78.4482 3.8945C81.6798 5.79105 84.5023 8.31048 86.752 11.3065L87.0092 11.6498C87.2625 11.9879 87.4467 12.3726 87.5513 12.7819C87.6559 13.1913 87.6788 13.6172 87.6188 14.0353C87.5587 14.4535 87.4169 14.8558 87.2013 15.2191C86.9858 15.5825 86.7008 15.8999 86.3626 16.1531Z"
        fill="#A69DE4"
      />
      <path
        d="M167.606 11.3065C169.856 8.31047 172.679 5.79104 175.91 3.8945C179.142 1.99795 182.718 0.762063 186.431 0.258578C190.144 -0.244908 193.921 -0.00596069 197.541 0.961553C201.161 1.92907 204.553 3.60587 207.519 5.89462C219.79 15.3779 221.889 33.1154 212.592 45.5261C212.338 45.8643 212.021 46.1492 211.657 46.3647C211.294 46.5802 210.892 46.722 210.473 46.782C210.055 46.8421 209.629 46.8191 209.22 46.7146C208.81 46.61 208.425 46.4258 208.087 46.1726C192.198 40.0906 179.167 29.5658 167.996 16.1531C167.313 15.6417 166.861 14.8799 166.74 14.0353C166.618 13.1908 166.838 12.3327 167.349 11.6498L167.606 11.3065Z"
        fill="#A69DE4"
      />
      <path
        d="M163.621 203.775C163.042 204.1 162.615 204.642 162.434 205.281C162.254 205.921 162.334 206.606 162.658 207.186L177.127 233.036C177.452 233.616 177.994 234.044 178.633 234.224C179.273 234.405 179.959 234.324 180.539 233.999C181.119 233.675 181.546 233.133 181.727 232.493C181.908 231.854 181.827 231.169 181.503 230.588L167.033 204.738C166.708 204.159 166.166 203.732 165.526 203.552C164.887 203.371 164.202 203.451 163.621 203.775Z"
        fill="#A69DE4"
      />
      <path
        d="M87.3274 204.738L72.8581 230.589C72.5335 231.169 72.4528 231.854 72.6335 232.494C72.8142 233.133 73.2417 233.675 73.8219 233.999C74.4021 234.324 75.0874 234.405 75.7273 234.224C76.3671 234.044 76.909 233.617 77.2338 233.037L91.7031 207.186C91.864 206.899 91.9667 206.583 92.0054 206.256C92.044 205.929 92.0179 205.598 91.9284 205.281C91.839 204.964 91.688 204.668 91.484 204.409C91.2801 204.151 91.0272 203.935 90.7399 203.774C90.4525 203.614 90.1363 203.511 89.8093 203.472C89.4823 203.434 89.1508 203.46 88.8339 203.549C88.5171 203.639 88.2209 203.79 87.9624 203.994C87.7039 204.198 87.4881 204.451 87.3274 204.738H87.3274Z"
        fill="#A69DE4"
      />
      <path
        d="M180.058 239.655C184.338 239.655 187.807 236.186 187.807 231.908C187.807 227.629 184.338 224.16 180.058 224.16C175.778 224.16 172.309 227.629 172.309 231.908C172.309 236.186 175.778 239.655 180.058 239.655Z"
        fill="#A69DE4"
      />
      <path
        d="M185.527 41.411C189.807 41.411 193.276 37.9423 193.276 33.6635C193.276 29.3847 189.807 25.916 185.527 25.916C181.247 25.916 177.777 29.3847 177.777 33.6635C177.777 37.9423 181.247 41.411 185.527 41.411Z"
        fill="#A69DE4"
      />
      <path
        d="M126.722 23.1815C131.002 23.1815 134.471 19.7128 134.471 15.434C134.471 11.1552 131.002 7.68652 126.722 7.68652C122.442 7.68652 118.973 11.1552 118.973 15.434C118.973 19.7128 122.442 23.1815 126.722 23.1815Z"
        fill="#A69DE4"
      />
      <path
        d="M70.6556 40.4989C74.9354 40.4989 78.4049 37.0302 78.4049 32.7514C78.4049 28.4726 74.9354 25.0039 70.6556 25.0039C66.3757 25.0039 62.9062 28.4726 62.9062 32.7514C62.9062 37.0302 66.3757 40.4989 70.6556 40.4989Z"
        fill="#A69DE4"
      />
      <path
        d="M75.6673 239.655C79.9472 239.655 83.4167 236.186 83.4167 231.908C83.4167 227.629 79.9472 224.16 75.6673 224.16C71.3875 224.16 67.918 227.629 67.918 231.908C67.918 236.186 71.3875 239.655 75.6673 239.655Z"
        fill="#A69DE4"
      />
      <path
        d="M173.979 162.564C174.284 163.042 174.691 163.447 175.17 163.751C175.649 164.056 176.189 164.251 176.752 164.324C177.315 164.397 177.887 164.345 178.428 164.173C178.968 164.001 179.465 163.713 179.882 163.328L192.042 169.842L191.39 162.705L179.943 157.613C179.25 156.955 178.34 156.574 177.385 156.542C176.43 156.51 175.496 156.83 174.761 157.44C174.026 158.05 173.54 158.909 173.396 159.853C173.252 160.798 173.459 161.762 173.979 162.564H173.979Z"
        fill="#FFB7B7"
      />
      <path
        d="M182.84 165.055L187.605 158.479L210.561 169.328L239.128 163.969C240.54 163.704 241.993 163.754 243.384 164.113C244.774 164.473 246.069 165.135 247.175 166.051C248.282 166.967 249.173 168.115 249.786 169.415C250.398 170.714 250.717 172.132 250.72 173.568C250.722 176.163 249.694 178.652 247.863 180.489C246.031 182.327 243.545 183.364 240.951 183.371L208.214 183.432L182.84 165.055Z"
        fill="#E4E4E4"
      />
      <path
        d="M175.501 278.636L170.68 276.331L177.303 256.184L184.418 259.586L175.501 278.636Z"
        fill="#FFB7B7"
      />
      <path
        d="M207.822 275.388C207.471 275.608 207.12 275.823 206.766 276.039C206.062 276.471 205.35 276.896 204.638 277.314C204.28 277.523 203.919 277.732 203.561 277.937V277.948L203.54 277.951L203.533 277.955L202.988 278.089L202.205 276.022L201.844 275.069L201.334 273.72L200.399 271.253L195.477 258.259L203.129 256.372L203.416 257.544H203.42L203.561 258.125L206.235 268.966L206.78 271.182L206.798 271.249L207.128 272.573L207.411 273.72L207.726 275.002L207.822 275.388Z"
        fill="#FFB7B7"
      />
      <path
        d="M253.472 229.757C252.987 230.536 252.491 231.312 251.992 232.08C251.064 233.51 250.111 234.919 249.134 236.307C249.134 236.311 249.13 236.311 249.127 236.314C248.557 236.842 247.927 237.302 247.25 237.684C247.222 237.706 247.19 237.72 247.161 237.738C246.663 238.013 246.133 238.226 245.582 238.371C245.384 238.421 245.182 238.47 244.983 238.52C244.268 238.69 243.56 238.835 242.855 238.963C241.602 239.186 240.362 239.345 239.13 239.444C239.031 239.455 238.931 239.462 238.832 239.469C233.765 239.841 228.831 239.242 223.682 238.261C221.14 237.78 218.544 237.203 215.856 236.605C214.978 236.406 214.096 236.212 213.204 236.017C213.136 236.003 213.066 235.985 212.995 235.971C211.72 235.691 210.424 235.415 209.096 235.142C207.424 234.799 205.7 234.466 203.908 234.151L204.035 236.675C204.141 238.617 204.545 240.531 205.232 242.351C205.997 244.588 206.603 246.359 205.324 250.044L204.708 251.829C205.027 252.715 205.137 253.663 205.028 254.598C204.919 255.534 204.595 256.432 204.081 257.221L203.957 257.408L209.096 270.405L209.429 271.248H206.797L203.561 271.252H198.075L190.16 255.376L189.781 254.619V254.58L190.599 224.071C190.61 223.802 190.631 223.53 190.667 223.26C190.811 222.037 191.197 220.855 191.804 219.783C192.41 218.71 193.223 217.77 194.197 217.015C195.13 216.286 196.199 215.75 197.342 215.437C198.484 215.125 199.677 215.043 200.851 215.195L203.561 215.546L209.096 216.261L212.995 216.767L213.073 216.778L224.571 218.265L225.396 214.901L225.549 214.845L230.39 213.071L247.133 206.935L247.176 206.921L248.277 206.518L248.408 206.73C248.447 206.794 248.486 206.857 248.521 206.921C249.61 208.872 250.496 210.929 251.167 213.06C252.294 216.542 253.222 220.084 253.947 223.671C253.978 223.827 254.007 223.969 254.032 224.096L254.042 224.135C254.053 224.195 254.06 224.234 254.063 224.245C254.449 225.852 254.18 227.814 253.472 229.757Z"
        fill="#544E51"
      />
      <path
        d="M254.181 218.514C254.128 218.287 254.061 218.057 253.99 217.834C253.428 216.145 252.773 214.488 252.028 212.87C251.193 211.029 250.19 209.057 248.997 206.922C248.42 205.889 247.799 204.814 247.134 203.697C246.801 203.138 246.455 202.568 246.097 201.987C246.083 201.96 246.067 201.934 246.051 201.909L245.948 201.739L229.382 203.956L223.213 204.781L222.844 207.854L222.756 208.615C222.752 208.615 222.752 208.615 222.756 208.618L222.508 210.693V210.697L222.178 213.437L222.15 213.433L212.996 212.247L203.562 211.026L198.494 210.371C197.32 210.218 196.127 210.3 194.984 210.612C193.842 210.925 192.773 211.461 191.84 212.191C190.866 212.945 190.053 213.886 189.447 214.959C188.84 216.031 188.454 217.213 188.309 218.436C188.277 218.684 188.256 218.939 188.245 219.19L183.465 230.516C180.604 237.29 178.782 244.456 178.061 251.773L174.555 262.979L175.054 263.025L182.484 263.715L184.446 263.899L188.766 257.594C189.258 256.875 189.726 256.135 190.161 255.378C190.878 254.14 191.516 252.858 192.07 251.54L201.416 229.305C202.138 229.429 202.853 229.547 203.562 229.66C206.837 230.191 209.982 230.624 212.996 230.959C222.795 232.053 231.28 232.138 238.833 231.21C240.955 230.952 243.005 230.612 244.985 230.191C245.711 230.038 246.427 229.874 247.134 229.698C247.301 229.66 247.464 229.621 247.626 229.578C249.09 229.201 250.436 228.462 251.54 227.431C252.644 226.399 253.472 225.107 253.948 223.673C254.516 222.01 254.597 220.221 254.181 218.514Z"
        fill="#544E51"
      />
      <path
        d="M177.04 275.346L171.823 272.846L171.005 272.453L169.699 275.661L160.576 277.668C160.125 277.767 159.717 278.008 159.413 278.355C159.108 278.702 158.923 279.137 158.883 279.597C158.843 280.057 158.952 280.517 159.193 280.911C159.434 281.305 159.794 281.611 160.222 281.786L169.135 285.422L171.965 282.876L171.271 286.293L174.628 287.663L178.307 275.955L177.04 275.346Z"
        fill="#544E51"
      />
      <path
        d="M209.098 270.405L209.431 271.248H209.098V270.405Z"
        fill="#2F2E41"
      />
      <path
        d="M207.279 271.428L201.813 273.321L200.955 273.617L202.284 276.816L197.215 284.66C196.964 285.047 196.843 285.505 196.871 285.966C196.899 286.426 197.073 286.866 197.369 287.221C197.664 287.576 198.066 287.826 198.514 287.937C198.963 288.047 199.434 288.011 199.861 287.835L208.754 284.148L208.973 280.349L210.884 283.265L214.234 281.877L208.608 270.969L207.279 271.428Z"
        fill="#544E51"
      />
      <path
        d="M252.818 223.644C252.493 223.643 252.174 223.564 251.887 223.413L223.043 208.345L222.71 206.981L222.082 202.664L222.091 202.624L224.834 189.98C224.783 189.872 224.678 189.694 224.56 189.493C223.335 187.418 219.608 181.105 226.721 172.412L239.209 157.856L249.927 157.395L250.049 157.549C252.915 161.145 254.848 165.393 255.676 169.916C256.504 174.439 256.201 179.097 254.795 183.475L253.679 203.626L254.876 207.695C255.575 210.087 255.46 212.643 254.547 214.962L254.821 221.551C254.832 221.821 254.788 222.091 254.692 222.344C254.596 222.596 254.451 222.827 254.264 223.023C254.077 223.218 253.852 223.374 253.604 223.48C253.356 223.587 253.088 223.643 252.818 223.644L252.818 223.644Z"
        fill="#E4E4E4"
      />
      <path
        d="M227.797 240.263C228.285 239.974 228.705 239.582 229.025 239.114C229.346 238.645 229.56 238.113 229.652 237.553C229.745 236.993 229.713 236.42 229.56 235.873C229.407 235.327 229.136 234.821 228.766 234.391L235.701 222.468L228.543 222.871L223.055 234.132C222.373 234.801 221.961 235.697 221.896 236.65C221.831 237.603 222.118 238.547 222.702 239.303C223.286 240.058 224.128 240.573 225.067 240.75C226.006 240.927 226.977 240.754 227.797 240.263L227.797 240.263Z"
        fill="#FFB7B7"
      />
      <path
        d="M230.595 231.497L224.188 226.507L235.831 203.947L231.468 175.219C231.253 173.799 231.353 172.349 231.76 170.971C232.168 169.594 232.875 168.324 233.829 167.25C234.783 166.176 235.962 165.326 237.282 164.759C238.603 164.192 240.031 163.923 241.467 163.97C244.06 164.058 246.513 165.172 248.286 167.065C250.06 168.959 251.009 171.479 250.927 174.072L249.849 206.784L230.595 231.497Z"
        fill="#E4E4E4"
      />
      <path
        d="M232.332 151.807V141.204C232.33 137.436 233.825 133.821 236.488 131.154C239.152 128.488 242.766 126.989 246.535 126.986C250.304 126.984 253.92 128.479 256.587 131.141C259.254 133.804 260.753 137.417 260.756 141.186V141.204C263.261 146.422 261.694 148.903 260.756 151.807C260.755 152.313 260.554 152.798 260.196 153.155C259.838 153.513 259.353 153.714 258.848 153.715H234.241C233.735 153.715 233.25 153.513 232.892 153.156C232.534 152.798 232.333 152.313 232.332 151.807Z"
        fill="#2F2E41"
      />
      <path
        d="M241.884 153.026C247.759 153.026 252.522 148.264 252.522 142.39C252.522 136.517 247.759 131.755 241.884 131.755C236.009 131.755 231.246 136.517 231.246 142.39C231.246 148.264 236.009 153.026 241.884 153.026Z"
        fill="#FFB7B7"
      />
      <path
        d="M253.017 145.178C252.921 145.163 252.824 145.142 252.728 145.127C251.068 144.833 249.409 144.544 247.755 144.244L247.602 140.495L245.598 143.854C241.026 145.371 236.748 144.513 232.724 142.621C231.397 141.991 230.109 141.281 228.867 140.495C229.205 138.422 230.003 136.45 231.201 134.724C232.4 132.998 233.969 131.562 235.794 130.521C235.946 130.435 236.093 130.359 236.246 130.283C236.246 130.283 236.247 130.282 236.248 130.282C236.248 130.282 236.249 130.282 236.249 130.281C236.25 130.281 236.25 130.28 236.25 130.279C236.251 130.279 236.251 130.278 236.251 130.278C236.509 130.156 236.773 130.039 237.037 129.933C239.103 129.125 241.356 128.921 243.533 129.343C245.711 129.765 247.724 130.796 249.338 132.317C252.586 135.417 254.047 140.536 253.017 145.178Z"
        fill="#2F2E41"
      />
    </g>
    <defs>
      <clipPath id="clip0_4502_131850">
        <rect width={size} height={size} fill="white" />
      </clipPath>
    </defs>
  </svg>
);
export default FormExpiryIcon;
