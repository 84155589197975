
import theme from "../../../utils/themes";
import darktheme from "../../../utils/darkThemes";




export const CALENDER_EVENT_STATUSES = {
  EVENT: 1,
  INTERVIEW: 2,
  MEETING: 3
};



export const CALENDER_EVENT_COLORS = {
  OTHER: theme.primaryLight,
  INTERVIEW: theme.eventColor2,
  MEETING: theme.eventColor3,
  OCCY: theme.primary,
  EXTERNAL: theme.lightYellow,
  DELEGATED: theme.eventGreen,
};

export const CALENDER_DARK_EVENT_COLORS = {
  OTHER: darktheme.externalEventTileColor,
  INTERVIEW: darktheme.externalEventTileColor,
  MEETING: darktheme.externalEventTileColor,
  OCCY: darktheme.occyEventTileColor,
  EXTERNAL: darktheme.externalEventTileColor,
  DELEGATED: darktheme.delegatedEventTileColor,
};

export  const OC_CALENDAR_TYPE = {
  OFFICE365 : "OFFICE365",
  GOOGLE_CALENDAR : "GOOGLE_CALENDAR"
}