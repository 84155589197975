import React, { useEffect, useRef, useState } from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import moment from 'moment';
import './datePicker.scss';
import styled, { useTheme } from 'styled-components';
import CalenderIcon from '../svgIcons/CalenderIcon';
import { Calendar } from 'react-calendar';
import DropDownIcon from '../svgIcons/DropDownIcon';
import { isArray } from 'lodash';
import StyledToolTip from '../styledComponents/StyledToolTip';

const DatePickerComponent = ({
  date,
  onChange,
  toggleClassName = 'form-control date-picker__toggle d-flex justify-content-between align-items-center input',
  isValid,
  isInvalid,
  custom,
  size,
  color,
  calendarCustom,
  analytics,
  format = 'Do MMMM YY',
  isReadOnly,
  drop,
  isPositioning = true,
  mainClass = '',
  ...props
}) => {
  const theme = useTheme();
  const [state, setState] = useState(
    moment(new Date(date)).isValid() ? new Date(date) : new Date(),
  );
  const [show, isShow] = useState(false);

  const menuRef = useRef();

  const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className={`${toggleClassName} ${isValid ? 'is-valid' : ''} ${
        isInvalid ? 'is-invalid' : ''
      }`}
    >
      <>
        {!calendarCustom && children}
        {custom ? (
          <DropDownIcon className={`ms-2 ${color ? theme?.headingColor : ''}`} size={size || 12} />
        ) : (
          <CalenderIcon color={theme.primaryText} />
        )}
      </>
    </div>
  ));
  // const onToggleHandler = (isOpen, e, metadata) => {
  //   if (!e?.originalEvent?.srcElement?.closest('.react-calendar')) {
  //     isShow(isOpen);
  //   }
  // };
  const CustomMenu = React.forwardRef(
    (
      { style, className, 'aria-labelledby': labeledBy, isPositioning },
      ref,
    ) => {
      return (
        <div
          ref={ref}
          style={isPositioning ? style : { position: 'absolute' }}
          className={`hello ${className}`}
          aria-labelledby={labeledBy}
        >
          <Calendar
            value={state}
            onChange={(item) => {
              setState(item);
              onChange(item);
              isShow(false);
            }}
            next2Label={null}
            prev2Label={null}
            nextLabel={<DropDownIcon rotate="right" />}
            prevLabel={<DropDownIcon rotate="left" />}
            {...props}
          />
          <div className=" d-flex  align-items-center justify-content-end mr-2 mb-2 d-none">
            <Dropdown.Item>Cancel</Dropdown.Item>
            <Dropdown.Item>Ok</Dropdown.Item>
          </div>
        </div>
      );
    },
  );

  useEffect(() => {
    let handler = (e) => {
      if (!menuRef?.current?.contains(e.target)) {
        isShow(false);
      }
    };

    document.addEventListener('mousedown', handler);

    return document.addEventListener('mousedown', handler);
  }, [show]);

  const getViewableDate = (date) => {
    if (isArray(date)) {
      return `${moment(new Date(date[0])).format('Do MMMM YY')} - ${moment(
        new Date(date[1]),
      ).format('Do MMMM YY')}`;
    } else if (moment(new Date(date)).isValid()) {
      return moment(new Date(date)).format(
        analytics ? 'Do MMM YY' : 'Do MMMM YY',
      );
    } else
      return (
        <span className="date-placeholder ">
          {props?.placeholder ?? 'Select Date'}
        </span>
      );
  };

  return (
    <DatePickerStyled
      autoClose={true}
      show={show}
      ref={menuRef}
      custom={custom && custom}
      isreadonly={isReadOnly ? isReadOnly.toString() : undefined}
      drop={drop}
      className={mainClass}
      // onToggle={(isOpen, e, metadata) => onToggleHandler(isOpen, e, metadata)}
    >
      {custom ? (
        <StyledToolTip
          title={props.toolTip}
          // className="mt-2"
          placement="top"
        >
          <Dropdown.Toggle
            id="single-date-picker"
            as={CustomToggle}
            onClick={() => isShow(isReadOnly ? false : !show)}
          >
            {(custom && !calendarCustom) && (
              <CalenderIcon
                className="me-2"
                size={analytics === 'true' ? 14 : 18}
              />
            )}
            {props.children || (
              <span className="date-view date-range m-0">
                {getViewableDate(date)}
              </span>
            )}
          </Dropdown.Toggle>
        </StyledToolTip>
      ) : (
        <Dropdown.Toggle
          id="single-date-picker"
          as={CustomToggle}
          onClick={() => isShow(isReadOnly ? false : !show)}
        >
          {custom && (
            <CalenderIcon className="me-2" color={theme?.primaryText} />
          )}
          {props.children || (
            <span className="date-view m-0">{getViewableDate(date)}</span>
          )}
        </Dropdown.Toggle>
      )}
      <Dropdown.Menu
        as={CustomMenu}
        className="p-0 border-0 shadow-lg"
        isPositioning={isPositioning}
      />
    </DatePickerStyled>
  );
};

DatePickerComponent.propTypes = {
  date: PropTypes.any.isRequired,
  onChange: PropTypes.func.isRequired,
  toggleClassName: PropTypes.string,
  children: PropTypes.any,
  isValid: PropTypes.any,
  isInvalid: PropTypes.any,
};

const DatePickerStyled = styled(Dropdown)`
  .date-picker__toggle {
    padding: ${({ custom }) => (custom ? '5px' : '8px 14px')};
    margin-top: ${({ custom }) => (custom ? '5px' : '')};
    cursor: pointer;
    font-size: 14px;
    border-radius: 8px;
    border: 1.5px solid
      ${({ theme, custom }) => (custom ? theme.borderLight : theme.inputBorder)};
    display: flex;
    align-items: center;
    color: ${({ theme }) => theme.primaryText};
    background: ${({ theme, isreadonly }) =>
      isreadonly ? theme?.dark ? theme?.tagChips : theme?.borderLight : theme?.dark ? theme?.selectBg :'transparent'};
    border-color: ${({ theme, custom }) =>
      custom ? theme.borderLight : theme.inputBorder};

    &.publicForm {
      border: none;
      border-bottom: 1px solid ${({ theme }) => theme.primary};
      border-radius: 0;
      background: transparent;
      color: ${({ theme }) => theme.primary};

      &:hover,
      &:active,
      &:focus {
        border-bottom: 2px solid ${({ theme }) => theme.primary};
        background-color: ${({ theme }) => 'transparent'} !important;
        color: ${({ theme }) => theme.primary} !important;
      }

      &>svg path{
      stroke: ${({ theme }) => theme.primary} !important;
      }

      .date-placeholder {
        color: ${({ theme }) => theme.primary} !important;
      }
    }

    &:after {
      content: none;
    }

    &:focus {
      box-shadow: none !important;
      border-color: ${({ theme }) => theme.primaryLight2} !important;
    }

    &:hover,
    &:active,
    &:focus{
      background-color: ${({ theme, isreadonly }) =>
        isreadonly ?  theme?.dark ? theme?.selectBg : theme.borderLight : theme?.dark ? theme?.selectBg : theme.white} !important;
      color: ${({ theme }) =>  theme?.dark ? theme?.headingColor :theme.inputColor} !important;
    }

     
    .date-view {
      margin: 0 10px;
      cursor: pointer;
    }
    .date-range {
      color: ${({ theme }) => theme.primaryText} !important;
    }
    &.is-invalid {
      border-color: ${({ theme }) => theme.danger} !important;
    }

    //
    // &.is-valid {
    //   border-color: ${({ theme }) => theme.success} !important;
    // }

    .date-placeholder {
      color: ${({ theme }) => theme.inputPlaceHolder} !important;
    }
  }

`;
export default DatePickerComponent;
