import React, { useMemo } from 'react';
import StyledProfileDropDown from '../../components/styledComponents/StyledProfileDropDown';
import {  useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import useAuth from '../../../hooks/useAuth';
import LogoutIcon from '../../components/svgIcons/LogoutIcon';
import {
  removeCredentials,
  removeLicence,
  removeDelegatedUser
} from '../../../features/auth/authSlice';
import { useDispatch } from 'react-redux';
import NotificationDropDown from '../../components/styledComponents/NotificationDropDown';
import { useGetNotificationsQuery } from '../../../features/Setting/Notifications/Apis/notificationApiSlice';
import MailBoxIcon from '../../components/svgIcons/MailBoxIcon';
import { StyledDotsLoader, Wrap } from '../../../assets/styledComponents/Global';
import { useGetUnreadQuery } from '../../../features/mailbox/mailBoxApi';
import StyledDot from '../../components/styledComponents/StyledDot';
import { AllowedFunc } from '../../../midleware/midleware';
import Wallet from '../../components/svgIcons/Wallet.png';
import ChildCompaniesDropdown from './ChildCompaniesDropdown';
import { useGetCompanyCreditsQuery } from '../../../features/job/jobApiSlice';
import { isArray } from 'lodash';
import { useEffect } from 'react';
import { setHeaderCredits } from './HeaderDataSlice';
import WizardIcon from '../../components/svgIcons/WizardSvg/WizardIcon';
import { formateNumber } from '../../../utils/helper';
import { valueToLocaleString } from '../../../features/analytics/assets/analyticsUtil';
import InfoIconOutline from '../../components/svgIcons/InfoIconOutline';
import StyledToolTip from '../../components/styledComponents/StyledToolTip';
import { isEmptyArray } from 'formik';

const Header = () => {
  const { user } = useAuth();
  const theme = useTheme();
  const { data: notifications, refetch } = useGetNotificationsQuery();
  const { data: credits, isLoading, refetch: refetchOccyCredits } = useGetCompanyCreditsQuery();
  const { data } = useGetUnreadQuery(null, { skip: AllowedFunc('mailbox', user) });;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userMenuItems = [
    {
      name: 'Logout',
      icon: <LogoutIcon />,
      subItems: [{
        name: 'Logout',
        icon: <LogoutIcon />,
      }],
      action: () => {
        dispatch(removeCredentials());
        dispatch(removeLicence());
        dispatch(removeDelegatedUser());
        navigate('/');
      },
    },
  ];
  // let fiatBalance = 0.00, occyCredits = 0.00;
  const creditsArray = (Array.isArray(credits) && credits) || [];

  for (const crd of creditsArray) {
    if (crd.credit_type === 'OCCY_CREDITS') {
      // occyCredits = crd?.amount || 0.00;
    } else if (crd.credit_type === 'FIAT') {
      // fiatBalance = crd?.amount || 0.00;
    }
  }
  // const companyMenuItems = [
  //   {
  //     name: 'C1',
  //     icon: <>C1</>,
  //     action: () => {
  //       occyLs.clear();
  //       navigate('/');
  //     },
  //   },
  // ];
  useEffect(() => {
    dispatch(setHeaderCredits({ refetchOccyCredits,credits }))
  }, [credits])


  const IsLowCredits= useMemo(() => (isEmptyArray(credits) || (credits && credits[0]?.amount < 100)), [credits])

  const IsBillingAdmin = useMemo(() => (!AllowedFunc('subcriptionRedirection', user)), [user])

  return (
    <StyledHeader>
      <div className="d-flex justify-content-between align-items-center">
        <div className="d-flex p-2 p-md-4 justify-content-between w-100">
          <ChildCompaniesDropdown profile={user}></ChildCompaniesDropdown>
          {/* <StyledIconInput
          icon={<SearchIcon />}
          width="50em"
          searchIcon={<HeaderSearchICon />}
          className="feature-pending-new"
        /> */}
      
          <div className="d-flex gap-4 align-items-center ">
            <span  className="pe-auto  cursor-pointer position-relative"
            >
              <StyledToolTip
                title={IsLowCredits ? 'Low credit balance.': false}
                childrenClass={`ms-0`}
                placement="top">
            <StyledCredit isCreditZero={(isEmptyArray(credits)) || (credits &&  credits[0]?.amount < 1)} isLowBalance={IsLowCredits? true: false} className={`pe-auto rounded p-2 d-flex justify-content-center align-items-center ${user?.accountType === 2 && user?.company?.isParent === 1 ? 'd-none' : ''}`} onClick={() => IsBillingAdmin && navigate('/settings/company/subscription-hub')}>
           
            
            {IsLowCredits &&
                <InfoIconOutline
                  color={theme.lowCreditLoaderColor}
                  strokeWidth={2}
                  size="20"
                  className='lowCreditIcon'
                />}
                 <img src={Wallet} alt="wallet" height={20} width={20} />
                 {isLoading ? <StyledWrap><StyledDotsLoader size={'8px'} gap={'11px'} color={theme?.primaryLight2}/></StyledWrap>:  
              <span className='ps-2 fs-6 fw-bold'>
                {/* £{(isArray(credits) && credits.length !== 0) ? valueToLocaleString(credits[1]?.amount) || 0.00 : 0} |  */}
              {(isArray(credits) && credits.length !== 0) ? valueToLocaleString(formateNumber(credits[0]?.amount)) || 0.00 : 0} Credits</span>}
                </StyledCredit> 
            </StyledToolTip>
            </span>
            {/* <StyledProfileDropDown
            profile={{
              firstName: 'Parallel',
            }}
            menuItems={companyMenuItems}
            toggleClassName={'bg-transparent'}
          /> */}

          {!AllowedFunc('viewWizard', user)
           && (
          <Wrap onClick={() => navigate('wizard')} className={`${user?.accountType === 2 && user?.company?.isParent === 1 ? 'd-none' : ''} cursor-pointer position-relative`}>
          <WizardIcon size={23} />
          </Wrap>
          )}

            <Wrap
              onClick={() => navigate('mails')}
              className="cursor-pointer me-3 position-relative"
              hidden={AllowedFunc('mailbox', user)}
            >
              <MailBoxIcon />
              {data?.count === 0 ? null : (
                <StyledDot
                  color={theme?.dark ? theme?.darkDoubleRed : theme.danger}
                  $position="absolute"
                  $top="0px"
                  $right="0px"
                  $border={`2px solid ${theme.white}`}
                  $size="10px"
                />
              )}
            </Wrap>
            <NotificationDropDown
              notifications={notifications?.notifications}
              totalUnreadNotifications={notifications?.totalUnreadNotifications}
              menuItems={userMenuItems}
              profile={user}
              toggleClassName={'bg-transparent p-0'}
              menuClassName="rounded-4 Larger shadow "
              refetch={refetch}
            />
            {/* <ParallelDropDown
            profile={user}
            toggleClassName={'bg-transparent'}
          /> */}
            <StyledProfileDropDown
              menuItems={userMenuItems}
              menuClassName={`ooo`}
              profile={user}
              toggleClassName={'bg-transparent p-0'}
            />
          </div>
        </div>
      </div>
    </StyledHeader>
  );
};
const StyledHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 99;
  margin-bottom: 5px;
  background-color: ${({theme})=>theme.darkThemBoxBgColor || theme.white};
 

  .section-container {
    position: sticky;
    top: 0;
  }
`;
const StyledCredit = styled.div`
  background-color: ${({ theme, isLowBalance }) => isLowBalance ? theme.darkLight || theme.evaluationBannerColor : theme.darkLight || theme.primaryLight};
  color: ${({ theme, isLowBalance, isCreditZero }) => isCreditZero ? theme.danger : theme?.primaryText};
  & > .lowCreditIcon{
    position: absolute;
    top: -9px;
    right: -9px;
    fill:${({theme}) => theme?.white};
  }
  &>.dotIcon{
    transform: scale(1);
	animation: pulse 0.9s infinite;
  }
  @keyframes pulse {
    0% {
      transform: scale(0.25);
     
    }
    25% {
      transform: scale(0.45);
    }
    50% {
      transform: scale(0.65);
    }
    75% {
      transform: scale(0.75);
     
    }
    100% {
      transform: scale(1);
    }
  }
`;

const StyledWrap= styled.div`
width: 42px;
margin-left: 33px;
`
export default Header;
