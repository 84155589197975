import { apiSlice } from '../../../app/services/apiSlice';

const CHILD_COMPANY = 'company';
const DEPARTMENT_URL = 'departments';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getAllDepartments: builder.query({
      query: (params) => ({
        url: `${DEPARTMENT_URL}`,
        params,
      }),
    }),
    getAllChildCompanies: builder.query({
      query: (params) => ({
        url: `${CHILD_COMPANY}/all-companies`,
        params,
      }),
    }),
    getAllChildCompaniesListing: builder.mutation({
      query: (params) => ({
        url: `${CHILD_COMPANY}/all-companies`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.companies = responseData?.companies?.map((comp) => ({
          ...comp,
          title: comp?.name,
          value: comp?.id,
        }));
        return responseData || {};
      },
    }),
    getAsyncChildCompaniesListing: builder.mutation({
      query: (params) => ({
        url: `${CHILD_COMPANY}/all-companies`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.companies?.map((comp) => ({
          ...comp,
          title: comp?.name,
          value: comp?.id,
        }));
        return responseData || {};
      },
    }),
    addChildCompany: builder.mutation({
      query: (data) => ({
        url: `${CHILD_COMPANY}/add-company`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    updateChildCompany: builder.mutation({
      query: (data) => ({
        url: `${CHILD_COMPANY}/${data.companyId}/update`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),
    switchChildCompany: builder.mutation({
      query: (compId) => ({
        url: `${CHILD_COMPANY}/switch-company/${compId}`,
        method: 'POST',
      }),
    }),
    leaveChildCompany: builder.mutation({
      query: (compId) => ({
        url: `${CHILD_COMPANY}/${compId}/leave-company`,
        method: 'DELETE',
      }),
    }),
    markAsInactive: builder.mutation({
      query: (data) => ({
        url: `${CHILD_COMPANY}/${data.id}/update`,
        method: 'PUT',
        body: {
          ...data
        }
      }),
    }),
  }),
});

export const {
  useGetAllDepartmentsQuery,
  useGetAllChildCompaniesListingMutation,
  useAddChildCompanyMutation,
  useLeaveChildCompanyMutation,
  useMarkAsInactiveMutation,
  useSwitchChildCompanyMutation,
  useUpdateChildCompanyMutation,
  useGetAllChildCompaniesQuery,
  useGetAsyncChildCompaniesListingMutation
} = extendedApiSlice;
