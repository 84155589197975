import React from 'react';

const WelcomeUperLayer = ({ size=40, color='#C4994A', ...props }) => (
<svg width="874" height="900" viewBox="0 0 874 900" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g opacity="0.07">
<path d="M-680.53 304.258C-614.011 232.69 -572.812 270.982 -506.293 199.414C-439.773 127.846 -480.972 89.5536 -414.472 18.0061C-347.972 -53.5414 -306.754 -15.2693 -240.235 -86.8374C-173.715 -158.405 -214.915 -196.698 -148.395 -268.266C-81.876 -339.834 -40.6769 -301.542 25.8615 -373.13C92.3999 -444.719 51.1817 -482.991 117.701 -554.559" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-515.65 457.507C-449.131 385.939 -407.848 424.309 -341.329 352.741C-274.81 281.173 -316.093 242.802 -249.592 171.255C-183.092 99.7073 -141.79 138.057 -75.2711 66.4892C-8.75181 -5.07894 -50.0347 -43.4494 16.4846 -115.018C83.0039 -186.586 124.287 -148.215 190.825 -219.804C257.364 -291.392 216.062 -329.742 282.581 -401.31" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-288.888 668.272C-222.368 596.704 -181.169 634.997 -114.65 563.429C-48.1308 491.861 -89.3299 453.568 -22.8298 382.021C43.6704 310.473 84.8886 348.745 151.408 277.177C217.927 205.609 176.728 167.316 243.247 95.7482C309.767 24.1801 350.966 62.4727 417.504 -9.11594C484.042 -80.7046 442.824 -118.977 509.344 -190.545" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-598.048 380.921C-531.529 309.353 -490.329 347.646 -423.81 276.077C-357.291 204.509 -398.49 166.217 -331.99 94.6692C-265.49 23.1217 -224.272 61.3937 -157.752 -10.1743C-91.233 -81.7424 -132.432 -120.035 -65.9129 -191.603C0.606391 -263.171 41.8055 -224.879 108.344 -296.467C174.882 -368.056 133.664 -406.328 200.183 -477.896" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-762.929 227.672C-696.409 156.104 -655.21 194.397 -588.691 122.829C-522.172 51.2607 -563.371 12.968 -496.871 -58.5795C-430.371 -130.127 -389.152 -91.8549 -322.633 -163.423C-256.114 -234.991 -297.313 -273.284 -230.794 -344.852C-164.274 -416.42 -123.075 -378.127 -56.5369 -449.716C10.0015 -521.305 -31.2168 -559.577 35.3025 -631.145" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-433.167 534.17C-366.648 462.601 -325.449 500.894 -258.929 429.326C-192.41 357.758 -233.609 319.465 -167.109 247.918C-100.609 176.37 -59.3907 214.642 7.12858 143.074C73.6478 71.5062 32.4487 33.2135 98.968 -38.3546C165.487 -109.923 206.686 -71.63 273.225 -143.219C339.763 -214.807 298.545 -253.079 365.064 -324.648" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-206.489 744.857C-139.97 673.289 -98.6871 711.659 -32.1679 640.091C34.3514 568.523 -6.93145 530.152 59.5687 458.605C126.069 387.057 167.371 425.407 233.89 353.839C300.409 282.271 259.126 243.901 325.646 172.333C392.165 100.765 433.448 139.135 499.986 67.5464C566.525 -4.04232 525.223 -42.3922 591.742 -113.96" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-639.331 342.551C-572.812 270.983 -531.529 309.353 -465.01 237.785C-398.49 166.217 -439.773 127.846 -373.273 56.2989C-306.773 -15.2486 -265.471 23.1013 -198.952 -48.4668C-132.432 -120.035 -173.715 -158.405 -107.196 -229.973C-40.6768 -301.542 0.606055 -263.171 67.1445 -334.76C133.683 -406.348 92.3809 -444.698 158.9 -516.266" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-804.211 189.302C-737.692 117.734 -696.409 156.104 -629.89 84.5364C-563.37 12.9683 -604.653 -25.4022 -538.153 -96.9497C-471.653 -168.497 -430.351 -130.147 -363.832 -201.715C-297.312 -273.283 -338.595 -311.654 -272.076 -383.222C-205.557 -454.79 -164.274 -416.42 -97.7354 -488.008C-31.197 -559.597 -72.499 -597.947 -5.97973 -669.515" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-474.366 495.878C-407.847 424.31 -366.648 462.602 -300.129 391.034C-233.609 319.466 -274.808 281.174 -208.308 209.626C-141.808 138.079 -100.59 176.351 -34.0706 104.783C32.4486 33.2145 -8.7505 -5.07818 57.7688 -76.6463C124.288 -148.214 165.487 -109.922 232.026 -181.51C298.564 -253.099 257.346 -291.371 323.865 -362.939" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-247.688 706.565C-181.169 634.997 -139.97 673.29 -73.4508 601.722C-6.93157 530.154 -48.1307 491.861 18.3695 420.313C84.8696 348.766 126.088 387.038 192.607 315.47C259.126 243.902 217.927 205.609 284.446 134.041C350.966 62.473 392.165 100.766 458.703 29.177C525.242 -42.4117 484.023 -80.6838 550.543 -152.252" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-556.849 419.214C-490.329 347.646 -449.13 385.938 -382.611 314.37C-316.092 242.802 -357.291 204.509 -290.791 132.962C-224.291 61.4144 -183.072 99.6865 -116.553 28.1184C-50.0338 -43.4497 -91.2329 -81.7423 -24.7137 -153.31C41.8056 -224.879 83.0047 -186.586 149.543 -258.175C216.082 -329.763 174.863 -368.035 241.383 -439.603" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-330.086 629.979C-263.567 558.411 -222.368 596.704 -155.848 525.136C-89.329 453.568 -130.528 415.275 -64.028 343.728C2.47213 272.18 43.6904 310.452 110.21 238.884C176.729 167.316 135.53 129.023 202.049 57.4554C268.568 -14.1127 309.767 24.18 376.306 -47.4087C442.844 -118.997 401.626 -157.27 468.145 -228.838" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-721.729 265.965C-655.209 194.397 -614.01 232.69 -547.491 161.122C-480.972 89.5535 -522.171 51.2608 -455.671 -20.2867C-389.17 -91.8342 -347.952 -53.5621 -281.433 -125.13C-214.914 -196.698 -256.113 -234.991 -189.594 -306.559C-123.074 -378.127 -81.8752 -339.834 -15.3367 -411.423C51.2017 -483.012 9.98342 -521.284 76.5027 -592.852" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-391.969 572.464C-325.449 500.895 -284.25 539.188 -217.731 467.62C-151.212 396.052 -192.411 357.759 -125.911 286.212C-59.4107 214.664 -18.1924 252.936 48.3268 181.368C114.846 109.8 73.647 71.5075 140.166 -0.0606057C206.685 -71.6287 247.885 -33.336 314.423 -104.925C380.961 -176.513 339.743 -214.785 406.262 -286.354" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-165.206 783.228C-98.6868 711.66 -57.4877 749.953 9.03158 678.385C75.5508 606.817 34.3518 568.524 100.852 496.976C167.352 425.429 208.57 463.701 275.09 392.133C341.609 320.565 300.41 282.272 366.929 210.704C433.448 139.136 474.647 177.429 541.186 105.84C607.724 34.2512 566.506 -4.02084 633.025 -75.5889" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-659.931 323.404C-593.411 251.836 -552.129 290.206 -485.609 218.638C-419.09 147.07 -460.373 108.699 -393.873 37.152C-327.373 -34.3955 -286.071 3.9544 -219.551 -67.6137C-153.032 -139.182 -194.315 -177.552 -127.796 -249.12C-61.2764 -320.688 -19.9936 -282.318 46.5449 -353.907C113.083 -425.495 71.7813 -463.845 138.301 -535.413" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-824.81 170.156C-758.29 98.5882 -717.091 136.881 -650.572 65.3127C-584.053 -6.25534 -625.252 -44.548 -558.752 -116.096C-492.252 -187.643 -451.033 -149.371 -384.514 -220.939C-317.995 -292.507 -359.194 -330.8 -292.675 -402.368C-226.155 -473.936 -184.956 -435.643 -118.418 -507.232C-51.8794 -578.821 -93.0976 -617.093 -26.5784 -688.661" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-495.05 476.654C-428.531 405.085 -387.248 443.456 -320.728 371.888C-254.209 300.32 -295.492 261.949 -228.992 190.402C-162.492 118.854 -121.19 157.204 -54.6705 85.6361C11.8488 14.068 -29.4341 -24.3025 37.0852 -95.8705C103.604 -167.439 144.887 -129.068 211.426 -200.657C277.964 -272.246 236.662 -310.595 303.181 -382.164" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-268.288 687.419C-201.769 615.851 -160.57 654.144 -94.0505 582.576C-27.5312 511.008 -68.7303 472.715 -2.23015 401.167C64.27 329.62 105.488 367.892 172.007 296.324C238.527 224.756 197.328 186.463 263.847 114.895C330.366 43.3271 371.565 81.6197 438.104 10.031C504.642 -61.5577 463.424 -99.8297 529.943 -171.398" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-577.448 400.067C-510.929 328.499 -469.73 366.791 -403.211 295.223C-336.691 223.655 -377.89 185.362 -311.39 113.815C-244.89 42.2675 -203.672 80.5396 -137.153 8.97146C-70.6334 -62.5966 -111.833 -100.889 -45.3133 -172.457C21.206 -244.025 62.4051 -205.733 128.944 -277.321C195.482 -348.91 154.264 -387.182 220.783 -458.75" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-350.769 610.755C-284.249 539.187 -242.966 577.558 -176.447 505.989C-109.928 434.421 -151.211 396.051 -84.7106 324.503C-18.2105 252.956 23.0915 291.306 89.6108 219.738C156.13 148.17 114.847 109.799 181.366 38.231C247.886 -33.3371 289.169 5.03342 355.707 -66.5553C422.245 -138.144 380.943 -176.494 447.463 -248.062" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-742.329 246.819C-675.81 175.251 -634.611 213.544 -568.091 141.976C-501.572 70.4077 -542.771 32.115 -476.271 -39.4325C-409.771 -110.98 -368.553 -72.7079 -302.034 -144.276C-235.514 -215.844 -276.713 -254.137 -210.194 -325.705C-143.675 -397.273 -102.476 -358.98 -35.9373 -430.569C30.6011 -502.158 -10.6172 -540.43 55.9021 -611.998" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-412.567 553.317C-346.048 481.748 -304.849 520.041 -238.33 448.473C-171.81 376.905 -213.01 338.612 -146.509 267.065C-80.0093 195.517 -38.7911 233.789 27.7282 162.221C94.2475 90.6532 53.0483 52.3605 119.568 -19.2076C186.087 -90.7757 227.286 -52.483 293.824 -124.072C360.363 -195.66 319.145 -233.932 385.664 -305.501" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-185.806 764.082C-119.286 692.514 -78.0873 730.807 -11.568 659.239C54.9512 587.671 13.7521 549.378 80.2523 477.83C146.752 406.283 187.971 444.555 254.49 372.987C321.009 301.419 279.81 263.126 346.329 191.558C412.849 119.99 454.048 158.283 520.586 86.694C587.125 15.1053 545.906 -23.1668 612.426 -94.7349" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-618.648 361.775C-552.129 290.207 -510.93 328.5 -444.411 256.931C-377.892 185.363 -419.091 147.071 -352.591 75.5232C-286.09 3.97572 -244.872 42.2478 -178.353 -29.3203C-111.834 -100.888 -153.033 -139.181 -86.5135 -210.749C-19.9942 -282.317 21.2049 -244.025 87.7433 -315.613C154.282 -387.202 113.063 -425.474 179.583 -497.042" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-783.611 208.449C-717.092 136.881 -675.809 175.251 -609.29 103.683C-542.771 32.1153 -584.054 -6.25526 -517.553 -77.8028C-451.053 -149.35 -409.751 -111 -343.232 -182.568C-276.713 -254.137 -317.996 -292.507 -251.476 -364.075C-184.957 -435.643 -143.674 -397.273 -77.1358 -468.861C-10.5974 -540.45 -51.8994 -578.8 14.6199 -650.368" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-453.768 515.024C-387.248 443.456 -346.049 481.748 -279.53 410.18C-213.011 338.612 -254.21 300.319 -187.71 228.772C-121.21 157.224 -79.9913 195.496 -13.472 123.928C53.0473 52.3603 11.8481 14.0676 78.3674 -57.5005C144.887 -129.069 186.086 -90.7759 252.624 -162.365C319.163 -233.953 277.944 -272.225 344.464 -343.793" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-227.089 725.71C-160.57 654.142 -119.287 692.512 -52.7675 620.944C13.7518 549.376 -27.5311 511.006 38.9691 439.458C105.469 367.911 146.771 406.26 213.29 334.692C279.81 263.124 238.527 224.754 305.046 153.186C371.565 81.6176 412.848 119.988 479.387 48.3995C545.925 -23.1892 504.623 -61.5392 571.142 -133.107" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-536.25 438.362C-469.731 366.794 -428.532 405.086 -362.012 333.518C-295.493 261.95 -336.692 223.658 -270.192 152.11C-203.692 80.5625 -162.474 118.835 -95.9544 47.2665C-29.4352 -24.3016 -70.6343 -62.5942 -4.11502 -134.162C62.4042 -205.73 103.603 -167.438 170.142 -239.026C236.68 -310.615 195.462 -348.887 261.981 -420.455" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-309.486 649.126C-242.967 577.558 -201.768 615.851 -135.249 544.283C-68.7294 472.715 -109.929 434.422 -43.4284 362.875C23.0717 291.327 64.29 329.599 130.809 258.031C197.329 186.463 156.129 148.17 222.649 76.6024C289.168 5.03427 330.367 43.3269 396.905 -28.2618C463.444 -99.8504 422.226 -138.123 488.745 -209.691" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-701.13 285.112C-634.611 213.544 -593.412 251.837 -526.892 180.269C-460.373 108.7 -501.572 70.4078 -435.072 -1.13972C-368.572 -72.6872 -327.354 -34.4151 -260.834 -105.983C-194.315 -177.551 -235.514 -215.844 -168.995 -287.412C-102.476 -358.98 -61.2765 -320.687 5.26189 -392.276C71.8003 -463.865 30.5821 -502.137 97.1013 -573.705" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-371.369 591.608C-304.85 520.04 -263.567 558.411 -197.048 486.842C-130.528 415.274 -171.811 376.904 -105.311 305.356C-38.8111 233.809 2.49091 272.159 69.0102 200.591C135.529 129.023 94.2466 90.6521 160.766 19.084C227.285 -52.484 268.568 -14.1135 335.106 -85.7022C401.645 -157.291 360.343 -195.641 426.862 -267.209" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
<path d="M-144.607 802.373C-78.0882 730.805 -36.8891 769.097 29.6302 697.529C96.1495 625.961 54.9504 587.669 121.451 516.121C187.951 444.574 229.169 482.846 295.688 411.278C362.207 339.709 321.008 301.417 387.528 229.849C454.047 158.281 495.246 196.573 561.784 124.985C628.323 53.3959 587.105 15.1238 653.624 -56.4443" stroke="#4A154B" strokeWidth="0.637431" strokeMiterlimit="10"/>
</g>
</svg>

   
);


export default WelcomeUperLayer;
