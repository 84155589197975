import React from 'react';

const MagicPenIcon = ({ size = 16, color = '#996B00', ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.9999 4.99927L12.0066 5.9926L10.0066 3.9926L10.9999 2.99927C11.2799 2.71927 11.6399 2.58594 11.9999 2.58594C12.3599 2.58594 12.7199 2.71927 12.9999 2.99927C13.5533 3.5526 13.5533 4.44594 12.9999 4.99927Z"
      fill={color}
    />
    <path
      d="M11.5399 6.4668L4.33321 13.6668C3.77988 14.2201 2.88655 14.2201 2.33321 13.6668C1.77988 13.1135 1.77988 12.2201 2.33321 11.6668L9.53988 4.4668L11.5399 6.4668Z"
      fill={color}
    />
    <path
      d="M6.63326 2.33237L6.9066 1.4057C6.93326 1.31903 6.9066 1.2257 6.8466 1.15903C6.7866 1.09237 6.67993 1.0657 6.59326 1.09237L5.6666 1.3657L4.73993 1.09237C4.65326 1.0657 4.55993 1.09237 4.49326 1.15237C4.4266 1.21903 4.4066 1.31237 4.43326 1.39903L4.69993 2.33237L4.4266 3.25903C4.39993 3.3457 4.4266 3.43903 4.4866 3.5057C4.55326 3.57237 4.6466 3.59237 4.73326 3.5657L5.6666 3.29903L6.59326 3.57237C6.61993 3.57903 6.63993 3.5857 6.6666 3.5857C6.73326 3.5857 6.79326 3.55903 6.8466 3.51237C6.91326 3.4457 6.93326 3.35237 6.9066 3.2657L6.63326 2.33237Z"
      fill={color}
    />
    <path
      d="M3.96676 6.33237L4.24009 5.4057C4.26676 5.31903 4.24009 5.2257 4.18009 5.15903C4.11343 5.09237 4.02009 5.07237 3.93343 5.09903L3.00009 5.3657L2.07343 5.09237C1.98676 5.0657 1.89343 5.09237 1.82676 5.15237C1.76009 5.21903 1.74009 5.31237 1.76676 5.39904L2.03343 6.33237L1.76009 7.25904C1.73343 7.3457 1.76009 7.43903 1.82009 7.5057C1.88676 7.57237 1.98009 7.59237 2.06676 7.5657L2.99343 7.29237L3.92009 7.5657C3.94009 7.57237 3.96676 7.57237 3.99343 7.57237C4.06009 7.57237 4.12009 7.5457 4.17343 7.49903C4.24009 7.43237 4.26009 7.33903 4.23343 7.25237L3.96676 6.33237Z"
      fill={color}
    />
    <path
      d="M13.9667 9.66664L14.2401 8.73997C14.2667 8.6533 14.2401 8.55997 14.1801 8.4933C14.1134 8.42664 14.0201 8.40664 13.9334 8.4333L13.0067 8.70664L12.0801 8.4333C11.9934 8.40664 11.9001 8.4333 11.8334 8.4933C11.7667 8.55997 11.7467 8.6533 11.7734 8.73997L12.0467 9.66664L11.7734 10.5933C11.7467 10.68 11.7734 10.7733 11.8334 10.84C11.9001 10.9066 11.9934 10.9266 12.0801 10.9L13.0067 10.6266L13.9334 10.9C13.9534 10.9066 13.9801 10.9066 14.0067 10.9066C14.0734 10.9066 14.1334 10.88 14.1867 10.8333C14.2534 10.7666 14.2734 10.6733 14.2467 10.5866L13.9667 9.66664Z"
      fill={color}
    />
  </svg>
);

export default MagicPenIcon;
