import React from 'react';

const AvatarIcon = ({ size = 40 ,...props}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 40 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_582_53008)">
      <rect width="40" height="40" rx="20" fill="#B5E4CA" />
      <path
        d="M33.1154 35.067C25.2509 41.1224 14.2814 41.074 6.4707 34.9493L12.1954 32.2658C12.468 32.1376 12.7297 31.9873 12.9778 31.8164C13.0531 31.7646 13.1284 31.7105 13.2001 31.654C13.8232 31.1773 14.336 30.5718 14.7036 29.8787C14.9816 29.3462 15.1662 28.7701 15.2495 28.1752C15.307 27.7892 15.3361 27.3996 15.3366 27.0093C15.3366 26.3364 15.3001 25.6634 15.2931 24.9893C15.2884 24.616 15.2844 24.243 15.2813 23.8705L15.5166 23.9881L16.1284 24.3046L16.4119 24.3529L19.6919 24.9105L23.3531 25.534L23.4636 29.2987L23.5001 30.5293L23.5178 31.127V31.1658L24.9413 31.7446L33.1154 35.067Z"
        fill="#E18477"
      />
      <path
        d="M23.5126 31.0211C23.1083 31.0907 22.6923 31.0262 22.3279 30.8376L15.3079 25.0952C15.2997 24.8293 15.2926 25.1081 15.2891 24.8423C15.2844 24.4697 15.2805 24.0968 15.2773 23.7234L15.5126 23.8411L16.1244 24.1576L16.4079 24.2058L19.6879 24.7634L23.3491 25.387L23.4597 29.1517L23.4962 30.3823L23.5138 30.9799V31.0187L23.5126 31.0211Z"
        fill="#A76962"
      />
      <path
        d="M28.762 11.9458C30.7804 13.0892 32.3701 14.8606 33.2891 16.9905C32.5079 17.0538 31.7376 17.2142 30.9962 17.4681H30.9891C30.7962 17.534 30.6048 17.6066 30.415 17.6858L30.2973 17.7364L30.1103 17.8187V17.8187C30.0479 17.8458 29.9856 17.8752 29.9244 17.9058C29.8632 17.9364 29.8068 17.9658 29.7397 17.9976C29.6726 18.0293 29.622 18.0599 29.5573 18.0929C29.4926 18.1258 29.4503 18.1505 29.3973 18.1811C29.2703 18.2517 29.1456 18.3258 29.0232 18.4023C28.9746 18.4321 28.9264 18.4627 28.8785 18.494C28.4067 18.7994 27.9637 19.1471 27.555 19.5329C27.0385 20.0211 26.5562 20.5823 26.0256 21.0834C25.5705 21.5421 25.0375 21.9162 24.4515 22.1881C23.3173 22.6764 22.0056 22.9587 20.8279 23.3646L15.2985 25.2764C15.2985 25.1893 14.6726 24.5705 13.8868 23.8164C11.8185 21.8501 10.6201 19.14 10.5573 16.287C10.3668 7.66814 20.4915 5.13403 27.3809 6.98109C28.4752 7.27576 29.5159 7.74193 30.4644 8.36226C31.0925 8.76843 31.6672 9.25181 32.175 9.80109C32.7675 10.4386 33.2434 11.1753 33.5809 11.9776L33.4879 11.9611C31.9263 11.6731 30.3256 11.6679 28.762 11.9458Z"
        fill="url(#paint0_linear_582_53008)"
      />
      <path
        d="M33.2894 16.9905C32.5082 17.0538 31.7379 17.2142 30.9965 17.4681H30.9894C30.7942 17.5301 30.6025 17.6027 30.4153 17.6858L30.2976 17.7364L30.1082 17.8211C30.0459 17.8493 29.9847 17.8776 29.9223 17.9081C29.86 17.9387 29.8047 17.9681 29.7376 17.9999C29.6706 18.0317 29.62 18.0623 29.5553 18.0952C29.4906 18.1281 29.4482 18.1529 29.3953 18.1834C29.2682 18.254 29.1435 18.3281 29.0212 18.4046L28.8765 18.4964C28.4047 18.8018 27.9617 19.1495 27.5529 19.5352C27.0365 20.0234 26.5541 20.5846 26.0235 21.0858C25.8518 21.1564 25.6812 21.2305 25.5106 21.3046C25.5318 21.2952 25.3753 19.3046 25.38 19.1034C25.3996 18.3348 25.4874 17.5695 25.6423 16.8164C25.9412 15.3681 25.7506 14.0211 26.0388 12.5364C26.0997 12.205 26.1782 11.8771 26.2741 11.554C26.7659 11.187 27.4506 11.074 28.047 10.9881C29.4765 10.7823 30.947 10.9776 32.3223 11.4105C32.5498 11.4825 32.7732 11.5665 32.9918 11.6623C33.0953 11.7081 33.1965 11.7576 33.2965 11.8105C33.3846 11.8769 33.4802 11.933 33.5812 11.9776C31.9877 11.6836 30.3548 11.6728 28.7576 11.9458C30.7778 13.0884 32.3692 14.8599 33.2894 16.9905Z"
        fill="url(#paint1_linear_582_53008)"
      />
      <circle cx="14.124" cy="20.5752" r="2" fill="#E18477" />
      <circle cx="14.7574" cy="20.5752" r="1.79059" fill="#A76962" />
      <path
        d="M14.7852 18.6881L15.3005 25.394L23.1169 29.9611C24.2391 30.3797 25.4956 29.8776 26.0205 28.8011C27.1452 26.4976 28.9499 22.8611 28.7122 21.507C28.3593 19.507 27.4181 18.8011 27.4181 18.8011C27.5491 18.0168 27.6793 17.2348 27.8087 16.4552C27.9569 15.5717 28.1452 14.6493 27.9263 13.7611C27.6757 12.7434 26.9063 12.3081 26.2734 11.547C24.6075 13.6928 21.8687 14.9058 19.4769 16.1352C18.2416 16.7846 14.7852 18.6881 14.7852 18.6881Z"
        fill="#E18477"
      />
      <path
        d="M18.5557 17.3011C19.6451 17.0778 20.7653 17.0479 21.8651 17.2129"
        stroke="#00214E"
      />
      <path
        d="M25.5029 17.314C26.1755 17.1586 26.8683 17.1106 27.5559 17.1717"
        stroke="#00214E"
      />
      <path
        d="M24.9164 20.6246C24.9164 20.6246 24.9505 21.2893 25.107 21.6011C25.174 21.7352 25.2787 21.8481 25.3422 21.9823C25.6022 22.4917 25.1822 22.5999 24.7764 22.5999"
        stroke="#00214E"
      />
      <path
        d="M33.1154 35.067C25.2509 41.1224 14.2814 41.074 6.4707 34.9493L12.1954 32.2658C12.468 32.1376 12.7297 31.9873 12.9778 31.8164C13.0531 31.7646 13.1284 31.7105 13.2001 31.654C13.8232 31.1773 14.336 30.5718 14.7036 29.8787C14.9816 29.3462 15.1662 28.7701 15.2495 28.1752C15.3326 28.2557 15.4198 28.3319 15.5107 28.4034C16.2321 28.9369 17.0149 29.3816 17.8425 29.7281C18.2442 29.8957 18.6551 30.0407 19.0731 30.1623C20.3324 30.5295 21.6472 30.6685 22.9554 30.5728C23.1366 30.5587 23.3189 30.5411 23.5001 30.5281L23.5178 31.1258V31.1646L24.9413 31.7434L33.1154 35.067Z"
        fill="#00214E"
      />
      <ellipse
        cx="21.0165"
        cy="19.0658"
        rx="2.23529"
        ry="2.23529"
        stroke="white"
      />
      <ellipse
        cx="27.2685"
        cy="18.7128"
        rx="2.23529"
        ry="2.23529"
        stroke="white"
      />
      <path d="M23.252 19.0658L25.0331 18.9129" stroke="white" />
      <path
        d="M20.8491 23.9458C20.8819 23.8943 20.9346 23.8587 20.9946 23.8475C21.0546 23.8364 21.1165 23.8507 21.1656 23.887C21.4091 24.0587 21.8574 24.4234 22.4927 24.5493C23.3585 24.7223 24.1091 24.3752 24.2891 24.5987C24.3903 24.7246 24.2668 24.8787 24.0303 25.0752C23.5296 25.4491 22.8995 25.6056 22.2821 25.5093C21.4491 25.3529 20.8232 24.3881 20.8232 24.0458C20.8234 24.0108 20.8323 23.9765 20.8491 23.9458V23.9458Z"
        fill="white"
      />
      <ellipse
        cx="20.4902"
        cy="19.045"
        rx="0.384706"
        ry="0.934117"
        transform="rotate(-85.77 20.4902 19.045)"
        fill="#00214E"
      />
      <ellipse
        cx="26.5506"
        cy="18.8541"
        rx="0.934118"
        ry="0.384706"
        transform="rotate(-8.86001 26.5506 18.8541)"
        fill="#00214E"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_582_53008"
        x1="23.8739"
        y1="27.2807"
        x2="37.7777"
        y2="10.039"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16243F" />
        <stop offset="1" stopColor="#6878B1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_582_53008"
        x1="25.3812"
        y1="21.3043"
        x2="33.5812"
        y2="21.3043"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#16243F" />
        <stop offset="1" stopColor="#6878B1" />
      </linearGradient>
      <clipPath id="clip0_582_53008">
        <rect width="40" height="40" rx="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default AvatarIcon;
