import React from 'react';
import { useTheme } from 'styled-components';

const GearIcon = ({ size = 17, filled, className = '' }) => {
  const theme = useTheme();
  return (
    <>
      <svg
        width={size}
        height={size}
        viewBox="0 0 16 16"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 10C9.10457 10 10 9.10457 10 8C10 6.89543 9.10457 6 8 6C6.89543 6 6 6.89543 6 8C6 9.10457 6.89543 10 8 10Z"
          stroke={theme?.dark ? 'white' : "#585558"}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M1.33337 8.58667V7.41333C1.33337 6.72 1.90004 6.14667 2.60004 6.14667C3.80671 6.14667 4.30004 5.29333 3.69337 4.24667C3.34671 3.64667 3.55337 2.86667 4.16004 2.52L5.31337 1.86C5.84004 1.54666 6.52004 1.73333 6.83337 2.26L6.90671 2.38666C7.50671 3.43333 8.49337 3.43333 9.10004 2.38666L9.17337 2.26C9.48671 1.73333 10.1667 1.54666 10.6934 1.86L11.8467 2.52C12.4534 2.86667 12.66 3.64667 12.3134 4.24667C11.7067 5.29333 12.2 6.14667 13.4067 6.14667C14.1 6.14667 14.6734 6.71333 14.6734 7.41333V8.58667C14.6734 9.28 14.1067 9.85333 13.4067 9.85333C12.2 9.85333 11.7067 10.7067 12.3134 11.7533C12.66 12.36 12.4534 13.1333 11.8467 13.48L10.6934 14.14C10.1667 14.4533 9.48671 14.2667 9.17337 13.74L9.10004 13.6133C8.50004 12.5667 7.51337 12.5667 6.90671 13.6133L6.83337 13.74C6.52004 14.2667 5.84004 14.4533 5.31337 14.14L4.16004 13.48C3.55337 13.1333 3.34671 12.3533 3.69337 11.7533C4.30004 10.7067 3.80671 9.85333 2.60004 9.85333C1.90004 9.85333 1.33337 9.28 1.33337 8.58667Z"
          stroke={theme?.dark ? 'white' : "#585558"}
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </>
  );
};

export default GearIcon;
