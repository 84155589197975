import React from 'react';

const SettingsIcon = ({ size = 17, filled,className='' }) => {
  return (
    <>

      {
        !filled ?
          <svg
            width={size}
            height={size}
            viewBox="0 0 24 25"
            fill="none"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
          >
            < path
              d="M12 15.895C13.6569 15.895 15 14.5519 15 12.895C15 11.2382 13.6569 9.89502 12 9.89502C10.3431 9.89502 9 11.2382 9 12.895C9 14.5519 10.3431 15.895 12 15.895Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2 13.775V12.015C2 10.975 2.85 10.115 3.9 10.115C5.71 10.115 6.45 8.83502 5.54 7.26502C5.02 6.36502 5.33 5.19502 6.24 4.67502L7.97 3.68502C8.76 3.21502 9.78 3.49502 10.25 4.28502L10.36 4.47502C11.26 6.04502 12.74 6.04502 13.65 4.47502L13.76 4.28502C14.23 3.49502 15.25 3.21502 16.04 3.68502L17.77 4.67502C18.68 5.19502 18.99 6.36502 18.47 7.26502C17.56 8.83502 18.3 10.115 20.11 10.115C21.15 10.115 22.01 10.965 22.01 12.015V13.775C22.01 14.815 21.16 15.675 20.11 15.675C18.3 15.675 17.56 16.955 18.47 18.525C18.99 19.435 18.68 20.595 17.77 21.115L16.04 22.105C15.25 22.575 14.23 22.295 13.76 21.505L13.65 21.315C12.75 19.745 11.27 19.745 10.36 21.315L10.25 21.505C9.78 22.295 8.76 22.575 7.97 22.105L6.24 21.115C5.33 20.595 5.02 19.425 5.54 18.525C6.45 16.955 5.71 15.675 3.9 15.675C2.85 15.675 2 14.815 2 13.775Z"
              stroke="currentColor"
              strokeWidth="2"
              strokeMiterlimit="10"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg > :
          <svg width="21" height="20" viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18.1 7.23019C16.29 7.23019 15.55 5.95019 16.45 4.38019C16.97 3.47019 16.66 2.31019 15.75 1.79019L14.02 0.80019C13.23 0.33019 12.21 0.61019 11.74 1.40019L11.63 1.59019C10.73 3.16019 9.25 3.16019 8.34 1.59019L8.23 1.40019C7.78 0.61019 6.76 0.33019 5.97 0.80019L4.24 1.79019C3.33 2.31019 3.02 3.48019 3.54 4.39019C4.45 5.95019 3.71 7.23019 1.9 7.23019C0.86 7.23019 0 8.08019 0 9.13019V10.8902C0 11.9302 0.85 12.7902 1.9 12.7902C3.71 12.7902 4.45 14.0702 3.54 15.6402C3.02 16.5502 3.33 17.7102 4.24 18.2302L5.97 19.2202C6.76 19.6902 7.78 19.4102 8.25 18.6202L8.36 18.4302C9.26 16.8602 10.74 16.8602 11.65 18.4302L11.76 18.6202C12.23 19.4102 13.25 19.6902 14.04 19.2202L15.77 18.2302C16.68 17.7102 16.99 16.5402 16.47 15.6402C15.56 14.0702 16.3 12.7902 18.11 12.7902C19.15 12.7902 20.01 11.9402 20.01 10.8902V9.13019C20 8.09019 19.15 7.23019 18.1 7.23019ZM10 13.2602C8.21 13.2602 6.75 11.8002 6.75 10.0102C6.75 8.22019 8.21 6.76019 10 6.76019C11.79 6.76019 13.25 8.22019 13.25 10.0102C13.25 11.8002 11.79 13.2602 10 13.2602Z" fill="#CCB2C8" />
          </svg>
      }
    </>
  );
};

export default SettingsIcon;


