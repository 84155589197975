import React from 'react';

const FormSuccessIcon = ({ size = 300, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 311 244"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_4509_125353)">
      <path
        d="M190.872 216.714L182.599 224.331L189.174 213.299C183.994 203.905 175.522 195.786 175.522 195.786C175.522 195.786 157.945 212.626 157.945 225.863C157.945 239.1 165.815 243.722 175.522 243.722C185.229 243.722 193.098 239.1 193.098 225.863C193.098 222.917 192.226 219.792 190.872 216.714Z"
        fill="#F2F2F2"
      />
      <path
        d="M180.471 224.744V225.396C180.432 232.981 179.153 238.898 176.643 243.065C176.607 243.126 176.569 243.185 176.533 243.246L176.252 243.075L175.984 242.907C178.768 238.407 179.753 232.047 179.789 225.432C179.792 225.219 179.795 225.002 179.792 224.786C179.783 221.985 179.611 219.153 179.337 216.401C179.317 216.187 179.295 215.971 179.272 215.754C178.891 212.124 178.342 208.655 177.78 205.641C177.741 205.428 177.699 205.215 177.657 205.005C176.685 199.908 175.693 196.216 175.467 195.398C175.441 195.298 175.425 195.243 175.422 195.23L175.729 195.143L175.732 195.14L176.042 195.053C176.045 195.066 176.1 195.25 176.191 195.592C176.536 196.871 177.434 200.344 178.316 204.924C178.355 205.131 178.397 205.344 178.436 205.557C178.894 208.002 179.343 210.735 179.699 213.603C179.789 214.324 179.87 215.034 179.941 215.735C179.967 215.952 179.989 216.168 180.009 216.381C180.306 219.356 180.46 222.144 180.471 224.744Z"
        fill="white"
      />
      <path
        d="M178.314 204.923C178.098 204.952 177.878 204.982 177.656 205.004C177.05 205.066 176.442 205.098 175.834 205.098C173.241 205.101 170.68 204.523 168.34 203.405C168.204 203.576 168.069 203.748 167.93 203.922C170.391 205.124 173.095 205.747 175.834 205.744C176.483 205.744 177.132 205.709 177.778 205.64C177.998 205.618 178.218 205.589 178.434 205.557C180.22 205.3 181.958 204.774 183.586 203.996C183.447 203.819 183.311 203.644 183.179 203.473C181.638 204.193 179.999 204.682 178.314 204.923Z"
        fill="white"
      />
      <path
        d="M179.942 215.735C179.719 215.748 179.496 215.754 179.273 215.754C179.205 215.757 179.134 215.757 179.066 215.757C176.256 215.757 173.488 215.078 170.998 213.777C168.507 212.476 166.368 210.592 164.763 208.286C164.634 208.477 164.505 208.667 164.379 208.861C166.052 211.195 168.257 213.096 170.811 214.407C173.365 215.719 176.195 216.403 179.066 216.403C179.157 216.403 179.247 216.403 179.338 216.4C179.564 216.397 179.786 216.39 180.009 216.381C183.458 216.204 186.783 215.038 189.587 213.021C189.483 212.831 189.38 212.64 189.273 212.45C186.549 214.429 183.305 215.571 179.942 215.735Z"
        fill="white"
      />
      <path
        d="M180.469 224.744C180.243 224.763 180.017 224.776 179.791 224.786C179.548 224.796 179.306 224.802 179.064 224.802C175.288 224.798 171.616 223.571 168.596 221.304C165.576 219.038 163.372 215.854 162.312 212.23C162.167 212.492 162.022 212.751 161.883 213.012C163.076 216.628 165.378 219.775 168.463 222.008C171.547 224.24 175.256 225.444 179.064 225.448C179.306 225.448 179.548 225.445 179.787 225.432C180.017 225.425 180.243 225.413 180.469 225.396C185.036 225.039 189.296 222.96 192.388 219.579C192.32 219.34 192.243 219.104 192.165 218.865C189.179 222.271 184.984 224.38 180.469 224.744Z"
        fill="white"
      />
      <path
        d="M42.9012 86.1015C66.3188 86.1015 85.3024 67.1178 85.3024 43.7002C85.3024 20.2826 66.3188 1.29883 42.9012 1.29883C19.4837 1.29883 0.5 20.2826 0.5 43.7002C0.5 67.1178 19.4837 86.1015 42.9012 86.1015Z"
        fill="#E4E4E4"
      />
      <path
        d="M42.9008 79.0341C62.4154 79.0341 78.2351 63.2144 78.2351 43.6997C78.2351 24.185 62.4154 8.36523 42.9008 8.36523C23.3861 8.36523 7.56641 24.185 7.56641 43.6997C7.56641 63.2144 23.3861 79.0341 42.9008 79.0341Z"
        fill="#7263E1"
      />
      <path
        d="M155.499 86.1015C178.916 86.1015 197.9 67.1178 197.9 43.7002C197.9 20.2826 178.916 1.29883 155.499 1.29883C132.081 1.29883 113.098 20.2826 113.098 43.7002C113.098 67.1178 132.081 86.1015 155.499 86.1015Z"
        fill="#E4E4E4"
      />
      <path
        d="M155.498 79.0341C175.013 79.0341 190.833 63.2144 190.833 43.6997C190.833 24.185 175.013 8.36523 155.498 8.36523C135.984 8.36523 120.164 24.185 120.164 43.6997C120.164 63.2144 135.984 79.0341 155.498 79.0341Z"
        fill="white"
      />
      <path
        d="M155.537 79.083C175.052 79.083 190.872 63.2632 190.872 43.7485C190.872 24.2338 175.052 8.41406 155.537 8.41406C136.023 8.41406 120.203 24.2338 120.203 43.7485C120.203 63.2632 136.023 79.083 155.537 79.083Z"
        fill="#7263E1"
      />
      <path
        d="M268.1 86.1015C291.518 86.1015 310.502 67.1178 310.502 43.7002C310.502 20.2826 291.518 1.29883 268.1 1.29883C244.683 1.29883 225.699 20.2826 225.699 43.7002C225.699 67.1178 244.683 86.1015 268.1 86.1015Z"
        fill="#E4E4E4"
      />
      <path
        d="M268.1 79.0341C287.615 79.0341 303.434 63.2144 303.434 43.6997C303.434 24.185 287.615 8.36523 268.1 8.36523C248.585 8.36523 232.766 24.185 232.766 43.6997C232.766 63.2144 248.585 79.0341 268.1 79.0341Z"
        fill="white"
      />
      <path
        d="M268.334 79.083C287.849 79.083 303.669 63.2632 303.669 43.7485C303.669 24.2338 287.849 8.41406 268.334 8.41406C248.82 8.41406 233 24.2338 233 43.7485C233 63.2632 248.82 79.083 268.334 79.083Z"
        fill="#7263E1"
      />
      <path
        d="M262.252 56.5583C262.188 56.5583 262.125 56.5568 262.062 56.5537C261.503 56.5268 260.957 56.3793 260.46 56.1213C259.964 55.8634 259.529 55.5011 259.186 55.0593L253.765 48.0906C253.134 47.2772 252.852 46.2468 252.979 45.2253C253.107 44.2037 253.635 43.2745 254.446 42.6415L254.641 42.4898C255.455 41.8587 256.485 41.5759 257.507 41.7036C258.528 41.8313 259.458 42.3589 260.091 43.1707C260.396 43.5635 260.783 43.8856 261.224 44.1151C261.666 44.3445 262.152 44.4759 262.649 44.5001C263.146 44.5243 263.642 44.4409 264.104 44.2555C264.566 44.0701 264.982 43.7871 265.324 43.4258L276.33 31.8083C276.681 31.4381 277.101 31.1405 277.566 30.9327C278.032 30.7249 278.534 30.6109 279.044 30.5971C279.554 30.5834 280.061 30.6701 280.538 30.8525C281.014 31.0349 281.449 31.3093 281.82 31.66L281.999 31.8303C282.747 32.5386 283.182 33.5148 283.21 34.5442C283.238 35.5737 282.856 36.572 282.147 37.3196L265.07 55.3457C264.707 55.7289 264.27 56.034 263.786 56.2425C263.301 56.451 262.779 56.5585 262.252 56.5583Z"
        fill="white"
      />
      <path
        d="M148.087 56.4773C148.024 56.4773 147.961 56.4758 147.898 56.4727C147.339 56.4457 146.793 56.2982 146.296 56.0403C145.8 55.7823 145.365 55.4201 145.022 54.9783L139.601 48.0095C138.97 47.1962 138.688 46.1657 138.815 45.1442C138.943 44.1227 139.47 43.1935 140.282 42.5605L140.477 42.4087C141.291 41.7776 142.321 41.4949 143.343 41.6225C144.364 41.7502 145.294 42.2778 145.927 43.0896C146.232 43.4824 146.619 43.8046 147.06 44.034C147.502 44.2635 147.988 44.3948 148.485 44.419C148.982 44.4433 149.478 44.3598 149.94 44.1744C150.402 43.989 150.818 43.706 151.16 43.3448L162.166 31.7272C162.517 31.357 162.937 31.0595 163.402 30.8517C163.868 30.6439 164.37 30.5298 164.88 30.5161C165.39 30.5023 165.897 30.5891 166.373 30.7715C166.85 30.9538 167.285 31.2282 167.656 31.579L167.835 31.7492C168.583 32.4575 169.018 33.4337 169.046 34.4632C169.074 35.4926 168.692 36.4909 167.983 37.2385L150.906 55.2647C150.543 55.6478 150.106 55.953 149.621 56.1615C149.137 56.37 148.615 56.4774 148.087 56.4773Z"
        fill="white"
      />
      <path
        d="M39.9 56.4773C39.8368 56.4773 39.7736 56.4758 39.7102 56.4727C39.1514 56.4457 38.605 56.2982 38.1086 56.0403C37.6121 55.7823 37.1773 55.4201 36.834 54.9783L31.4139 48.0095C30.7828 47.1962 30.5 46.1657 30.6277 45.1442C30.7553 44.1227 31.283 43.1935 32.0948 42.5605L32.2898 42.4087C33.1032 41.7776 34.1337 41.4949 35.1553 41.6225C36.1768 41.7502 37.1061 42.2778 37.7392 43.0896C38.0446 43.4824 38.4314 43.8046 38.8729 44.034C39.3144 44.2635 39.8004 44.3948 40.2974 44.419C40.7944 44.4433 41.2907 44.3598 41.7525 44.1744C42.2142 43.989 42.6305 43.706 42.9727 43.3448L53.9783 31.7272C54.329 31.357 54.7492 31.0595 55.2149 30.8517C55.6806 30.6439 56.1827 30.5298 56.6925 30.5161C57.2022 30.5023 57.7097 30.5891 58.186 30.7715C58.6622 30.9538 59.0978 31.2282 59.468 31.579L59.6477 31.7492C60.3952 32.4575 60.8308 33.4337 60.8586 34.4632C60.8864 35.4926 60.5041 36.4909 59.7959 37.2385L42.718 55.2647C42.3555 55.6478 41.9185 55.953 41.434 56.1615C40.9495 56.37 40.4275 56.4774 39.9 56.4773Z"
        fill="white"
      />
      <path
        d="M148.087 56.4773C148.024 56.4773 147.961 56.4758 147.898 56.4727C147.339 56.4457 146.793 56.2982 146.296 56.0403C145.8 55.7823 145.365 55.4201 145.022 54.9783L139.601 48.0095C138.97 47.1962 138.688 46.1657 138.815 45.1442C138.943 44.1227 139.47 43.1935 140.282 42.5605L140.477 42.4087C141.291 41.7776 142.321 41.4949 143.343 41.6225C144.364 41.7502 145.294 42.2778 145.927 43.0896C146.232 43.4824 146.619 43.8046 147.06 44.034C147.502 44.2635 147.988 44.3948 148.485 44.419C148.982 44.4433 149.478 44.3598 149.94 44.1744C150.402 43.989 150.818 43.706 151.16 43.3448L162.166 31.7272C162.517 31.357 162.937 31.0595 163.402 30.8517C163.868 30.6439 164.37 30.5298 164.88 30.5161C165.39 30.5023 165.897 30.5891 166.373 30.7715C166.85 30.9538 167.285 31.2282 167.656 31.579L167.835 31.7492C168.583 32.4575 169.018 33.4337 169.046 34.4632C169.074 35.4926 168.692 36.4909 167.983 37.2385L150.906 55.2647C150.543 55.6478 150.106 55.953 149.621 56.1615C149.137 56.37 148.615 56.4774 148.087 56.4773Z"
        fill="white"
      />
      <path
        d="M136.889 35.114H108.98V10.8423C109.009 9.33939 109.633 7.90923 110.714 6.86529C111.796 5.82136 113.247 5.24883 114.75 5.27321H124.027C131.119 5.27321 136.889 10.8429 136.889 17.6889V35.114Z"
        fill="#2F2E41"
      />
      <path
        d="M147.221 238.219L141.923 238.219L139.402 217.783L147.222 217.784L147.221 238.219Z"
        fill="#FFB6B6"
      />
      <path
        d="M148.576 243.355L131.492 243.355V243.139C131.492 241.375 132.193 239.684 133.44 238.437C134.687 237.19 136.378 236.489 138.142 236.489H138.142L148.576 236.49L148.576 243.355Z"
        fill="#2F2E41"
      />
      <path
        d="M98.389 238.219L93.0907 238.219L90.5703 217.783L98.3899 217.784L98.389 238.219Z"
        fill="#FFB6B6"
      />
      <path
        d="M99.7397 243.355L82.6562 243.355V243.139C82.6564 241.375 83.357 239.684 84.6039 238.437C85.8509 237.19 87.5421 236.489 89.3056 236.489H89.306L99.7401 236.49L99.7397 243.355Z"
        fill="#2F2E41"
      />
      <path
        d="M104.785 74.043L101.328 96.0831L134.172 96.515L129.85 75.3397L104.785 74.043Z"
        fill="#FFB6B6"
      />
      <path
        d="M102.281 90.7012L91.8199 118.555L90.5234 233.985H99.3826L120.558 141.243L140.005 231.564H148.648L142.598 130.007C142.598 130.007 144.382 109.623 135.252 97.5953L133.029 91.1742L102.281 90.7012Z"
        fill="#544E51"
      />
      <path
        d="M147.154 112.415C146.239 112.165 145.39 111.719 144.667 111.106C143.944 110.493 143.364 109.729 142.968 108.868C142.572 108.007 142.37 107.07 142.375 106.122C142.38 105.174 142.594 104.239 143 103.382L129.027 57.8292L139.304 55.792L152.084 100.444C153.477 101.221 154.537 102.483 155.063 103.989C155.589 105.496 155.543 107.143 154.936 108.619C154.328 110.094 153.2 111.296 151.766 111.995C150.332 112.695 148.69 112.844 147.154 112.415H147.154Z"
        fill="#FFB6B6"
      />
      <path
        d="M128.661 86.2153L106.021 85.7783C105.374 85.7645 104.749 85.539 104.242 85.1363C103.735 84.7336 103.375 84.1759 103.215 83.5486C102.001 78.7432 99.3311 66.2197 100.748 53.7463C101.027 51.33 101.788 48.9942 102.986 46.8772C104.185 44.7603 105.795 42.9051 107.723 41.4215C109.65 39.9379 111.856 38.856 114.209 38.2398C116.562 37.6236 119.015 37.4856 121.423 37.834C123.962 38.1976 126.396 39.094 128.565 40.4644C130.734 41.8349 132.588 43.6484 134.007 45.7859C135.425 47.9235 136.376 50.3368 136.796 52.8676C137.216 55.3984 137.097 57.9894 136.445 60.4708C133.595 71.2933 131.985 79.0359 131.661 83.484C131.612 84.1271 131.355 84.7368 130.928 85.2199C130.501 85.7031 129.927 86.0331 129.295 86.1597C129.107 86.197 128.915 86.2159 128.724 86.216C128.703 86.216 128.682 86.2158 128.661 86.2153Z"
        fill="#E4E4E4"
      />
      <path
        d="M59.8891 46.2013C60.0251 46.5063 60.1369 46.8216 60.2233 47.1441L88.5623 56.774L94.0382 51.9902L102.338 59.3107L93.4669 68.7216C92.7502 69.4819 91.8063 69.9892 90.7768 70.1673C89.7473 70.3454 88.6878 70.1848 87.7573 69.7095L57.4329 54.2198C56.3362 54.8925 55.0608 55.2153 53.7762 55.1455C52.4916 55.0756 51.2587 54.6162 50.2416 53.8286C49.2244 53.0409 48.4711 51.9622 48.082 50.736C47.6928 49.5098 47.6862 48.1941 48.063 46.9641C48.4398 45.734 49.1822 44.6478 50.1914 43.8499C51.2006 43.052 52.4287 42.5803 53.7126 42.4975C54.9964 42.4147 56.275 42.7247 57.3783 43.3863C58.4817 44.0479 59.3574 45.0298 59.8891 46.2012V46.2013Z"
        fill="#FFB6B6"
      />
      <path
        d="M88.6212 55.2382C88.6233 54.8479 88.7097 54.4627 88.8744 54.1089C89.039 53.7551 89.2781 53.4409 89.5752 53.1879L99.9517 44.349C101.265 42.8442 103.121 41.9217 105.114 41.7837C107.106 41.6457 109.072 42.3035 110.58 43.6129C112.088 44.9222 113.015 46.7763 113.158 48.7683C113.301 50.7604 112.648 52.7278 111.342 54.239L103.953 65.7457C103.742 66.074 103.463 66.3531 103.134 66.5638C102.806 66.7746 102.436 66.912 102.049 66.9669C101.663 67.0217 101.269 66.9926 100.895 66.8816C100.521 66.7706 100.175 66.5802 99.8811 66.3235L89.5503 57.3003C89.2562 57.0437 89.021 56.7266 88.8606 56.3707C88.7002 56.0148 88.6186 55.6285 88.6212 55.2382Z"
        fill="#E4E4E4"
      />
      <path
        d="M128.401 69.3686C128.056 69.187 127.754 68.9329 127.516 68.6238C127.277 68.3147 127.108 67.9579 127.021 67.5776L123.953 54.2965C123.222 52.4381 123.258 50.3657 124.053 48.5339C124.848 46.702 126.337 45.2602 128.194 44.5246C130.05 43.7889 132.123 43.8195 133.957 44.6097C135.79 45.3999 137.236 46.8851 137.977 48.7396L144.788 60.5974C144.983 60.9359 145.102 61.3122 145.138 61.7008C145.173 62.0894 145.125 62.4812 144.996 62.8495C144.867 63.2177 144.66 63.5538 144.389 63.8348C144.118 64.1158 143.79 64.3351 143.426 64.4778L130.66 69.4933C130.296 69.6362 129.907 69.6991 129.517 69.6775C129.127 69.656 128.747 69.5506 128.401 69.3686Z"
        fill="#E4E4E4"
      />
      <path
        d="M139.384 7.64544C140.432 5.19139 138.629 2.06895 135.357 0.671242C132.085 -0.726464 128.583 0.129852 127.535 2.5839C126.487 5.03794 128.289 8.16041 131.561 9.55811C134.833 10.9558 138.336 10.0995 139.384 7.64544Z"
        fill="#2F2E41"
      />
      <path
        d="M129.233 25.8864C132.734 19.4831 130.381 11.4545 123.977 7.95415C117.574 4.45377 109.545 6.80709 106.045 13.2104C102.545 19.6138 104.898 27.6423 111.301 31.1427C117.705 34.6431 125.733 32.2897 129.233 25.8864Z"
        fill="#FFB6B6"
      />
      <path
        d="M124.887 4.41443H109.773C108.665 4.40009 107.592 4.80519 106.771 5.5485C105.949 6.2918 105.438 7.31833 105.342 8.42224C105.302 9.00887 105.384 9.59729 105.582 10.151C105.779 10.7047 106.089 11.212 106.49 11.6413C106.892 12.0707 107.378 12.4129 107.917 12.6469C108.456 12.8809 109.038 13.0017 109.626 13.0017C110.765 13.0017 111.857 13.4541 112.662 14.2593C113.467 15.0645 113.92 16.1566 113.92 17.2954V34.8993H137.534V17.0621C137.534 15.4012 137.207 13.7565 136.572 12.222C135.936 10.6875 135.005 9.29328 133.83 8.11884C132.656 6.9444 131.261 6.01277 129.727 5.37717C128.192 4.74157 126.548 4.41443 124.887 4.41443Z"
        fill="#2F2E41"
      />
      <path
        d="M199.392 244H35.8044C35.6905 244 35.5813 243.955 35.5008 243.875C35.4202 243.794 35.375 243.685 35.375 243.571C35.375 243.457 35.4202 243.348 35.5008 243.267C35.5813 243.187 35.6905 243.142 35.8044 243.142H199.392C199.506 243.142 199.615 243.187 199.695 243.267C199.776 243.348 199.821 243.457 199.821 243.571C199.821 243.685 199.776 243.794 199.695 243.875C199.615 243.955 199.506 244 199.392 244Z"
        fill="#CACACA"
      />
    </g>
    <defs>
      <clipPath id="clip0_4509_125353">
        <rect
          width="310"
          height="244"
          fill="white"
          transform="translate(0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default FormSuccessIcon;
