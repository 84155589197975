import { createSlice } from '@reduxjs/toolkit';
import { occyLs } from '../../utils/localStorage';
import { apiSlice } from '../../app/services/apiSlice';

const lsToken = occyLs.getObject('token');
const lsAccount = occyLs.getObject('account');
const authType = occyLs.getObject('authType');
const lsLicence = occyLs.getObject('licence');
const lsMode = occyLs.getObject('MODE');
const lsDelegated = occyLs.getObject('delegated');
const AUTH_URL = 'auth/';
const PreferScheme =  lsMode!==null ? lsMode : window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches ? true : false;


export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    claimGrant: builder.query({
      query: (data) => ({
        url: `${AUTH_URL}claim-grant`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    googleClaimGrant: builder.query({
      query: (data) => ({
        url: `google/${AUTH_URL}claim-grant`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    outlookClaimGrant: builder.query({
      query: (data) => ({
        url: `ms/${AUTH_URL}claim-grant`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    getOutlookUrl: builder.mutation({
      query: () => ({ 
        url: `ms/${AUTH_URL}signin`,
      })
    }),
    getGoogleUrl: builder.mutation({
      query: () => ({ 
        url: `google/${AUTH_URL}signin`,
      })
    }),
    getUserLicence: builder.mutation({
      query: () => ({
        url: `company/licence`,
      }),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}log-in`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    loginWithOutlookCallback: builder.query({
      query: (params) => ({ 
        url: `${AUTH_URL}callback`,
        params 
      })
    }),
    resetPassword: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}reset-password`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    forgotEmailPassword: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}forgot`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}confirm-forgot`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    verifyCode: builder.mutation({
      query: (data) => ({
        url: `${AUTH_URL}log-in`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
  }),
});

const slice = createSlice({
  name: 'auth',
  initialState: { token: lsToken, account: lsAccount, authType: authType,licence: lsLicence,sideMenu:false,mode: PreferScheme, delegated:lsDelegated },
  reducers: {
    setCredentials: (
      state,
      {
        payload: {
          token: { accessToken, refreshToken, expiresIn },
          account,
          authType
        },
      },
    ) => {
      state.token = accessToken;
      state.account = account;
      occyLs.setObject('token', accessToken);
      occyLs.setObject('refreshToken', refreshToken);
      occyLs.setObject('expiry', expiresIn);
      occyLs.setObject('account', account);
      occyLs.setObject('authType', authType);
    },
    removeCredentials: (state, action) => {
      state.token = undefined;
      state.account = undefined;
      state.auth = undefined;
      occyLs.removeItem('token');
      occyLs.removeItem('refreshToken');
      occyLs.removeItem('expiry');
      occyLs.removeItem('account');
      occyLs.removeItem('authType');
    },
    updateToken: (state, { payload: { accessToken, expiresIn } }) => {
      if (!accessToken || accessToken === 'undefined') return;
      state.token = accessToken;
      occyLs.setObject('token', accessToken  );
      occyLs.setObject('expiry', expiresIn);
    },
    updateProfileState: (state, { payload }) => {
      state.account = payload;
      occyLs.setObject('account', payload);
    },
    setAppendedToken: (state, { payload }) => {
      state.account = payload;
      occyLs.setObject('token', payload);
    },
    setDelegatedUser: (state, { payload }) => {
      state.delegated = payload;
      occyLs.setObject('delegated', payload);
    },
    removeDelegatedUser: (state, action) => {
      state.licence = undefined;
      occyLs.removeItem('delegated');
    },
    sideMenuState: (state, { payload }) => {
      state.sideMenu=payload;
      occyLs.setObject('APP_SIDEBAR', payload);
    },
    setModeState: (state, { payload }) => {
      state.mode= payload;
      occyLs.setItem('MODE', payload);
    },
    setLicence: (state, { payload: { licence } }) => {
      state.licence = licence;
      occyLs.setObject('licence', licence);
    },
    removeLicence: (state, action) => {
      state.licence = undefined;
      occyLs.removeItem('licence');
    },
  },
});

export const {
  setCredentials,
  setAppendedToken,
  removeCredentials,
  setDelegatedUser,
  updateToken,
  updateProfileState,
  sideMenuState,
  setLicence,
  removeLicence,
  setModeState,
  removeDelegatedUser
} = slice.actions;

export default slice.reducer;

export const selectCurrentUser = (state) => {
  return state.auth.account;
};

export const getCurrentUserLicence = (state) => {
  return state.auth.licence;
};

export const getSideMenuState = (state) => {
  return state.auth.sideMenu;
};

export const getDelegatedState = (state) => {
  return state.auth.delegated;
};

export const getAppMode = (state) => {
  return state.auth.mode;
};


export const { useGoogleClaimGrantQuery, useClaimGrantQuery,useGetUserLicenceMutation, useLoginMutation, useVerifyCodeMutation,useGetOutlookUrlMutation, useLoginWithOutlookCallbackQuery, useResetPasswordMutation, useGetGoogleUrlMutation , useOutlookClaimGrantQuery,useForgotEmailPasswordMutation,useForgotPasswordMutation} = extendedApiSlice;
