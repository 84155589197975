import React from 'react';
import { useTheme } from 'styled-components';

const GlobalIcon = ({ size = 16 ,color="#292D32"}) => {
  const theme = useTheme();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.10004 13.9402C5.08004 13.9402 5.05337 13.9536 5.03337 13.9536C3.74004 13.3136 2.68671 12.2536 2.04004 10.9602C2.04004 10.9402 2.05337 10.9136 2.05337 10.8936C2.86671 11.1336 3.70671 11.3136 4.54004 11.4536C4.68671 12.2936 4.86004 13.1269 5.10004 13.9402Z"
        fill={theme?.dark ? theme?.headingColor : color}
      />
      <path
        d="M13.9604 10.9669C13.3004 12.2936 12.2004 13.3669 10.8604 14.0136C11.1137 13.1669 11.327 12.3136 11.467 11.4536C12.307 11.3136 13.1337 11.1336 13.947 10.8936C13.9404 10.9202 13.9604 10.9469 13.9604 10.9669Z"
        fill={theme?.dark ? theme?.headingColor : color}
      />
      <path
        d="M14.0137 5.13966C13.1737 4.88633 12.327 4.67966 11.467 4.53299C11.327 3.67299 11.1204 2.81966 10.8604 1.98633C12.2404 2.64633 13.3537 3.75966 14.0137 5.13966Z"
        fill={theme?.dark ? theme?.headingColor : color}
      />
      <path
        d="M5.09966 2.06004C4.85966 2.87337 4.68633 3.70004 4.54633 4.54004C3.68633 4.67337 2.83299 4.88671 1.98633 5.14004C2.63299 3.80004 3.70633 2.70004 5.03299 2.04004C5.05299 2.04004 5.07966 2.06004 5.09966 2.06004Z"
        fill={theme?.dark ? theme?.headingColor : color}
      />
      <path
        d="M10.3262 4.39301C8.77952 4.21967 7.21952 4.21967 5.67285 4.39301C5.83952 3.47967 6.05285 2.56634 6.35285 1.68634C6.36618 1.63301 6.35952 1.59301 6.36618 1.53967C6.89285 1.41301 7.43285 1.33301 7.99952 1.33301C8.55952 1.33301 9.10618 1.41301 9.62618 1.53967C9.63285 1.59301 9.63285 1.63301 9.64618 1.68634C9.94618 2.57301 10.1595 3.47967 10.3262 4.39301Z"
        fill={theme?.dark ? theme?.headingColor : color}
      />
      <path
        d="M4.39301 10.3262C3.47301 10.1595 2.56634 9.94618 1.68634 9.64618C1.63301 9.63285 1.59301 9.63952 1.53967 9.63285C1.41301 9.10618 1.33301 8.56618 1.33301 7.99952C1.33301 7.43952 1.41301 6.89285 1.53967 6.37285C1.59301 6.36618 1.63301 6.36618 1.68634 6.35285C2.57301 6.05952 3.47301 5.83952 4.39301 5.67285C4.22634 7.21952 4.22634 8.77952 4.39301 10.3262Z"
        fill={theme?.dark ? theme?.headingColor : color}
      />
      <path
        d="M14.6664 7.99952C14.6664 8.56618 14.5864 9.10618 14.4598 9.63285C14.4064 9.63952 14.3664 9.63285 14.3131 9.64618C13.4264 9.93952 12.5198 10.1595 11.6064 10.3262C11.7798 8.77952 11.7798 7.21952 11.6064 5.67285C12.5198 5.83952 13.4331 6.05285 14.3131 6.35285C14.3664 6.36618 14.4064 6.37285 14.4598 6.37285C14.5864 6.89952 14.6664 7.43952 14.6664 7.99952Z"
        fill={theme?.dark ? theme?.headingColor : color}
      />
      <path
        d="M10.3262 11.6064C10.1595 12.5264 9.94618 13.4331 9.64618 14.3131C9.63285 14.3664 9.63285 14.4064 9.62618 14.4598C9.10618 14.5864 8.55952 14.6664 7.99952 14.6664C7.43285 14.6664 6.89285 14.5864 6.36618 14.4598C6.35952 14.4064 6.36618 14.3664 6.35285 14.3131C6.05952 13.4264 5.83952 12.5264 5.67285 11.6064C6.44618 11.6931 7.21952 11.7531 7.99952 11.7531C8.77952 11.7531 9.55952 11.6931 10.3262 11.6064Z"
        fill={theme?.dark ? theme?.headingColor : color}
      />
      <path
        d="M10.5086 10.5086C8.84116 10.7189 7.15818 10.7189 5.49079 10.5086C5.28041 8.84116 5.28041 7.15818 5.49079 5.49079C7.15818 5.28041 8.84116 5.28041 10.5086 5.49079C10.7189 7.15818 10.7189 8.84116 10.5086 10.5086Z"
        fill={theme?.dark ? theme?.headingColor : color}
      />
    </svg>
  );
};

export default GlobalIcon;
