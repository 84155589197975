import { apiSlice } from '../../app/services/apiSlice';
import { STATUSES } from '../../utils/Statuses';

const JOB_URL = 'jobs/';
const APPLICATIONS_URL = 'applications/';
const JOB_ACCOUNTS = 'job-accounts/';
const CANDIDATES = 'candidates/';
const STAGES_URL = 'stages/';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addNewJob: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    addNewLocation: builder.mutation({
      query: (data) => ({
        url: `locations`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    addNewBenefit: builder.mutation({
      query: (data) => ({
        url: `company/benefit`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),

    getSingleJobById: builder.query({
      query: (id) => `${JOB_URL}${id}`,
    }),

    getSingleJobDetails: builder.mutation({
      query: (id) => `${JOB_URL}${id}`,
    }),
    getCredits: builder.query({
      query: (accessKey) => `products?accessKey=${accessKey}`,
    }),

    updateJob: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}${data.id}`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),

    updateLocation: builder.mutation({
      query: (data) => ({
        url: `locations/${data.id}`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),
    updateBenefit: builder.mutation({
      query: (data) => ({
        url: `company/benefit/${data.id}`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),
    updateJobTitle: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}${data.id}/title`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),
    updateCampaignTitle: builder.mutation({
      query: (data) => ({
        url: `campaigns/${data.id}/title`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),
    publishJob: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}${data.id}/published`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),

    getJobCategories: builder.mutation({
      query: (params) => ({
        url: `job-category`,
        params: { ...params, sort: 'title', sortDir: 'ASC', perPage: 100 },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.jobCatergories?.map((cat) => ({
          ...cat,
        }));
        return responseData || {};
      },
    }),

    getJobDataFields: builder.query({
      query: () => `${JOB_URL}data-fields/company`,
    }),
    getLocations: builder.query({
      query: (params) => ({
        url: `/locations`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.locations = responseData?.locations?.map((loc) => ({
          ...loc,
          label: loc.title,
          value: loc.id,
        }));
        return responseData || {};
      },
    }),
    getAllLocations: builder.mutation({
      query: (params) => ({
        url: `/locations`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.locations = responseData?.locations?.map((loc) => ({
          ...loc,
          label: loc.title,
          value: loc.id,
        }));
        return responseData || {};
      },
    }),

    getAllJobLevelStages: builder.mutation({
      query: (params) => ({
        url: `${JOB_URL}${params.jobId}/hiring-stages`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.jobStages?.map((stage) => ({
          ...stage,
          label: stage.name,
          value: stage.id,
        }));
        return responseData || {};
      },
    }),
    getPipelineAsyncStages: builder.mutation({
      query: (params) => ({
        url: `tpl-automations/tpl/${params.tplId}/hiring-stages-events`,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.hiringStageTemplates?.map(
          (stage) => ({
            ...stage,
            title: stage.name,
            value: stage.id,
          }),
        );
        return responseData || {};
      },
    }),

    getAllStagesByJobId: builder.mutation({
      query: (id) => `${JOB_URL}${id}/hiring-stages`,
    }),

    addStageTriggers: builder.mutation({
      query: (data) => ({
        url: `${STAGES_URL}jobs-hiring-stage/trigger`,
        method: 'POST',
        body: { ...data },
      }),
    }),

    updateStageTriggers: builder.mutation({
      query: (data) => ({
        url: `${STAGES_URL}jobs-hiring-stage/${data?.id}/trigger`,
        method: 'PUT',
        body: { ...data?.updatedData },
      }),
    }),

    deleteEmailTrigger: builder.mutation({
      query: (id) => ({
        url: `${STAGES_URL}jobs-hiring-stage/email-trigger/${id}`,
        method: 'DELETE',
      }),
    }),

    deleteTrigger: builder.mutation({
      query: (id) => ({
        url: `${STAGES_URL}jobs-hiring-stage/trigger/${id}`,
        method: 'DELETE',
      }),
    }),

    deleteSmartMoveTrigger: builder.mutation({
      query: (id) => ({
        url: `${STAGES_URL}jobs-hiring-stage/smart-move-trigger/${id}`,
        method: 'DELETE',
      }),
    }),

    getStageTriggers: builder.mutation({
      query: (id) => `${STAGES_URL}jobs-hiring-stage/trigger/${id}/details`,
    }),

    getAllUsers: builder.query({
      query: () => `accounts`,
    }),

    getAllSkillsByJob: builder.mutation({
      query: (params) => ({
        url: `skills`,
        params,
      }),
    }),

    getAllHiringLocations: builder.mutation({
      query: (params) => ({
        url: `/hiring-plans/locations`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.hiringPlanLocations?.map(
          (loc) => ({
            ...loc,
            label: loc.address,
            title: loc.address,
            value: loc,
          }),
        );
        return responseData || {};
      },
    }),
    getSkillsAverage: builder.mutation({
      query: (data) => ({
        url: `applications/skills/average`,
        method: 'POST',
        body: { ...data },
      }),
    }),

    getAllSkillsByJobId: builder.mutation({
      query: (id) => `${JOB_URL}${id}/skills`,
    }),

    addNewSkills: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}${data.jobId}/skills`,
        method: 'POST',
        body: { jobGrades: data.jobGrades },
      }),
    }),
    removeSkillByJob: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}${data.jobId}/skills/${data.id}`,
        method: 'DELETE',
      }),
    }),
    createCampaign: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}${data?.jobId}/campaigns`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),

    getCampaignChannels: builder.query({
      query: ({ jobId, id }) => ({
        url: `${JOB_URL}${jobId}/campaigns/${id}/products`,
      }),
    }),

    getCampaignByJobId: builder.query({
      query: ({ jobId, id }) => ({
        url: `${JOB_URL}${jobId}/campaigns/${id}`,
      }),
    }),

    getJobIdBasedCampaign: builder.mutation({
      query: ({ jobId, campId }) => ({
        url: `${JOB_URL}${jobId}/campaigns/${campId}`,
      }),
    }),

    updateCampaignByJobId: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}${data?.jobId}/campaigns/${data?.id}`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),

    // getJobAccountsByJobId: builder.mutation({
    //   query: (id) => `${JOB_ACCOUNTS}${id}`,
    // }),

    addJobAccountUsers: builder.mutation({
      query: (data) => ({
        url: `${JOB_ACCOUNTS}${data.id}`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),

    updateJobAccountUsers: builder.mutation({
      query: (data) => ({
        url: `${JOB_ACCOUNTS}${data.jobId}`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),

    // removeJobAccountUser: builder.mutation({
    //   query: (data) => ({
    //     url: `${JOB_URL}${data.jobId}/job-accounts/${data.id}`,
    //     method: 'DELETE',
    //   }),
    // }),

    removeSkill: builder.mutation({
      query: (data) => ({
        url: `skills/${data}`,
        method: 'DELETE',
      }),
    }),

    removeCompanyAddress: builder.mutation({
      query: (data) => ({
        url: `locations/${data}`,
        method: 'DELETE',
      }),
    }),
    removeBenefitDetail: builder.mutation({
      query: (data) => ({
        url: `company/benefit/${data}`,
        method: 'DELETE',
      }),
    }),
    addNewStage: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}${data.jobId}/hiring-stages`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),

    getSingleStage: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}${data.jobId}/hiring-stages/${data.id}`,
      }),
    }),

    deleteSingleStage: builder.mutation({
      query: (params) => ({
        url: `${JOB_URL}${params.jobId}/hiring-stages/${params.id}`,
        method: 'DELETE',
        params,
      }),
    }),

    updateSingleStage: builder.mutation({
      query: (data) => {
        return {
          url: `${JOB_URL}${data.jobId}/hiring-stages/${data.id}`,
          method: 'PUT',
          body: {
            ...data,
          },
        };
      },
    }),

    updateStageOrdering: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}${data.jobId}/order/hiring-stages`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),

    getAllJobs: builder.query({
      query: (params) => ({
        url: `jobs`,
        params,
      }),
    }),

    getAllSubsidiaries: builder.query({
      query: (params) => ({
        url: `subsidiaries`,
        params,
      }),
    }),
    getAllSkills: builder.query({
      query: (params) => ({
        url: `skills`,
        params,
      }),
    }),
    getApplicationSkills: builder.mutation({
      query: (params) => ({
        url: `${params.candidate ? 'candidates' : 'applications'}/${
          params.id
        }/skills`,
      }),
    }),
    getAllApplicationSkillsById: builder.mutation({
      query: (params) => ({
        url: `${params.candidate ? 'candidates' : 'applications'}/${
          params.id
        }/skills/all`,
      }),
    }),
    getAllEvaluationFormsById: builder.mutation({
      query: (params) => ({
        url: `jobs/${params.jobId}/questionnaire-forms/by-users/${params.id}`,
      }),
    }),

    addGrading: builder.mutation({
      query: (data) => ({
        url: `applications/${data.id}/skills`,
        method: 'POST',
        body: { applicationGrades: data.applicationGrades },
      }),
    }),
    getAllForms: builder.query({
      query: (params) => ({
        url: `forms`,
        params,
      }),
    }),
    getOfferTemplates: builder.mutation({
      query: (params) => ({
        url: `jobOffers/jobOffersTemplate`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData['options'] = responseData?.companyJobOfferTemplates?.map(
          (loc) => ({
            label: loc.title,
            value: loc.id,
            document: loc.documents,
          }),
        );
        return responseData || {};
      },
    }),
    getCandidateForms: builder.query({
      query: (params) => ({
        url: `forms`,
        params: {
          ...params,
          type: 1,
          statusId: STATUSES.LIVE,
        },
      }),
    }),

    getAllMedia: builder.query({
      query: (params) => ({
        url: `media`,
        params,
      }),
    }),
    getAllTemplates: builder.mutation({
      query: (params) => ({
        url: `templates`,
        params,
      }),
    }),
    getAllOfferTemplates: builder.mutation({
      query: (params) => ({
        url: `jobOffers/jobOffersTemplate`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData['templates'] = responseData?.companyJobOfferTemplates?.map(
          (loc) => ({
            title: loc?.title,
            label: loc?.title,
            value: loc?.id,
            offerLetterBody: loc?.offerLetterBody,
            templatedata: loc?.documents,
            templateFingerprint: loc?.templateFingerprint,
            type: loc?.type,
          }),
        );
        return responseData || {};
      },
    }),
    getSingleOfferTemplate: builder.query({
      query: (id) => ({
        // url: `${FORM_URL}questionnaire-forms/${id}`,
        url: `jobs/${id}/offer-template`,
      }),
    }),
    updateJobOfferTemplate: builder.mutation({
      query: (data) => ({
        url: `jobs/${data.jobId}/offer-template`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    getAllFormsShortCode: builder.mutation({
      query: (params) => ({
        url: `forms`,
        params: {
          ...params,
          type: 1,
          statusId: STATUSES.LIVE,
        },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.data?.customForms?.map((form) => ({
          ...form,
          id: form.shortCode,
          label: form.title,
        }));
        return responseData || {};
      },
    }),

    getAllInternalFormsShortCode: builder.mutation({
      query: (params) => ({
        url: `forms`,
        params: {
          ...params,
          type: 4,
          statusId: STATUSES.LIVE,
        },
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.data?.customForms?.map((form) => ({
          ...form,
          label: form.title,
        }));
        return responseData || {};
      },
    }),

    //  Kanban Routes
    getAllStagesWithApplicationsByJobId: builder.mutation({
      query: (params) => ({
        url: `${APPLICATIONS_URL}`,
        params,
      }),
    }),

    getAllStagesWithApplicationsListByJobId: builder.mutation({
      query: (params) => ({
        url: `${APPLICATIONS_URL}apps`,
        params,
      }),
    }),

    updateApplicationStage: builder.mutation({
      query: (data) => ({
        url: `${APPLICATIONS_URL}change-stages`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),

    triggerNotify: builder.mutation({
      query: (data) => ({
        url: `${APPLICATIONS_URL}trigger-stages-notifications`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    declinedApplications: builder.mutation({
      query: (data) => ({
        url: `${APPLICATIONS_URL}declined`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),
    getGoogleLocation: builder.mutation({
      query: (params) => ({
        url: `locations/search`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.data?.filter(item=> item?.postcode && item?.postcode?.length > 3 )
        responseData.options = responseData?.options?.map((loc) => {
          Object.keys((o) => {});
          const title = [loc.postcode, loc.town, loc.city, loc.country]
            .filter((a) => a != null )
            .join(', ');
          return {
            ...loc,
            title: title,
            value: loc._id,
          };
        });
        return responseData || {};
      },
    }),
    getCompanyLocations: builder.mutation({
      query: (params) => ({
        url: `locations`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.locations?.filter(item=> item?.postcode && item?.postcode?.length > 3 )
        responseData.options = responseData?.options?.map((loc) => {
          Object.keys((o) => {});
          const title = [
            loc.postcode,
            loc.buildingNo,
            loc.address,
            loc.town,
            loc.country,
          ]
            .filter((a) => a != null)
            .join(', ');
          return {
            ...loc,
            title: title,
            value: loc.id,
          };
        });
        return responseData || {};
      },
    }),
    getJobSummary: builder.query({
      query: (id) => ({
        url: `${JOB_URL}summary/${id}`,
      }),
    }),
    //  presigned upload
    preSignedUrl: builder.mutation({
      query: (data) => ({
        url: `${CANDIDATES}presigned-appcand-doc`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    getLocationByPostcode: builder.mutation({
      query: (params) => ({
        url: `locations/search`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.data;
        responseData.options = responseData?.options?.map((loc) => {
          Object.keys((o) => {});
          const title = [loc.postcode, loc.town, loc.city, loc.country]
            .filter((a) => a != null )
            .join(', ');
          return {
            ...loc,
            title: title,
            value: loc._id,
          };
        });
        return responseData || {};
      },
    }),
     //  presigned upload
     preSignedUrlCand: builder.mutation({
      query: (data) => ({
        url: `${CANDIDATES}presigned-doc`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    preSignedAttachmentUrl: builder.mutation({
      query: (data) => ({
        url: `/pre-create/media`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    postSignedAttachmentUrl: builder.mutation({
      query: (data) => ({
        url: `/post-create/media`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    // TODO later
    // uploadPreSignedFile: builder.mutation({
    //   query(data) {
    //     return {
    //       url: `${CANDIDATES}presigned-doc`,
    //       method: 'POST',
    //       body: data,
    //     };
    //   },
    //   async onQueryStarted(args, { dispatch, queryFulfilled }) {
    //     try {
    //       const { data } = await queryFulfilled;
    //       // const a = await fetchWithBQ('asdfasdfads');
    //       const aa = await extendedApiSlice.endpoints.preOnUpload({ ...data });
    //
    //       // await dispatch(userApi.endpoints.getMe.initiate(null));
    //     } catch (error) {}
    //   },
    // }),
    preOnUpload: builder.mutation({
      query: (data) => ({ ...data }),
    }),

    listAllJobs: builder.mutation({
      query: (params) => ({
        url: `jobs`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.jobs?.map((job) => ({
          ...job,
          value: job.id,
          label: job.title,
        }));
        return responseData || {};
      },
    }),
    analyticsListAllJobs: builder.mutation({
      query: (params) => ({
        url: `analytics/jobs`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.jobs?.map((job) => ({
          ...job,
          value: job.id,
          label: job.title,
        }));
        return responseData || {};
      },
    }),
    listApplicationAllJobs: builder.mutation({
      query: (params) => ({
        url: `jobs`,
        params,
      }),
      transformResponse: (responseData) => {
        responseData.options = responseData?.jobs?.map((job) => ({
          ...job,
          value: job.id,
          label: { title: job.title, stageName: job.title },
        }));
        return responseData || {};
      },
    }),
    bulkAddCandidatesByForm: builder.mutation({
      query: (data) => ({
        url: `${CANDIDATES}manual-dashboard-form`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),

    closeJob: builder.mutation({
      query: (id) => ({
        url: `${JOB_URL}${id}/close`,
        method: 'PUT',
      }),
    }),

    getProductsChannels: builder.query({
      query: (params) => ({
      url: `company/company-products`,
      params: params,
    })
    }),

    getProductsChannel: builder.mutation({
      query: () => `company/company-products`,
      transformResponse: (responseData) => {
        responseData.options = responseData?.advertising?.map((channel) => ({
          ...channel,
        }));
        return responseData || {};
      },
    }),

    getCompanyCredits: builder.query({
      query: () => `company/credits`,
    }),

    advertise: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}${data?.jobId}/campaigns/${data?.campaignId}`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    followJob: builder.mutation({
      query: (data) => ({
        url: `${JOB_URL}account/follow/${data?.jobId}/${data?.jobHiringPlanId}`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),
    addNewSkill: builder.mutation({
      query: (data) => ({
        url: `skills`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    updateSkill: builder.mutation({
      query: (data) => ({
        url: `skills/${data?.id}`,
        method: 'PUT',
        body: {
          ...data?.body,
        },
      }),
    }),
    getCompanyJobPosting: builder.query({
      query: (params) => ({
        url: `${JOB_URL}${params}/postings`,
      }),
    }),
    getCompanyInformation: builder.query({
      query: (params) => ({
        url: `company`,
      }),
    }),

    addCompanyLocation: builder.mutation({
      query: (data) => ({
        url: `locations/company`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    updateJobStatus: builder.mutation({
      query: ({ id, status }) => ({
        url: `${JOB_URL}${id}/status/${status}`,
        method: 'PUT',
      }),
    }),
    getAllPositions: builder.query({
      query: (params) => ({
        url: `${JOB_URL}${params.id}/positions`,
        params: params.params,
      }),
    }),
    duplicateJob: builder.mutation({
      query: (id) => ({
        url: `${JOB_URL}${id}/duplicate`,
        method: 'POST',
      }),
    }),
    resetCompany: builder.mutation({
      query: (id) => ({
        url: `company/reset-data`,
        method: 'POST',
      }),
    }),
    getPipelineStagesById: builder.mutation({
      query: (params) => ({
        url: `tpl-automations/tpl/${params.tplId}/hiring-stages-events`,
      }),
    }),
  }),
});

export const {
  usePreSignedUrlCandMutation,
  useAnalyticsListAllJobsMutation,
  useGetAllHiringLocationsMutation,
  useUpdateCampaignTitleMutation,
  useGetPipelineAsyncStagesMutation,
  useGetPipelineStagesByIdMutation,
  useResetCompanyMutation,
  useGetCampaignChannelsQuery,
  useGetAllPositionsQuery,
  useGetSkillsAverageMutation,
  useCreateCampaignMutation,
  useGetAllSkillsByJobMutation,
  useGetApplicationSkillsMutation,
  useGetAllApplicationSkillsByIdMutation,
  useGetAllEvaluationFormsByIdMutation,
  useAddGradingMutation,
  useGetAllSkillsByJobIdMutation,
  useAddNewSkillsMutation,
  useGetAllLocationsMutation,
  useGetAllOfferTemplatesMutation,
  useGetSingleOfferTemplateQuery,
  useUpdateJobOfferTemplateMutation,
  useGetAllMediaQuery,
  useAddNewJobMutation,
  useAddNewSkillMutation,
  useUpdateSkillMutation,
  useAddNewLocationMutation,
  useUpdateLocationMutation,
  useUpdateJobMutation,
  useGetAllJobsQuery,
  useGetAllSubsidiariesQuery,
  useGetAllSkillsQuery,
  useGetAllFormsQuery,
  useUpdateJobTitleMutation,
  usePublishJobMutation,
  useGetLocationsQuery,
  useAddNewBenefitMutation,
  useUpdateBenefitMutation,
  useRemoveBenefitDetailMutation,
  useGetCompanyLocationsMutation,
  useGetSingleJobByIdQuery,
  useGetSingleJobDetailsMutation,
  useGetCreditsQuery,
  useGetAllStagesByJobIdMutation,
  useGetAllJobLevelStagesMutation,
  useGetAllStagesWithApplicationsByJobIdMutation,
  useGetAllStagesWithApplicationsListByJobIdMutation,
  // useGetJobAccountsByJobIdMutation,
  useAddNewStageMutation,
  useGetJobCategoriesMutation,
  useGetJobSummaryQuery,
  useAddJobAccountUsersMutation,
  useGetGoogleLocationMutation,
  useGetLocationByPostcodeMutation,
  useTriggerNotifyMutation,
  useDeclinedApplicationsMutation,
  useUpdateJobAccountUsersMutation,
  useUpdateApplicationStageMutation,
  // useRemoveJobAccountUserMutation,
  useRemoveSkillMutation,
  useRemoveSkillByJobMutation,
  useGetCandidateFormsQuery,
  useRemoveCompanyAddressMutation,
  useUpdateStageOrderingMutation,
  useUpdateSingleStageMutation,
  useGetAllTemplatesMutation,
  useDeleteSingleStageMutation,
  useGetAllFormsShortCodeMutation,
  useGetAllInternalFormsShortCodeMutation,
  useGetSingleStageMutation,
  useGetProductsChannelMutation,
  useGetJobDataFieldsQuery,
  usePreSignedUrlMutation,
  usePreSignedAttachmentUrlMutation,
  usePostSignedAttachmentUrlMutation,
  useGetCampaignByJobIdQuery,
  useGetJobIdBasedCampaignMutation,
  useUpdateCampaignByJobIdMutation,
  usePreOnUploadMutation,
  useGetAllUsersQuery,
  useBulkAddCandidatesByFormMutation,
  useListAllJobsMutation,
  useListApplicationAllJobsMutation,
  useCloseJobMutation,
  useGetProductsChannelsQuery,
  useGetCompanyCreditsQuery,
  useAdvertiseMutation,
  useFollowJobMutation,
  useGetCompanyJobPostingQuery,
  useGetCompanyInformationQuery,
  useAddCompanyLocationMutation,
  useUpdateJobStatusMutation,
  useDuplicateJobMutation,
  useAddStageTriggersMutation,
  useUpdateStageTriggersMutation,
  useGetStageTriggersMutation,
  useGetOfferTemplatesMutation,
  useDeleteEmailTriggerMutation,
  useDeleteTriggerMutation,
  useDeleteSmartMoveTriggerMutation,
} = extendedApiSlice;
