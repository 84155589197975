import React, { useMemo } from 'react';
import Proptypes from 'prop-types';
import CheckIcon from './CheckIcon';
import { StyledCheck } from './StyledCheck';

const StyledCheckbox = ({
  forms,
  value,
  onChange,
  label,
  disabled,
  size,
  align,
  ...props
}) => {
  const id = useMemo(() => `checkbox-${Math.random()}`, []);
  const onChangeCheckbox = (event) => {
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <>
      <label
        htmlFor={id}
        className={`d-flex ${align ? align : 'align-items-center'} mb-0 mr-1 mr-sm-4 ${props?.className ?? ''
          }`}
        style={{ cursor: 'pointer', fontSize: props.labelSize }}
      >
        <StyledCheck
          forms={forms}
          value={value}
          height={size}
          width={size}
          size={size}
          {...props}
        >
          {value && <CheckIcon size="10px" />}
        </StyledCheck>
        {label && (
          <span
            className={`ms-2 ${align ? 'text-break w-100' : 'text-nowrap'}`}
          >
            {label}
          </span>
        )}
      </label>
      <input
        id={id}
        type="checkbox"
        style={{ display: 'none' }}
        checked={value ?? false}
        disabled={disabled}
        onChange={onChangeCheckbox}
      />
    </>
  );
};

StyledCheckbox.propTypes = {
  value: Proptypes.bool,
  onChange: Proptypes.func,
};

export default StyledCheckbox;
