import { createSlice } from '@reduxjs/toolkit';


const slice = createSlice({
  name: 'notification',
  initialState: {
    notifictionData:{}
  },
  reducers: {
    setNotifictionData: (state, { payload }) => {
      state.notifictionData = {payload};
    },
  },
});

export const { setNotifictionData } = slice.actions;

export default slice.reducer;

// export const getNotificationState = (state) => state.notification.notifictionData;