import React from 'react';

const InterviewZeroStateIcon = ({size="125"}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 125 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_87_6468)">
      <path
        d="M2.40918 107.769C2.40918 107.769 60.4696 95.8736 124.156 107.769"
        stroke="#BA157F"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M47.8651 113.412C47.8651 113.412 47.2387 118.736 50.8321 118.868C54.4255 119 56.0739 117.28 54.6343 115.011C53.1948 112.742 48.0849 107.291 47.8596 113.412H47.8651Z"
        fill="url(#paint0_linear_87_6468)"
      />
      <path
        d="M78.7448 111.121C78.7448 111.121 75.3931 114.473 76.6898 117.83C77.9865 121.181 82.6404 119.918 83.9371 118.874C85.2338 117.83 80.7173 108.016 78.7448 111.121Z"
        fill="url(#paint1_linear_87_6468)"
      />
      <path
        d="M80.4153 115.527C76.6021 114.681 73.7614 101.401 72.8713 96.7033C72.7065 95.8516 71.7999 95.2363 70.745 95.2363C70.6735 95.2363 70.6021 95.2418 70.5252 95.2473C69.8988 95.3022 69.3384 95.5714 68.9812 95.989C66.8494 98.4945 59.0142 107.593 54.0142 112.038C53.9922 112.06 53.9757 112.077 53.9537 112.093C53.7065 112.313 53.4702 112.516 53.2395 112.709C53.2285 112.72 53.212 112.731 53.201 112.736C49.7285 115.33 48.5691 110.802 48.5691 110.802H48.5636C44.8548 96.3187 57.1461 79.6319 57.1461 79.6319C54.3988 78.7472 52.5307 77.3077 51.3658 76.1154C50.2944 75.022 49.8329 73.6154 50.0911 72.2363C52.0417 61.9396 56.3988 57.3407 56.3988 57.3407L82.9702 55.8681L86.8109 72.4341C87.9318 83.7527 85.0527 116.555 80.4153 115.527Z"
        fill="#67A2FF"
      />
      <path
        d="M54.6289 79.3352C54.6289 79.3352 66.4201 81.7637 74.5465 79.6319C82.6729 77.5 59.2333 71.2802 59.2333 71.2802L60.563 67.6923"
        stroke="white"
        strokeWidth="0.88"
        strokeMiterlimit="10"
      />
      <path
        d="M65.7939 64.7527C65.7939 64.7527 73.0687 72.3407 79.5357 63.8846L65.7939 64.7527Z"
        fill="#BA157F"
      />
      <path
        d="M42.1047 52.6048C46.0026 52.3623 48.8066 46.4451 48.3675 39.3882C47.9285 32.3314 44.4127 26.8073 40.5148 27.0498C36.6169 27.2923 33.8129 33.2096 34.252 40.2664C34.691 47.3232 38.2068 52.8473 42.1047 52.6048Z"
        fill="#D50285"
      />
      <path
        d="M102.464 35.967C103.47 52.1319 89.6839 63.5494 71.53 65.3846C70.8817 65.4505 70.2278 65.5055 69.5685 65.544C68.1069 65.6374 66.6894 65.6593 65.3267 65.6154C48.8817 65.1483 39.7113 55.3791 38.7498 39.9286C37.7773 24.3077 45.6894 10.9066 62.2773 8.21429C62.2773 8.21429 63.2938 10.2747 69.1234 7.73627C86.8487 8.01649 101.481 20.1484 102.464 35.967Z"
        fill="url(#paint2_linear_87_6468)"
      />
      <path
        d="M57.5799 32.956C57.0689 43.7747 61.7502 54.6813 71.5305 65.3846C70.8821 65.4506 70.2283 65.5055 69.5689 65.544C68.1074 65.6374 66.6898 65.6594 65.3272 65.6154C56.4316 54.8517 52.2063 43.7912 52.7612 32.6593C53.2997 21.7418 58.3107 13.2747 62.2777 8.21978C62.2777 8.21978 63.2942 10.2802 69.1239 7.74176C66.0964 10.8407 58.2063 20.1264 57.5854 32.956H57.5799Z"
        fill="#BA157F"
      />
      <path
        d="M89.3212 36.7857C89.7388 43.4725 85.6344 49.6813 78.964 53.8242C84.7662 49.7198 88.2498 43.9396 87.8651 37.7528C87.1673 26.5165 73.975 18.1923 58.4036 19.1648C52.9201 19.5055 48.3541 20.9231 44.7607 23.1099C48.541 20.3736 53.6124 18.5879 59.8651 18.1978C75.4366 17.2308 88.6234 25.555 89.3267 36.7912L89.3212 36.7857Z"
        fill="#AD93A4"
      />
      <path
        d="M87.8705 37.7528C88.2551 43.9341 84.7716 49.7143 78.9694 53.8242C74.4309 56.6429 68.7057 58.5 62.398 58.8901C46.8265 59.8572 37.4694 51.2967 36.7716 40.0605C36.354 33.3462 39.1232 27.1923 44.7496 23.1264C44.7496 23.1209 44.7606 23.1154 44.7661 23.1099C48.3595 20.9231 52.9309 19.5055 58.409 19.1649C73.9804 18.1978 87.1727 26.522 87.8705 37.7528Z"
        fill="url(#paint3_linear_87_6468)"
      />
      <path
        d="M87.8705 37.7528C88.2551 43.9341 84.7716 49.7143 78.9694 53.8242C74.4309 56.6429 68.7057 58.5 62.398 58.8901C46.8265 59.8572 37.4694 51.2967 36.7716 40.0605C36.354 33.3462 39.1232 27.1923 44.7496 23.1264C44.7496 23.1209 44.7606 23.1154 44.7661 23.1099C48.3595 20.9231 52.9309 19.5055 58.409 19.1649C73.9804 18.1978 87.1727 26.522 87.8705 37.7528Z"
        fill="url(#paint4_linear_87_6468)"
      />
      <path
        d="M46.9525 49.0908C49.7419 48.9172 51.8468 46.2624 51.6538 43.161C51.4609 40.0597 49.0432 37.6862 46.2538 37.8597C43.4644 38.0333 41.3595 40.6881 41.5525 43.7895C41.7454 46.8908 44.1631 49.2643 46.9525 49.0908Z"
        fill="#ED018C"
      />
      <path
        d="M68.9096 47.7143C72.0169 47.7143 74.536 45.1953 74.536 42.0879C74.536 38.9806 72.0169 36.4615 68.9096 36.4615C65.8022 36.4615 63.2832 38.9806 63.2832 42.0879C63.2832 45.1953 65.8022 47.7143 68.9096 47.7143Z"
        fill="#ED018C"
      />
      <path
        d="M47.701 43.4081C48.5218 43.3571 49.141 42.5742 49.0841 41.6595C49.0272 40.7449 48.3157 40.0448 47.4949 40.0959C46.6742 40.1469 46.0549 40.9298 46.1118 41.8445C46.1687 42.7591 46.8802 43.4592 47.701 43.4081Z"
        fill="white"
      />
      <path
        d="M70.464 41.989C71.3805 41.989 72.1234 41.2461 72.1234 40.3297C72.1234 39.4132 71.3805 38.6703 70.464 38.6703C69.5476 38.6703 68.8047 39.4132 68.8047 40.3297C68.8047 41.2461 69.5476 41.989 70.464 41.989Z"
        fill="white"
      />
      <path
        d="M99.7053 49.0187C103.603 48.7762 106.407 42.859 105.968 35.8021C105.529 28.7453 102.013 23.2212 98.1154 23.4637C94.2175 23.7062 91.4135 29.6235 91.8526 36.6803C92.2916 43.7371 95.8074 49.2612 99.7053 49.0187Z"
        fill="#BA157F"
      />
      <path
        d="M102.004 47.0928C105.36 46.884 107.774 41.7921 107.396 35.7196C107.018 29.6471 103.992 24.8936 100.636 25.1024C97.28 25.3111 94.8658 30.4031 95.2436 36.4756C95.6214 42.5481 98.6481 47.3016 102.004 47.0928Z"
        fill="#CF0283"
      />
      <path
        d="M81.3102 57.7528C80.2278 58.3407 79.5409 59.467 79.5245 60.6978C79.4475 65.5659 79.7168 77.8132 84.2827 80.5055C90.008 83.8846 91.8871 75.9066 90.7607 72.5275C89.6343 69.1484 86.442 61.4506 86.442 61.4506L89.3816 55.7308C89.8926 54.7308 88.8102 53.6758 87.8267 54.2088L81.3157 57.7528H81.3102Z"
        fill="url(#paint5_linear_87_6468)"
      />
      <path
        d="M5.87051 33.7912L7.45843 37.0055L11.0024 37.522L8.43645 40.0165L9.04084 43.5494L5.87051 41.8846L2.70568 43.5494L3.31007 40.0165L0.744141 37.522L4.2881 37.0055L5.87051 33.7912Z"
        fill="#EDAED9"
      />
      <path
        d="M113.744 76.4451L115.327 79.6539L118.871 80.1703L116.305 82.6703L116.909 86.2033L113.744 84.533L110.574 86.2033L111.178 82.6703L108.612 80.1703L112.156 79.6539L113.744 76.4451Z"
        fill="#EDAED9"
      />
      <path
        d="M31.5953 0L32.5074 1.85714L34.5569 2.15385L33.0734 3.5989L33.425 5.63736L31.5953 4.67033L29.7602 5.63736L30.1118 3.5989L28.6338 2.15385L30.6777 1.85714L31.5953 0Z"
        fill="#EDAED9"
      />
      <path
        d="M121.195 29.8407L122.107 31.6923L124.156 31.989L122.673 33.4341L123.025 35.4725L121.195 34.511L119.365 35.4725L119.711 33.4341L118.233 31.989L120.277 31.6923L121.195 29.8407Z"
        fill="#EDAED9"
      />
      <path
        d="M14.8984 81.1484C16.4534 82.4341 18.6127 82.9231 20.6732 82.2583C22.7336 81.5879 24.1951 79.9286 24.7006 77.9725C23.327 78.7363 21.6951 79.4396 19.9424 80.011C18.2446 80.5604 16.4918 80.9615 14.8984 81.1484Z"
        fill="#EDAED9"
      />
      <path
        d="M13.7052 79.8297C11.7052 79.9396 10.5239 79.5989 10.3865 79.1703C10.2492 78.7418 11.0019 77.7692 12.6832 76.6813C12.6613 76.0824 12.7272 75.489 12.8701 74.9176C10.2712 76.3682 8.5349 78.0879 9.02391 79.5989C9.40852 80.7747 10.9305 81.2692 12.9745 81.2692C13.5734 81.2692 14.2217 81.2198 14.892 81.1429C14.436 80.7692 14.0349 80.3242 13.6997 79.8242L13.7052 79.8297Z"
        fill="#BA157F"
      />
      <path
        d="M28.5462 73.2802C28.0517 71.7582 25.6396 71.3901 22.6836 71.7418C23.1396 72.1154 23.5407 72.5604 23.8759 73.0604C24.1286 73.0494 24.3759 73.0385 24.6012 73.0385C26.1616 73.0385 27.0792 73.3461 27.2001 73.7198C27.277 73.9615 27.0133 74.7088 25.4638 75.8242C25.2825 75.9505 25.0957 76.0824 24.8979 76.2088C24.9199 76.8077 24.8539 77.4011 24.7056 77.967C25.2825 77.6483 25.8155 77.3132 26.288 76.978C28.6122 75.3187 28.799 74.0439 28.5517 73.2802H28.5462Z"
        fill="#BA157F"
      />
      <path
        d="M19.5028 78.6538C17.1731 79.4121 15.2115 79.7473 13.7061 79.8352C14.0412 80.3352 14.4423 80.7802 14.8984 81.1539C16.4918 80.9615 18.25 80.5659 19.9423 80.0165C21.6951 79.4451 23.3269 78.7418 24.7006 77.978C24.8489 77.4121 24.9148 76.8187 24.8929 76.2198C23.4643 77.1374 21.5797 77.9945 19.5028 78.6593V78.6538Z"
        fill="#BA157F"
      />
      <path
        d="M24.6014 74.5604C24.4256 74.0165 24.1728 73.5165 23.8706 73.0604C23.5355 72.5604 23.1344 72.1154 22.6783 71.7418C21.1234 70.4561 18.964 69.9615 16.8981 70.6319C14.8322 71.3022 13.3761 72.967 12.8706 74.9231C12.7223 75.489 12.6564 76.0824 12.6838 76.6868C12.7058 77.2363 12.7937 77.7857 12.975 78.3352C13.1509 78.8791 13.4036 79.3791 13.7058 79.8352C15.2058 79.7528 17.1673 79.4121 19.5025 78.6538C21.5794 77.9835 23.4586 77.1319 24.8926 76.2143C24.8706 75.6648 24.7827 75.1099 24.6014 74.5659V74.5604Z"
        fill="#EDAED9"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_87_6468"
        x1="47.8211"
        y1="114.736"
        x2="55.1563"
        y2="114.736"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ED018C" />
        <stop offset="1" stopColor="#C70180" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_87_6468"
        x1="76.3876"
        y1="115.258"
        x2="84.1679"
        y2="115.258"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ED018C" />
        <stop offset="1" stopColor="#C70180" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_87_6468"
        x1="39.0045"
        y1="43.3185"
        x2="101.272"
        y2="29.1602"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ED018C" />
        <stop offset="1" stopColor="#C70180" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_87_6468"
        x1="37.4469"
        y1="44.2755"
        x2="86.9901"
        y2="33.0105"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#FFEEFF" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_87_6468"
        x1="68.1166"
        y1="19.5919"
        x2="55.8712"
        y2="59.1881"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="0.46" stopColor="#FDFCFD" />
        <stop offset="0.79" stopColor="#F8F2FA" />
        <stop offset="1" stopColor="#F3E8F7" />
      </linearGradient>
      <linearGradient
        id="paint5_linear_87_6468"
        x1="79.5135"
        y1="67.6923"
        x2="91.0684"
        y2="67.6923"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#ED018C" />
        <stop offset="1" stopColor="#C70180" />
      </linearGradient>
      <clipPath id="clip0_87_6468">
        <rect
          width="123.511"
          height="120"
          fill="white"
          transform="translate(0.744141)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default InterviewZeroStateIcon;