const breakpoints = {
  xs: '400px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
};

const mediaQueries = {
  xs: `@media (max-width: ${breakpoints.xs})`,
  sm: `@media (max-width: ${breakpoints.sm})`,
  md: `@media (max-width: ${breakpoints.md})`,
  lg: `@media (max-width: ${breakpoints.lg})`,
  xl: `@media (max-width: ${breakpoints.xl})`,
  gtSm: `@media (min-width: ${parseInt(breakpoints.sm) + 1}px)`,
};

const darktheme = {
  ...mediaQueries,
  primary: '#2F0D30',
  secondary:'#E00087',
  primaryDark: '#4A154B',
  primaryLight: '#2F0D30',
  primaryText: '#F5F5F5',
  primaryLight2: '#CCB2C8',
  darkPurple: '#280329',
  purpleLight: '#351236',
  purpleBorder:'#774B78',
  purpleBtnBorder:'#4d2e4e',
  lightYellowAutomation: '#FDF4ED',
  primaryLight3: '#001F5C1f',
  primaryLight4: '#e9e9e9',
  water: '#1c1c1c',
  fantasy: '#FDF4ED',
  primaryDisabled: '#EDEDED',
  lightBlack:' #0A0509',
  headingColor: '#e7e7e7',
  textMuted: '#F5F5F5',
  body: '#000000',
  textColor: '#e1e1e1',
  lightGray: '#FCFCFC',
  iconGray: '#A4A2A4',
  lightHover: '#FAFAFA',
  inputDisabled: '#707070',
  lightBoldBlack: '#9C9C9C',
  purple: "#F6EFFF",
  skyblue: "#EFF4FA",
  thunder: "#292D32",
  inputBorder: '#373737',
  bodyBg: '#121212',
  white: '#121212',
  WhiteLinen: '#E7EFDC',
  lightbrown: "#F0E9D9",
  finch: '#5B6251',
  berylGreen: '#D8E6C6',
  inputPlaceHolder: '#9C9C9C',
  successColorSky: '#00796B',
  inputColor: '#787377',
  defaultImage: '#8C0020',
  danger: '#E45353',
  skygreen: '#E0F2F1',
  success: '#6EAA64',
  lightGreen: '#c1e8eb33',
  tuna: '#33383F',
  orange: '#DBA745',
  blue: '#001F5C',
  gold: '#996B00',
  occyLogo: '#EC008C',
  successLight: '#ECF5EA',
  tagLightBlue: '#EDF1FC',
  tagBlueColor: '#6D93C9',
  tagLightgray: '#A4ADA3',
  tagLightYellow: '#FCF1E9',
  statusTextColor: '#F8F2F7',
  statusTagOverDue: '#5C1F1F',
  statusTagCompleted: '#1E423C',
  statusTagSent: '#4A154B',
  statusTagNotSent: '#996B00',
  statusTagNew: '#1E3F6E',
  statusTagPending: '#996B00',
  statusTagAwaiting: '#996B00',
  statusAwaitingColor: '#664700',
  TagCompletedBg :'#1E423C',
  chartBorderColor:'#E5E5E5',
  notesColorSent: '#BFAB61',
  notesColorOverDue: '#AF6060',
  notesColorCompleted: '#6F876F',
  lightGreenNew: '#E6F4F4',
  crystal: '#B2D3D6',
  noteTags: '#C2D3EB80',
  borderColor: '#767476',
  borderLight: '#E0E0E0',
  cornSilk: '#FFF9E5',
  bgPink: '#F9F5F6',
  darkGraydarkMode: '#E6E5E6',
  eventColor1: '#F4DDE5',
  eventColor2: '#DADAF3',
  eventColor3: '#F4E5DE',
  eventColor4: '#ECEEEF',
  eventColor5: '#DCEAF5',
  interviewAttend: '#E3F1FF',
  blueIcon: '#204B8C',
  formSettingCardDesc: '#6F767E',
  hintBg: '#3B393C',
  green: '#1E423C',
  lightYellow: '#996B00',
  dullVoilet:'#73308A',
  chartFilled: '#A5A5E9',
  chartVacant: '#F7CEB5',
  LavenderColor: '#E0F3F5',
  NCSColor: '#E0E9F5',
  LineChartBorder: "#775078",
  ChartDashes: "#ABADB5",
  eventGreen: '#00796B',
  pyramidInterviewed: '#8D6587',
  pyramidOffered: '#521753',
  triggerIconColor: '#775078',
  chartManual: '#DC89A6',
  doughGraphtooltipTitle: '#2F0D30',
  tootipBg: '#CCB2C8',
  doughplaceholderColor: '#d2dee2',
  lineGraphBorderColor: '#8F8FF0',
  lightBgPink: "#FDFDFD",
  funnelIndex1: '#b68fb0',
  funnelIndex3: '#73486d',
  funnelIndex4: '#5e2f5e',
  graphPrimary: '#943D73',
  graphOrange: '#D97109',
  teamLabelbg: 'rgba(194, 211, 235, 0.2)',
  scheduleSelfSlotInfoColor: 'rgba(255, 246, 218, 0.3)',
  greenBarylLightColor: '#1E423C',
  orangeProcessingColor: 'rgba(219,167,69, 0.4)',
  dangerFailColor: 'rgba(255,0,0,0.4)',
  selfGrey: 'rgba(252, 241, 233, 0.2)',
  interviewtimebg: '#121212',
  interviewsuccess: '#26A69A',
  lightBrown: '#FCEDE3',
  darkBrown: '#C67E4F',
  stageIconColor: '#76747680',
  stagesDarkColor: '#313F62',
  DotColor: '#996b004d',
  darkRed: '#FFF1E6',
  creditBg1: '#dbf8ff',
  creditBg2: '#dedbff ',
  neutral: '#1A1D1F',
  InterviewFormikToolbarBg: '#FAFAFB',
  camelColor: '#FFAE1A',
  evaluationBannerColor: '#5C1F1F',
  previewColor: '#FFFAEB',
  previewColorBorder: '#FFE3AD',
  sourceColor: '#0080D0',
  screenColor: '#F9D28C',
  selectColor: '#C39CBD',
  offerColor: '#BBD49A',
  hireColor: '#34A023',
  StageHr: "#EFEFEF",
  seaPink: '#EF9A9A',
  cyan: '#85C8DD',
  cyanBlue: '#B3CBED',
  palePeach: '#FFE2AC',
  blueDark: 'rgba(0, 31, 92, 1)',
  bodyColor: '#121212',
  lightBlue: '#f6f8fc',
  darkYellow: '#FFF6DA',
  cerebellumGrey: "#CDCBCD",
  verticalBarLineColor:'#58558',
  lightSeaGreen:'#1B9AAA',
  SlateBlue:'#5E4DB2',
  subscriptionBorderColor:'#ede8ed',
  lowCreditLoaderColor:'#ff3d00',
  scroll: '#c8c8c8',
  lightOrange:'#FFE3CF',
  lightRed:'#FED9DF',
  darkDoubleRed:'#C00',
  tagPremiumColor: '#FFEBEE',
  tagDefaultColor : '#eae7ea',
  tagBlueBgColor:'#C2D3EB',
  darkThemBoxBgColor:'#171717',
  dark:'#000000',
  darkLight:'#2a2a2a',
  darkGrayBackground:'#292929',
  darkTagGreenBg : '#1E423C',
  darkTagGreenTextColor: '#C9F3EF',
  darkHeaderBg:'#212529',
  skeltonBaseColor: '#1b1b1b',
  skeltonHighlightColor :'#363636',
  toastErrorBg: '#e35353',
  toastErrorBorderColor: '#ef9a9a',
  toastErrorIconColor:'#ffebee',
  toastErrorTextColor: '#C9F3EF',
  toastSuccessIconColor: '#80cbc4',
  toastSuccessBorderColor: "#00796b",
  toastSuccessBg: '#1E423C',
  selectBg: '#1d1d1d',
  customMultiSelectOptionbg:'#383838',
  actionsIconColor:'#CCB2C8',
   tableHeaderBg: '#252525',
   tagChips: '#383838',
   toothShadeIconColor: '#C2E8EB',
   LineGraphTextColor: '#e8e8e8',
   LineGraphBorderColor: '#585558',
   modalInnerBg : '#202020',
   occyEventTileColor: '#9E769F',
   externalEventTileColor: '#C7932E',
   delegatedEventTileColor: '#00796B',
   profileDropdownBgColor: '#181818',
   darkTileBg: '#212121',
   modalInnerSideBgColor:'#222222',
   darkSecondaryColor: '#A00161',
};

export default darktheme;
