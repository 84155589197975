import React from 'react';

const NewArrowIcon = ({ size = 13,rotate = 'right' ,...props}) => {
  const getRotateDeg = (val) => {
    switch (val) {
      case 'down':
        return '90';
      case 'left':
        return '180';
      case 'up':
        return '270';
      default:
        return '0';
    }
  };
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 13 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{ transform: `rotate(${getRotateDeg(rotate)}deg)` }}
      {...props}
    >
      <path
        d="M8 1L12 5L8 9"
        stroke="#767476"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 5L1.6709 5"
        stroke="#767476"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NewArrowIcon;
