import React from 'react';

const OpenMailBoxIcon = ({  ...props }) => {
  return (
<svg width="183" height="160" viewBox="0 0 183 160" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<g clip-path="url(#clip0_198_8496)">
<path d="M181.084 157.712C180.766 158.156 180.444 158.598 180.126 159.036C179.212 159.666 178.127 160.002 177.017 160H5.98271C4.92197 160.001 3.88372 159.694 2.99367 159.117C2.69828 158.727 2.40623 158.335 2.11084 157.942L2.40623 157.78L88.9912 109.786C89.7595 109.361 90.6228 109.138 91.5008 109.137C92.3787 109.136 93.2426 109.357 94.012 109.78L180.792 157.549L181.084 157.712Z" fill="#4A154B"/>
<path d="M177.017 56.7952L101.977 13.4588C98.8204 11.6361 95.2403 10.6756 91.5956 10.6738C87.9509 10.672 84.3699 11.629 81.2119 13.4486L5.98257 56.7952C4.51995 56.7973 3.11782 57.3793 2.08358 58.4135C1.04935 59.4477 0.467401 60.8499 0.465332 62.3125V154.483C0.467036 155.946 1.04886 157.348 2.08318 158.382C3.11749 159.417 4.51983 159.998 5.98257 160H177.017C178.48 159.998 179.881 159.416 180.916 158.381C181.95 157.347 182.532 155.945 182.534 154.483V62.3125C182.532 60.8499 181.95 59.4477 180.916 58.4135C179.882 57.3792 178.48 56.7973 177.017 56.7952ZM181.885 154.483C181.882 155.773 181.368 157.009 180.456 157.922C179.544 158.834 178.307 159.348 177.017 159.351H5.98257C4.69217 159.349 3.45529 158.835 2.54284 157.923C1.63038 157.01 1.11674 155.773 1.11442 154.483V62.3125C1.11599 61.0219 1.62939 59.7845 2.542 58.8719C3.45462 57.9593 4.69194 57.4459 5.98257 57.4443L81.2119 14.0977C84.3699 12.2781 87.9509 11.3211 91.5956 11.3229C95.2403 11.3247 98.8204 12.2852 101.977 14.1079L177.017 57.4443C178.308 57.4459 179.545 57.9593 180.458 58.8719C181.37 59.7845 181.884 61.0219 181.885 62.3125V154.483Z" fill="#3F3D56"/>
<path d="M91.597 108.943C91.0318 108.945 90.4703 108.85 89.9366 108.664L89.8385 108.629L89.8186 108.411C89.7574 108.441 89.6957 108.47 89.6339 108.497L89.5686 108.526L89.5032 108.497C89.3665 108.437 89.2307 108.369 89.0996 108.295L29.3496 74.7203V6.16617C29.3514 4.57434 29.9845 3.04822 31.1101 1.92263C32.2357 0.797039 33.7618 0.163896 35.3537 0.162109H147.646C149.238 0.163896 150.764 0.797039 151.889 1.92263C153.015 3.04822 153.648 4.57434 153.65 6.16617V74.8079L153.567 74.8545L94.0945 108.295C93.331 108.721 92.4711 108.944 91.597 108.943Z" fill="white"/>
<path d="M91.5972 109.105C91.0136 109.107 90.4339 109.01 89.8829 108.817L89.6864 108.748L89.678 108.655L89.5687 108.703L89.4381 108.646C89.2964 108.584 89.1559 108.513 89.0202 108.437L29.1875 74.8155V6.16633C29.1894 4.53148 29.8396 2.96413 30.9956 1.80812C32.1516 0.652111 33.719 0.00185025 35.3538 0H147.646C149.281 0.00185711 150.848 0.652118 152.004 1.80813C153.16 2.96413 153.81 4.53149 153.812 6.16633V74.903L94.1741 108.437C93.3864 108.876 92.4991 109.106 91.5972 109.105ZM90.2946 108.269C91.4878 108.612 92.7681 108.469 93.8563 107.871L153.163 74.5233V6.16633C153.162 4.70357 152.58 3.30119 151.545 2.26687C150.511 1.23254 149.109 0.650734 147.646 0.649087H35.3538C33.8911 0.650727 32.4887 1.23253 31.4544 2.26686C30.42 3.30119 29.8382 4.70357 29.8366 6.16633V74.4355L33.7277 76.6208L89.3384 107.871C89.4141 107.914 89.4909 107.954 89.5689 107.992C89.6468 107.954 89.7236 107.914 89.7991 107.871L90.2361 107.625L90.2946 108.269Z" fill="#3F3D56"/>
<path d="M181.096 59.1898L157.544 72.4312L153.487 74.7127L94.0143 108.154C93.2752 108.566 92.4427 108.782 91.5964 108.78C91.0496 108.782 90.5064 108.691 89.99 108.511C89.8463 108.465 89.7054 108.411 89.568 108.348C89.4351 108.29 89.3053 108.225 89.1787 108.154L33.5681 76.9034L29.5113 74.6251L1.95106 59.1379C1.87612 59.0949 1.82115 59.0241 1.79807 58.9409C1.77498 58.8576 1.78565 58.7687 1.82777 58.6932C1.87 58.6184 1.94019 58.5634 2.02293 58.5403C2.10567 58.5172 2.19421 58.5278 2.2691 58.5699L29.5113 73.8786L33.5681 76.1569L89.4967 107.586C89.6822 107.69 89.8753 107.78 90.0743 107.855C90.1588 107.888 90.2464 107.917 90.3341 107.943C90.8896 108.113 91.4738 108.169 92.0517 108.108C92.6295 108.047 93.189 107.869 93.6963 107.586L153.487 73.9695L157.544 71.688L180.778 58.6251C180.815 58.6042 180.856 58.5908 180.898 58.5857C180.94 58.5806 180.983 58.5839 181.024 58.5953C181.065 58.6068 181.103 58.6262 181.137 58.6525C181.17 58.6787 181.198 58.7113 181.219 58.7484C181.24 58.7855 181.253 58.8263 181.258 58.8686C181.264 58.9108 181.26 58.9536 181.249 58.9946C181.237 59.0356 181.218 59.074 181.192 59.1074C181.165 59.1409 181.133 59.1689 181.096 59.1898Z" fill="#3F3D56"/>
<path d="M53.7143 21.4202H44.6271C43.9385 21.4202 43.2781 21.1467 42.7912 20.6598C42.3043 20.1729 42.0308 19.5125 42.0308 18.8239C42.0308 18.1353 42.3043 17.4749 42.7912 16.988C43.2781 16.5011 43.9385 16.2275 44.6271 16.2275H53.7143C54.4029 16.2275 55.0633 16.5011 55.5502 16.988C56.0371 17.4749 56.3107 18.1353 56.3107 18.8239C56.3107 19.5125 56.0371 20.1729 55.5502 20.6598C55.0633 21.1467 54.4029 21.4202 53.7143 21.4202Z" fill="#4A154B"/>
<path d="M64.4243 30.1829H44.6271C43.9385 30.1829 43.2781 29.9094 42.7912 29.4225C42.3043 28.9356 42.0308 28.2752 42.0308 27.5866C42.0308 26.898 42.3043 26.2376 42.7912 25.7507C43.2781 25.2638 43.9385 24.9902 44.6271 24.9902H64.4243C65.1129 24.9902 65.7733 25.2638 66.2602 25.7507C66.7471 26.2376 67.0206 26.898 67.0206 27.5866C67.0206 28.2752 66.7471 28.9356 66.2602 29.4225C65.7733 29.9094 65.1129 30.1829 64.4243 30.1829Z" fill="#E6E6E6"/>
<path d="M119.735 53.5501H63.589C62.9004 53.5501 62.24 53.2766 61.7531 52.7897C61.2662 52.3028 60.9927 51.6424 60.9927 50.9538C60.9927 50.2652 61.2662 49.6048 61.7531 49.1179C62.24 48.631 62.9004 48.3574 63.589 48.3574H119.735C120.424 48.3574 121.084 48.631 121.571 49.1179C122.058 49.6048 122.331 50.2652 122.331 50.9538C122.331 51.6424 122.058 52.3028 121.571 52.7897C121.084 53.2766 120.424 53.5501 119.735 53.5501Z" fill="#4A154B"/>
<path d="M124.928 63.6107H58.0719C57.3833 63.6107 56.7229 63.3371 56.236 62.8502C55.7491 62.3633 55.4756 61.7029 55.4756 61.0143C55.4756 60.3257 55.7491 59.6653 56.236 59.1784C56.7229 58.6915 57.3833 58.418 58.0719 58.418H124.928C125.617 58.418 126.277 58.6915 126.764 59.1784C127.251 59.6653 127.524 60.3257 127.524 61.0143C127.524 61.7029 127.251 62.3633 126.764 62.8502C126.277 63.3371 125.617 63.6107 124.928 63.6107Z" fill="#E6E6E6"/>
<path d="M124.928 73.347H58.0719C57.3833 73.347 56.7229 73.0735 56.236 72.5865C55.7491 72.0996 55.4756 71.4392 55.4756 70.7506C55.4756 70.0621 55.7491 69.4017 56.236 68.9147C56.7229 68.4278 57.3833 68.1543 58.0719 68.1543H124.928C125.617 68.1543 126.277 68.4278 126.764 68.9147C127.251 69.4017 127.524 70.0621 127.524 70.7506C127.524 71.4392 127.251 72.0996 126.764 72.5865C126.277 73.0735 125.617 73.347 124.928 73.347Z" fill="#E6E6E6"/>
</g>
<defs>
<clipPath id="clip0_198_8496">
<rect width="182.069" height="160" fill="white" transform="translate(0.465332)"/>
</clipPath>
</defs>
</svg>

  );
};

export default OpenMailBoxIcon;
