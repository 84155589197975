import styled, { useTheme } from 'styled-components';
import {
  capitalizeFirstLetter,
  getGraphLabels,
  getStatusFriendly,
} from '../../../utils/helper';
import { STATUSES } from '../../../utils/Statuses';
import { find, get, isDate, isEmpty, isNil, isObject, map, sortBy, startCase, unionBy } from 'lodash';
import NoChartIcon from '../../../app/components/svgIcons/NoChartIcon';
import { StyledLabel } from '../../../assets/styledComponents/Global';
import { toast } from 'react-toastify';
import { toTitleCase } from '../../kanban/components/hiringListing/hiringUtils';
import moment from 'moment';
import Skeleton from 'react-loading-skeleton';
import theme from '../../../utils/themes';

export const getDeiBreakDownOffersData = (
  deiBreakDownOffers,
  deiFilters,
  deiForm,
) => {
  if (!deiBreakDownOffers) return null;
  const questionId = deiFilters?.questionId || deiForm?.options?.[0]?.value;
  const formQuestion = deiForm?.formQuestions?.find((f) => f.id === questionId);
  const questionAnswers = formQuestion?.sectionOptions?.opts || [];
  const data = questionAnswers.map(({ label }, i) => {
    const answerExist =
      deiBreakDownOffers.find((o) => o.answer === label) || {};
    const { accepted = 0, rejected = 0, pending = 0 } = answerExist;

    return {
      label: answerExist.answer || label || '',
      data: [accepted, rejected, pending],
      id: `${i + 1}`,
    };
  });

  return {
    answers: data,
    labels: [
      getStatusFriendly(STATUSES.ACCEPTED),
      getStatusFriendly(STATUSES.REJECTED),
      getStatusFriendly(STATUSES.PENDING),
    ],
  };
};

export const KpiMetrics = [
  {
    title: 'positions created',
    // count: '60',
    tooltip: 'The number of new positions created from selected Hiring Plans',
    key: 'positionCreated',
    countSign: '',
  },
  {
    title: 'applications recieved',
    // count: '1.5',
    tooltip: 'The number of job applications received from candidates for the created positions',
    key: 'applicationRecieved',
    countSign: 'k',
  },
  {
    title: 'Hired',
    // count: '20',
    key: 'hiredApplicants',
    tooltip: 'The number of candidates who have been successfully hired',
    countSign: '',
  },
  {
    title: 'fill rate',
    // count: '20.2',
    tooltip: 'The percentage of open positions that have been successfully filled on time (with candidates)',
    key: 'fillRate',
    countSign: '%',
  },
  {
    title: 'cost per hire',
    // count: '100.7',
    key: 'costPerHire',
    tooltip: 'The average cost incurred for each successful hire, taking into account campaign expenses on occy',
    countSign: '',
  },
  {
    title: 'Hiring plans approved',
    key: 'hiringPlanApproved',
    tooltip: 'The number of hiring plans that have been approved',
    countSign: '',
  },
  {
    title: 'time to offer',
    // count: '8',
    key: 'timeToOffer',
    tooltip: 'The average amount of time it takes to extend a job offer to a candidate once the positions are created (Hiring Plan is approved)',
    countSign: 'days',
  },
  {
    title: 'total campaigns run',
    key: 'totalCampaignRun',
    tooltip: 'The overall number of recruitment campaigns launched to attract candidates',
    countSign: '',
  },
  {
    title: 'Campaign cost',
    // count: '1.5',
    key: 'campaignCost',
    tooltip: 'The total expenses associated with running recruitment campaigns',
    countSign: 'k',
  },
  {
    title: 'cost/applicant',
    // count: '50',
    key: 'costPerApplicant',
    tooltip: 'The average cost incurred for attracting each applicant from the recruitment campaigns run on occy',
    countSign: '$',
  },
  {
    title: 'applicant quality',
    key: 'applicantQuality',
    tooltip: 'The percentage of applicants who have not been declined',
    countSign: '%',
  },
  {
    title: 'hire ratio',
    key: 'hireRatio',
    tooltip: 'The ratio of successfully hired candidates to the total number of job offers extended.',
    countSign: '%',
  },
  // {
  //   title: 'candidates from campaign',
  //   // count: '1.3',
  //   tooltip: '',
  //   countSign: 'k',
  // },

  {
    title: 'candidate pool',
    // count: '0.5',
    key: 'candidatePool',
    tooltip: 'The total number of candidates in your talent pool available for consideration in current or future positions',
    countSign: 'k',
  },
  {
    title: 'screened',
    // count: '800',
    tooltip: 'The number of candidates who have reached the screen stage',
    key: 'screened',
    countSign: '',
  },
  {
    title: 'selected',
    // count: '500',
    key: 'selected',
    tooltip: 'The number of candidates who have reached the select stage',
    countSign: '',
  },
  {
    title: 'offered',
    // count: '60',
    key: 'offered',
    tooltip: 'Total number of offers extended',
    countSign: '',
  },
];

export const getPipelineDiversityData = (data, deiFilters, deiForm) => {
  if (!data) return null;
  const questionId = deiFilters?.questionId || deiForm?.options?.[0]?.value;
  const formQuestion = deiForm?.formQuestions?.find((f) => f.id === questionId);
  const questionAnswers = formQuestion?.sectionOptions?.opts || [];

  const answers = questionAnswers.map(({ label }, i) => {
    const answerExist = data.find((o) => o.answer === label) || {};
    const {
      hire = 0,
      offer = 0,
      screen = 0,
      select = 0,
      source = 0,
    } = answerExist;

    return {
      label: answerExist.answer || label || '',
      data: [source, screen, select, hire, offer],
      id: `${i + 1}`,
    };
  });
  let labels = get(data, '[0]');
  labels = isObject(labels)
    ? Object.keys(labels).filter((l) => l !== 'answer')
    : [];

  return {
    answers,
    labels: [...(labels?.map((l) => capitalizeFirstLetter(l)) || [])],
  };
};

export const EmptyChart = ({
  height='241px',
  mt,
  subClass = '',
  isLoading = false,
}) => {
  const theme = useTheme();
  return !isLoading ? (
    <StyledEmptyChart
      height={height}
      mt={mt}
      className={`d-flex align-items-center justify-content-center bg-body rounded ${subClass}`}
    >
      <NoChartIcon className="me-2" />{' '}
      <StyledLabel size="14px" weight={600} color={theme?.lightBoldBlack}>
        Not enough data to display
      </StyledLabel>
    </StyledEmptyChart>
  ) : (
    <StyledLoadingGraphSekelton
      height={height}
      mt={mt}
      width={'100%'}
      className={subClass}
    />
  );
};

export const transformDataSorted = (data, groupBy) =>
  sortBy(data, ['year', groupBy === 'week' ? 'week' : 'month']);

export const formatter = new Intl.NumberFormat('en-US', {
  notation: 'compact',
  compactDisplay: 'short',
  maximumSignificantDigits: 3,
});

export const AccountTypes = [
  {
    value: 'Basic Account',
    label: 'Guest User',
    color: 'green',
  },
  {
    value: 'Standard Account',
    label: 'Standard Account',
    color: 'orange',
  },
  {
    value: 'Child Account',
    label: 'Child Account',
    color: 'orange',
  },
];

export const CustomerStatus = [
  {
    value: STATUSES.ACTIVE,
    label: 'Active',
  },
  {
    value: STATUSES.PENDING,
    label: 'Pending',
  },
];

export const ratings = [
  {
    label: '0 - 1',
    value: 1,
  },
  {
    label: '1 - 2',
    value: 2,
  },
  {
    label: '2 - 3',
    value: 3,
  },
  {
    label: '3 - 4',
    value: 4,
  },
  {
    label: '4 - 5',
    value: 5,
  },
]

export const filterSelectedPlaceholder = {
  companyIds: 'Company Selected',
  status: 'Customers',
  departmentIds: 'Departments Selected',
  jobIds: 'Jobs Selected',
  jobType: 'Job Types Selected',
  location: 'Locations Selected',
  role: 'Roles Selected',
  source: 'Source Selected',
  accountType: 'Account Type Selected',
  // position:'Levels Selected',
  approverIds: 'Approver Selected',
  recruitersIds: 'Recruiter Selected',
  hiringManagerIds: 'Hiring Managers Selected',
  interviewerIds: 'Interviewers Selected',
  campaignName:'Campaigns Selected',
  campaignJobName:'Job Name For Campaigns Selected',
  campaignStatus:'Campaigns',
  campaignRepeatAfter:'days',
  channelIds:'Channels Selected'
 
};

export const filterSelectedPrefix = {
  campaignReferenceId: 'Ref-id: ',
  campaignDuration:'Duration: ',
  campaignRepeatAfter:'Repeat After ',
  rating:'Rating ',
  candPostcode:'Candidate Postcode: ',
  candTown:'Candidate Town: ',
  campaignStartDate:'Campaign Starts ',
  campaignEndDate:'Campaign Ends ',
  hp_start_date:'Job Starts ',
  
};
const verifyChartValue = (value) =>
  !value || value===null || Number(value) < 0.01 ? 0 : Number(value);

export const formateDataByMonth = (data, proptyName,groupBy='month',params) => {
  
  const responceData = Array(12).fill(null);
  if(groupBy==='month')
  {
   const monthsNumbers= getGraphLabels(params?.toDate, params?.fromDate, 'Month', true)
  data.forEach((item) => {
    const monthIndex = monthsNumbers.findIndex(mNum=>Number(mNum?.value) === item?.month && Number(mNum?.year) === item?.year); // Subtract 1 because arrays are 0-based
    responceData[monthIndex] = verifyChartValue(item[proptyName]);
  });

  }
  else{
    const weeksNumbers = generateWeeklyLabels(params.fromDate,params.toDate)
    data.forEach((item) => {
    const weekIndex = weeksNumbers.findIndex(wnum=>wnum?.value===Number(item?.week) && Number(wnum?.year) === item?.year);
    responceData[weekIndex] = verifyChartValue(item[proptyName]);
  });}
  return responceData;
};

export const StyledCards = styled.div`
  //  min-width: 185px;
  //  max-width:188px;
  heigth: 60px;
  padding: 6px, 0px, 6px, 0px;
  .state-tile {
    .copyIcon {
      display: none;
      position: absolute;
      right: 0px;
      top: 0px;
    }

    &:hover {
      cursor: pointer;
      .copyIcon {
        display: block;
      }
    }
  }
`;

export const StyledMainContainer = styled.div`
  height: ${(props) => (props?.height ? `${props?.height}` : '806px')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? `1130px` : '1328px')};
`;
export const StyledKpis = styled.div`
  height: 60px;
  width: ${({ sidebarVisible }) => (sidebarVisible ? `1140px` : '1280px')};
  margin-bottom: 10px;
`;

export const StyledChartContainer = styled.div`
  height: ${(props) => (props?.height ? `${props?.height}` : '357px')};
  width: ${({ sidebarVisible }) => (sidebarVisible ? `1120px` : '1280px')};
`;

export const StyledSingleChart = styled.div`
max-height: ${(props) => (props?.maxHeight ? `${props?.maxHeight}` : '')};
  height: ${(props) => (props?.height ? `${props?.height}` : '357px')};
  width ${(props) =>
    props?.width
      ? `${props?.width}`
      : props?.sidebarVisible
      ? `560px`
      : '632px'};
  padding: 20px;
  padding-top:${(props) => props?.pt && `${props?.pt}`};
    padding-left:${(props) => props?.pl && `${props?.pl}`};
    .left{
      margin-left:-38px;
    }
`;

export const StyledEmptyChart = styled.div`
  height: ${(props) => (props?.height ? `${props?.height}` : '241px')};
  margin-top: ${(props) => (props?.mt ? `${props?.mt}` : '20px')};
`;

export const StyledLoadingGraphSekelton = styled(Skeleton)`
height: ${(props) => (props?.height ? `${props?.height}` : '241px')};
  margin-top: ${(props) => (props?.mt ? `${props?.mt}` : '20px')};
`;

export const salaryOptions = ['Hourly', 'Daily', 'Yearly'];

export const SalaryRange = {
  Hourly: [
    {
      id: 1,
      min: 5,
      max: undefined,
      name: 'UP to £5',
    },
    {
      id: 2,
      min: 6,
      max: 10,
      name: '£6 - £10',
    },
    {
      id: 3,
      min: 11,
      max: 15,
      name: '£11 - £15',
    },
    {
      id: 4,
      min: 16,
      max: 20,
      name: '£16 - £20',
    },
    {
      id: 5,
      min: 21,
      max: 25,
      name: '£21 - £25',
    },
    {
      id: 6,
      min: 26,
      max: 30,
      name: '£26 - £30',
    },
    {
      id: 7,
      min: 31,
      max: 35,
      name: '£31 - £35',
    },
    {
      id: 8,
      min: 36,
      max: 40,
      name: '£36 - £40',
    },
    {
      id: 9,
      min: 41,
      max: 45,
      name: '£41 - £45',
    },
    {
      id: 10,
      min: 46,
      max: 50,
      name: '£46 - £50',
    },
    {
      id: 11,
      min: 51,
      max: undefined,
      name: '£51+',
    },
  ],
  Daily: [
    {
      id: 1,
      min: 100,
      max: undefined,
      name: 'UP to £100',
    },
    {
      id: 2,
      min: 101,
      max: 200,
      name: '£101 - £200',
    },
    {
      id: 3,
      min: 201,
      max: 300,
      name: '£201 - £300',
    },
    {
      id: 4,
      min: 301,
      max: 400,
      name: '£301 - £400',
    },
    {
      id: 5,
      min: 401,
      max: 500,
      name: '£401 - £500',
    },
    {
      id: 6,
      min: 501,
      max: 600,
      name: '£501 - £600',
    },
    {
      id: 7,
      min: 601,
      max: 700,
      name: '£601 - £700',
    },
    {
      id: 8,
      min: 701,
      max: 800,
      name: '£701 - £800',
    },
    {
      id: 9,
      min: 801,
      max: 900,
      name: '£801 - £900',
    },
    {
      id: 10,
      min: 901,
      max: 1000,
      name: '£901 - £1,000',
    },
    {
      id: 11,
      min: 1001,
      max: undefined,
      name: '£1,001+',
    },
  ],
  Yearly: [
    {
      id: 1,
      min: 10000,
      max: undefined,
      name: 'UP to £10,000',
    },
    {
      id: 2,
      min: 10001,
      max: 20000,
      name: '£10,001 - £20,000',
    },
    {
      id: 3,
      min: 20001,
      max: 30000,
      name: '£20,001 - £30,000',
    },
    {
      id: 4,
      min: 30001,
      max: 40000,
      name: '£30,001 - £40,000',
    },
    {
      id: 5,
      min: 40001,
      max: 50000,
      name: '£40,001 - £50,000',
    },
    {
      id: 6,
      min: 50001,
      max: 60000,
      name: '£50,001 - £60,000',
    },
    {
      id: 7,
      min: 60001,
      max: 80000,
      name: '£60,001 - £80,000',
    },
    {
      id: 8,
      min: 80001,
      max: 100000,
      name: '£80,001 - £100,000',
    },
    {
      id: 9,
      min: 100001,
      max: undefined,
      name: '£100,001',
    },
  ],
};

export const clipboardDatatoCSV = (data = [], showToast=true) => {
  if (data) {
    const keys = Object.keys(data[0]);
    const headers = keys?.map((titles) => toTitleCase(titles, '_'));
    const csvData = [headers]?.concat(
      data.map((obj) => keys?.map((key) => obj[key])),
    );
    // Convert the array of arrays to a CSV string
    const csvString = csvData?.map((row) => row?.join('\t'))?.join('\n');
    // Check if the browser supports the Clipboard API
    if (navigator?.clipboard) {
      navigator?.clipboard
        .writeText(csvString)
        .then(() => {
          showToast && toast.success('Copied');
        })
        .catch((err) => {
          console.error('Copy to clipboard failed:', err);
        });
    } else {
      console.error('Clipboard API is not supported in this browser.');
    }
  }
};



export const AnalysisFiltersCampaignOptions = [
  { title: 'Campaign Name', value: 'campaign_name' },
  { title: 'Company', value: 'customers' },
  { title: 'Campaign Job Name', value: 'campaign_job_name' },
  { title: 'Campaign Reference Id', value: 'campaign_reference_id' },
  { title: 'Campaign Start Date', value: 'campaign_start_date' },
  { title: 'Campaign End Date', value: 'campaign_end_date' },
  { title: 'Campaign Duration', value: 'campaign_duration' },
  { title: 'Campaign Repeat After', value: 'campaign_repeat_after' },
  { title: 'Campaign Status', value: 'campaign_status' },
  { title: 'Job Category', value: 'categories' },
  // { title: 'Company', value: 'customers' },
  { title: 'Copywritten', value: 'copywritten' },
  { title: 'Employment Type', value: 'employmenttype' },
  { title: 'Job Level', value: 'joblevel' },
  { title: 'Job Title', value: 'jobtitle' },
  { title: 'Department', value: 'departments' },
  { title: 'Budgeted', value: 'budgeted' },
  { title: 'Approver', value: 'approver' },
  { title: 'Hiring Manager', value: 'hiring_manager' },
  // { title: 'Recruiter', value: 'recruiter' },
  { title: 'Job Address', value: 'hp_location' },
  { title: 'Job Postcode', value: 'hp_postcode' },
  { title: 'Job Town', value: 'hp_town' },
  { title: 'Job Country', value: 'hp_country' },
  { title: 'Remote', value: 'remote' },
  { title: 'Job Start Date', value: 'hp_start_date' },
  { title: 'Pay Period', value: 'pay_period' },
  { title: 'Pay Currency', value: 'pay_currency' },
  { title: 'Is Competitive', value: 'is_competitive' },
];

export const AnalysisFiltersCandAnalysisOptions = [
  { title: 'Company', value: 'customers' },
  { title: 'Department', value: 'departments' },
  { title: 'Job Category', value: 'categories' },
  { title: 'Job Level', value: 'joblevel' },
  { title: 'Employment Type', value: 'employmenttype' },
  { title: 'Job Title', value: 'jobtitle' },
  { title: 'Copywrite Status', value: 'copywritten' },
  { title: 'Budgeted', value: 'budgeted' },
  { title: 'Approver', value: 'approver' },
  { title: 'Hiring Manager', value: 'hiring_manager' },
  // { title: 'Recruiter', value: 'recruiter' },
  { title: 'Job Location', value: 'hp_location' },
  { title: 'Job Postcode', value: 'hp_postcode' },
  { title: 'Job Town', value: 'hp_town' },
  { title: 'Job Country', value: 'hp_country' },
  { title: 'Remote', value: 'remote' },
  { title: 'Job Start Date', value: 'hp_start_date' },
  { title: 'Pay Period', value: 'pay_period' },
  { title: 'Pay Currency', value: 'pay_currency' },
  { title: 'Is Competitive', value: 'is_competitive' },
  { title: 'Campaign Name', value: 'campaign_name' },
  { title: 'Job Name For Campaign', value: 'campaign_job_name' },
  { title: 'Campaign Reference Id', value: 'campaign_reference_id' },
  { title: 'Campaign Start Date', value: 'campaign_start_date' },
  { title: 'Campaign End Date', value: 'campaign_end_date' },
  { title: 'Campaign Duration', value: 'campaign_duration' },
  { title: 'Campaign Repeat After', value: 'campaign_repeat_after' },
  { title: 'Campaign Status', value: 'campaign_status' },
  { title: 'Channels', value: 'channel' },
  { title: 'Ratings', value: 'rating' },
  { title: 'Candidate Postcode', value: 'cand_postcode' },
  { title: 'Candidate Town', value: 'cand_town' },
  { title: 'Interview Scheduled', value: 'interview_scheduled' },
  { title: 'Rejected', value: 'cand_rejected' },
  { title: 'Reason For Rejection', value: 'rejected_reason' },
];

export const AnalysisFiltersJobOption = [
  { title: 'Job Category', value: 'categories' },
  { title: 'Company', value: 'customers' },
  { title: 'Copywritten', value: 'copywritten' },
  { title: 'Employment Type', value: 'employmenttype' },
  { title: 'Job Level', value: 'joblevel' },
  { title: 'Job Title', value: 'jobtitle' },
  { title: 'Department', value: 'departments' },
  { title: 'Budgeted', value: 'budgeted' },
  { title: 'Approver', value: 'approver' },
  { title: 'Hiring Manager', value: 'hiring_manager' },
  // { title: 'Recruiter', value: 'recruiter' },
  { title: 'Job Postcode', value: 'hp_postcode' },
  { title: 'Job Town', value: 'hp_town' },
  { title: 'Job Country', value: 'hp_country' },
  { title: 'Remote', value: 'remote' },
  { title: 'Job Start Date', value: 'hp_start_date' },
  { title: 'Pay Period', value: 'pay_period' },
  { title: 'Pay Currency', value: 'pay_currency' },
  { title: 'Is Competitive', value: 'is_competitive' },
];




export const getKPIPercentage=(previousValue , currentValue)=>{

  if(!previousValue && !currentValue) return 0;
  else if(previousValue && !currentValue) return -100;
  else if(!previousValue && currentValue) return '-';
  else if(previousValue > currentValue)  return  - Math.ceil(((previousValue - currentValue )/ previousValue) * 100)
  else return Math.ceil(((currentValue - previousValue)/ currentValue) * 100)
}



export const synatizeCount=(value)=>  Number(typeof value === 'string'? value.replace(/[^0-9.]/g, ''):value)




export const prepareKpiData = (title, daterange, newData, previousData) => [
  {
    title,
    'Start Date': moment(daterange.fromDate).format('Do MMM YYYY'),
    'End Date':  moment(daterange.toDate).format('Do MMM YYYY'),
    count: newData || 0,
  },
  {
    title,
    'Start Date': moment(daterange?.fromDate).subtract(
      daterange?.toDate?.diff(daterange?.fromDate, 'days'),
      'days',
    ).format('Do MMM YYYY'),
    'End Date': moment(daterange?.toDate).subtract(
      daterange?.toDate?.diff(daterange?.fromDate, 'days'),
      'days',
    ).format('Do MMM YYYY'),
    count: previousData || 0,
  },
];


export const calculatePercentage = (count, totalItems) => {
  if(totalItems===0) return 0;
  const percentage = (count / totalItems) * 100;
  return Math.round(percentage);
}



export const generateWeeklyLabels = (start, end) => {
  const weeks = [];
  let currentWeek = start.clone().startOf('isoWeek');
  while (currentWeek.isBefore(end) || currentWeek.isSame(end, 'week')) {
    const isoWeekNumber = currentWeek.isoWeek();
    weeks.push({label:`w${isoWeekNumber}`,value:isoWeekNumber, year: currentWeek.format('Y')});
    currentWeek.add(1, 'week');
  }
  return weeks;
};



export const jobTabfiltersKeys = ['companyIds', 'jobIds','departmentIds', 'categoryIds', 'jobLevel', 'employmentType','jobType','copywriteStatus','budgeted', 'remoteStatus', 'isCompetitive', 'hp_start_date', 'hiringManagerIds','approverIds','town','postcode','country','address','salaryPeriod','salaryCurrency', 'recruitersIds'];

export const campaignTabfiltersKeys = ['companyIds', 'jobIds','departmentIds', 'categoryIds', 'jobLevel', 'employmentType','jobType','copywriteStatus','budgeted', 'remoteStatus', 'isCompetitive', 'hp_start_date', 'hiringManagerIds','approverIds','town','postcode','country','address','salaryPeriod','salaryCurrency', 'campaignName', 'campaignJobName', 'campaignReferenceId', 'campaignStartDate', 'campaignEndDate', 'campaignDuration', 'campaignRepeatAfter', 'campaignStatus', 'channelIds', 'recruitersIds'];

export const candidateTabfiltersKeys = ['companyIds', 'jobIds','departmentIds', 'categoryIds', 'jobLevel', 'employmentType','jobType','copywriteStatus','budgeted', 'remoteStatus', 'isCompetitive', 'hp_start_date', 'hiringManagerIds','approverIds','town','postcode','country','address','salaryPeriod','salaryCurrency', 'campaignName', 'campaignJobName', 'campaignReferenceId', 'campaignStartDate', 'campaignEndDate', 'campaignDuration', 'campaignRepeatAfter', 'campaignStatus', 'channelIds', 'rating', 'candPostcode', 'candTown', 'interviewScheduled', 'interviewStatus', 'candStageGroup', 'rejected', 'rejectionReason', 'recruitersIds'];

export const getFiltersParamsValues = (filters, filterName) => {
  switch (filterName) {
    case 'jobIds':
    case 'departmentIds':
    case 'hiringManagerIds':
    case 'approverIds':
    case 'jobType':
    case 'channelIds':
    case 'companyIds':
    case 'recruitersIds':
      return filters[filterName]?.map((item) => item?.value);
    case 'campaignName':
    case 'campaignJobName':
      return filters[filterName]?.map((item) => item?.label);
    case 'categoryIds':
      return filters[filterName]?.value;
    case 'rejectionReason':
      return filters[filterName]?.label;
    case 'budgeted':
    case 'isCompetitive':
    case 'interviewScheduled':
    case 'rejected':
      return filters[filterName] === 'yes'
        ? 1
        : filters[filterName] === 'no'
        ? 0
        : undefined;
    default:
      return filters[filterName];
  }
};

export const compaignStatus = [
  {
    label: 'Live',
    value: STATUSES?.LIVE,
  },
  {
    label: 'Partially Live',
    value: STATUSES?.PARTIALLY_LIVE,
  },
  {
    label: 'Draft',
    value: STATUSES?.DRAFT,
  },
  {
    label: 'Pending',
    value: STATUSES?.PENDING,
  },
  {
    label: 'Closed',
    value: STATUSES?.CLOSED,
  },
  {
    label: 'Scheduled',
    value: STATUSES?.SCHEDULED,
  },
  {
    label: 'Suspended',
    value: STATUSES?.SUSPENDED,
  },
  {
    label: 'Failed',
    value: STATUSES?.FAILED,
  },
];


export const InterviewStatus = [
  {
    label: 'Scheduled',
    value: STATUSES?.ACTIVE,
  },
  {
    label: 'Confirmed',
    value: STATUSES?.ACCEPTED,
  },
  {
    label: 'Awaiting Feedback',
    value: STATUSES?.AWAITING_FEEDBACK,
  },
];


export const candidateStageGroups = [
  {
    label: 'Screened',
    value: 'screened',
  },
  {
    label: 'Selected',
    value: 'selected',
  },
  {
    label: 'Offered',
    value: 'offered',
  },
  {
    label: 'Hired',
    value: 'hired',
  },
];





export const PieChartColors = [theme.graphOrange, theme.lightSeaGreen, theme.SlateBlue, theme.graphPrimary, theme.berylGreen, theme.orange, theme.gold]

export const  getRandomColor = () => {
  const colorsArray = Object.values(theme);
  const randomIndex = Math.floor(Math.random() * colorsArray.length);
  return colorsArray[randomIndex];
}



export const getParamsbytab=(tabKeys,globalFilters)=>{
  const valuesByFilter = {};
  tabKeys.forEach((key) => {
    if (key in globalFilters) {
      const value = getFiltersParamsValues(globalFilters, key);
      if (
        !isEmpty(value) || ( typeof value ==='number' && value > 0 ) ||  isDate(value)||
        (['isCompetitive', 'budgeted','rejected','interviewScheduled'].includes(key) &&
          (value === 0 || value === 1))
      ) valuesByFilter[key] = value;
      
    }
  });

  return valuesByFilter
}


export const convertTitlestoReadable = (value, groupBy) => {
  switch (groupBy) {
    case 'campaign_reference_id':
    case 'campaign_duration':
    case 'campaign_repeat_after':
      return value;
      case 'customers':
      return value;
    case 'hiring_manager':
    case 'approver':
      return value?.trim();
    case 'campaign_start_date':
      return moment(value).isValid()? moment(value).format('Do MMM YY'):'';
      case 'campaign_name':
        return value;
        case 'hp_location':
        return value;
        case 'hp_postcode':
          return value;
    case 'campaign_end_date':
      return moment(value).isValid()? moment(value).format('Do MMM YY'):'';
    case 'hp_start_date':
      return  (value !== 'Others' && moment(value).isValid()) ? moment(value).format('Do MMM YY'):'Others';
    case 'cand_town':
      return value?.replace(/[^a-zA-Z]/g, '') || '';
    case 'campaign_status':
      return getStatusFriendly(value);
    default:
      return startCase(value||'-');
  }
};


export const allFilters = [
  {
    name: 'Companies',
    id: 1,
    data: {},
    allowed: [3, 4, 5],
    allfilters: ['companyIds'],
  },
  // {
  //   name: 'Customer',
  //   id: 2,
  //   data: { status: '', role: '', department: '', accountType: '' },
  // },
  {
    name: 'Job',
    id: 3,
    data: {
      category: '',
      jobType: '',
      jobDepartment: '',
      level: '',
      employmentType: '',
      postCategory: '',
    },
    allowed: [3, 4, 5],
    allfilters: [
      'jobIds',
      'categoryIds',
      'departmentIds',
      'jobLevel',
      'employmentType',
      'copywriteStatus',
    ],
  },
  {
    name: 'Hiring Plan',
    id: 4,
    data: {
      job: '',
      location: '',
      hiringPlanJobType: '',
      remote: '',
      startDate: '',
      endDate: '',
      budgeted: '',
      salary: '',
      hiringManager: [],
      recruiters: [],
      interviewers: [],
    },
    allowed: [3, 4, 5],
    allfilters: [
      'jobType',
      'remoteStatus',
      'budgeted',
      'isCompetitive',
      'salaryPeriod',
      'salaryCurrency',
      'hiringManagerIds',
      'approverIds',
      'address',
      'postcode',
      'town',
      'country',
      'recruitersIds'
    ],
  },
  {
    name: 'Campaigns',
    id: 5,
    data: {},
    allfilters: [
      'campaignName',
      'campaignJobName',
      'campaignReferenceId',
      'campaignStartDate',
      'campaignEndDate',
      'campaignDuration',
      'campaignRepeatAfter',
      'campaignStatus',
      'channelIds',
    ],
    allowed: [4, 5],
  },
  {
    name: 'Applications',
    id: 6,
    data: {},
    allowed: [5],
    allfilters: [
      'rating',
      'candPostcode',
      'candTown',
      'interviewScheduled',
      'interviewStatus',
      'candStageGroup',
      'rejected',
      'rejectionReasons',
    ],
  },
];




export const checkDatasetIsNotEmpty=(chartData, index=0)=>chartData && chartData?.datasets && !chartData?.datasets[index]?.data?.every(item=>Number(item)===0)


export const checkInterviewDatasetIsNotEmpty = (chartData) =>
  chartData &&
  chartData[0] &&
  (Number(chartData[0]?.NOT_RESPONDED) !== 0 ||
    Number(chartData[0]?.CONFIRMED) !== 0);

export const toFixedOneDecimal= (value) => value ? Number(value).toFixed(1) : 0;


export const valueToLocaleString= (value) => value ? Number(value).toLocaleString('en-US') : 0;


export const setNullArayValuesToZero= (data) => map(data, value => isNil(value) ? 0 : value);

export const checkNull= (value) => value??0 ;



const generateDateRange = (fromDate, toDate, sortedPairs, property) => {
    const result = [];
    if (sortedPairs.length === 0) {
        return result;
    }
    const fromDateMoment = moment(fromDate);
    const toDateMoment = moment(toDate);
    let current,end ;
    if(property==="week"){
     current = moment().isoWeekYear(sortedPairs[0].year).isoWeek(sortedPairs[0][property]).startOf('isoWeek').subtract(1,'day');
     end=moment().isoWeekYear(sortedPairs[sortedPairs.length - 1].year).isoWeek(sortedPairs[sortedPairs.length - 1][property]).endOf('isoWeek');
    }
    else {
      current = moment({[property]: sortedPairs[0][property], year: sortedPairs[0].year});
      end= moment({
        [property]: sortedPairs[sortedPairs.length - 1][property],
        year: sortedPairs[sortedPairs.length - 1].year
    });
    }
    
   
    let startDate = current < fromDateMoment? current : fromDateMoment ;
    let lastDate= end > toDateMoment ? end : toDateMoment;

    while (startDate <= lastDate) {
            result.push({
                [property]: property === "week"? startDate?.isoWeek() : startDate.get(property) + 1,
                year: startDate.year()
            });
        startDate = moment(startDate).add(1, property);
    }
    return result;
};


// const fromDate = new Date('Sun Jul 30 2023 00:00:00 GMT+0500');
// const toDate = new Date('Mon Jan 29 2024 23:59:59 GMT+0500');

export const getUniqueSortedLabelsByYear = (data, property, arg) => {
  // Extract month/week and year pairs from the dataset
  const monthYearPairs = map(data, item => ({ [property]: item[property], year: item.year }));

  // Create a unique set of month/week-year pairs using lodash's unionBy function
  const uniquePairs = unionBy(monthYearPairs, pair => `${pair[property]}-${pair.year}`);

  // Sort the unique pairs by year and then by month/week
  const sortedPairs = sortBy(uniquePairs, ['year', property]);

  const generatepairs= generateDateRange(arg?.fromDate, arg?.toDate, sortedPairs,property)

  // Extract month/week numbers from the sorted pairs
  const sortedUniqueNames = map(generatepairs, pair => ({
    name:property==="month"? moment().month(pair[property] - 1).format('MMM'):`w${pair[property]}`, // Assuming month starts from 1
    number: pair[property],
    year: pair.year
  }));

  return sortedUniqueNames;
};







export const getDataValueAgainstLabels = (labels, data, groupBy, key) => {
  return map(labels, item => {
    const foundCandidate = find(data, {
      [groupBy]: item?.number,
      year: item?.year,
    });
    return foundCandidate ? get(foundCandidate, key, 0):0;
  });
};