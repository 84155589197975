import styled from 'styled-components';
import React from 'react';

const StyledIconInput = ({
  width,
  icon,
  placeholder = 'Search',
  inputClass,
  value,
  icon1,
  loading,
  onChange,
  className,
  elementRef,
  ...props
}) => {
  return (
    <div className={` ${className ? className : 'flex-grow-1'}`}>
      <InputStyled {...props}>
        {icon && icon}
        <input
          type="search"
          placeholder={placeholder}
          className={`${inputClass} ${props.small ? "p-1" : "p-2"} rounded`}
          value={value}
          onChange={onChange}
          ref={elementRef}
        />
        <span
          className={`spinner-border spinner-border-sm me-2 p-1 ${!loading ? 'd-none' : 'd-inline'
            }`}
          style={{
            transition: 'all 0.3s linear',
          }}
        />
        {icon1}
      </InputStyled>
    </div>
  );
};

const InputStyled = styled.div`
  padding: 0 0 0 6px;
  max-width: ${({ $maxWidth }) => ($maxWidth ? $maxWidth : '50em')};
  font-size: 12px;
  width: 100%;
  border-radius: 6px;
  border: ${({ theme }) =>theme?.dark ? `0.5px solid ${theme.tableHeaderBg}`: `1.5px solid ${theme.inputBorder}`};
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.inputColor} !important;
  background-color: ${({ theme, $background }) =>
    theme?.dark ? theme?.tableHeaderBg : $background ? $background : theme.bodyBg};
  &:focus-within {
    color: ${({ theme }) => theme.inputColor};
    border: ${({ theme }) =>theme?.dark ? `0.5px solid ${theme.primaryLight2}`: `1.5px solid ${theme.primaryLight2}`};
    background-color: ${({ theme, $background }) =>
      theme?.dark ? theme?.tableHeaderBg : $background ? $background : theme.bodyBg};
  }
  input {
    border: 0;
    border-radius:${({ $borderRadius }) => ($borderRadius ? $borderRadius : '0')};;
    width: 100%;
    margin-left: ${({ $marginLeft }) => ($marginLeft ? $marginLeft : '3px')};
    font-weight: 600;
    background-color: ${({ theme, $background }) =>
      theme?.dark ? theme?.tableHeaderBg : $background ? $background : theme.bodyBg};
    &:valid {
      background-image: none;
    }
    &:focus {
      box-shadow: none;
      outline: none;
      border: 0;
      background-color: ${({ theme, $background }) =>
        theme?.dark ? theme?.tableHeaderBg : $background ? $background : theme.bodyBg};
    }
    &::placeholder {
      color: ${({ theme }) => theme.inputPlaceHolder};
      opacity: 1;
    }
  }
`;

export default StyledIconInput;
