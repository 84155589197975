//*Dropzone.js*//

import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import ResumeIcon from '../svgIcons/ResumeIcon';
import styled from 'styled-components';

function Dropzone({
  onDrop,
  accept,
  label,
  content,
  name,
  hideName,
  isInValid,
  type,
  values
}) {
  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    accept,
    onDrop,
  });
  const [selectedFile, setSelectedFile] = useState()
  useEffect(() => {
    acceptedFiles?.length > 0 && setSelectedFile(acceptedFiles[0])
  }, [acceptedFiles])

  useEffect(() => {
    name === 'candidateKey' && setSelectedFile(values?.candidateKey?.length ? values?.candidateKey[0]?.file ?? values?.candidateKey[0] : null)
  }, [values?.candidateKey])

  return (
    <div {...getRootProps({ className: 'dropzone' })}>
      <input className="input-zone" {...getInputProps()} />
      <div className={`text-center ${isInValid ? 'border border-danger' : ''}`}>
        <StyledResumeContainer className={`mt-2 ${type === 'career' && 'bg-white'} dropzone_container`}>
          <div className="d-flex flex-column align-items-center mt-3">
            <>
              {type !== 'career' && <ResumeIcon />}
              <label className={content ? 'mt-2' : 'my-4'}>{label}</label>
              {(content && type !== 'career') && <label> or</label>}
              {content}
            </>
          </div>
        </StyledResumeContainer>
      </div>
      {!(
        name === 'candidate' ||
        name === 'candidateKey' ||
        name === 'jobSummaryId' ||
        name === 'documents'   ||
        hideName
      )  && (
        <aside className="mb-3">
          {selectedFile && <ul><li>
            {selectedFile?.path}
          </li>
          </ul>}
        </aside>
      )}
    </div>
  );
}

const StyledResumeContainer = styled.div`
  border: 2px dashed ${({theme}) => theme.inputDisabled};
  background-color: ${({theme}) => theme.bodyBg};
  display: flex;
  justify-content: center;
  gap: 8px;
  height: auto;
  border-radius: 16px;
  cursor: pointer;

  label {
    font-weight: 600;
  }
  .btn-text{
  :focus,
  :hover{
    background-color: transparent !important;
    border: none !important;
    color: ${({theme}) => theme.primaryText};
    }}
`;

export default Dropzone;
