import styled from 'styled-components';

export const StyledCheck = styled.span`
  height: ${(props) => (props?.size ? `${props?.size}px` : '20px')};
  width: ${(props) => (props?.size ? `${props?.size}px` : '20px')};
  border-radius: ${(props) => (props?.size ? `${props?.size / 3}px` : '6px')};
  margin-bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ theme }) => theme.white};

  transition: background-color 0.3s ease;
  background-color: ${({ theme, value, forms }) =>
    value ? theme.secondary : forms ? '' : theme.white};
  border: 2px solid
    ${({ theme, value }) => (value ? theme.secondary : theme.inputBorder)};
  cursor: pointer;

  svg {
    fill: currentColor;
  }

  &.publicForms {
    background-color: ${({ theme, value }) =>
      value ? theme.backgroundColor : ''};
    border: 2px solid
      ${({ theme, value }) => (value ? theme.backgroundColor : theme.primary)};

    color: ${({ theme, value }) =>
      value ? theme.primary : theme.backgroundColor};
  }
`;
