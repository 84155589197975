import styled from 'styled-components';

const StyledImage = styled.div`
  overflow: hidden;
  width: ${(props) => props?.width || '30px'};
  margin: 0;
  height: ${(props) => props?.height || '30px'};
  border: ${(props) => props?.border || 'none'};
  border-radius: ${(props) => props?.borderRadius || 'none'};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 100%;
    height: 100%;
    object-fit: fill;
  }
`;
export default StyledImage;
