import moment from 'moment';
import React, { useEffect, useMemo } from 'react';
import { useState } from 'react';
import { Calendar } from 'react-calendar';
import { Link } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import StyledSelect from '../../../app/components/select/StyledSelect';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import BriefCaseColorIcon from '../../../app/components/svgIcons/BriefCaseColorIcon';
import ClockFillIcon from '../../../app/components/svgIcons/ClockFillIcon';
import DropDownIcon from '../../../app/components/svgIcons/DropDownIcon';
import GlobalIcon from '../../../app/components/svgIcons/GlobalIcon';
import LocationIcon from '../../../app/components/svgIcons/LocationIcon';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import {
  getAdvertRedirectUrl,
  useQuery,
  useWindowSize,
} from '../../../utils/helper';
import {
  useGetInterviewDetailsQuery,
  useGetInterviewSlotsQuery,
  usePostInterviewSlotTimeMutation,
} from '../../Interviews/InterviewSliceApi';
import CandidateInterviewConfirm from './CandidateInterviewConfirm';
import CandidateProposeModal from './CandidateProposeModal';
import moments from 'moment-timezone';
import BackArrowIcon from '../../../app/components/svgIcons/BackArrowIcon';
import BottomModal from './MobileCalendarModal';
import { Helmet } from 'react-helmet';
import { capitalize, isArray, flatMap } from 'lodash';
import Contactus from './Contactus';
import { STATUSES } from '../../../utils/Statuses';
import { StyledCustomLoader } from '../../../app/components/styledComponents/StyledCustomLoader';
import Calender365Icon from '../../../app/components/svgIcons/Calender365Icon';
import MeetIcon from '../../../app/components/svgIcons/MeetIcon';
import StyledToolTip from '../../../app/components/styledComponents/StyledToolTip';
import StyledImage from '../../../app/components/styledComponents/StyledImage';
import Skeleton from 'react-loading-skeleton';
import InterviewZeroStateIcon from '../../../app/components/svgIcons/InterviewZeroStateIcon';
import SlotsSkeleton from '../../calender/component/SlotsSkeleton';

const CandidateSelfSchedule = () => {
  const theme = useTheme();
  const timezone = moment.tz.guess();
  const offset = moment.tz(timezone).format('Z');
  const autoTimezone = `GMT${offset} (${timezone})`;
  const query = useQuery();
  const token = query?.get('token');
  const isSmallScreen = useWindowSize();
  const [slotResponse, setSlotResponse] = useState([]);
  const {
    data: interviewData,
    refetch,
    isLoading: dataLoading,
  } = useGetInterviewDetailsQuery(token);
  const { data: interviewSlots, isLoading:slotsLoading,refetch: refetchSlots } =
    useGetInterviewSlotsQuery(token);
  const [contactus, setContactus] = useState(false);
  const [avtiveTimezone, setActiveTimezone] = useState(autoTimezone);
  const [selectDate, setSelectDate] = useState();
  const [date, setDate] = useState('');
  const [day, setDay] = useState('');
  const [chooseDate, setChooseDate] = useState(false);
  const [bookSlot, setBookSlot] = useState({ hour: '', date: '' });
  const [showPropseModal, setShowPropseModal] = useState(false);
  const [interviewTimeSlot, { isLoading }] = usePostInterviewSlotTimeMutation();
  const [confirmedSlot, setConfirmedSlot] = useState({});
  const [timeZone, setTimeZone] = useState(autoTimezone);
  const [defaultData, setDefaultData] = useState(slotResponse);
  const [filterDates, setFilterDates] = useState([]);
  const handleSubmit = (values) => {
    // console.log('vlaues', values);
  };

  const [visibility, setVisibility] = useState(false);

  const [index, setIndex] = useState(0);

  const selectedDate = (date, timezone) => {
    let dateString = moment(date, 'DD MMM YYYY, ddd').format('DD MMM YYYY');
    let dayString = moment(date, 'DD MMM YYYY, ddd').format('dddd');
    setDate(dateString);
    setDay(dayString);
    setSelectDate(date);
    const filteredArray =
      isArray(defaultData) &&
      defaultData?.filter((slot) => {
        let convTime = moment(slot?.availableDate)
          .tz(timeZone)
          .format('Do MMM YYYY');
        let slotDate = new Date(moment(convTime, 'Do MMM YYYY').format());

        return (
          slotDate?.getDate() === date?.getDate() &&
          slotDate?.getMonth() === date?.getMonth() &&
          slotDate?.getFullYear() === date?.getFullYear()
        );
      });
    setIndex(0);
    const transformArray = testData(filteredArray);
    setFilterDates(transformArray);
    if (isSmallScreen) setVisibility(true);
  };
  const confirmedInterviewSlot = (itemHour, itemHourTo, itemDate) => {
    const date3 = itemHour;
    const date4 = itemHourTo;
    const date = moment(itemDate);
    const timeStart = date3;
    const [hours, minutes] = timeStart.split(':');
    date.hours(hours);
    date.minutes(minutes);
    const startDate = date.toString();
    const date2 = moment(itemDate);
    const timeEnd = date4;
    const [hours2, minutes2] = timeEnd.split(':');
    date2.hours(hours2);
    date2.minutes(minutes2);
    const endDate = date2.toString();
    const timeZoneName = timeZone;
    let dateTime = moment(startDate ?? new Date()).format(
      'ddd MMM DD YYYY HH:mm:ss',
    );

    /**
     * the timezone is calculated before candidate chooses slot. this causes problem in day light saving timezones. because in that case, the timezone is still same but the offset changes.
     * i.e. the GMT goes up or down 1 hour. so to accomodate that, we are caclulating it again here so that if the choosen slot is within the range, then we use based on that
     * this implementation will give result GMT 0 for UK time in winter and GMT +1 in summer
     * @type {string}
     */
    const useableTimeZone = `${moment(dateTime).format("ZZ")} ${avtiveTimezone.split(" ").pop()}`

    let customStartDate = `${dateTime} ${useableTimeZone}`;

    let endateTime = moment(endDate ?? new Date()).format(
      'ddd MMM DD YYYY HH:mm:ss',
    );

    let customEndDate = `${endateTime} ${useableTimeZone}`;

    let values = {};
    values.token = token;
    values.startDate = customStartDate;
    values.endDate = customEndDate;
    values.timeZoneName = timeZoneName;
    values['timezone'] = useableTimeZone;
    interviewTimeSlot(values).then((res) => {
      const data = res.data.responseEvent;
      setConfirmedSlot({ data, confirm: true });
      refetchSlots();
    });
  };
  const timeConversion = (time) => {
    let times =
      time < 60 ? time + ' minutes' : (time / 60).toFixed(0) + ' hours';
    return times;
  };
  const dateConversion = (dateTime) => {
    let timezone = '';
    const pattern = /^(GMT[+-]\d{2}:\d{2})\s*\(([A-Za-z/_]+)\)$/;
    if (pattern.test(timeZone)) {
      const timeZonee = 'GMT +5:00 (Asia/Karachi)';
      const timeZoneString = timeZonee.split(' ').pop();
      const cleanedTimeZone = timeZoneString.replace(/[()]/g, '');
      timezone = cleanedTimeZone;
    } else {
      timezone = timeZone;
    }
    const date = typeof dateTime === 'string' ? new Date(dateTime) : dateTime;
    const dateString = date.toLocaleString('en-US', { timeZone: timezone });

    let formattedDateTime = moment(dateString).format('Do MMM YYYY,ddd');
    return formattedDateTime;
  };
  const handleBackward = () => {
    if (index > 0) {
      setIndex(index - 3);
    }
  };
  const handleForward = () => {
    if (index < filterDates?.length && index + 3 < filterDates?.length) {
      setIndex(index + 3);
    }
  };
  const tileClassName = (date) => {
    const localDate = new Date(date.date).toLocaleDateString();
    return slotResponse?.some(
      (slot) => new Date(slot.availableDate).toLocaleDateString() === localDate,
    )
      ? 'bg-lightPrimary'
      : '';
  };
  const timezonesOptions = useMemo(() => {
    var timeZones = moments.tz.names();
    var offsetTmz = [];

    for (var i in timeZones) {
      offsetTmz.push({
        label: `GMT${moments.tz(timeZones[i]).format('Z')} (${timeZones[i]})`,
        value: timeZones[i],
      });
    }
    return offsetTmz;
  }, []);

  useEffect(() => {
    if (!contactus) refetch();
  }, [contactus]);
  const selectedTimeZone = (value) => {
    setTimeZone(value.value);
    const timeZone = value.value;
    const newArray = slotResponse?.map((day) => {
      const date = moment.tz(day?.availableDate, 'UTC');
      const availableDate = date.tz(timeZone).format();
      const timeSlots = day.timeSlots.map((slot) => {
        const from = moment
          .tz(`${day.availableDate.split('T')[0]} ${slot.from}`, 'UTC')
          .tz(timeZone)
          .format('HH:mm');
        const to = moment
          .tz(`${day.availableDate.split('T')[0]} ${slot.to}`, 'UTC')
          .tz(timeZone)
          .format('HH:mm');
        return { from, to, start: slot.from, end: slot.to };
      });
      return { availableDate, timeSlots };
    });
    let convTime = moment(newArray[0]?.availableDate)
      .tz(timeZone)
      .format('Do MMM YYYY');
    let dateTime = moment(convTime, 'Do MMM YYYY').format();
    setSelectDate(new Date(dateTime));
    const transformArray = testData(newArray);

    if (isSmallScreen) {
      const filteredArray =
        isArray(transformArray) &&
        transformArray?.filter((slot) => {
          let convTime = moment(slot?.availableDate)
            .tz(timeZone)
            .format('Do MMM YYYY');
          let slotDate = new Date(moment(convTime, 'Do MMM YYYY').format());
          return (
            slotDate?.getDate() === selectDate?.getDate() &&
            slotDate?.getMonth() === selectDate?.getMonth() &&
            slotDate?.getFullYear() === selectDate?.getFullYear()
          );
        });

      setFilterDates(filteredArray);
      setDefaultData(transformArray);
    }
    if (!isSmallScreen) {
      setFilterDates(transformArray);
      setDefaultData(transformArray);
    }
  };

  const testData = (availability) => {
    const _ = require('lodash');
    const groupedAvailability = _(availability)
      .groupBy((obj) => obj.availableDate.slice(0, 10))
      .map((group) => ({
        availableDate: group[0].availableDate.slice(0, 10) + 'T00:00:00.000Z',
        timeSlots: flatMap(group, (obj) => obj.timeSlots),
      }))
      .value();

    return groupedAvailability;
  };

  const defaultTimeZone = (array, timezone) => {
    const pattern = /^(GMT[+-]\d{2}:\d{2})\s*\(([A-Za-z/_]+)\)$/;
    let timeZone = '';
    if (pattern.test(timezone)) {
      const timeZonee = 'GMT +5:00 (Asia/Karachi)';
      const timeZoneString = timeZonee.split(' ').pop();
      const cleanedTimeZone = timeZoneString.replace(/[()]/g, '');
      timeZone = cleanedTimeZone;
    } else {
      timeZone = timezone;
    }
    if (timeZone) {
      setTimeZone(timeZone);
      const newArray = array?.map((day) => {
        const date = moment.tz(day?.availableDate, 'UTC');
        const availableDate = date?.tz(timeZone)?.format();
        const timeSlots = day?.timeSlots?.map((slot) => {
          const from = moment
            .tz(`${day?.availableDate?.split('T')[0]} ${slot?.from}`, 'UTC')
            .tz(timeZone)
            .format('HH:mm');
          const to = moment
            .tz(`${day?.availableDate?.split('T')[0]} ${slot?.to}`, 'UTC')
            .tz(timeZone)
            .format('HH:mm');
          return { from, to, start: slot?.from, end: slot?.to };
        });
        return { availableDate, timeSlots };
      });

      const transformArray = testData(newArray);
      return transformArray;
    }
  };

  useEffect(() => {
    if (interviewSlots?.availableSlots?.length) {
      const availableSlots = interviewSlots?.availableSlots?.map((slot) => {
        const availableDate = slot.from;
        const fromTime = moment(slot.from).utc().format('HH:mm');
        const toTime = moment(slot.to).utc().format('HH:mm');
        const timeSlots = [{ from: fromTime, to: toTime }];
        return { availableDate, timeSlots };
      });
      setSlotResponse(availableSlots);
    }
  }, [interviewSlots?.availableSlots]);

  useEffect(() => {
    let extractedPart =
      timeZone &&
      timeZone?.substring(timeZone?.indexOf('(') + 1, timeZone?.indexOf(')'));

    if (slotResponse) {
      setFilterDates(
        defaultTimeZone(
          slotResponse,
          extractedPart || Intl.DateTimeFormat().resolvedOptions().timeZone,
        ),
      );
      setDefaultData(
        defaultTimeZone(
          slotResponse,
          extractedPart || Intl.DateTimeFormat().resolvedOptions().timeZone,
        ),
      );
    }

    if (interviewData?.interviewDetails) {
      setTimeZone(
        extractedPart
          ? extractedPart
          : Intl.DateTimeFormat().resolvedOptions().timeZone,
      );
    }
  }, [slotResponse]);

  const formattedTime = (hours) => {
    const time = moment(hours, 'HH:mm');
    const formattedTime = time.format('h:mm A');
    return formattedTime;
  };

  let checkDate = interviewData?.interviewDetails?.maxDate
    ? interviewData?.interviewDetails?.maxDate
    : interviewData?.interviewDetails?.minDate;

  const isPastInterview =
    !dataLoading &&
    moment
      ?.utc(checkDate)
      ?.add(interviewData?.interviewDetails?.duration, 'minutes')
      ?.local()
      ?.isBefore(new Date());

  return interviewSlots?.interviewDetails?.selectedDate ||
    confirmedSlot?.confirm ? (
    <CandidateInterviewConfirm
      confirmedSlot={confirmedSlot}
      interviewSlots={interviewSlots}
      interviewData={interviewData}
    />
  ) : contactus ? (
    <Contactus
      setContactus={setContactus}
      token={token}
      contactus={contactus}
    />
  ) : !dataLoading && !interviewData?.interviewDetails ? (
    <StyledError className="d-flex align-items-center justify-content-center flex-column gap-4">
      <InterviewZeroStateIcon size="200" />
      <Wrap className=" d-flex flex-column">
        <StyledLabel size="60px">404 </StyledLabel>
        <StyledLabel size="16px">Page not found </StyledLabel>
      </Wrap>
    </StyledError>
  ) : !dataLoading && interviewData?.interviewDetails && isPastInterview ? (
    <StyledError className="d-flex align-items-center justify-content-center flex-column gap-4">
      <InterviewZeroStateIcon size="200" />
      <Wrap className="d-flex flex-column align-items-center ">
        <StyledLabel size="60px">404 </StyledLabel>
        <StyledLabel size="16px">
          This interview invite is expired. Please contact support for help{' '}
        </StyledLabel>
        <LinkButton onClick={() => setContactus(true)}>Contact Us</LinkButton>
      </Wrap>
    </StyledError>
  ) : (
    !dataLoading && (
      <>
        <Wrap
          className={
            'justify-content-md-center d-md-flex d-lg-flex justify-content-lg-center '
          }
        >
          <Helmet>
            <title>
              {interviewData?.interviewDetails?.application?.job?.title
                ? capitalize(
                    interviewData?.interviewDetails?.application?.job?.title,
                  )
                : 'Job interview'}
            </title>
          </Helmet>
          <Wrap className="col-lg-10 col-xl-8 col-sm mt-lg-5 mt-md-5">
            <StyledWrapContainer className="d-flex p-md-0 p-lg-0 p-0 bg-white rounded-3">
              <Wrap
                className={
                  visibility
                    ? 'd-none'
                    : 'col col-lg-4 col-md-4 m-3 p-md-2 p-lg-4'
                }
              >
                <Wrap className="d-flex flex-column align-items-center align-items-md-start">
                  <section className="d-flex justify-content-center py-2 py-md-3">
                    <StyledImage
                      width={'auto'}
                      height={'80px'}
                      className="rounded-3"
                    >
                      <img
                        src={
                          interviewData?.interviewDetails?.application?.job
                            ?.company?.compLOGOSignedUrl
                        }
                        alt="company_logo"
                      />
                    </StyledImage>
                  </section>
                  <StyledLabel size="24px" className="d-md-none d-lg-none">
                    {interviewData?.interviewDetails.title}
                  </StyledLabel>
                </Wrap>
                <hr className="d-md-none d-lg-none" />
                <Wrap className="d-md-none d-lg-none">
                  <Link
                    className="d-flex align-items-center gap-2 "
                    to={getAdvertRedirectUrl(
                      interviewData?.interviewDetails?.application?.job?.title,
                      interviewData?.interviewDetails?.application
                        ?.applicationSourceTracking?.postingId,
                    )}
                    target="_blank"
                  >
                    <BriefCaseColorIcon size="17px" />
                    <WrapText color={theme.primary}>
                      {interviewData?.interviewDetails?.application?.job?.title}{' '}
                    </WrapText>
                  </Link>
                  <Wrap className="d-flex align-items-center gap-2 my-3">
                    <ClockFillIcon color={theme.lightBoldBlack} size={17} />
                    <StyledLabel color={theme.textColor}>
                      {timeConversion(
                        interviewData?.interviewDetails?.duration,
                      )}
                    </StyledLabel>
                  </Wrap>
                  <Wrap className="d-flex align-items-center gap-2">
                    {interviewData?.interviewDetails?.type?.includes(
                      'Microsoft',
                    ) ? (
                      <Calender365Icon size="20px" />
                    ) : interviewData?.interviewDetails?.type ===
                      'Google Meet' ? (
                      <MeetIcon />
                    ) : (
                      <LocationIcon color={theme.lightBoldBlack} size={17} />
                    )}
                    <StyledLabel color={theme.textColor}>
                      {interviewData?.interviewDetails?.type}
                    </StyledLabel>
                  </Wrap>
                </Wrap>
                <Wrap className="mt-3 d-md-none d-lg-none">
                  <StyledLabel color={theme.textColor} weight="500px">
                    {interviewData?.interviewDetails.note}
                  </StyledLabel>
                </Wrap>
                <hr className="d-md-none d-lg-none" />

                <Wrap className="d-flex flex-column">
                  <StyledLabel size="16px" className="text-center d-md-none">
                    Please tap on highlighted date to see the available slots.
                  </StyledLabel>

                  <StyledLabel size="16px" className="text-center d-md-none">
                    Select a date
                  </StyledLabel>
                  <Calendar
                    tileClassName={tileClassName}
                    className="shadow-none p-0 w-auto"
                    value={selectDate}
                    minDate={new Date()}
                    onChange={(date) => selectedDate(date, timeZone)}
                    next2Label={null}
                    prev2Label={null}
                    nextLabel={<DropDownIcon rotate="right" className="p-0" />}
                    prevLabel={<DropDownIcon rotate="left" className="p-0" />}
                  />
                  <Wrap className="mt-4">
                    <StyledLabel size="16px">Time Zone</StyledLabel>

                    <Wrap className="d-flex align-items-center ">
                      <GlobalIcon />
                      {interviewData?.interviewDetails ? (
                        <StyledCustomSelect
                          id="timeZone"
                          name="timeZone"
                          value={timeZone}
                          options={timezonesOptions}
                          onChange={(value) => {
                            selectedTimeZone(value);
                            setActiveTimezone(value?.label);
                          }}
                        />
                      ) : (
                        <StyledRow className="d-flex gap-2 align-items-center">
                          <Skeleton width={215} height={30} />
                        </StyledRow>
                      )}
                    </Wrap>
                  </Wrap>
                  {interviewData?.interviewDetails?.location && (
                    <Wrap className="mt-4">
                      <StyledLabel size="16px">Location</StyledLabel>
                      <Wrap className="d-flex mt-2">
                        <StyledLabel size="14px">
                          <StyledGlobaleIcon>
                            <LocationIcon />
                          </StyledGlobaleIcon>
                          {[
                            interviewData?.interviewDetails?.location
                              ?.buildingNo,
                            interviewData?.interviewDetails?.location?.address,
                            interviewData?.interviewDetails?.location?.town,
                            interviewData?.interviewDetails?.location?.postcode,
                            interviewData?.interviewDetails?.location?.country,
                          ]
                            .filter(Boolean)
                            .join(', ')}
                        </StyledLabel>
                      </Wrap>
                    </Wrap>
                  )}

                  {interviewData?.interviewDetails?.statusId ===
                  STATUSES.AWAITING_FEEDBACK
                    ? null
                    : filterDates?.length !== 0 && (
                        <Wrap className="mt-4">
                          <Wrap className="d-flex align-items-center gap-2">
                            <StyledLabel size="16px">
                              Not available on these dates?
                            </StyledLabel>
                          </Wrap>

                          <>
                            <LinkButton onClick={() => setContactus(true)}>
                              Contact Us
                            </LinkButton>
                          </>
                        </Wrap>
                      )}
                </Wrap>
              </Wrap>
              <StyledVerticalLine
                color={theme.inputBorder}
                className="d-none d-md-block d-lg-block"
              />

              <Wrap
                className={`col col-md p-4 p-md-5 p-lg-5 ${
                  visibility ? '' : 'd-none d-md-block d-lg-block'
                } `}
              >
                {visibility ? (
                  <>
                    <div className="d-flex">
                      <Wrap className=" justify-content-start">
                        <BackArrowIcon
                          onClick={() => setVisibility(false)}
                          className="d-sm-block d-md-none d-lg-none"
                        />
                      </Wrap>
                      <Wrap className="d-flex w-100 justify-content-evenly align-items-center me-4 pe-1">
                        <Wrap
                          className="d-flex flex-column align-items-center"
                          onClick={() => setChooseDate(true)}
                        >
                          <Wrap>
                            <StyledLabel size="20px">
                              {date}
                              <DropDownIcon className="ms-2 text-body" />
                            </StyledLabel>
                          </Wrap>
                          <StyledLabel weight="500px">{day}</StyledLabel>
                        </Wrap>
                      </Wrap>
                    </div>

                    <hr />

                    <Wrap className=" d-flex flex-column align-items-center">
                      <StyledLabel size="16px">Time Zone</StyledLabel>

                      <Wrap className="d-flex align-items-center ">
                        <GlobalIcon />
                        <StyledCustomSelect
                          id="timeZone"
                          name="timeZone"
                          value={timeZone}
                          options={timezonesOptions}
                          onChange={(value) => selectedTimeZone(value)}
                        />
                      </Wrap>
                    </Wrap>
                    <hr />
                  </>
                ) : (
                  <>
                    <Wrap>
                      <StyledLabel size="24px">
                        {interviewData?.interviewDetails.title}
                      </StyledLabel>
                      <Wrap className="d-md-flex d-lg-flex gap-2 align-items-center gap-4">
                        <Link
                          className="d-flex align-items-center gap-2 "
                          to={getAdvertRedirectUrl(
                            interviewData?.interviewDetails?.application?.job
                              ?.title,
                            interviewData?.interviewDetails?.application
                              ?.applicationSourceTracking?.postingId,
                          )}
                          target="_blank"
                        >
                          <BriefCaseColorIcon />
                          <StyledLabel
                            className="cursor-pointer"
                            color={theme.primary}
                          >
                            <StyledToolTip
                              title={
                                interviewData?.interviewDetails?.application
                                  ?.job?.title
                              }
                              childrenClass={'ms-0'}
                            >
                              <span>
                                {interviewData?.interviewDetails?.application?.job?.title.substring(
                                  0,
                                  35,
                                )}
                                ...
                              </span>
                            </StyledToolTip>{' '}
                          </StyledLabel>
                        </Link>
                        <Wrap className="d-flex align-items-center gap-2">
                          <ClockFillIcon color={theme.lightBoldBlack} />
                          <StyledLabel color={theme.textColor}>
                            {timeConversion(
                              interviewData?.interviewDetails?.duration,
                            )}
                          </StyledLabel>
                        </Wrap>

                        <Wrap className="d-flex align-items-center gap-2">
                          {interviewData?.interviewDetails?.type?.includes(
                            'Microsoft',
                          ) ? (
                            <Calender365Icon size="20px" />
                          ) : interviewData?.interviewDetails?.type.includes(
                              'Google Meet',
                            ) ? (
                            <StyledRow className="d-flex gap-2 align-items-center">
                              <MeetIcon />
                            </StyledRow>
                          ) : (
                            <LocationIcon color={theme.lightBoldBlack} />
                          )}
                          <StyledLabel color={theme.textColor}>
                            {interviewData?.interviewDetails?.type}
                          </StyledLabel>
                        </Wrap>
                      </Wrap>
                      <Wrap className="my-3">
                        <StyledLabel color={theme.textColor} weight="500px">
                          {interviewData?.interviewDetails.note}
                        </StyledLabel>
                      </Wrap>
                    </Wrap>
                    {filterDates?.length !== 0 && (
                      <Wrap className="d-flex justify-content-between align-items-center">
                        <Wrap className="my-3">
                          <StyledLabel size="16px">
                            Select Time & Date
                          </StyledLabel>
                        </Wrap>
                        <Wrap className="d-flex gap-3 cursor-pointer">
                          <DropDownIcon
                            rotate="left"
                            disabled={index === 0}
                            onClick={handleBackward}
                          />
                          <DropDownIcon
                            rotate="right"
                            disabled={index >= filterDates?.length - 3}
                            onClick={handleForward}
                          />
                        </Wrap>
                      </Wrap>
                    )}
                  </>
                )}

                {interviewSlots?.availableSlots.length &&
                filterDates?.length !== 0 ? (
                  <Wrap className="d-flex gap-4">
                    <div className="container">
                      {filterDates && (
                        <div className="row">
                          {filterDates
                            ?.slice(index, index + 3)
                            .map((itemDate, i) => (
                              <div
                                className="col-sm col-lg-4 col-md-4 mb-3"
                                key={i}
                              >
                                <Wrap className="text-center">
                                  {visibility ? (
                                    <Wrap>
                                      <StyledLabel
                                        size="16px"
                                        className=" d-md-none"
                                      >
                                        Select a Slot
                                      </StyledLabel>
                                      <Wrap className="d-flex align-items-center justify-content-center gap-1">
                                        <StyledLabel
                                          color={theme.textColor}
                                          size="14px"
                                          weight="500px"
                                        >
                                          Duration:
                                        </StyledLabel>
                                        <StyledLabel color={theme.textColor}>
                                          {timeConversion(
                                            interviewData?.interviewDetails
                                              ?.duration,
                                          )}
                                        </StyledLabel>
                                      </Wrap>
                                    </Wrap>
                                  ) : (
                                    <StyledLabel>
                                      {dateConversion(itemDate?.availableDate)}
                                    </StyledLabel>
                                  )}
                                </Wrap>
                                {itemDate?.timeSlots?.map((itemHours, j) => (
                                  <>
                                    {itemHours?.from === bookSlot?.hour?.from &&
                                    itemDate?.availableDate ===
                                      bookSlot?.date ? (
                                      <Wrap
                                        className="d-flex align-items-center justify-content-between w-100 gap-2 mt-3"
                                        key={j}
                                      >
                                        <StyledButton className="bg-lightGreen  w-50 border-0 rounded-1 bg-opacity-50  text-headingColor">
                                          {formattedTime(bookSlot?.hour?.from)}
                                        </StyledButton>
                                        {isLoading ? (
                                          <Wrap className="d-flex align-items-center justify-content-center w-50">
                                            <StyledCustomLoader />
                                          </Wrap>
                                        ) : (
                                          <StyledButton
                                            className="w-50 bg-primary"
                                            onClick={() =>
                                              confirmedInterviewSlot(
                                                itemHours?.from,
                                                itemHours.to,
                                                itemDate?.availableDate,
                                              )
                                            }
                                          >
                                            Confirm
                                          </StyledButton>
                                        )}
                                      </Wrap>
                                    ) : (
                                      <StyledButton
                                        className="mt-3 w-100"
                                        variant="border"
                                        onClick={() =>
                                          setBookSlot({
                                            hour: itemHours,
                                            date: itemDate?.availableDate,
                                          })
                                        }
                                      >
                                        {formattedTime(itemHours?.from)}
                                      </StyledButton>
                                    )}
                                  </>
                                ))}
                              </div>
                            ))}
                        </div>
                      )}
                    </div>
                  </Wrap>
                ) : (
                  <>
                    {!slotsLoading ? <StyledError className="d-flex align-items-center justify-content-center flex-column gap-4 empty-slot">
                      <InterviewZeroStateIcon size="200" />
                      <Wrap className="d-flex flex-column align-items-center ">
                        <StyledLabel size="60px">404 </StyledLabel>
                        <StyledLabel size="16px">
                          No slots available at this time, Please contact
                          support for help{' '}
                        </StyledLabel>
                        <LinkButton onClick={() => setContactus(true)}>
                          Contact Us
                        </LinkButton>
                      </Wrap>
                    </StyledError>
                    :

                    slotsLoading && <SlotsSkeleton/>

                    }
                  </>
                )}
              </Wrap>
            </StyledWrapContainer>
          </Wrap>
        </Wrap>

        <CandidateProposeModal
          show={showPropseModal}
          onHide={() => setShowPropseModal(false)}
          handleSubmit={handleSubmit}
        />
        {chooseDate && (
          <BottomModal
            tileClassName={tileClassName}
            timeZone={timeZone}
            selectedDate={selectedDate}
            selectDate={selectDate}
            setChooseDate={setChooseDate}
            chooseDate={chooseDate}
          />
        )}
      </>
    )
  );
};

const StyledCustomSelect = styled(StyledSelect)`
  max-width: fit-content !important;
  [class$='-control'] {
    border: none;
  }

  [class$='css-veulyy-control'] {
    border: none;
    width: fit-content !important;
  }

  [class$='-menu'] {
    width: fit-content !important;
  }
  [class$='-Input'] {
    width: 190px;
  }
`;
const StyledRow = styled.div`
  color: ${({ theme }) => theme.textColor};
`;
const StyledVerticalLine = styled.span`
  border-right: 1px solid ${({ color }) => color};
  margin-left: 5px;
  height: 100;
`;

const StyledWrapContainer = styled.div`
  ${(props) => props.theme.md} {
    overflow-y: scroll;
    scrollbar-width: none;
    -ms-overflow-style: none;
    ::-webkit-scrollbar {
      display: none;
    }
    height: 100vh;
    border-radius: 0px;
  }
`;

const LinkButton = styled(StyledLabel)`
  color: ${({ theme })=> theme.primary};
  text-decoration: underline;
  cursor: pointer;
`;

const WrapText = styled(StyledLabel)`
  ${(props) => props.theme.sm} {
    width: 200px;
    whitespace: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const StyledGlobaleIcon = styled.span`
  margin-right: 12px;
`;

const StyledError = styled.div`
  height: 100vh;
  &.empty-slot {
    height: 46vh;
  }
`;
export default CandidateSelfSchedule;
