import React, { useMemo } from 'react';
import Proptypes from 'prop-types';
import { StyledPublicCheck } from '../styledComponents/StyledPublicCheck';
import StyledText from '../styledComponents/StyledText';
import StyledCheckbox from './StyledCheckbox';
import StyledRadioButton from '../radio/StyledRadioButton';
import styled from 'styled-components';
import { Wrap } from '../../../assets/styledComponents/Global';
import { validOption } from '../../../utils/helper';

const StyledPublicCheckbox = ({
  value,
  onChange,
  label,
  disabled,
  size,
  className,
  isRadio,
  name,
  isReadOnly,
  assessment,
  dei,
  ...props
}) => {
  const id = useMemo(() => `checkbox-${Math.random()}`, []);
  const onChangeCheckbox = (event) => {
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <>
      <label
        htmlFor={id}
        className={`${!assessment ? "w-100 py-2" : !isRadio ? "w-100" : ""} ${className ?? ''}`}
        style={{ cursor: 'pointer' }}
      >
        <StyledFontSize>
          <StyledPublicCheck value={value} {...props} assessment={assessment} className={`${!assessment ? "publicForms" : ""}`}>
            {label && (
              <>
                {!isRadio ? (
                  <StyledCheckbox
                    forms
                    className={`${!assessment ? "ms-3 publicForms" : "me-1"}`}
                    value={value}
                    disabled={disabled}
                    onChange={onChangeCheckbox}
                    {...props}
                  />
                ) : (
                  <StyledRadioButton
                    className={`${!assessment ? "ms-3 me-0 publicForms" : ""}`}
                    value={value}
                    name={label}
                    disabled={disabled}
                    onChange={onChangeCheckbox}
                  />
                )}
                <StyledText
                  assessment={assessment ?? false}
                  as="h3"
                  className={`${!assessment ? "fs-4 p-3" : isRadio ? "py-3 pe-3" : "py-2"} mb-0`}
                  value={value}
                >
                  {dei && validOption(label,'Others') ? 'Others' : label}
                </StyledText>

              </>
            )}
          </StyledPublicCheck>
        </StyledFontSize>
      </label>
      <input
        id={id}
        type={!isRadio ? 'checkbox' : 'radio'}
        style={{ display: 'none' }}
        checked={value}
        disabled={disabled}
        onChange={onChangeCheckbox}
      />
    </>
  );
};

StyledPublicCheckbox.propTypes = {
  value: Proptypes.bool,
  onChange: Proptypes.func,
  label: Proptypes.string,
};
const StyledFontSize = styled(Wrap)`
${(props) => props.theme.md} {
  h3 {
    font-size: 16px !important;
  }
}
`;
export default StyledPublicCheckbox;
