import React from 'react';
import { useTheme } from 'styled-components';

const LongTextIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        rx="4"
        fill={theme?.dark ? theme?.primaryDark : '#F8F2F7'}
      />
      <path
        d="M6 7H18"
        stroke={theme?.dark ? theme?.primaryLight2 : '#4A154B'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 10.3333H12.3133"
        stroke={theme?.dark ? theme?.primaryLight2 : '#4A154B'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 13.6667H18"
        stroke={theme?.dark ? theme?.primaryLight2 : '#4A154B'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6 17H12.3133"
        stroke={theme?.dark ? theme?.primaryLight2 : '#4A154B'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LongTextIcon;
