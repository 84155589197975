import React from 'react';
import { useTheme } from 'styled-components';

const DocsIcon = ({ size = 30, ...props }) => {
  const theme = useTheme();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M3.375 0C1.51425 0 0 1.51425 0 3.375V26.625C0 28.4858 1.51425 30 3.375 30H20.625C22.4858 30 24 28.4858 24 26.625V12H15.375C13.5142 12 12 10.4858 12 8.625V0H3.375ZM14.25 0.65918V8.625C14.25 9.24525 14.7548 9.75 15.375 9.75H23.3408L14.25 0.65918ZM7.125 15.75H16.875C17.496 15.75 18 16.254 18 16.875C18 17.496 17.496 18 16.875 18H7.125C6.504 18 6 17.496 6 16.875C6 16.254 6.504 15.75 7.125 15.75ZM7.11768 21H13.8647C14.4857 21 14.9897 21.5033 14.9897 22.125C14.9905 22.6943 14.5676 23.1655 14.0186 23.2397L13.8662 23.25H7.11914C6.49814 23.25 5.99414 22.746 5.99414 22.125C5.99414 21.5557 6.41633 21.0845 6.96533 21.0103L7.11768 21Z"
        fill={theme?.dark ? 'white' : '#869BB8'}
      />
    </svg>
  );
};

export default DocsIcon;
