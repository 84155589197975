import React, { useState, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProtectedRoute from './app/ProtectedRoute';
// import { Login } from './features/auth/Login';
import ComponentsList from './features/componentsLibrary/ComponentsList';
import StyledToastContainer from './app/components/styledComponents/StyledToastContainer';
import LoginByCognito from './features/auth/LoginByCognito';
import { Logout } from './features/auth/Logout';
import CareerPublicPreview from './features/careerPage/CareerPublicPreview';
import SearchPublicPage from './features/careerPage/SearchPublicPage';
import PublicFormContainer from './features/forms/formPublic/PublicFormContainer';
import Helmet from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { routesWithMeta, trackEvent } from './utils/meta';
import { find } from 'lodash';
import FormPreview from './features/forms/formPreview/FormPreview';
import { Container } from 'react-bootstrap';
import RestrictedAccess from './midleware/RestrictedAccess';
import { MidleWare } from './midleware/midleware';
import CandidateSelfSchedule from './features/ReInterviews/components/CandidateSelfSchedule';
import PrivacyPolicyContainer from './features/termsAndConditions/components/PrivacyPolicyContainer';
import OutlookComponent from './features/Setting/AccountCalender/OutlookComponent';
import JobOfferContainer from './features/kanban/CandidateOffer/JobOfferContainer';
import GoogleCalendar from './features/Setting/AccountCalender/GoogleCalendarComponent';
import OfferLetter from './features/kanban/CandidateOffer/OfferLetter';
import KanbanOfferLetterContainer from './features/kanban/CandidateOffer/KanbanOfferLetterContainer';
import { ClearStorage } from './features/auth/ClearStorage';
import { useWindowSize } from './utils/helper';
import PolicyContainer from './features/termsAndConditions/Privacy&Policy';
import DocumentContainer from './features/kanban/ApplicationDetails/SendDocument/DocumentContainer';
import WelcomePage from './features/occyWelcome/WelcomePage';
import { USERROLES } from './features/users/creation/assets/utills';
import ViewAllNotifications from './features/Setting/Notifications/components/ViewAllNotifications';
import useAuth from './hooks/useAuth';
import NonPresignedOfferContainer from './features/kanban/CandidateOffer/NonPresignedOfferContainer';
import UnsubscribeContainer from './features/termsAndConditions/Unsubscription';
import DelegetedUserLogin from './features/dashboard/listing/DelegatedUserLogin';
import { setOOOPermission } from './midleware/permisssionReducer';
import LimitedPermissionModal from './utils/LimitedPermissionModal';
import { useDispatch } from 'react-redux';
import usePermission from './hooks/usePermission';
import PublicPagesTheme from './midleware/PublicPagesTheme';
import LoginForm from './features/auth/LoginForm';
import OutlookComponentLogin from './features/auth/OutlookComponentLogin';
import GoogleComponentLogin from './features/auth/GoogleComponentLogin';
// import { ChurnZeroScript } from './midleware/ChurnZeroTracking';
const DashboardContainer = lazy(() => import('./features/dashboard/listing/DashboardContainer'));
const Alerts = lazy(() => import('./features/Setting/Alerts'));
const UserRoutes = lazy(() => import('./features/users/userRoutes'));
const CampaignRoutes = lazy(() => import('./features/campaigns/CampaignRoutes'));
const CopyrightListing = lazy(() => import('./features/copyright/CopyrightListing'));
const AnalyticsRoutes = lazy(() => import('./features/analytics/AnalyticsRoutes'));
const RecruitersRoutes = lazy(() => import('./features/recruitersSlots/recruitersRoutes'));
const JobRoutes = lazy(() => import('./features/job/JobRoutes'));
const PipelineAutomation = lazy(() => import('./features/Automation/PipelineAutomation'));
const WizardRoutes = lazy(() => import('./features/dashboard/listing/WizardRoutes'));
const CalenderView = lazy(() => import('./features/calender/calenderView'));
const CopywriteDetailView = lazy(() => import('./features/copyright/CopywriteDetailView'));
const CareerPageContainer = lazy(() => import('./features/CreateCareerPage/CareerPageContainer'));
const MailRoutes = lazy(() => import('./features/mailbox/mailRoutes'));
const CandidateRoutes = lazy(() => import('./features/candidates/candidateRoutes'));
const HiringRequestRoutes = lazy(() => import('./features/HiringRequests/HiringRequestRoutes'));
const OffersRoutes = lazy(() => import('./features/OffersAndAgreements/OffersRoutes'));
const SignablePreview=lazy(()=>import('./features/kanban/CandidateOffer/SignablePreview'));
const TransferResponsiblities =lazy(() =>
  import('./features/Setting/TransferResponsibilities/TransferResponsiblitiesContainer'),)

function App() {
  const dispatch = useDispatch();
   const isReadOnly = usePermission();
  const {user} = useAuth();
  const isSmallScreen = useWindowSize();
  const [pageTitle, setPageTitle] = useState('');
  const [pageDescription, setPageDescription] = useState('');
  const location = useLocation();
  React.useEffect(() => {
    const activeRoute = find(routesWithMeta, { path: location.pathname });
    const eventName = find(trackEvent, { path: location.pathname });
    setPageTitle(
      (activeRoute?.meta?.title
        ? activeRoute?.meta?.title.charAt(0).toUpperCase() +
        activeRoute?.meta?.title.slice(1)
        : location.pathname?.split('/')?.pop()?.charAt(0).toUpperCase() +
        location.pathname?.split('/')?.pop()?.slice(1)) + ' - occy APP',
    );
    setPageDescription(activeRoute?.meta?.description);
    const existingChurnZeroScript = document.querySelector(`script[src='${process.env.REACT_APP_HUBSPOT_DOMAIN}']`);
    if (existingChurnZeroScript) {
      existingChurnZeroScript.remove();
    }
 if(eventName){
    const churnZeroScript = `
    var ChurnZero = ChurnZero || [];
    (function(a, b, c, d){
      var cz = a.createElement(b); 
      cz.type = c;  
      cz.async = true; 
      cz.src = d;
      var s = a.getElementsByTagName(b)[0];
      s.parentNode.insertBefore(cz, s);
    })(document, "script", 'text/javascript', '${process.env.REACT_APP_HUBSPOT_DOMAIN}');
    ChurnZero.push(['setAppKey', '${process.env.REACT_APP_HUBSPOT_APP_KEY}']);
    ChurnZero.push(['setContact', '${user?.company?.hubspotId}','${user?.email}']);
    ChurnZero.push(['trackEvent', '${
      eventName?.meta?.title
      ? eventName?.meta?.title.charAt(0).toUpperCase() +
      eventName?.meta?.title.slice(1)
      : location.pathname?.split('/')?.pop()?.charAt(0).toUpperCase() +
      location.pathname?.split('/')?.pop()?.slice(1)
    }','${eventName?.meta?.description || ''}']);
  `;

  // Create a new script element
  const script = document.createElement('script');
  script.text = churnZeroScript;

  // Append the new script to the document head
  document.head.appendChild(script);

  return () => {
    // Clean up function to remove the script when the component unmounts
    script.remove();
  };
}
  }, [location]);

  return (
    <Container fluid className={isSmallScreen && 'p-0'}>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
      </Helmet>
    
      <Routes>
      <Route
          path="oauth2/idpresponse"
          element={<GoogleComponentLogin />}
        />
      <Route
          path="oauth2/ms/idpresponse"
          element={<OutlookComponentLogin />}
        />
        {/* <Route path="/" element={<Login />} /> */}
        <Route
          path="api/calendar/outlook-callback-url"
          element={<OutlookComponent />}
        />
        <Route
          path="api/calendar/google-callback-url"
          element={<GoogleCalendar />}
        />
        <Route path="/_clear" element={<ClearStorage />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/auth/cb" element={<LoginByCognito />} />
        <Route path="/" element={<LoginForm />} />
        {/* Public Routes */}
        <Route element={<PublicPagesTheme />} >
        
        <Route path="/forms/public" element={<PublicFormContainer />} />
        <Route path="forms/:id/preview" element={<FormPreview />} />
        <Route path="/components" element={<ComponentsList />} />
        <Route path="/p/interview/" element={<CandidateSelfSchedule />} />

        <Route path="/p/job-offer" element={<JobOfferContainer />} />
        <Route path="/p/signable-preview" element={<SignablePreview />} />
        <Route path="/p/non-signable-preview" element={<NonPresignedOfferContainer />} />
        <Route path="/p/employment-agreement" element={<DocumentContainer />} />

        <Route path="/offer-letter/" element={<OfferLetter />} />
        <Route
          path="/kanban-offer-letter/"
          element={<KanbanOfferLetterContainer />}
        />
        <Route path="/p/unsubscribe/:token" element={<UnsubscribeContainer />} />
        <Route path="/privacy-policy/" element={<PrivacyPolicyContainer />} />
        <Route path="/policies/" element={<PolicyContainer />} />
        <Route path="/restricted" element={<RestrictedAccess />} />
        <Route path="/p/jobs/:companyId" element={<CareerPublicPreview />} />
        <Route path="/p/job/:publicId" element={<SearchPublicPage />} />
        <Route path="/welcome/" element={<WelcomePage />} />
        </Route>

        {/* Protected Routes */}
        <Route element={<ProtectedRoute />}>
     
        <Route path="/dashboard/*" element={<DashboardContainer />} />
        <Route path="/delegated/user/:id/*" element={<DelegetedUserLogin />} />
        <Route path="/activities" element={<Alerts />} />
      <Route path="/notifications/*" element={<ViewAllNotifications />} />
        <Route path="/settings/*" element={<UserRoutes />} />
        <Route element={<MidleWare allowedRoles={[USERROLES.ADMIN, USERROLES.RECRUITER]} noBasicAllowed/>}>
        <Route path="/copywrite/*" element={<CopyrightListing />} />
        <Route path="/analytics/*" element={<AnalyticsRoutes />} />
        <Route path="/slots/*" element={<RecruitersRoutes />} />
        <Route path="/offers-and-agreements/*" element={<OffersRoutes/>} />
        </Route>
        
        <Route element={<MidleWare allowedRoles={[USERROLES.ADMIN, USERROLES.RECRUITER,USERROLES.INTERVIEWER,USERROLES.HIRINGMANAGER]} />}>
        <Route path="/jobs/*" element={<JobRoutes />} />
</Route>

        <Route element={<MidleWare allowedRoles={[USERROLES.ADMIN, USERROLES.RECRUITER,USERROLES.INTERVIEWER,USERROLES.HIRINGMANAGER,USERROLES.BILLINGADMIN]} />}>
          <Route path="/workflow/:tplId/*" element={<PipelineAutomation />} />
          <Route path="/wizard/*" element={<WizardRoutes />} />
          <Route path="/calendar/*" element={<CalenderView />} />
          <Route path="/transfer-responsibilities/:teamMemberId/:process" element={<TransferResponsiblities />} />
        
          
          <Route
            path="copywrite/:copywriteId/copywrite-information"
            element={<CopywriteDetailView />}
          />

         
  
          </Route>
          <Route element={<MidleWare allowedRoles={[USERROLES.ADMIN]} />}>
        <Route path="/settings/company/career-site/*" element={<CareerPageContainer />} />
        </Route>
          <Route
            element={
              <MidleWare allowedRoles={[USERROLES.ADMIN,USERROLES.RECRUITER]} noBasicAllowed />
            }
          >
            <Route path="/mails/*" element={<MailRoutes />} />
          </Route>
          <Route
            element={
              <MidleWare allowedRoles={[USERROLES.ADMIN,USERROLES.RECRUITER, USERROLES.HIRINGMANAGER]} noBasicAllowed/>
            }
          >
            <Route path="/campaigns/*" element={<CampaignRoutes />} />
            <Route path="/candidates/*" element={<CandidateRoutes />} />
          </Route>
          <Route
            element={
              <MidleWare allowedRoles={[USERROLES.APPROVER]} />
            }
          >
              <Route path="/approvers/*" element={<HiringRequestRoutes />} noBasicAllowed />
          </Route>
      
        </Route>
        <Route path="*" element={<Navigate to="/" replace />} />
       
      </Routes>
     
      <StyledToastContainer
        position="top-center"
        autoClose={1700}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={true}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
      {isReadOnly?.permission?.payload && <LimitedPermissionModal
      show={isReadOnly?.permission?.payload}
      onHide={() => {
        dispatch(setOOOPermission(false));
      }}
      />}
    </Container>
  );
}
export default App;