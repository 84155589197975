import React from 'react';
import PropTypes from 'prop-types';
import ScrollContainer from 'react-indiana-drag-scroll';

const PortletHeaderTabs = ({ tabs, onChangeTabs, title, wizardTemp }) => {
  const handleChangeTabs = (tab) => () => {
    onChangeTabs(tab);
  };
  return (
    <ScrollContainer
      className={`portlet__header-tabs ${(title === 'Team') ? '_2-step' : ''} ${(wizardTemp) ? '_1-step' : ''}`}
      vertical={false}
      hideScrollbars
    >
      {tabs?.map((tab, i) => (
        <div
          key={tab.id || i}
          className={`${(title === 'Notifications' &&  !wizardTemp) ? `_1-step` : ''} ${(title !== 'Team' &&  !wizardTemp) ? `_${i + 2}-step` : ''} ${tab.tabsDisbaled ? 'feature-pending' : ''
            } portlet__header-tab ${tab.active ? 'tab-active' : ''} ${tab?.hide ? 'd-none' : ''}`}
          onClick={handleChangeTabs(tab.id)}
        >
          {tab.title}
        </div>
      ))}
    </ScrollContainer>
  );
};

PortletHeaderTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  onChangeTabs: PropTypes.func.isRequired,
};

PortletHeaderTabs.defaultProps = {
  tabs: [],
};

export default PortletHeaderTabs;
