import { apiSlice } from '../../../app/services/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAllCalendars: builder.query({
            query: () => ({
                url: `calendar/providers`,
            }),
        }),
        getCalendarUrl: builder.mutation({
            query: (params) => ({
                url: `calendar/providers/${params.provider}/login`,
            })
        }),
        getCalenderSync: builder.mutation({
            query: (params) => ({
                url: `calendar/providers/${params.provider}/sync`,
            })
        }),
        loginWithCalender: builder.query({
            query: (params) => ({
                url: `calendar/providers/callback`,
                params: params
            })
        }),
        getAvailableHours: builder.query({
            query: () => ({
                url: `accounts/working-hours`,
            }),
        }),
        getAvailableSlots: builder.query({
            query: () => ({
                url: `accounts/availability-slots`,
            }),
        }),
        getAvailability: builder.query({
            query: () => ({
                url: `accounts/availability`,
            }),
        }),
        addScheduledOutOffice: builder.mutation({
            query: (data) => ({
                url: `out-of-office`,
                method: 'POST',
                body: {
                    ...data,
                },
            }),
        }),
        updateWorkingHours: builder.mutation({
            query: (data) => ({
                url: `accounts/working-hours`,
                method: 'PUT',
                body: {
                    ...data,
                },
            }),
        }),
        updateScheduleEvents: builder.mutation({
            query: (data) => ({
                url: `accounts/availability`,
                method: 'PUT',
                body: {
                    ...data,
                },
            }),
        }),
        getCalendarsInfo: builder.query({
            query: (params) => ({
                url: `calendar/providers/${params.provider}/login-info`,
            }),
        }),
        getScheduledOffice: builder.query({
            query: (params) => ({
                url: `out-of-office`,
            }),
        }),
        removeCalendar: builder.mutation({
            query: (params) => ({
                url: `calendar/providers/${params.provider}/logout`,
                method: 'DELETE',
            }),
        }),
        updateScheduledOffice: builder.mutation({
            query: (data) => ({
                url: `out-of-office/${data?.id}`,
                method: 'PUT',
                body:{
                    ...data
                }
            }),
        }),
        deleteScheduledOffice: builder.mutation({
            query: (data) => ({
                url: `out-of-office/${data?.id}`,
                method: 'DELETE',
            }),
        }),
    }),
});

export const {
    useDeleteScheduledOfficeMutation,
    useUpdateScheduledOfficeMutation,
    useGetScheduledOfficeQuery,
    useAddScheduledOutOfficeMutation,
    useGetCalendarUrlMutation,
    useGetAvailableHoursQuery,
    useGetAvailableSlotsQuery,
    useGetAvailabilityQuery,
    useLoginWithCalenderQuery,
    useGetAllCalendarsQuery,
    useRemoveCalendarMutation,
    useUpdateWorkingHoursMutation,
    useUpdateScheduleEventsMutation,
    useGetCalendarsInfoQuery,
    useGetCalenderSyncMutation,
} = extendedApiSlice;
