import React from 'react';
import Proptypes from 'prop-types';
import { StyledRadio } from './StyledRadio';
import { capitalizeFirstLetter } from '../../../utils/helper';
import { toTitleCase } from '../../../features/kanban/components/hiringListing/hiringUtils';
import RadioButtonIcon from '../svgIcons/RadioButtonIcon';

const StyledRadioButton = ({
  value,
  onChange,
  label,
  disabled,
  type,
  hasNoLabel,
  size,
  name,
  desp,
  ...props
}) => {
  const onChangeRadio = (event) => {
    if (onChange) {
      onChange(event);
    }
  };
  return (
    <>
      <label
        htmlFor={`${label}+${name}`}
        className={`${
          props?.className ?? ''
        } d-flex align-items-center mb-0 me-2`}
        style={{ cursor: 'pointer' }}
      >
        {type === 'documentstyles' ? (
          <RadioButtonIcon
            size={size}
            active={value ? true : false}
            onClick={() => onChange()}
          />
        ) : (
          <StyledRadio
            value={value}
            size={size}
            {...props}
            onClick={() => onChange()}
          >
            {value && <span className="dot" />}
          </StyledRadio>)}
        
        {type === 'scheduleEvents' && (
          <div className="d-flex justify-content-center flex-column ps-3">
            <div className="fw-bold">{label}</div>
            {desp}
          </div>
        )}
        {type === 'documentstyles' || type==='bulkEmail' ? (
          <span className={`ms-2 text-break my-2`}>{label}</span>
        ) :
        type === 'documentTypes' ? (
          <span className={`ms-2 text-break my-2`}>{toTitleCase(label)}</span>
        ):
        (
          label &&
          !hasNoLabel &&
          type !== 'scheduleEvents' &&
          (type === 'jobOffer' ? (
            <span className={`ms-2 text-break`}>{label?.toUpperCase()}</span>
          ) : (
            <span className={`ms-2 text-break`}>
              {capitalizeFirstLetter(label)}
            </span>
          ))
        )}
      </label>

      {type !== 'documentstyles' ?
      <input
        id={`${label}+${name}`}
        type="radio"
        style={{ display: 'none' }}
        checked={value}
        disabled={disabled}
        onChange={onChangeRadio}
      /> 
      : null}
    </>
  );
};

StyledRadioButton.propTypes = {
  value: Proptypes.bool,
  onChange: Proptypes.func,
  label: Proptypes.string || Proptypes.object,
};

export default StyledRadioButton;
