import React, { useEffect } from 'react';
import { Row } from 'react-bootstrap';
import { Wrap } from '../../../assets/styledComponents/Global';
import { removeDelegatedUser, setDelegatedUser } from '../../auth/authSlice';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';

function DelegetedUserLogin() {
    const { id } = useParams();
const dispatch = useDispatch();
    useEffect(() => {
      if (id) {
       if(id === 'default' ){dispatch(removeDelegatedUser())}
       else{ dispatch(setDelegatedUser(id));}
        setTimeout(()=>{
            window.location.href = window.location.origin + '/settings/accounts/profile';
        },1000)
      }
    }, [id]);
  return (
    <Wrap className={`px-4 py-2`}>
      <Row className="my-4">
      Loading
      </Row>
    </Wrap>
  );
}

export default DelegetedUserLogin;
