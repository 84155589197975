import React, { useEffect, useState } from 'react';
import { Formik, Form } from 'formik';
import { Row, Col } from 'react-bootstrap';
import styled, { useTheme } from 'styled-components';
import { HR, StyledLabel, Wrap } from '../../assets/styledComponents/Global';
import FormikInputField from '../../app/components/formikComponents/FormikInputField';
import FormikCheckBox from '../../app/components/formikComponents/FormikCheckBox';
import { Link, useNavigate } from 'react-router-dom';
import StyledButton from '../../app/components/styledComponents/StyledButton';
import OccyColoredIcon from '../../app/components/svgIcons/OccyColoredIcon';
import GoogleIcon from '../../app/components/svgIcons/GoogleIcon';
import MicrosoftIcon from '../../app/components/svgIcons/MicrosoftIcon';
import EyeIcon from '../../app/components/svgIcons/EyeIcon';
import StyledText from '../../app/components/styledComponents/StyledText';
import {
  removeCredentials,
  removeLicence,
  setCredentials,
  setLicence,
  sideMenuState,
  useGetGoogleUrlMutation,
  useGetOutlookUrlMutation,
  useGetUserLicenceMutation,
  useLoginMutation,
  useResetPasswordMutation,
  useVerifyCodeMutation,
  useForgotEmailPasswordMutation,
  useForgotPasswordMutation
} from './authSlice';
import { toast } from 'react-toastify';
import { isApprover } from '../users/creation/assets/utills';
import { useDispatch } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import useLicence from '../../hooks/useLicence';
import moment from 'moment';

const LoginForm = () => {
  const dispatch = useDispatch();
  const theme = useTheme();
  const [showPassword, setShowPassword] = useState(false);
  const [screen, setScreen] = useState('login');
  const [view, setView] = useState('');
  const [login, { isLoading: loginLoading }] = useLoginMutation();
  const [userLicense, { isLoading: licenceLoading }] =
    useGetUserLicenceMutation();
  const [verifyCode, { isLoading: verificationLoading }] =
    useVerifyCodeMutation();
  const [reset, { isLoading: resetLoading }] =
    useResetPasswordMutation();
    const [forgotEmail,{ isLoading: emailLoading }] = useForgotEmailPasswordMutation();
    const [forgot, { isLoading: forgotLoading }] =
    useForgotPasswordMutation();
  const nav = useNavigate();
  const { user } = useAuth();
  const { licence } = useLicence();
  const [googleLogin, { isLoading: googleLoading }] = useGetGoogleUrlMutation();
  const getUrlForGoogleLogin = () => {
    googleLogin()
      .unwrap()
      .then((res) => {
        window.open(
          res.url,
          '_blank',
          'location=1,status=1,scrollbars=1,width=700,height=700',
        );
        window.onunload = function (res) {
          if (res?.token) {
            // Get the current time in Unix seconds
            const currentUnixTime = moment().unix();
            // Add expiryInSeconds to the current time to get the expiry timestamp
            const tokenExpiryTime = currentUnixTime + res?.token?.expiresIn;
            let data = {
              ...res, // Spread the original object
              token: {
                ...res.token, // Spread the token object to keep other properties the same
                expiresIn: tokenExpiryTime, // Override the expiresIn value
              },
            };
            dispatch(removeCredentials());
            dispatch(removeLicence());
            dispatch(setCredentials(data));
            dispatch(sideMenuState(false));
            userLicense()
              .then((license) => {
                if (license?.data) {
                  dispatch(setLicence({ licence: license }));
                  window.location.href =
                    license?.data?.accepted === 1
                      ? isApprover(res?.account)
                        ? 'approvers'
                        : 'dashboard'
                      : 'privacy-policy';
                } else nav(`/logout`);
              })
              .catch((error) => nav(`/logout`));
          } else {
            if (res.message === 'MFA enabled') {
              setScreen(res?.session);
            } else if (res?.status === 401) {
              toast.error(res?.data?.message || 'Error while login');
            }
          }
        };
      });
  };
  const [data, { isLoading: outlookLoading }] = useGetOutlookUrlMutation();
  const getUrlForOutlookLogin = () => {
    data()
      .unwrap()
      .then((res) => {
        window.open(
          res.url,
          '_blank',
          'location=1,status=1,scrollbars=1,width=700,height=700',
        );
        window.onunload = function (res) {
          if (res?.token) {
            // Get the current time in Unix seconds
            const currentUnixTime = moment().unix();
            // Add expiryInSeconds to the current time to get the expiry timestamp
            const tokenExpiryTime = currentUnixTime + res?.token?.expiresIn;
            let data = {
              ...res, // Spread the original object
              token: {
                ...res.token, // Spread the token object to keep other properties the same
                expiresIn: tokenExpiryTime, // Override the expiresIn value
              },
            };
            dispatch(removeCredentials());
            dispatch(removeLicence());
            dispatch(setCredentials(data));
            dispatch(sideMenuState(false));
            userLicense()
              .then((license) => {
                if (license?.data) {
                  dispatch(setLicence({ licence: license }));
                  window.location.href =
                    license?.data?.accepted === 1
                      ? isApprover(res?.account)
                        ? 'approvers'
                        : 'dashboard'
                      : 'privacy-policy';
                } else nav(`/logout`);
              })
              .catch((error) => nav(`/logout`));
          } else {
            if (res.message === 'MFA enabled') {
              setScreen(res?.session);
            } else if (res?.status === 401) {
              toast.error(res?.data?.message || 'Error while login');
            }
          }
        };
      });
  };
  const handleLogin = (values) => {
    const { email, password } = values;
    login({ email, password })
      .then((res) => {
        if (res?.data?.token) {
          dispatch(removeCredentials());
          dispatch(removeLicence());
          dispatch(setCredentials(res?.data));
          dispatch(sideMenuState(false));
          userLicense()
            .then((license) => {
              if (license?.data) {
                dispatch(setLicence({ licence: license }));
                window.location.href =
                  license?.data?.accepted === 1
                    ? isApprover(res?.data)
                      ? '/approvers'
                      : '/dashboard'
                    : 'privacy-policy';
              } else nav(`/logout`);
            })
            .catch((error) => nav(`/logout`));
        } else if (
          res?.data?.message === 'MFA enabled' ||
          res?.data?.message === 'Password reset required'
        ) {
          setScreen(res?.data?.session);
          if (res?.data?.message === 'MFA enabled') {
            setView('MFA');
          } else if (res?.data?.message === 'Password reset required') {
            setView('RESET');
          }
        }
      })
      .catch((error) => toast.error(error || 'Error occured while login'));
  };

  const handleVerificationCode = (values) => {
    if (view === 'MFA') {
      const { email, code } = values;
      verifyCode({ email, code, session: screen }).then((res) => {
        if (res?.data?.token) {
          dispatch(removeCredentials());
          dispatch(removeLicence());
          dispatch(setCredentials(res?.data));
          dispatch(sideMenuState(false));
          userLicense()
            .then((license) => {
              if (license?.data) {
                dispatch(setLicence({ licence: license }));
                window.location.href =
                  license?.data?.accepted === 1
                    ? isApprover(res?.data)
                      ? '/approvers'
                      : '/dashboard'
                    : 'privacy-policy';
              } else nav(`/logout`);
            })
            .catch((error) => nav(`/logout`));
        } else if (res?.data?.message === 'User not verified') {
          setScreen('login');
        } else {
          toast.error('Invalid code');
        }
      });
    } else if (view === 'RESET') {
      const { email, newPassword } = values;
      reset({ email, newPassword, session: screen }).then((res) => {
        if (res?.data?.message === 'Password updated') {
          setScreen('login');
          window.location.href = '/';
        } else if (res?.data?.message === 'Password not updated') {
          setScreen('login');
          window.location.href = '/';
        }
      });
    }  
    else if (view === 'FORGOTEMAIL') {
      const { email } = values;
      forgotEmail({email}).unwrap().then(()=>{
      setView('FORGOT');
    })}
    else if (view === 'FORGOT') {
      const { email,code, newPassword } = values;
      forgot({ email, newPassword, code }).then((res) => {
        if (res?.data?.message ===  "Confirm forgot password request processed") {
          window.location.href = '/';
        } else if (res?.data?.message === 'Password not updated') {
          setScreen('login');
          window.location.href = '/';
        }
      });
    }
  };

  useEffect(() => {
    if (user) {
      if (licence?.data)
        licence?.data?.accepted === 1
          ? nav(isApprover(user) ? '/approvers' : '/dashboard')
          : nav('/privacy-policy');
    }
  }, []);

  return (
    <LoginContainer>
      <LoginFormWrap>
        <Formik
          initialValues={{
            email: '',
            password: '',
            newPassword: '',
            confirmPassword: '',
            code: '',
            remember: false,
          }}
          validate={(values) => {
            const errors = {};
            if (screen === 'login') {
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              if (!values.password) {
                errors.password = 'Required';
              }
              return errors;
            } 
            else if (view === 'FORGOT') {
              if (!values.newPassword) {
                errors.newPassword = 'Required';
              }else {
                // Minimum length of 6 characters
                if (values.newPassword.length < 6) {
                  errors.newPassword = 'Password must be at least 6 characters long';
                }

                // Must contain at least one uppercase letter
                if (!/[A-Z]/.test(values.newPassword)) {
                  errors.newPassword = 'Password must contain at least one uppercase letter';
                }

                // Must contain at least one special character
                if (!/[!@#$%^&*(),.?":{}|<>]/.test(values.newPassword)) {
                  errors.newPassword = 'Password must contain at least one special character';
                }
              }
              if (!values.code) {
                errors.code = 'Required';
              }
              return errors;
            }
            else if (view === 'FORGOTEMAIL') {
              if (!values.email) {
                errors.email = 'Required';
              } else if (
                !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
              ) {
                errors.email = 'Invalid email address';
              }
              return errors;
            }else if (view === 'MFA') {
              if (!values.code) {
                errors.code = 'Required';
              }
              return errors;
            } else if (view === 'RESET') {
              // Password validation
              if (!values.newPassword) {
                errors.newPassword = 'Required';
              } else {
                // Minimum length of 6 characters
                if (values.newPassword.length < 6) {
                  errors.newPassword =
                    'Password must be at least 6 characters long';
                }

                // Must contain at least one uppercase letter
                if (!/[A-Z]/.test(values.newPassword)) {
                  errors.newPassword =
                    'Password must contain at least one uppercase letter';
                }

                // Must contain at least one special character
                if (!/[!@#$%^&*(),.?":{}|<>]/.test(values.newPassword)) {
                  errors.newPassword =
                    'Password must contain at least one special character';
                }
              }
              // Confirm password validation
              if (!values.confirmPassword) {
                errors.confirmPassword = 'Required';
              } else if (values.newPassword !== values.confirmPassword) {
                errors.confirmPassword =
                  'New password and confirm password must be same';
              }
              return errors;
            }
          }}
          onSubmit={(values) => {
            screen === 'login'
              ? handleLogin(values)
              : handleVerificationCode(values);
          }}
        >
          {({ isSubmitting }) => (
            <Form className="w-100">
              {screen === 'login' ? (
                <>
                  <Wrap className="d-flex flex-column justify-content-center align-items-center mb-5">
                    <OccyColoredIcon />
                    <StyledLabel
                      size={'24px'}
                      color={theme?.heading}
                      className="text-center mt-3"
                    >
                      Login in to your account
                    </StyledLabel>
                  </Wrap>
                  <Row className="my-4 px-1">
                    <Col xs={12} className="mb-4">
                      <StyledLabel color={theme?.headingColor} className="mb-1">
                        Email ID
                      </StyledLabel>

                      <StyledFormikInputField
                        type="email"
                        name="email"
                        placeholder="Email ID"
                      />
                    </Col>
                    <Col xs={12}>
                      <StyledLabel color={theme?.headingColor} className="mb-1">
                        Password
                      </StyledLabel>
                      <StyledPasswordInputWrap>
                        {
                          <StyledFormikInputField
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            placeholder="Password"
                          />
                        }

                        {!showPassword ? (
                          <EyeIcon
                            className="password-visibility-icon"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        ) : (
                          <EyeIcon
                            className="password-visibility-icon"
                            onClick={() => setShowPassword(!showPassword)}
                          />
                        )}
                      </StyledPasswordInputWrap>
                    </Col>
                  </Row>
                  <Row className="my-4 px-1 d-flex justify-content-end">
                    {/* <Col xs={6} className='d-none'>
                      <FormikCheckBox
                        className="text-headingColor"
                        labelSize={14}
                        label={'Remember me'}
                        name={'remember'}
                        id={'remember'}
                        size={18}
                      />
                    </Col> */}
                   
                      <StyledButton
                        variant="text"
                        className={`fw-bold bg-transparent border-0  pe-0 justify-content-end pe-3 text-decoration-underline ${theme?.dark ? 'text-headingColor' : 'text-primary'}`}
                      >
                       <div onClick={()=>{setView('FORGOTEMAIL');setScreen('')}}> Forgot Password</div>
                      </StyledButton>
                    
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <StyledButtonWrap
                        type="submit"
                        disabled={loginLoading || licenceLoading}
                        loading={loginLoading || licenceLoading}
                        className="w-100 py-2"
                      >
                        Login
                      </StyledButtonWrap>
                    </Col>
                  </Row>
                </>
              ) : (
                <>
                  <>
                    {' '}
                    {view === 'MFA' && (
                      <VerificationWrapper>
                        <StyledText
                          as={'p'}
                          $fontWeight={'600'}
                          $fontSize={'20px'}
                          className="mb-0 text-headingColor"
                        >
                          Enter the 6-digit code generated by the authenticator
                          app
                        </StyledText>
<div className='my-4'>
                        <StyledFormikInputField
                          type="code"
                          name="code"
                          placeholder="Enter 6-digit code"
                        />
                        </div>

                        <FormikCheckBox
                          className="text-lightGray fw-normal d-none"
                          labelSize={14}
                          label={'Recognize this device in future'}
                          name={'rememberDevice'}
                          id={'rememberDevice'}
                          size={16}
                        />

                        <StyledButtonWrap
                          type="submit"
                          disabled={verificationLoading}
                          loading={verificationLoading}
                          className="w-100 py-2  my-4"
                        >
                          Verify
                        </StyledButtonWrap>

                  <StyledLabel
                    className="text-center w-100 d-flex justify-content-start text-lightGray d-none"
                    weight="400"
                  >
                    Didn’t get a code.
                    <StyledButton
                      as={Link}
                      className="text-actionIcons ms-1 fw-bold"
                      to="/signup"
                    >
                      Resend code
                    </StyledButton>
                  </StyledLabel>
                </VerificationWrapper>)}
                </><>
                {view === 'RESET' && (
                  <VerificationWrapper>
                    <StyledText
                      as={'p'}
                      $fontWeight={'600'}
                      $fontSize={'20px'}
                      className="mb-0 text-headingColor mb-4"
                    >
                      Reset Password
                    </StyledText>
<div className='mb-3'>
                    <StyledFormikInputField
                      type="password"
                      name="newPassword"
                      placeholder="New password" />
</div>
                    <StyledFormikInputField
                      type="password"
                      name="confirmPassword"
                      placeholder="Confirm password" />

                    <StyledButtonWrap
                      type="submit"
                      disabled={resetLoading}
                      loading={resetLoading}
                      className="w-100 py-2 mt-4"
                    >
                      Reset
                    </StyledButtonWrap>

                  </VerificationWrapper>)}
                  </></>
              )}
                {view === 'FORGOT' && (
                  <VerificationWrapper>
                    <StyledText
                      as={'p'}
                      $fontWeight={'600'}
                      $fontSize={'20px'}
                      className="mb-0 text-headingColor mb-4"
                    >
                      Forgot Password
                    </StyledText>

                   <div className='mb-3'> <StyledFormikInputField
                      type="text"
                      name="code"
                      placeholder="Enter code"
                      autoComplete="off" /> </div>
                    <StyledFormikInputField
                      type="password"
                      name="newPassword"
                      placeholder="New password" 
                      autoComplete="off" 
                      />
                     

                    <StyledButtonWrap
                      type="submit"
                      disabled={forgotLoading}
                      loading={forgotLoading}
                      className="w-100 py-2 mt-4"
                    >
                      Submit
                    </StyledButtonWrap>

                  </VerificationWrapper>)}
                  {view === 'FORGOTEMAIL' && (
                  <VerificationWrapper>
                    <StyledText
                      as={'p'}
                      $fontWeight={'600'}
                      $fontSize={'20px'}
                      className="mb-0 text-headingColor mb-4"
                    >
                      Forgot Password
                    </StyledText>

                    <StyledFormikInputField
                      type="text"
                      name="email"
                      placeholder="Enter email" 
                      autoComplete="off" 
                      />

                    <StyledButtonWrap
                      type="submit"
                      disabled={emailLoading}
                      loading={emailLoading}
                      className="w-100 py-2 mt-4"
                    >
                      Submit
                    </StyledButtonWrap>

                  </VerificationWrapper>)}
              
                  {screen === 'login' &&  <Row className="my-4 px-2">
                <Col xs={12}>
                  <StyledWrap>
                    <HR />
                    <StyledOr>or</StyledOr>
                  </StyledWrap>
                </Col>
              </Row>}

              {screen === 'login' && <Row className="px-2">
                <Col xs={12} md="6">
                  <StyledButton
                    variant="white"
                    className="w-100 py-2 fw-bold socialBtn"
                    icon={<GoogleIcon />}
                    onClick={() => getUrlForGoogleLogin()}
                    disabled={googleLoading}
                    loading={googleLoading}
                  >
                    Continue with Google
                  </StyledButton>
                </Col>
                <Col xs={12} md="6">
                  <StyledButton
                    variant="white"
                    className="w-100 py-2 fw-bold socialBtn"
                    icon={<MicrosoftIcon />}
                    disabled={outlookLoading}
                    loading={outlookLoading}
                    onClick={() => getUrlForOutlookLogin()}
                  >
                    Continue with Microsoft
                  </StyledButton>
                </Col>
              </Row>}

              <StyledLabel className="text-center w-100 mt-5 d-flex justify-content-center d-none">
                Don’t have an account?{' '}
                <StyledButton
                  as={Link}
                  className="text-actionIcons ms-1 fw-bold"
                  to="/signup"
                >
                  Sign up
                </StyledButton>
              </StyledLabel>
            </Form>
          )}
        </Formik>
      </LoginFormWrap>
    </LoginContainer>
  );
};

const VerificationWrapper = styled.div`
  // display: flex;
  padding: 20px 16px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  align-self: stretch;
  border-radius: 8px;
  background-color: transparent`;

const StyledPasswordInputWrap = styled.div`
  position: relative;
  .password-visibility-icon {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 1;
  }
`;

const StyledOr = styled.div`
  background-color: ${({ theme }) =>
    theme?.dark ? theme?.purpleLight : '#fff'};
  color: ${({ theme }) => theme?.headingColor};
  font-size: 14px;
  height: 24px;
  width: 24px;
  text-align: center;
  position: absolute;
  left: 47%;
  right: 53%;
  top: -12px;
`;

const StyledWrap = styled.div`
  position: relative;
  ${({ theme }) => theme?.dark && `
   hr {
    border-top: 1px solid
      ${({ theme }) => (theme?.dark ? theme?.purpleBorder : theme?.skeltonBaseColor)} !important;
  }
  ` }
  }
 
`;

const LoginContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: ${({ theme }) =>
    theme?.dark ? theme?.darkPurple : theme?.primaryLight};
`;

const LoginFormWrap = styled.div`
  width: 556px;
  padding: 40px 30px;
  background-color: ${({ theme }) =>
    theme?.dark ? 'rgba(255, 255, 255, 0.06)' : theme?.white};
  // border: 1px solid #ddd;
  border-radius: 16px;
  box-shadow: 0px 4px 6px -2px ${({ theme }) => (theme?.dark ? 'rgba(33, 0, 34, 0.20)' : 'rgba(74, 21, 75, 0.03)')},
    0px 12px 16px -4px ${({ theme }) => (theme?.dark ? 'rgba(48, 0, 48, 0.12)' : 'rgba(74, 21, 75, 0.08)')};
  display: flex;
  gap: 40px;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus {
    background-color: ${({ theme }) =>
      theme?.dark ? '#3c1b3d' : theme?.white} !important;
    color: #000 !important;
    box-shadow: none !important;
  }
  .socialBtn {
    border:1px solid ${({ theme }) => (theme?.dark ? theme?.purpleBtnBorder : theme?.primary)};
  }
`;

const StyledButtonWrap = styled(StyledButton)`
  height: 48px;
  padding: 12px 20px;
  background-color: ${({ theme }) =>
    theme?.dark ? theme?.secondary : '#5e1b60'} !important;
`;

const StyledFormikInputField = styled(FormikInputField)`
  background-color: ${({ theme }) => (theme?.dark ? '#3c1b3d' : theme?.white)};
  border-color: ${({ theme }) =>
    theme?.dark ? 'rgba(255, 255, 255, 0.12)' : theme.inputBorder};
  &:focus {
    background-color: ${({ theme }) =>
      theme?.dark ? '#3c1b3d' : theme?.white};
  }
`;

export default LoginForm;
