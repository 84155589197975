import moment from 'moment';
import React from 'react';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledDot from '../../../app/components/styledComponents/StyledDot';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import { getCurrencySign } from '../../../utils/helper';
import { STATUSES } from '../../../utils/Statuses';
import { useTheme } from 'styled-components';
// import { datesOffered } from '../assets/kanbanUtill';

const KanbanOfferLetter = ({ data }) => {
  const { msg, datesOffered } = data;
  const theme = useTheme();

  const filterHtmlRigix = (text) => {
    return (
      <div
        dangerouslySetInnerHTML={{
          __html: text.replace('<p className="ql-align-justify"><br></p>', ''),
        }}
      />
    );
  };


  return (
    <Wrap className="justify-content-center d-flex">
      <Wrap className="col-7 mt-5 border rounded">
        <Wrap className="bg-gray rounded-3 ">
          <Wrap className="bg-white px-5 py-4 rounded-top flex-column d-flex">
            <StyledLabel size="24px">Offer Letter</StyledLabel>
            <StyledLabel weight="500px" color={theme.textColor}>
              {msg?.jobOffer?.statusId ===
                STATUSES.REJECTED
                ? 'You rejected the offer.'
                : msg?.jobOffer?.statusId ===
                  STATUSES.ACCEPTED
                  ? 'You have accepted the offer successfuly'
                  : msg?.jobOffer?.statusId ===
                    STATUSES.AWAITING_FEEDBACK
                    ? 'The following is your offer letter. Please read carefully before accepting.'
                    : 'The following is your offer letter. Please read carefully before accepting.'}
            </StyledLabel>
          </Wrap>
        </Wrap>
        <Wrap className="px-5 py-4">
          <PortletBody className="border">
            <StyledLabel weight="500px" color={theme.textColor}>
              {msg?.jobOffer?.statusId ===
                STATUSES.REJECTED
                ? 'Rejected'
                : msg?.jobOffer?.statusId ===
                  STATUSES.ACCEPTED
                  ? 'Accepted'
                  : msg?.jobOffer?.statusId ===
                    STATUSES.AWAITING_FEEDBACK
                    ? 'Awaiting Feedback'
                    : 'Sent'}
              {filterHtmlRigix(msg?.jobOffer?.offerLetter ?? '')}
            </StyledLabel>

            <Wrap className="my-4">
              <Wrap className="d-flex align-items-center gap-2 mb-2">
                <StyledLabel weight="500px" color={theme.textColor}>
                  Job Title:
                </StyledLabel>
                <StyledLabel weight="500px" color={theme.primary}>
                  {msg?.job?.title ?? ''} ({msg?.job?.jobType ?? ''})
                </StyledLabel>
              </Wrap>
              <Wrap className="d-flex align-items-center gap-2">
                <StyledLabel weight="500px" color={theme.textColor}>
                  Salary:
                </StyledLabel>
                <StyledLabel weight="500px" color={theme.primary}>
                  {getCurrencySign(msg?.jobOffer?.currency ?? '')}{' '}
                  {msg?.jobOffer?.salary ?? ''}/{msg?.jobOffer?.payPer ?? ''}
                </StyledLabel>
              </Wrap>
            </Wrap>
          </PortletBody>

          {msg?.jobOffer?.statusId === STATUSES.ACTIVE ? (
            <PortletBody className="border my-3 ">
              <Wrap className="d-flex align-items-center gap-2">
                <StyledDot color={theme.camelColor} />
                <StyledLabel>Start Dates Offered</StyledLabel>
              </Wrap>
              {datesOffered?.map((item, index) => (
                <Wrap key={index} className="border-bottom py-3">
                  <StyledLabel>
                    {moment(item?.jobPosition?.startDate).format('D MMM YYYY')}
                  </StyledLabel>
                </Wrap>
              ))}
            </PortletBody>
          ) : msg?.jobOffer?.statusId === STATUSES.REJECTED ||
            msg?.jobOffer?.statusId === STATUSES.AWAITING_FEEDBACK ? (
            <PortletBody className="border my-3 ">
              <Wrap className="d-flex align-items-center gap-2">
                <StyledDot
                  color={
                    msg?.jobOffer?.statusId === STATUSES.REJECTED
                      ? theme.danger
                      : theme.blue
                  }
                />
                <StyledLabel>
                  {' '}
                  {msg?.jobOffer?.statusId === STATUSES.REJECTED
                    ? 'Offer Rejected'
                    : 'Offer Awaiting Feedback'}
                </StyledLabel>
              </Wrap>
              <Wrap>
                {/* <Wrap className="d-flex flex-column my-3">
                  <StyledLabel color={theme.textColor}>
                    Reason for rejection
                  </StyledLabel>
                  <StyledLabel
                    color={theme.lightBoldBlack}
                    weight="500px"
                    className="mt-2"
                  >
                    Found a better job offer
                  </StyledLabel>
                </Wrap> */}
                {/* <hr /> */}
                <Wrap>
                  <StyledLabel color={theme.textColor} className="me-1">
                    Comment:
                  </StyledLabel>
                  <StyledLabel
                    color={theme.lightBoldBlack}
                    weight="500px"
                    className="mt-2"
                  >
                    {filterHtmlRigix(msg?.jobOffer?.applicantComment ?? '')}
                  </StyledLabel>
                </Wrap>
              </Wrap>
            </PortletBody>
          ) : msg?.jobOffer?.statusId === STATUSES.ACCEPTED ? (
            <PortletBody className="border my-3 ">
              <Wrap className="d-flex align-items-center gap-2">
                <StyledDot color={theme.green} />
                <StyledLabel>Offer Accepted</StyledLabel>
              </Wrap>
              <Wrap className="d-flex justify-content-start align-items-center">
                {/* <Wrap className="col-5 d-flex flex-column my-3 border-end">
                  <StyledLabel color={theme.textColor}>
                    Custom Date Suggested
                  </StyledLabel>
                  <StyledLabel
                    color={theme.lightBoldBlack}
                    weight="500px"
                    className="mt-2"
                  >
                    {msg?.jobOffer?.offerProposedDate
                      ? moment(msg?.jobOffer?.offerProposedDate).format(
                          'Do   MMM YYYY',
                        )
                      : '- -'}
                  </StyledLabel>
                </Wrap> */}
                <Wrap className="d-flex flex-column my-3">
                  <StyledLabel color={theme.textColor}>
                    Marked as Hired for
                  </StyledLabel>
                  <StyledLabel
                    color={theme.lightBoldBlack}
                    weight="500px"
                    className="mt-2"
                  >
                    {datesOffered?.jobPosition?.startDate
                      ? moment(datesOffered?.jobPosition?.startDate).format(
                        'Do   MMM YYYY',
                      )
                      : '- -'}
                  </StyledLabel>
                </Wrap>
              </Wrap>
            </PortletBody>
          ) : null}

          <PortletBody className="d-flex flex-column border">
            <StyledLabel className="mb-2">For queries contact:</StyledLabel>
            <StyledLabel color={theme.textColor}>
              {msg?.job?.account?.firstName ?? ''}{' '}
              {msg?.job?.account?.lastName ?? ''}
            </StyledLabel>
            <Wrap className="d-flex align-items-center gap-2">
              <StyledLabel
                color={theme.lightBoldBlack}
                size="12px"
                weight="500px"
              >
                {msg?.job?.account?.email ?? ''}
              </StyledLabel>
              <StyledDot />
              <StyledLabel
                color={theme.lightBoldBlack}
                size="12px"
                weight="500px"
              >
                {msg?.job?.account?.mobile ?? ''}
              </StyledLabel>
            </Wrap>
          </PortletBody>
        </Wrap>
      </Wrap>
    </Wrap>
  );
};

export default KanbanOfferLetter;
