import React from 'react';
import { useState } from 'react';
import { useQuery } from '../../../../utils/helper';
import { useEffect } from 'react';
import { useAcceptRejectDocumentMutation, useGetDocumentQuery } from '../../offersApiSlice';
import DocumentAgreement from './DocumentAgreement';
import JobOfferAcception from '../../CandidateOffer/JobOfferAcception';
const DocumentContainer = () => {
    const [agreement, setAgreement] = useState('');
    const query = useQuery();
    const token = query?.get('token');
    const { data: document, refetch, isLoading: responceLoading } = useGetDocumentQuery(token);
    const [acceptRejectDocument, { isLoading }] = useAcceptRejectDocumentMutation();

    useEffect(() => {
        if (agreement === '') refetch();
    }, [agreement]);

    return agreement === 'Continue' ? (
        <JobOfferAcception
            title={document?.agreementTpl?.title}
            description={`Please find the details of our ${document?.agreementTpl?.title.toLowerCase() + ' agreement' ?? ' NDA'} below`}
            attachmentDescription={`Sign and return the  ${document?.agreementTpl?.title.toLowerCase() + ' agreement' ?? ' NDA'} and download other documents for future reference`}
            jobOffer={document}
            document
            setOfferLetter={setAgreement}
        />
    ) : (
        <DocumentAgreement
            refetch={refetch}
            setAgreement={setAgreement}
            document={document}
            token={token}
            acceptRejectDocument={acceptRejectDocument}
            isLoading={isLoading}
            agreement={agreement}
            responceLoading={responceLoading}
        />
    );
};

export default DocumentContainer;
