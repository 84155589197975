import React from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '../utils/themes';
import { Outlet } from 'react-router-dom';

const PublicPagesTheme = () => {

  return (
    <ThemeProvider
      theme={
        {
          ...theme,
          darkGraydarkMode: '',
          darkThemBoxBgColor: '',
          dark: '',
          darkLight: '',
          darkGrayBackground: '',
          darkTagGreenBg: '',
          darkTagGreenTextColor: '',
          darkHeaderBg: '',
        } || {}
      }
    >
      <div data-bs-theme="light" data-theme="light">
        <Outlet />
      </div>
    </ThemeProvider>
  );
};

export default PublicPagesTheme;
