import React from 'react';

const JobSettingsIcon2 = ({ size=20, color='#767476', ...props }) => (
<svg width={size} height={size} viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
<path d="M11.1298 16.239C10.8798 16.239 10.6298 16.109 10.4898 15.879C10.2798 15.529 10.3898 15.059 10.7498 14.849L11.6398 14.319V13.239C11.6398 12.829 11.9798 12.489 12.3898 12.489C12.7998 12.489 13.1398 12.829 13.1398 13.239V14.739C13.1398 14.999 12.9998 15.249 12.7798 15.379L11.5298 16.129C11.3898 16.199 11.2598 16.239 11.1298 16.239Z" fill={color}/>
<path d="M21.09 7.33894C20.24 6.39894 18.83 5.92894 16.76 5.92894H16.52V5.88894C16.52 4.20894 16.52 2.12894 12.76 2.12894H11.24C7.48004 2.12894 7.48004 4.20894 7.48004 5.88894V5.92894H7.24004C5.17004 5.92894 3.75004 6.39894 2.91004 7.33894C1.92004 8.44894 1.95004 9.91894 2.05004 10.9289L2.06004 10.9989L2.12912 11.8049C2.14232 11.9589 2.22586 12.0982 2.35558 12.1821C2.63927 12.3657 2.94109 12.5584 3.23004 12.7189C3.37004 12.8089 3.52004 12.8889 3.67004 12.9689C4.80004 13.5889 6.01004 14.0789 7.25004 14.4189C7.28004 17.0089 9.40004 19.1089 12 19.1089C14.62 19.1089 16.75 16.9789 16.75 14.3589V14.3189C18.01 13.9389 19.22 13.4089 20.35 12.7489C20.41 12.7189 20.45 12.6889 20.5 12.6589C20.8949 12.4443 21.3045 12.1634 21.6782 11.8984C21.7941 11.8162 21.869 11.6883 21.8847 11.547L21.9 11.4089L21.95 10.9389C21.96 10.8789 21.96 10.8289 21.97 10.7589C22.05 9.75894 22.03 8.37894 21.09 7.33894ZM8.91004 5.88894C8.91004 4.18894 8.91004 3.54894 11.24 3.54894H12.76C15.09 3.54894 15.09 4.18894 15.09 5.88894V5.92894H8.91004V5.88894ZM12 17.6089C10.35 17.6089 8.98004 16.3689 8.78004 14.7689C8.76004 14.6389 8.75004 14.4989 8.75004 14.3589C8.75004 12.5689 10.21 11.1089 12 11.1089C13.79 11.1089 15.25 12.5689 15.25 14.3589C15.25 14.4789 15.24 14.5889 15.23 14.6989V14.7089C15.05 16.3389 13.67 17.6089 12 17.6089Z" fill={color}/>
<path d="M21.0396 14.0889C21.2883 13.9508 21.611 14.151 21.5851 14.4343L21.2296 18.3189C21.0196 20.3189 20.1996 22.3589 15.7996 22.3589H8.17961C3.77961 22.3589 2.95961 20.3189 2.75961 18.3289L2.41374 14.6256C2.38689 14.3381 2.6944 14.1438 2.94961 14.2789C3.72961 14.7089 4.53961 15.0889 5.36961 15.3889C5.67961 15.4989 5.89961 15.7589 5.98961 16.0789C6.73961 18.6789 9.15961 20.6089 11.9996 20.6089C14.8896 20.6089 17.3296 18.6389 18.0396 15.9389C18.1196 15.6189 18.3396 15.3589 18.6496 15.2389C19.4896 14.9089 20.2896 14.5189 21.0396 14.0889Z" fill={color}/>
</svg>

);
export default JobSettingsIcon2;
