import React, { useEffect, useState } from 'react';
import CustomDropdown from '../dropdown/CustomDropDown';
// import DropdownItem from 'react-bootstrap/DropdownItem';
import styled, { useTheme } from 'styled-components';
import DropDownIcon from '../svgIcons/DropDownIcon';
import { handleErrorImg } from '../../../utils/helper';
import { useNavigate } from 'react-router-dom';
import { occyLs } from '../../../utils/localStorage';
import { StyledLabel } from '../../../assets/styledComponents/Global';
import AvatarIcon from '../svgIcons/AvatarIcon';
// import UserProfileBacground from '../svgIcons/UserProfileBackground';
import MagicPenIcon from '../svgIcons/MagicPenIcon';
import SignOutIcon from '../svgIcons/SignOutIcon';
import ToggleArrowIcon from '../svgIcons/ToggleArrowIcon';
import EditIcon from '../svgIcons/EditIcon';
import NavigateIcon from '../svgIcons/NavigateIcon';
import StyledColoredTag from './StyledColoredTag';

const StyledProfileDropDown = ({
  toggleClassName,
  menuItems,
  menuClassName,
  profile,
}) => {
  const navigate = useNavigate();
  const delegatedAccount = occyLs.getObject('account');
  const theme = useTheme();
  const formatDateRange = (start, end) => {
    if (start && end) {
      const options = { 
        day: '2-digit', 
        month: 'short', 
        year: 'numeric', 
        hour: 'numeric', 
        minute: 'numeric', 
        hour12: true // Use 12-hour format with AM/PM
      };
      
      const startDate = new Date(start);
      const endDate = new Date(end);
      
      // Automatically formats to the browser's local timezone
      const formattedStart = new Intl.DateTimeFormat('en-US', options).format(startDate);
      const formattedEnd = new Intl.DateTimeFormat('en-US', options).format(endDate);
      
      return `${formattedStart} - ${formattedEnd}`;
    } else {
      return '';
    }
  };

    // State to manage the visibility of the rows
    const [areRowsVisible, setAreRowsVisible] = useState(false);

    // Function to toggle the visibility of rows
    const toggleRowsVisibility = () => {
      setAreRowsVisible(!areRowsVisible);
    };
  
    const handleClick = (userId) => {
      const currentUrl = window.location.origin;
      if(delegatedAccount?.linkedAccounts && delegatedAccount?.linkedAccounts?.delegatedAcc?.id === userId){
   // Open a new tab with the same URL
   window.location.href = currentUrl + `/delegated/user/default`;
      }
      else{
        window.location.href = currentUrl + `/delegated/user/${userId}`;
      }
    };
const [linkedAccounts,setLinkedAccounts] = useState([]);
    useEffect(()=>{
if(delegatedAccount?.linkedAccounts){
  const mergedAccounts = [
    { account: delegatedAccount?.linkedAccounts?.delegatedAcc },
    ...delegatedAccount?.linkedAccounts?.oooAccounts?.map(acc => ({ account: acc }))
  ];
  // Set the merged array to state
  setLinkedAccounts(mergedAccounts);
}
if(delegatedAccount.oooDelegatedBy && delegatedAccount.oooDelegatedBy?.length > 0){
  setLinkedAccounts(delegatedAccount.oooDelegatedBy);
}
    },[delegatedAccount?.email])

return(
  <ProfileDropDownStyle className="d-flex align-items-center gap-4">
    <div className={`account-img d-flex cursor-pointer ${delegatedAccount?.oooAccount ? 'w-100' : ''} ${!profile?.image ? 'default' : ''}`} onClick={()=>navigate('settings/accounts/profile')}>
      {profile?.image ? (
        <><StyledFollowerImageOrig
        height={'35px'}
        width={'35px'}

      ><img
          src={profile?.signedUrl}
          alt={profile?.firstName || 'image'}
          onError={handleErrorImg}
          className={`${delegatedAccount?.oooAccount?.delegatedAccount ? 'delegated' : ''}`} /></StyledFollowerImageOrig>
          {delegatedAccount?.oooAccount && (<StyledFollowerImage
            height={'35px'}
            width={'35px'}

          >
             {delegatedAccount?.oooAccount?.delegatedAccount?.signedUrl ? <img
              src={delegatedAccount?.oooAccount?.delegatedAccount?.signedUrl}
              alt={delegatedAccount?.oooAccount?.delegatedAccount?.firstName || 'delegetaed'}
              className="rounded-circle"
              onError={(e) => handleErrorImg(e)} />
              : (
                                <AvatarIcon/>
                              )}
          </StyledFollowerImage>)}</>
      ) : (
        <><AvatarIcon/></>
      )}
       
    </div>
 
    <CustomDropdown
      toggleClassName={toggleClassName}
      menuClassName={menuClassName}
      dropdownToggle={
        delegatedAccount?.oooAccount ? (<div className={`account-menu ${toggleClassName} d-flex flex-column justify-content-start `}>
          {/*<div className={`account-img default`}>*/}
          {/* <StyledNote className='px-2 py-1'>
            <StyledLabel size={'12px'} weight={600}> Read only mode </StyledLabel>
              </StyledNote>  */}
              <div className='d-flex justify-content-start w-100 pt-2 ps-1'>
          <h6>{profile?.firstName || ''}</h6>

          <DropDownIcon rotate="down" className="ms-1" />
          </div>
        </div>):(
          <div className={`account-menu ${toggleClassName} `}>
          
          <h6>{profile?.firstName || ''}</h6>

          <DropDownIcon rotate="down" className="ms-1" />
        </div>
        )
      }
      dropdownMenu={
        <span>
          <>
        <Styleddiv className="d-flex justify-content-center align-items-end p-3">
          <StyledProfileText className="position-absolute">
            <div className='d-flex justify-content-center align-items-center'>
              <StyledLabel className="text-headingColor" size={'24px'} weight={600}>Hi, {delegatedAccount?.firstName}!</StyledLabel>
            </div>
          </StyledProfileText>
          <StyledProfileImage className="position-absolute border border-4 border-white rounded-circle">
            <div className="d-flex justify-content-end align-items-end">
              <div className="position-absolute rounded-circle bg-white p-2 start- edit-icon">
                 <EditIcon type="button" color={theme?.actionsIconColor} size={20} className="text-actionIcons" onClick={() => window.location.href='/settings/accounts/profile'} />
              </div>
              <StyledImage1 height={'100px'} width={'100px'}>
                <img src={delegatedAccount?.signedUrl} alt="img" className="rounded-circle" onError={handleErrorImg} />
              </StyledImage1>
            </div>
          </StyledProfileImage>
          {/* {theme?.dark ? null : <UserProfileBacground />} */}
        </Styleddiv>
        <div className="d-flex justify-content-center flex-column text-center pt-5 mt-2">
          <StyledLabel size={'24px'} weight={600}>
            {delegatedAccount?.firstName} {delegatedAccount?.lastName}
          </StyledLabel>
          <StyledLabel className='text-muted' size={'14px'} weight={400}  color={theme.textColor}>{delegatedAccount?.email}</StyledLabel>
        </div>
        <div className='px-2 py-1'>
          {delegatedAccount?.oooAccount && (
            <StyledNoticebar className='d-flex mb-3 w-100 ms-0'>
              <div className='pe-2'>
                <MagicPenIcon color={theme.gold} size={16} />
              </div>
              <div className='d-flex flex-column'>
                <StyledLabel size={'14px'} weight={'600'} >OOO - Read Only Mode</StyledLabel>
                <StyledLabel size={'12px'} weight={'500'} >{formatDateRange(delegatedAccount?.oooAccount?.startTime, delegatedAccount?.oooAccount?.endTime)}</StyledLabel>
              </div>
            </StyledNoticebar>
          )}
          {delegatedAccount?.oooAccount && (
            <>
              <StyledMain>
                <StyledRow className='d-flex flex-column'>
                  <StyledLabel size={'16px'} weight={'600'} className='text-primaryIcon'>Delegated User</StyledLabel>
                  <div className='d-flex my-3'>
                    <div className='pe-3'>
                      <StyledImgOOO>
                        {delegatedAccount?.oooAccount?.delegatedAccount?.signedUrl ? (
                          <img
                            src={delegatedAccount?.oooAccount?.delegatedAccount?.signedUrl}
                            alt={delegatedAccount?.oooAccount?.delegatedAccount?.firstName || "user_image"}
                            className="rounded-circle"
                            onError={handleErrorImg} />
                        ) : (
                          <AvatarIcon />
                        )}
                      </StyledImgOOO>
                    </div>
                    <div className=''>
                      <div className='d-flex justify-content-center flex-column'>
                        <StyledLabel className='text-primaryIcon' size={'16px'} weight={'600'}>
                          <div className='d-flex justify-content-between'>{delegatedAccount?.oooAccount?.delegatedAccount?.firstName + ' ' + delegatedAccount?.oooAccount?.delegatedAccount?.lastName}</div>
                        </StyledLabel>
                        <StyledLabel className='text-muted' size={'12px'} weight={'500'}>
                          {delegatedAccount?.oooAccount?.delegatedAccount?.email}
                        </StyledLabel> 
                      </div>
                    </div>
                  </div>
                </StyledRow>
                <StyledRowNew className='d-flex justify-content-start align-items-center m-0 mt-2 mb-3'>
  <div className='d-flex align-items-center ms-2 text-actionIcons' style={{ marginRight: '20px' }}>
    <SignOutIcon size={20} />
  </div>
  <div className='d-flex flex-grow-1 ms-2'>
    {menuItems?.map((item, index) => (
      <div
        className='cursor-pointer'
        onClick={item?.action}
        key={index}
        style={{ flex: 1 }}
      >
        <StyledLabel size={'16px'} weight={'600'} className='cursor-pointer text-primaryIcon'>
          Sign out
        </StyledLabel>
      </div>
    ))}
  </div>
</StyledRowNew>

              </StyledMain>
            </>
          )}
      {((linkedAccounts?.length === 0) && !delegatedAccount?.oooAccount ) && <StyledRowNew className='row m-0 rounded mt-4 mb-3 d-flex align-items-center'>
                  <div className='col-2 text-actionIcons'>
                    <SignOutIcon size={20}/>
                  </div>
                  {menuItems?.map((item, index) => (
                    <div className='col-10 cursor-pointer' onClick={item?.action} key={index}>
                      <StyledLabel size={'16px'} weight={'600'} className='cursor-pointer'>Sign out</StyledLabel>
                    </div>
                  ))}
                </StyledRowNew>}

{(delegatedAccount?.oooDelegatedBy?.length > 0 || delegatedAccount?.linkedAccounts) &&   <StyledRow className={`${areRowsVisible ? '' : 'mb-3'} d-flex flex-column bg-light p-3 mt-3`} onClick={toggleRowsVisibility}>
            <div className='d-flex justify-content-between align-items-center cursor-pointer w-100'>
              <StyledLabel size={'16px'} weight={'600'} className='text-primaryText'>
                {areRowsVisible ? 'Hide more accounts' : 'Show more accounts'}
              </StyledLabel>
              <div>{areRowsVisible ? <ToggleArrowIcon size={16} rotate='right'/>  : <ToggleArrowIcon rotate='left' size={16}/>}</div>
            </div>
          </StyledRow>}

          {areRowsVisible && (delegatedAccount?.oooDelegatedBy || delegatedAccount?.linkedAccounts ) && (
            <StyledMain>
             {linkedAccounts.map((delegatedAccountItem, index) => (
  <div key={index}>
    <StyledRow className="d-flex flex-column">
      {/* <div className="d-flex align-items-center justify-content-between">
        <div className="d-flex align-items-center">
          <div className="pe-3">
            <StyledImg>
              {delegatedAccountItem.account.signedUrl ? (
                <img
                  src={delegatedAccountItem.account.signedUrl}
                  alt={delegatedAccountItem.account.firstName || 'user_image'}
                  className="rounded-circle"
                  onError={handleErrorImg}
                />
              ) : (
                <AvatarIcon />
              )}
            </StyledImg>
          </div>
          <div>
            <div className="d-flex justify-content-center flex-column"> 
              <div className="d-flex justify-content-between align-items-center">
              <StyledLabel className="text-primary" font="16px" weight="600">
                <div className='d-flex justify-content-center align-items-center'>
                {delegatedAccountItem.account.firstName + ' ' + delegatedAccountItem.account.lastName}

                {(delegatedAccount?.linkedAccounts && index === 0) && <StyledColoredTag backgroundColor={theme.interviewAttend} color={theme.blue}>Default</StyledColoredTag>
      }</div>
              </StyledLabel>
            

                <StyledLabel className="text-secondary" font="14px" weight="500" style={{ marginLeft: '20px' }}  onClick={()=>handleClick(delegatedAccountItem?.account?.id)}>
               <NavigateIcon />
                </StyledLabel>
              </div>
              <StyledLabel className="text-muted" font="12px" weight="500" style={{ minWidth: '200px' }}>
                  {delegatedAccountItem.account.email}
                </StyledLabel>
            </div>
          </div>
        </div>
      </div> */}

      <div className='row'>
        <div className='col-2 d-flex align-items-center justify-content-center'> <div>
            <StyledImg border={(delegatedAccount?.linkedAccounts && index === 0) ? false : true}>
              {delegatedAccountItem && delegatedAccountItem?.account?.signedUrl ? (
                <img
                  src={delegatedAccountItem?.account?.signedUrl}
                  alt={delegatedAccountItem?.account?.firstName || 'user_image'}
                  className="rounded-circle"
                  onError={handleErrorImg}
                />
              ) : (
                <AvatarIcon />
              )}
            </StyledImg>
          </div></div>
        <div className='col-8'> <div className="d-flex justify-content-center flex-column"> 
              <div className="d-flex justify-content-between align-items-center">
              <StyledLabel className="text-primaryIcon" size="16px" weight="600">
                <div className='d-flex justify-content-center align-items-center'>
                {delegatedAccountItem?.account?.firstName || 'firstName' + ' ' + (delegatedAccountItem?.account?.lastName || 'lastName')}

                {(delegatedAccount?.linkedAccounts && index === 0) && <StyledColoredTag  backgroundColor={theme?.darkTagGreenBg || theme.interviewAttend} 
             color={theme?.darkTagGreenTextColor || theme.blue}>Default</StyledColoredTag>
      }</div>
              </StyledLabel>
            

               
              </div>
              <StyledLabel className="text-muted" size="12px" weight="500" style={{ minWidth: '200px' }}>
                  {delegatedAccountItem?.account?.email}
                </StyledLabel>
            </div></div>
        <div className={`col-2 d-flex align-items-center justify-content-center ${(new Date(delegatedAccountItem?.startTime) > new Date()) ? 'pe-none' : ''}`}> <StyledLabel className={theme?.dark ? "text-actionIcons" :"text-secondary"} size="14px" weight="500" style={{ marginLeft: '20px' }}  onClick={()=>handleClick(delegatedAccountItem?.account?.id)}>
               <NavigateIcon />
                </StyledLabel></div>
      </div>
    </StyledRow>
  </div>
))}

              {linkedAccounts?.length > 0 &&   <div className="mt-2 mb-3">
                        {/* Additional user details can go here */}
                        <StyledRowNew className="d-flex align-items-center m-0">
  <div className="d-flex align-items-center text-actionIcons ms-2" style={{ marginRight: '20px' }}>
    <SignOutIcon size={20} className='text-actionIcons'/>
  </div>
  <div className="d-flex flex-grow-1 ms-1">
    {menuItems?.map((item, itemIndex) => (
      <div
        className="cursor-pointer"
        onClick={item?.action}
        key={itemIndex}
        style={{ flex: 1 }}
      >
        <StyledLabel
          size={'16px'}
          weight={'600'}
          className="cursor-pointer text-primaryIcon"
        >
          {linkedAccounts <= 1 ? 'Sign out' : 'Sign out of all accounts'}
        </StyledLabel>
      </div>
    ))}
  </div>
</StyledRowNew>

                      </div>}
            </StyledMain>
          )}
        </div>
      </>
        </span>
      }
    />
  </ProfileDropDownStyle>
)}
StyledProfileDropDown.defaultProps = {
  toggleClassName: '',
  menuItems: [],
  menuClassName: '',
  profile: {
    firstName: '',
  },
};
const StyledRow = styled.div`
display: flex;
padding: 12px 16px;
justify-content: space-between;
align-items: flex-start;
align-self: stretch;
border-radius: 8px;
border-bottom: 0.5px solid ${({theme}) => theme?.dark ? theme?.darkLight : '#CCB2C8'};
background: ${({theme}) => theme?.dark ? theme?.darkTileBg : theme?.primaryLight};
`;

const StyledNoticebar = styled.div`
  background-color: ${({theme}) => theme?.tagLightYellow};
  padding: 12px 16px 12px 16px;
  border-radius: 8px;
  margin-right: 16px;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
  label{
   color:#585558;
  }
`;
const StyledMain= styled.div`
border-radius:8px;
background: ${({theme}) => theme?.dark ? theme?.darkTileBg : theme?.primaryLight};

`;
const StyledRowNew = styled.div`
display: flex;
padding: 12px 16px;
justify-content: space-between;
align-items: flex-start;
align-self: stretch;
border-bottom-right-radius: 8px;
border-bottom-left-radius: 8px;
// border-bottom: 0.5px solid var(--Primary-Shade-2, #CCB2C8);
background: ${({theme}) => theme?.dark ? theme?.darkTileBg : theme?.primaryLight};
`;
const StyledImgOOO = styled.div`
display: flex;
width: 40px;
height: 40px;
justify-content: center;
align-items: center;
border-radius: 19.156px;
background: var(--pastels-pastel-orange, #F7CEB5);
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* Medium */
box-shadow: 0px 2px 4px -2px rgba(74, 21, 75, 0.06), 0px 4px 8px -2px rgba(74, 21, 75, 0.10);
`;
const StyledImg = styled.div`
display: flex;
width: 40px;
height: 40px;
justify-content: center;
align-items: center;
border-radius: 19.156px;
border: ${(props) => props?.border ? '1px dashed var(--Reds-red-3, #C00)' : ''};
background: var(--pastels-pastel-orange, #F7CEB5);
img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* Medium */
box-shadow: 0px 2px 4px -2px rgba(74, 21, 75, 0.06), 0px 4px 8px -2px rgba(74, 21, 75, 0.10);
`;
const StyledImage1 = styled.div`
overflow: hidden;
width: ${(props) => props?.width || '30px'};
margin: 0;
height: ${(props) => props?.height || '30px'};
display: flex;
align-items: center;
justify-content: center;

img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
`;
const StyledProfileText = styled.div`
margin-bottom: 70px;
`;

const StyledProfileImage = styled.div`
  margin-bottom: -65px;
box-shadow: 0px 2px 4px -2px rgba(74, 21, 75, 0.06), 0px 4px 8px -2px rgba(74, 21, 75, 0.10);
.edit-icon{
fill: var(--Colors-Background-bg-primary, #FFF);
stroke-width: 1px;
stroke: var(--Primary-primary-shade-6, #E0E0E0);
filter: drop-shadow(0px 1px 2px rgba(74, 21, 75, 0.05));
margin-right:-11px;
}
`;
const Styleddiv = styled.div`
background-image: ${({theme}) => theme?.dark ? 'linear-gradient(106deg, #4A154B 2.09%, #004757 122.88%)' : 'linear-gradient(to right,#d1c2d6, #bbd1df)'};
border-top-right-radius: 8px;
border-top-left-radius: 8px;
height: 165px;
`
const StyledFollowerImageOrig = styled.div`
  overflow: hidden;
  width: ${(props) => props?.width || '30px'};
  height: ${(props) => props?.height || '30px'};
  display: inline-flex;
  align-items: center;
  position: relative;
  vertical-align: top;
  transition: transform 0.1s ease-out 0s;
  z-index: 5;
border-radius:30px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
`;
const StyledFollowerImage = styled.div`
  overflow: hidden;
  width: ${(props) => props?.width || '30px'};
  height: ${(props) => props?.height || '30px'};
  display: inline-flex;
  align-items: center;
  position: relative;
  margin-left: -12px;
  vertical-align: top;
  transition: transform 0.1s ease-out 0s;
  z-index: 5;
  ${({theme}) => theme?.dark && `color: ${theme?.headingColor}`};
border-radius:30px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;

  }
`;
// const StyledNote = styled.div`
//   background-color: ${theme?.skygreen};
//   border-radius: 8px;
//   color: ${theme?.green};
//   font-size: 14px;
//   font-weight: 600;
// `;
const ProfileDropDownStyle = styled.div`
.dropdown-menu {
 background-color: ${({ theme }) => theme?.profileDropdownBgColor || '#FFF'};
}
  .menu-toggle {
    padding-block: 0;
  }

  .account-menu {
    display: flex;
    align-items: center;

    h6 {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }
  }

  .account-img {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    overflow: hidden;
    margin-right: 8px;

    &.default {
      background-color: ${({ theme }) => theme.defaultImage};
      border-radius: 50%;
      color: ${({ theme }) => theme.white};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`;
export default StyledProfileDropDown;
