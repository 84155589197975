import React from 'react';

const LightModeIcon = ({color ="white", ...props}) => {
  return (
    <svg
      width="19"
      height="20"
      viewBox="0 0 19 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M9.32832 15.5217C12.3509 15.5217 14.8012 13.0715 14.8012 10.0489C14.8012 7.02633 12.3509 4.57605 9.32832 4.57605C6.30575 4.57605 3.85547 7.02633 3.85547 10.0489C3.85547 13.0715 6.30575 15.5217 9.32832 15.5217Z"
        fill={color}
        stroke={color}
        strokeWidth="1.26296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M15.2302 4.14666L15.3397 4.0372L15.2302 4.14666ZM3.31626 16.0606L3.42571 15.9512L3.31626 16.0606ZM9.32797 1.69651V1.62915V1.69651ZM9.32797 18.4687V18.4013V18.4687ZM0.975561 10.0489H0.908203H0.975561ZM17.7477 10.0489H17.6804H17.7477ZM3.42571 4.14666L3.31626 4.0372L3.42571 4.14666ZM15.3397 16.0606L15.2302 15.9512L15.3397 16.0606Z"
        fill={color}
      />
      <path
        d="M15.3397 16.0606L15.2302 15.9512M15.2302 4.14666L15.3397 4.0372L15.2302 4.14666ZM3.31626 16.0606L3.42571 15.9512L3.31626 16.0606ZM9.32797 1.69651V1.62915V1.69651ZM9.32797 18.4687V18.4013V18.4687ZM0.975561 10.0489H0.908203H0.975561ZM17.7477 10.0489H17.6804H17.7477ZM3.42571 4.14666L3.31626 4.0372L3.42571 4.14666Z"
        stroke={color}
        strokeWidth="1.68395"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default LightModeIcon;
