import React, { useState } from 'react';
import SideBar from './layout/sidebar/Sidebar';
import { Container } from 'react-bootstrap';
import Header from './layout/header/Header';
import { occyLs } from '../utils/localStorage';

const AppOutlet = ({ children, ...props }) => {
  const [openSideMenu, setOpenSideMenu] = useState(true);
  const sidebarVisible = !!occyLs.getObject('APP_SIDEBAR')

  return (
    <>
      <div className="row flex-nowrap bg-body">
        <div
          className={`col-auto px-0 h-auto min-vh-100 ${
            sidebarVisible ? 'openSidebarContainer' : 'closeSidebar'
          }`}
        >
          <SideBar
            setOpenSideMenu={setOpenSideMenu}
            openSideMenu={openSideMenu}
          />
        </div>

        <div className="col app_container px-0">
          <Container fluid className="p-0">
            <Header />
            <div className="py-2 pt-md-0 pb-0 mb-md-0" {...props}>
              {children}
            </div>
          </Container>
        </div>
      </div>
    </>
  );
};

export default AppOutlet;
