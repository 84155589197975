import * as yup from 'yup';
import { ControlTypes } from './helper';

const getValidationType = (type) => {
  switch (type) {
    case ControlTypes.TEXT_INPUT:
    case ControlTypes.TEXTAREA:
    case ControlTypes.DROPDOWN:
    case ControlTypes.EMAIL:
    case ControlTypes.FILE_UPLOAD:
      return 'string';
    // return 'email';
    case ControlTypes.NUMBER_INPUT:
      return 'number';
    case ControlTypes.CHECKBOX:
    case ControlTypes.RADIO:
    case ControlTypes.YES_NO:
      return 'array';
    case ControlTypes.DATE:
      return 'date';
    default:
      return 'string';
  }
};
export function editYupSchema(schema, config) {
  let { id, isRequired, sectionType } = config;
  const type = getValidationType(sectionType);
  if (!yup[type]) {
    return schema;
  }

  let validator = yup[type]();
  if (isRequired) {
    if (!validator['required']) {
      return;
    }
    if (sectionType === 'email') {
      validator = validator
        .email('Email must be valid')
      ['required'](...['This is a required question']);
    }
    validator = validator['required'](...['This is a required question']);
  }
  schema[`q_${id}`] = validator;
  return schema;
}
export function createYupSchema(schema, config) {
  let { id, isRequired, sectionType } = config;
  const type = getValidationType(sectionType);
  if (!yup[type]) {
    return schema;
  }

  let validator = yup[type]();
  if (isRequired) {
    if (!validator['required']) {
      return;
    }
    if (sectionType === 'email') {
      validator = validator
        .email('Email must be valid')
      ['required'](...['This is a required question']);
    }
    validator = validator['required'](...['This is a required question']);
  }
  schema[`q_${id}`] = validator;
  return schema;
}