import React from 'react';

const GoogleIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_168_3300)">
        <path
          d="M15.3959 6.6092L8.86962 6.60889C8.58143 6.60889 8.34784 6.84245 8.34784 7.13064V9.21548C8.34784 9.50361 8.58143 9.73723 8.86959 9.73723H12.5448C12.1423 10.7816 11.3912 11.6563 10.4329 12.212L12 14.9248C14.5138 13.471 16 10.9201 16 8.06451C16 7.65792 15.97 7.36726 15.9101 7.03998C15.8645 6.79132 15.6487 6.6092 15.3959 6.6092Z"
          fill="#167EE6"
        />
        <path
          d="M7.99997 12.8695C6.20141 12.8695 4.63129 11.8868 3.78801 10.4326L1.07532 11.9962C2.45579 14.3887 5.04185 15.9999 7.99997 15.9999C9.45113 15.9999 10.8204 15.6092 12 14.9283V14.9246L10.4329 12.2117C9.71607 12.6275 8.88657 12.8695 7.99997 12.8695Z"
          fill="#12B347"
        />
        <path
          d="M12 14.9284V14.9247L10.4329 12.2119C9.71609 12.6276 8.88666 12.8696 8 12.8696V16.0001C9.45116 16.0001 10.8205 15.6094 12 14.9284Z"
          fill="#0F993E"
        />
        <path
          d="M3.13044 8.00019C3.13044 7.11366 3.37238 6.28425 3.78803 5.56747L1.07534 4.00391C0.390687 5.17975 0 6.54531 0 8.00019C0 9.45506 0.390687 10.8206 1.07534 11.9965L3.78803 10.4329C3.37238 9.71612 3.13044 8.88672 3.13044 8.00019Z"
          fill="#FFD500"
        />
        <path
          d="M7.99997 3.13044C9.17282 3.13044 10.2501 3.54719 11.0916 4.24041C11.2992 4.41141 11.6009 4.39906 11.7911 4.20891L13.2683 2.73172C13.484 2.51597 13.4686 2.16281 13.2382 1.96287C11.8283 0.739781 9.99394 0 7.99997 0C5.04185 0 2.45579 1.61116 1.07532 4.00372L3.788 5.56728C4.63129 4.11312 6.20141 3.13044 7.99997 3.13044Z"
          fill="#FF4B26"
        />
        <path
          d="M11.0916 4.24041C11.2992 4.41141 11.601 4.39906 11.7911 4.20891L13.2683 2.73172C13.484 2.51597 13.4686 2.16281 13.2382 1.96288C11.8283 0.73975 9.99397 0 8 0V3.13044C9.17281 3.13044 10.2502 3.54719 11.0916 4.24041Z"
          fill="#D93F21"
        />
      </g>
      <defs>
        <clipPath id="clip0_168_3300">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default GoogleIcon;