import React from 'react';

const StarIcon = ({ size = 16, ...props}) => {

  return (
    <svg width={size} height={size} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M8.86651 1.96222C8.51192 1.24374 7.4874 1.24375 7.13282 1.96222L5.55026 5.16882L2.01156 5.68303C1.21868 5.79824 0.90209 6.77262 1.47582 7.33187L4.03645 9.82786L3.43197 13.3523C3.29653 14.1419 4.12538 14.7441 4.83455 14.3713L7.99966 12.7073L11.1648 14.3713C11.8739 14.7441 12.7028 14.1419 12.5674 13.3523L11.9629 9.82786L14.5235 7.33187C15.0972 6.77261 14.7806 5.79824 13.9878 5.68303L10.4491 5.16882L8.86651 1.96222ZM6.52779 5.74897L7.99966 2.76662L9.47154 5.74897C9.61235 6.03428 9.88453 6.23203 10.1994 6.27778L13.4906 6.75602L11.1091 9.07746C10.8812 9.29954 10.7773 9.6195 10.831 9.93309L11.3933 13.211L8.44949 11.6634C8.16788 11.5153 7.83145 11.5153 7.54983 11.6634L4.60608 13.211L5.16829 9.93309C5.22207 9.6195 5.11811 9.29954 4.89028 9.07746L2.50873 6.75602L5.79995 6.27778C6.1148 6.23203 6.38698 6.03428 6.52779 5.74897Z" fill="#75390D" stroke="#75390D" strokeWidth="0.3"/>
    </svg>
    

  );
};

export default StarIcon;
