import React from 'react';
import { useTheme } from 'styled-components';

const FileFormIcon = ({ size = '14', color = '#4A154B', ...props }) => {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        rx="4"
        fill={theme?.dark ? theme?.statusTagCompleted : '#D8E6C6'}
        filopacity="0.65"
      />
      <path
        d="M10 15.3333V11.3333L8.66669 12.6667"
        stroke={theme?.dark ? theme?.darkTagGreenTextColor : '#292D32'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10 11.3333L11.3333 12.6667"
        stroke={theme?.dark ? theme?.darkTagGreenTextColor : '#292D32'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6666 10.6667V14C18.6666 17.3333 17.3333 18.6667 14 18.6667H9.99998C6.66665 18.6667 5.33331 17.3333 5.33331 14V9.99999C5.33331 6.66666 6.66665 5.33333 9.99998 5.33333H13.3333"
        stroke={theme?.dark ? theme?.darkTagGreenTextColor : '#292D32'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.6666 10.6667H16C14 10.6667 13.3333 10 13.3333 7.99999V5.33333L18.6666 10.6667Z"
        stroke={theme?.dark ? theme?.darkTagGreenTextColor : '#292D32'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FileFormIcon;
