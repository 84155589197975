import FillCandidatesIcon from '../../app/components/svgIcons/FillCandidatesIcon';
import StandardUserIcon from '../../app/components/svgIcons/StandardUserIcon';
import { STATUSES } from '../../utils/Statuses';
import * as Yup from 'yup';

export const productLicensesTabs = [
  {
    id: 1,
    title: 'Licence Details',
    active: true,
    status: 1,
  },
  {
    id: 2,
    title: `Wallet Details`,
    active: false,
    status: 2,
  },
  {
    id: 3,
    title: `Purchase History`,
    active: false,
    status: 3,
  },
];
export const subscriptionHubTabs = [
  {
    id: 1,
    title: 'Plan Management',
    active: true,
    status: 1,
  },
  {
    id: 2,
    title: 'Transactions',
    active: false,
    status: 2,
  },
];
export const TwoFactor = {
APP:'APP',
SMS:'SMS'
}
export const delegatedTasks = [
  {
    id: 1,
    title: 'My Tasks',
    active: true,
    status: STATUSES.LIVE,
  },
  {
    id: 2,
    title: <div className='d-flex justify-content-between align-items-center'><div className='red-dot me-2'></div>Assigned to me</div>,
    active: false,
    status: STATUSES.INACTIVE,
  }
];
export const childCompanies = [
  {
    id: 1,
    title: 'Active',
    active: true,
    status: STATUSES.LIVE,
  },
  {
    id: 2,
    title: `Inactive`,
    active: false,
    status: STATUSES.INACTIVE,
  },
  {
    id: 3,
    title: `Pending`,
    active: false,
    status: STATUSES.PENDING,
  },
  {
    id: 4,
    title: `Suspended`,
    active: false,
    status: STATUSES.SUSPENDED,
  },
];

export const LicenseTiles = [
  {
    id: 1,
    title: 'Basic Accounts',
    count: 0,
    fillIcon: <FillCandidatesIcon size={44} />,
    key: 'basicAccounts',
  },
  {
    id: 2,
    title: 'Standard Accounts',
    count: 0,
    fillIcon: <StandardUserIcon />,
    key: 'standardAccounts',
  },
  // {
  //   id: 3,
  //   title: 'Licence Fee',
  //   count: 0,
  //   fillIcon: <LicenseFeeIcon />,
  //   key: 'licenseFee',
  // },
];


export const customeCompnayAddressValidation={
  saveAddress: Yup.boolean(),
  town: Yup.string().required('Required'),
  postcode: Yup.string().required('Required').test('valid-postcode', 'Invalid postcode', function(value) {
      return !!value && value?.length >= 4;
  }),
  country: Yup.string().required('Required'),
  title: Yup.string().required('Required'),
}


export const PRODUCT_TYPES_CUSTOM={
  INTERNAL:"INTERNAL",
  PRIVATE:"PRIVATE",
  ADVERTISING:"ADVERTISING",
  
  }

export const PRODUCT_TYPES={
AGENCY:"AGENCY",
CLIENT_APPLY_SOURCE:"CLIENT_APPLY_SOURCE"

}


const formatValidationError=(error)=> {
  const errors = [];
  if (error.constraints) {
    for (const constraintKey in error.constraints) {
      if (error.constraints[constraintKey]) {
        errors.push(`${error.property}: ${error.constraints[constraintKey]}`);
      }
    }
  }

  if (error.children && error.children.length > 0) {
    error.children.forEach(child => {
      errors.push(...formatValidationError(child));
    });
  }

  return errors;
}

export const processValidationErrors = (errorResponse) => {
  let errorMessages = [];
if((errorResponse?.data?.message || errorResponse?.data?.msg) && !errorResponse.data?.response){
  return errorResponse?.message || errorResponse?.msg;
}else{
  if (errorResponse && errorResponse.data.response && errorResponse.data.response.errors) {
    errorResponse.data.response.errors.forEach((error) => {
      errorMessages = formatValidationError(error);
      // const propertyName = error.property;
      // const constraints = Object.values(error.constraints).join(', '); // Join all constraints

      // errorMessages.push(`${propertyName}: ${constraints || 'Validation failed'}`);
    });
  } else {
    errorMessages.push('Invalid error response format');
  }

  return errorMessages&& errorMessages?.length > 0 && errorMessages?.join(', '); // Join array elements into a single string
}
};