import React, { useEffect, useState } from 'react';
import styled, { useTheme } from 'styled-components';
import Portlet from '../../app/components/portlet/Portlet';
import PortletBody from '../../app/components/portlet/PortletBody';
import StyledButton from '../../app/components/styledComponents/StyledButton';
import { Row, Col, Container, DropdownItem } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { StyledLabel, Wrap } from '../../assets/styledComponents/Global';
import { Form, Formik } from 'formik';
import moment from 'moment';
import { toast } from 'react-toastify';
import * as Yup from 'yup';
import FillLocation from '../../app/components/svgIcons/FillLocation';
import RedirectIcon from '../../app/components/svgIcons/RedirectIcon';
import UploadIconStyled from '../../app/components/svgIcons/UploadIconStyled';
import CrossIcon from '../../app/components/svgIcons/CrossIcon';
import { formateLocalNumber, getFileNameByFileDescriptor, getStatusFriendly } from '../../utils/helper';
import OccyLogoBlue1 from '../../app/components/svgIcons/OccyLogoBlue.png';

import DotIcon from '../../app/components/svgIcons/DotIcon';
import {
  usePreSignedPublicUrlMutation,
  useJobApplyAdvertMutation,
  useGetSinglePublicJobByIdQuery,
  useGetSinglePublicJobByJpIdQuery,
} from './PublicPageApi';
import FormikUploadField from '../../app/components/formikComponents/FormikUploadField';
import BriefCaseColorIcon from '../../app/components/svgIcons/BriefCaseColorIcon';
import ClockColoredFillIcon from '../../app/components/svgIcons/ClockColoredFillIcon';
import MultipleBoxIcon from '../../app/components/svgIcons/MultipleBoxIcon';
import FormikDropZone from '../../app/components/formikComponents/FormikDropZone';
import { publicPageSchema } from './CareerPageUtils/careerPageUtils';
import FormikInputField from '../../app/components/formikComponents/FormikInputField';
import { getCurrencySign, useWindowSize } from '../../utils/helper';
import { Helmet } from 'react-helmet';
import GoogleMap from '../../app/components/styledComponents/GoogleMap';
import OccyLogoDefault from '../../app/components/svgIcons/OccyLogoDefault.png';
import {
  employmentTypes,
  jobTypes,
  publicJobboards,
} from '../job/creation/assets/jobCreationUtill';
import StyledAccordion from '../../app/components/styledComponents/StyledAccordion';
import ApplicationSubmittedModal from './ApplicationSubmittedModal';
import CustomDropdown from '../../app/components/dropdown/CustomDropDown';
import DropDownIcon from '../../app/components/svgIcons/DropDownIcon';
import StyledToolTip from '../../app/components/styledComponents/StyledToolTip';
import InfoIcon from '../../app/components/svgIcons/InfoIcon';
import FormikCheckBox from '../../app/components/formikComponents/FormikCheckBox';


const SearchPublicPage = () => {
  const isSmallScreen = useWindowSize();
  const [showApplication, setShowApplication] = useState(false);
  const [applicationSubmitted, setApplicationSubmitted] = useState(false);
  const [isAgency, setIsAgency] = useState(false);
  const theme = useTheme();
  const ScrollToReply = React.useRef(null);
  const [getPreSignedPublicUrl] =
    usePreSignedPublicUrlMutation();
  const [jobAdvertApply, { isLoading }] = useJobApplyAdvertMutation();
  const { publicId } = useParams();
  let pattern = /.*[jp-].*/;
  let getafterdashpattern = /.*-(.*)/;
  const { data: singlePublicJob, isFetching:getJobDataLoading } = (
    pattern.test(publicId)
      ? useGetSinglePublicJobByJpIdQuery
      : useGetSinglePublicJobByIdQuery
  )(
    pattern.test(publicId) ? publicId.match(getafterdashpattern)[1] : publicId,
    {
      skip: !publicId,
    },
  );

  const [company, setCompany] = useState(null);

  useEffect(() => {
    setCompany(singlePublicJob?.company || singlePublicJob?.jobPosting?.job?.company || singlePublicJob?.campaign?.job?.company);
  }, [singlePublicJob]);
  const [totalJobs,setTotalJobs]=useState(true);
  useEffect(()=>{
    const searchParams = new URLSearchParams(window.location.search);
       // Get the value of the 'param' parameter
    const paramValue = searchParams.get('id');

    // Update state with the parameter value
    if (paramValue === publicJobboards.TotalJobs) {
      toast.success('Successfully applied to total jobs');
      setTotalJobs(false);
    }
  },[])
  const timePosted = (time) => {
    return moment(time).fromNow();
  };
  const handleUpload = (evt, setFieldValue) => {
    try {
      const acceptedFiles = [...evt.target.files];
      if (!acceptedFiles) return;
      acceptedFiles.map(async (file) => {
        const { data: responseData } = await getPreSignedPublicUrl({
          fileName: file.name,
          fileType: file.type,
        });
        if (!responseData?.url) {
          toast.error(
            'Could not process the upload. Please make sure the file is valid and the internet connection is stable',
          );
        }
        await fetch(`${responseData?.url}`, {
          method: responseData?.method,
          headers: {
            Accept: 'application/json',
            'Content-Type': file.type,
          },
          body: file,
        });
        return setFieldValue('coverLetter', {
          name: file.name,
          data: responseData,
        });
      });
    } catch (e) {
      console.error(e);
      toast.error('Something went wrong while trying to upload your files..');
    }
  };
  const handleSubmit = (values) => {
    const payload = { ...values };
    payload['jobId'] = singlePublicJob?.jobPosting?.jobId || singlePublicJob?.campaign?.job?.id || singlePublicJob?.id;
    payload['postingId'] = singlePublicJob?.jobPosting?.id || (singlePublicJob?.campaign?.jobPostings?.length && singlePublicJob?.campaign?.jobPostings[0]?.id);
    if (!payload?.isAgency) ['agencySource', 'agencyEmail', 'isAgency'].forEach(key => delete payload[key]);
    payload.cv = payload?.cv?.key;
    payload.coverLetter = payload?.coverLetter?.data?.key;
    payload['source'] = publicId;
    jobAdvertApply({
      ...payload,
    })
      .then((data) => {
        if (data?.data?.message) {
          // toast.success('Candidate added successfully');
          setApplicationSubmitted(true);
        }
      })
      .catch((e) => {
        console.error({ e });
      });
  };
  const onDropUploadResume = (acceptedFiles, setFieldValue, name) => {
    acceptedFiles.map(async (file) => {
      const { data: responseData } = await getPreSignedPublicUrl({
        fileName: file.name,
        fileType: file.type,
      });
      if (!responseData?.url) {
        toast.error(
          'Could not process the upload. Please make sure the file is valid and the internet connection is stable',
        );
      }
      await fetch(`${responseData?.url}`, {
        method: responseData?.method,
        headers: {
          Accept: 'application/json',
          'Content-Type': file.type,
        },
        body: file,
      });
      return setFieldValue('cv', responseData);
    });
  };
  const filterHtmlRigix = (data) => {
    return <div dangerouslySetInnerHTML={{ __html: data }} />;
  };
  const handleApply = () => {
    setShowApplication(true);
  };
  const addressfilter = (address, town, country) => {
    let completeAddress = '';
    if (address) {
      completeAddress = address;
    }
    if (town) {
      completeAddress += ', ' + town;
    }
    if (country) {
      completeAddress += ', ' + country;
    }
    return completeAddress;
  };
  const googleJobService = (job) => {
    const salaryPerEnum = {
      hour: 'HOUR',
      day: 'DAY',
      week: 'WEEK',
      month: 'MONTH',
      annum: 'YEAR',
      pro_rata: "PRO_RATA"
    }
    return {
      "@context": "https://schema.org/",
      "@type": "JobPosting",
      title: job?.title,
      description: `<h3>Job Description</h3> 
      <p>${job?.jobFunction}</p>
      <p>${job?.jobDescription}</p>
      <p>${job?.jobRequirement}</p>
      <p>${job?.elevatorPitch}</p>
      `,
      datePosted: moment(job?.createdAt).format('YYYY-MM-DD'),
      validThrough: moment(singlePublicJob?.expiresAt).toISOString(),
      employmentType: job?.jobType ? job?.jobType : job?.employmentType,
      "hiringOrganization": {
        "@type": "Organization",
        "name": company?.name,
      },

      jobLocationType: job?.remoteStatus,
      baseSalary: {
        "@type": "MonetaryAmount",
        currency: job?.salaryCurrency ? job?.salaryCurrency : 'GBP',
        value: {
          "@type": "QuantitativeValue",
          minValue: job?.salaryFrom ? job?.salaryFrom?.toFixed(0) : '10',
          maxValue: job?.salaryTo ? job?.salaryTo?.toFixed(0) : '1000000',
          unitText: job?.salaryPer ? salaryPerEnum[job?.salaryPer?.toLowerCase()] : 'YEAR'
        }
      },
      jobLocation: {
        "@type": "Place",
        address: {
          "@type": "PostalAddress",
          "addressLocality": job?.address,
          "addressRegion": job?.city,
          "postalCode": job?.postcode,
          "addressCountry": job?.countryShortname
        }
      },
    };
  }
  return (
    <>
      <Helmet>
        <title>{singlePublicJob?.title + ' - occy APP'}</title>
        {((new Date() < new Date(singlePublicJob?.campaign?.jobHiringPlan?.startDate)) && (getStatusFriendly(singlePublicJob?.campaign?.statusId) !== 'Closed')) &&
      <script type="application/ld+json">
          {JSON.stringify(googleJobService(singlePublicJob))}
        </script >}
      </Helmet>
      {/* <div className="row flex-nowrap bg-body"> */}
      <div className={`col px-0`} >
        <Formik
          initialValues={{
            firstName: '',
            lastName: '',
            email: '',
            coverLetter: '',
            cv: '',
            agencySource:'',
            agencyEmail:'',
            isAgency,
            isRead:false,
            isDetail:false

          }}
          validationSchema={Yup.object().shape({
            ...publicPageSchema,
          })}
          onSubmit={handleSubmit}
          enableReinitialize
        >
          {({ handleSubmit, values, setFieldValue, resetForm }) => (
            <Form onSubmit={handleSubmit}>
              <Portlet className="p-0">
                <Wrap
                  className={`d-flex align-items-center justify-content-center justify-content-md-between p-3 px-lg-5 py-lg-4 mx-lg-4`}
                >
                  <Link
                    to={`/p/jobs/${company?.id}`}
                    className=" text-decoration-none"
                  >
                    <Wrap className="d-flex align-items-center gap-2 p-sm-0">
                      <img
                        alt="company_logo"
                        src={
                          company?.compLOGOSignedUrl
                            ? company?.compLOGOSignedUrl
                            : OccyLogoDefault
                        }
                        width="100"
                        height="20%"
                      />
                      <StyledLabel size="22px">
                        {company?.name}
                      </StyledLabel>
                    </Wrap>
                  </Link>

                  {company?.id?<Link
                    className={
                      'text-decoration-none  cursor-pointer d-none d-md-block'
                    }
                    to={`/p/jobs/${company?.id}`}
                  >
                    <span className="h5">View Jobs</span>
                  </Link>:""}
                </Wrap>

                <StyledContainer className={`bg-body p-lg-4`} fluid>
                  <Wrap className="justify-content-center  gap-md-3 row row-lg pt-4 mx-2">
                    <div className={`col-md col-lg-7 p-0`}>
                      <PortletBody
                        className={
                          showApplication
                            ? 'd-none'
                            : 'rounded-2 mx-md-0 mx-lg-0 mb-3'
                        }
                      >
                        <Row className=" justify-content-between align-items-center">
                          <Col sm>
                            <Wrap>
                              <StyledWrapText className="fw-bold mb-0 text-headingColor">
                                {singlePublicJob?.title}
                              </StyledWrapText>
                              <hr className=" d-sm-block d-md-none" />
                              <Row className="mt-1 align-items-center">
                                <Wrap className="col-1 pe-0 w-auto">
                                  <FillLocation
                                    size={18}
                                    color={theme.primary}
                                  />
                                </Wrap>
                                <Wrap className="col">
                                  <StyledWrapText className=" mb-0 text-lightBlack">
                                    {singlePublicJob?.town}
                                    {singlePublicJob?.town && singlePublicJob?.postcode ? ', ' : ''}
                                    {singlePublicJob?.postcode}{' '}
                                  </StyledWrapText>
                                </Wrap>
                              </Row>
                            </Wrap>
                          </Col>
                          {/* {<Col sm> */}
                          {totalJobs && 
                          <StyledWrap className={`d-flex rounded px-3 py-4 bg-white col ${((new Date() < new Date(singlePublicJob?.campaign?.jobHiringPlan?.startDate)) && (getStatusFriendly(singlePublicJob?.campaign?.statusId) !== 'Closed')) ? '' : 'pe-none'}`}>
                            {!getJobDataLoading&&<StyledButtonContainer
                           
                              onClick={handleApply}
                              className={`ms-auto ${(( new Date() < new Date(singlePublicJob?.campaign?.jobHiringPlan?.startDate)) && (getStatusFriendly(singlePublicJob?.campaign?.statusId) !== 'Closed')) ? ' pe-5 ' : 'bg-body text-primary'}`}
                            >
                              {(new Date() < new Date(singlePublicJob?.campaign?.jobHiringPlan?.startDate)) && (getStatusFriendly(singlePublicJob?.campaign?.statusId) !== 'Closed') ? 'Apply Now' : 'This role is closed now.'}
                            </StyledButtonContainer>}
                            {singlePublicJob? <CustomDropdown
                    hidden={(new Date() > new Date(singlePublicJob?.campaign?.jobHiringPlan?.startDate)) || (getStatusFriendly(singlePublicJob?.campaign?.statusId) === 'Closed')}
                    dropdownToggle={
                      <StyledIconWrap
                        className={` py-3 border-start border-radius-left-0`}
                      >
                        <DropDownIcon />
                      </StyledIconWrap>
                    }
                    toggleClassName="bg-body p-0"
                    menuClassName={'rounded'}
                    dropdownMenu={
                      <>
                        <StyledDropDownContainer className="rounded p-0">
                          <DropdownItem
                          className='py-1'
                            onClick={() => {
                              setIsAgency(true)
                              handleApply()
                            }}
                          >
                          
                             Apply as Agency
                          </DropdownItem>
                        </StyledDropDownContainer>
                      </>
                    }
                  />:null}


                            {/* <div className="flex-center _2-step">
                <Wrap className="d-flex rounded">
                  <StyledButton
                    onClick={handleNewEmailTemplate}
                    className={`border-end rounded pe-5`}
                    hidden={AllowedFunc('createEmailTemplate', user)}
                  >
                    Create new template
                  </StyledButton>
                  
                </Wrap>
              </div> */}
                          </StyledWrap>}
                          {/* </Col>} */}
                        </Row>
                        <hr className=" d-none d-md-block" />
                        <Wrap className="d-md-flex justify-content-between align-items-center">
                          <Row className="align-items-center mt-3 mt-md-0">
                            <Wrap className="col-1  pe-0 w-auto">
                              <BriefCaseColorIcon
                                color={theme.primary}
                                size={20}
                              />
                            </Wrap>
                            <Wrap className="col">
                              <StyledWrapText className="mb-0 text-lightBlack">
                                {employmentTypes?.find(
                                  (p) =>
                                    p?.value ===
                                    singlePublicJob?.employmentType,
                                )?.label || ''}{' '}
                                {jobTypes?.find(
                                  (p) => p?.value === singlePublicJob?.jobType,
                                )?.label || ''}
                              </StyledWrapText>
                            </Wrap>
                          </Row>
                          {singlePublicJob?.salaryFrom != null ||
                            singlePublicJob?.salaryTo != null ||
                            singlePublicJob?.salaryPer != null ||
                            singlePublicJob?.isCompetitive ? (
                            <Row className="align-items-center mt-3 mt-md-0">
                              {/* {singlePublicJob?.salaryFrom != null ||
                                singlePublicJob?.salaryTo != null ||
                                singlePublicJob?.isCompetitive ? ( */}
                                <Wrap className="col-1  pe-0 w-auto">
                                  <MultipleBoxIcon size={20} />
                                </Wrap>
                              {/* ) : (
                                ''
                              )} */}
                              <Wrap className="col">
                                <StyledWrapText className="mb-0 text-lightBlack">
                                {singlePublicJob?.salaryFrom &&
                                  singlePublicJob?.isCompetitive === false
                                    ? getCurrencySign(
                                        singlePublicJob?.salaryCurrency,
                                      )
                                    : ' '}
                                  {singlePublicJob?.isCompetitive === false &&
                                  singlePublicJob?.salaryFrom > 0 &&
                                  singlePublicJob?.salaryTo > 0
                                    ? singlePublicJob?.salaryFrom === singlePublicJob?.salaryTo ?
                                    formateLocalNumber(singlePublicJob?.salaryFrom) + (singlePublicJob?.salaryPer ? ' per '+ singlePublicJob?.salaryPer : '') :
                                    formateLocalNumber(singlePublicJob?.salaryFrom) +
                                      ' - ' +
                                      getCurrencySign(
                                        singlePublicJob?.salaryCurrency,
                                      ) +
                                      formateLocalNumber(singlePublicJob?.salaryTo) +
                                      ' per ' +
                                      (singlePublicJob?.salaryPer == null
                                        ? ''
                                        : singlePublicJob?.salaryPer)
                                    : singlePublicJob?.salaryTo > 0 &&
                                      singlePublicJob?.isCompetitive ===
                                        false &&
                                        formateLocalNumber(singlePublicJob?.salaryFrom) +
                                        ' ' +
                                        (singlePublicJob?.salaryPer == null
                                          ? ''
                                          : singlePublicJob?.salaryPer)}

                                  {singlePublicJob?.salaryFrom &&
                                    !singlePublicJob?.salaryTo &&
                                    !singlePublicJob?.isCompetitive &&
                                    formateLocalNumber(singlePublicJob?.salaryFrom)}
                                  {!Number(singlePublicJob?.salaryFrom) > 0 &&
                                    'Competitive/negotiable'}
                                </StyledWrapText>
                              </Wrap>
                            </Row>
                          ) : null}
                          {singlePublicJob?.createdAt ? (
                            <Row className="align-items-center mt-3 mt-md-0">
                              <Wrap className="col-1  pe-0 w-auto">
                                <ClockColoredFillIcon size={18} />
                              </Wrap>
                              <Wrap className="col">
                                <StyledWrapText className="mb-0 text-lightBlack">
                                  {timePosted(singlePublicJob?.createdAt)}
                                </StyledWrapText>
                              </Wrap>
                            </Row>
                          ) : null}
                        </Wrap>
                      </PortletBody>

                      {singlePublicJob?.elevatorPitch && (
                        <PortletBody
                          className={`${showApplication && 'd-none'
                            } rounded-2 ${singlePublicJob?.elevatorPitch && 'my-3'
                            }`}
                        >
                          <StyledAccordion title="About the Role">
                            <StyledWrapText>
                              {filterHtmlRigix(singlePublicJob?.elevatorPitch)}
                            </StyledWrapText>
                          </StyledAccordion>
                        </PortletBody>
                      )}

                      {singlePublicJob?.jobDescription && (
                        <PortletBody
                          className={`${showApplication && 'd-none'
                            } rounded-2 ${singlePublicJob?.jobDescription && 'my-2'
                            }`}
                        >
                          <StyledAccordion title="Job Opportunity">
                            <StyledWrapText>
                              {filterHtmlRigix(singlePublicJob?.jobDescription)}
                            </StyledWrapText>
                          </StyledAccordion>
                        </PortletBody>
                      )}

                      {singlePublicJob?.benefits && (
                        <PortletBody
                          className={
                            showApplication ? 'd-none' : 'rounded-3 mb-3'
                          }
                        >
                          <StyledAccordion title="Company Benefits">
                            <StyledWrapText>
                              {filterHtmlRigix(singlePublicJob?.benefits)}
                            </StyledWrapText>
                          </StyledAccordion>
                        </PortletBody>
                      )}

                      {singlePublicJob?.jobRequirement && (
                        <PortletBody
                          className={` ${showApplication && 'd-none'
                            } rounded-2 ${singlePublicJob?.jobRequirement && 'my-3'
                            }`}
                        >
                          <StyledAccordion title="Essential Skills">
                            <StyledWrapText>
                              {filterHtmlRigix(singlePublicJob?.jobRequirement)}{' '}
                            </StyledWrapText>
                          </StyledAccordion>
                        </PortletBody>
                      )}


                    {showApplication ?  <PortletBody
                        className={'rounded-3'}
                      >
                        <h4 ref={ScrollToReply} className="text-headingColor">
                          Apply for this job
                        </h4>
                        <hr />
                        <div className="col-12">
                          <div className="mb-3">
                            <PortletBody className="p-0 w-100 rounded">
                              <div className="p-1 p-lg-3 ">
                                <div className="col-12">
                                  <div className="mb-3">
                                    <StyledLabel
                                      htmlFor="resume"
                                      className="form-label text-headingColor"
                                      size="16px"
                                    >
                                      Resume
                                    </StyledLabel>
                                    <label
                                      htmlFor="cv"
                                      className="text-headingColor"
                                    >
                                      Upload resume to autopopulate your
                                      information
                                    </label>
                                    <FormikDropZone
                                      name="cv"
                                      id="cv"
                                      label={
                                        isSmallScreen
                                          ? 'Click to Upload Resume'
                                          : null
                                      }
                                      noContent={isSmallScreen && true}
                                      onDrop={(val) => {
                                        onDropUploadResume(
                                          val,
                                          setFieldValue,
                                          'resume',
                                        );
                                      }}
                                      value={values?.cv?.key}
                                    />
                                  </div>
                                  {values?.cv?.key && (
                                    <div
                                      className="d-flex mt-1"
                                      onClick={() => setFieldValue('cv', '')}
                                    >
                                      {values?.cv?.key && getFileNameByFileDescriptor(values?.cv?.key)}
                                      <span className="cursor-pointer ms-2">
                                        <CrossIcon size={16} />
                                      </span>


                                    </div>
                                  )}
                                </div>
                              </div>
                            </PortletBody>
                            <div className={`d-md-flex px-3 gap-3`}>
                                <div className="col col-lg-6 ">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="firstName"
                                      className="form-label text-headingColor"
                                    >
                                      First Name
                                    </label>
                                    <FormikInputField
                                      name="firstName"
                                      id="firstName"
                                      placeholder={
                                        isAgency
                                          ? 'Enter Candidate First Name'
                                          : 'Enter First Name'
                                      }
                                    />
                                  </div>
                                </div>
                                <div className="col col-lg-6">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="lastName"
                                      className="form-label text-headingColor"
                                    >
                                      Last Name
                                    </label>
                                    <FormikInputField
                                      name="lastName"
                                      id="lastName"
                                      placeholder={
                                        isAgency
                                          ? 'Enter Candidate Last Name'
                                          : 'Enter Last Name'
                                      }
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="row px-3">
                                <div className="col-12">
                                  <div className="mb-3">
                                    <label
                                      htmlFor="email"
                                      className="form-label text-headingColor"
                                    >
                                      {isAgency ? 'Candidate Email' : 'Email'}
                                    </label>
                                    <FormikInputField
                                      name="email"
                                      id="email"
                                      placeholder={
                                        isAgency
                                          ? 'Enter Candidate Email'
                                          : 'Enter Email'
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                            {isAgency ? (
                                <div className="row px-3">
                                  <div className="col-lg-6 col-xs-12">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="agencySource"
                                        className="form-label text-headingColor"
                                      >
                                        Agency Code
                                        <StyledToolTip
                                          title={
                                            'The unique agency code provided by the hiring company.'
                                          }
                                          className="mt-2"
                                          placement="top"
                                        >
                                          <InfoIcon />
                                        </StyledToolTip>
                                      </label>

                                      <FormikInputField
                                        name="agencySource"
                                        id="agencySource"
                                        placeholder="Enter Agency Code"
                                      />
                                    </div>
                                  </div>
                                  <div className="col-lg-6 col-xs-12">
                                    <div className="mb-3">
                                      <label
                                        htmlFor="agencyEmail"
                                        className="form-label text-headingColor"
                                      >
                                        Agency Contact Email
                                      </label>
                                      <FormikInputField
                                        name="agencyEmail"
                                        id="agencyEmail"
                                        placeholder="Enter Agency Contact Email"
                                      />
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            <div className="mb-3 col-lg-6 col-md-6 col-sm mx-3">
                              <label
                                htmlFor="coverLetter"
                                className="form-label text-headingColor"
                              >
                                Upload Cover letter{' '}
                                <span className="ms-1 text-muted">
                                  (optional)
                                </span>
                              </label>
                              <FormikUploadField
                                icon={<UploadIconStyled size={20} />}
                                name="coverLetter"
                                id="coverLetter"
                                placeholder="Upload Here"
                                onChange={(e) => {
                                  handleUpload(e, setFieldValue);
                                }}
                                value={values?.coverLetter?.name}
                              />
                            </div>
                            <div
                                  className="d-flex align-items-start px-2"
                              
                                >
                                 <label
                                        htmlFor="isRead"
                                        className="form-label text-headingColor"
                                      >  <FormikCheckBox
                          size={'16'}
                          name="isRead"
                          id="isRead"
                          align={'align-items-start'}
                          label={<StyledLabel className="ms-2" size={'14px'} weight={500} color={theme.lightBoldBlack}>
                            I confirm that I have read and agree to the processing of my personal data as outlined in the
                              <StyledLabel size={'14px'} weight={500} color={theme.newPink} className='ps-1 cursor-pointer' onClick={()=>window.open('/policies', '_blank')}>{' Privacy Policy'}</StyledLabel> 
                           . I understand that my data will be used solely for recruitment purposes and that I have the right to access, correct, or request the deletion of my data at any time.</StyledLabel>}
                          className="my-2"
                                />
                                </label>
                                </div>
                               
                                <div
                                  className="d-flex align-items-start px-2"
                            
                                >
                                 <label
                                        htmlFor="isDetail"
                                        className="form-label text-headingColor"
                                      >  <FormikCheckBox
                          size={'16'}
                          name="isDetail"
                          id="isDetail"
                          align={'align-items-center'}
                          label={ <StyledLabel className="ms-2 mb-0 mt-1" size={'14px'} weight={500} color={theme.lightBoldBlack}>{'I confirm that the details I have provided are accurate and complete. I acknowledge that providing false information could result in the rejection of my application.'}</StyledLabel>}
                          className="my-2"
                                />
                                </label>
                                </div>
         
                            <StyledWrap className="px-3 py-4 bg-white col">
                              <StyledButtonContainer
                                type="submit"
                                disabled={isLoading}
                                loading={isLoading}
                                className="bg-primary"
                              >
                                Submit Application
                              </StyledButtonContainer>
                            </StyledWrap>
                          </div>
                        </div>
                      </PortletBody>:null}
                    </div>

                    <div className="col-md col-md-4 col-md-4 vertically-fit-content p-0">
                      <div
                        className={
                          showApplication
                            ? 'd-none'
                            : ` bg-white p-3 rounded-3  mt-md-0 py-3`
                        }
                      >
                        <StyledAccordion title="About us">
                          <div className="d-flex justify-content-center flex-column text-center pt-3">
                            <div className="mb-3 text-start">
                              <Link
                                to={`/p/jobs/${company?.id}`}
                              >
                                <img
                                  alt="company_logo"
                                  src={
                                    company?.compLOGOSignedUrl
                                      ? company?.compLOGOSignedUrl
                                      : OccyLogoDefault
                                  }
                                  width="20%"
                                  height="20%"
                                />
                              </Link>
                              {singlePublicJob?.aboutCompany && (
                                <p className="text-justify">
                                  <WrapText className="mt-2 text-secondary fs-5">
                                    {filterHtmlRigix(
                                      singlePublicJob?.aboutCompany || '',
                                    )}
                                  </WrapText>
                                </p>
                              )}
                            </div>
                            {company?.careerVideoId && (
                              <div className="mb-3">
                                <StyledText className="text-start mb-2">
                                  Video
                                </StyledText>
                                <video width="100%" height="200" controls>
                                  <source
                                    src={`${company?.careerVideoId}`}
                                    type="video/mp4"
                                  />
                                </video>
                              </div>
                            )}
                          </div>
                          <div className="d-flex justify-content-center flex-column mt-4">
                            <StyledText className="text-start mb-2">
                              Location
                            </StyledText>
                            <StyledLocaion>
                              <GoogleMap
                                height={300}
                                address={addressfilter(
                                  singlePublicJob?.address,
                                  singlePublicJob?.town,
                                  singlePublicJob?.county,
                                )}
                                postalCode={singlePublicJob?.postcode}
                              />
                            </StyledLocaion>
                          </div>
                        </StyledAccordion>
                      </div>
                      {singlePublicJob?.dei && (
                        <div
                          className={
                            showApplication
                              ? 'd-none'
                              : ` bg-white rounded-3 mt-3 `
                          }
                        >
                          <PortletBody
                            className={showApplication ? 'd-none' : 'rounded-3'}
                          >
                            <StyledAccordion title="Creating an Inclusive Environment">
                              <StyledWrapText className="pt-2">
                                {singlePublicJob?.dei
                                  ? filterHtmlRigix(singlePublicJob?.dei)
                                  : ''}
                              </StyledWrapText>
                            </StyledAccordion>
                          </PortletBody>
                        </div>
                      )}
                    </div>
                  </Wrap>
                </StyledContainer>
              </Portlet>
              <ApplicationSubmittedModal
                show={applicationSubmitted}
                onHide={() => {
                  setApplicationSubmitted(false);
                  setShowApplication(false);
                  resetForm();
                  window.location.href = `/p/jobs/${company?.id}`;
                }}
              />
            </Form>
          )}
        </Formik>
        <Wrap fluid>
          <StyledContainerWrap

          // backgroundSettings={backgroundSettings}
          // cardAlignment={cardAlignment}
          // contentAlignment={contentAlignment}
          >
            <StyledLinkWrap className="d-flex align-items-center gap-5 w-10">
              <div className='d-flex justify-content-center align-items-center w-100 flex-column flex-md-row'>
                <div ><label className='fs-5'>Powered By</label></div>
                <div className='ps-2'>
                  {/* <OccyLogoBlue /> */}
                  <img
                    src={OccyLogoBlue1}
                    alt="img"
                    height={45}
                    width={170}
                  />
                </div>
                <div className='ps-3 d-none d-md-block'>
                  <DotIcon />
                </div>
                <div className='d-flex justify-content-center align-items-center'><label className='ps-3 fs-5'>Privacy & Policy</label>
                  <a

                    color="descriptionTextColor"
                    href={`https://occy.com/all-policies`}
                    target={'_blank'}
                    rel="noreferrer"
                  >
                    <div className='ps-2'>
                      <RedirectIcon />
                    </div>
                  </a>
                </div>
              </div>
            </StyledLinkWrap>
          </StyledContainerWrap>
        </Wrap>
      </div>

      {/* </div> */}
    </>
  );
};


const StyledContainerWrap = styled.div`
margin-top: auto;
    display: flex;
  background: ${({ theme }) =>
    theme?.bgColor
      ? theme?.bgColor ?? theme.white
      : theme?.backgroundSettings?.imageURL
        ? `url(${theme?.backgroundSettings?.imageURL})`
        : theme.white};
  display: flex;
  flex-direction: ${({ theme }) =>
    theme?.cardAlignment ? theme?.cardAlignment : 'column'};
  align-items: ${({ theme }) =>
    theme?.contentAlignment ? theme?.contentAlignment : 'center'};
  text-align: center;
  gap: 8px;
  width: ${({ theme }) => (theme?.width ? theme?.width : '100%')};
padding:10px;
  cursor: pointer;
  @media (max-width: 768px) {
    
    margin-bottom: 90px;
    padding: 20px;
  }
`;
const StyledLinkWrap = styled.div`
  color: ${({theme}) => theme.headingColor};
  font-weight: 500;
  font-size: 18px;

  & > a {
    // color: ${({ theme }) =>
    theme.color ? theme.color : theme.headingColor};
    text-decoration: none;
  }
`;
const StyledText = styled.div`
  color: ${({ theme }) => theme.lightBoldBlack};
  font-size: 20px;
  font-weight: 600;
`;
const StyledLocaion = styled.div`
  width: '100%';
  height: '30px';
`;
const WrapText = styled.p`
  overflow-wrap: break-word;
`;

const StyledWrap = styled(Wrap)`
  ${(props) => props.theme.md} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  z-index: 999;
`;

const StyledButtonContainer = styled(StyledButton)`
  ${(props) => props.theme.md} {
    width: 100%;
  }
`;

const StyledContainer = styled(Container)`
  ${(props) => props.theme.md} {
    margin-bottom: 100px;
  }
`;

const StyledWrapText = styled(StyledLabel)`
  ${(props) => props.theme.sm} {
    flex-wrap: wrap;
    word-break: break-word;
    color: ${({theme}) => theme.textColor};
  }
`;

// const StyledPortlet = styled.div
// `
// &.smallScreenRow {
//   height:calc(155vh);
// }
// `

const StyledDropDownContainer = styled.div`
  width: 226px;
  border-radius: 11px;
  padding: 4px 10px;

  &:hover {
    background-color: #fafafa;
  }

  .templ {
    font-weight: 600;
    font-size: 13px;
    line-height: 20px;
    letter-spacing: 0.02em;
    color: #585558 !important;

    &:hover {
      color: #0a0509 !important;
    }
  }
`;

const StyledIconWrap = styled.div`
  border-radius: 11px;
  padding: 12px 15px !important;
  background: ${({ theme }) => theme.primary};
  color: white;

  &:hover {
    color: var(--bs-btn-hover-color);
    background-color: var(--bs-btn-hover-bg);
    border-color: var(--bs-btn-hover-border-color);
  }
`;

export default SearchPublicPage;
