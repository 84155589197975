import useAuth from '../hooks/useAuth';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { USERROLES } from '../features/users/creation/assets/utills';
const allowedFunctions = {
  createForm: [USERROLES.ADMIN],
  renameForm: [USERROLES.ADMIN, USERROLES.INTERVIEWER, USERROLES.RECRUITER],
  editForm: [USERROLES.ADMIN],
  deleteForm: [USERROLES.ADMIN],
  createVideoProfile: [USERROLES.ADMIN],
  renameVideoProfile: [USERROLES.ADMIN, USERROLES.INTERVIEWER, USERROLES.RECRUITER],
  editVideoProfile: [USERROLES.ADMIN],
  deleteVideoProfile: [USERROLES.ADMIN],
  addJobNotes: [USERROLES.ADMIN, USERROLES.RECRUITER, USERROLES.INTERVIEWER],
  createJob: [USERROLES.ADMIN, USERROLES.RECRUITER, USERROLES.HIRINGMANAGER],
  shareJob: [USERROLES.ADMIN, USERROLES.RECRUITER],
  deleteJob: [USERROLES.ADMIN, USERROLES.RECRUITER],
  editJob: [USERROLES.ADMIN, USERROLES.RECRUITER, USERROLES.HIRINGMANAGER],
  manageHiringTeam: [USERROLES.ADMIN, USERROLES.RECRUITER],
  followJobs: [USERROLES.ADMIN, USERROLES.BILLINGADMIN, USERROLES.RECRUITER, USERROLES.INTERVIEWER],
  addCandidates: [USERROLES.ADMIN, USERROLES.RECRUITER,USERROLES.HIRINGMANAGER],
  addCVLib:[USERROLES.ADMIN, USERROLES.RECRUITER],
  editCandidate: [USERROLES.ADMIN, USERROLES.RECRUITER],
  deleteCandidates: [USERROLES.ADMIN, USERROLES.RECRUITER],
  shareCandidates: [USERROLES.ADMIN, USERROLES.RECRUITER],
  addAppNotes: [USERROLES.ADMIN, USERROLES.INTERVIEWER, USERROLES.RECRUITER],
  viewApplicationForms: [USERROLES.ADMIN, USERROLES.INTERVIEWER, USERROLES.RECRUITER],
  addEvaluationForm: [USERROLES.ADMIN, USERROLES.INTERVIEWER, USERROLES.RECRUITER],
  editEvaluationForm: [USERROLES.ADMIN, USERROLES.INTERVIEWER, USERROLES.RECRUITER],
  createInterviewSlots: [USERROLES.ADMIN, USERROLES.RECRUITER],
  createEmailTemplate: [USERROLES.ADMIN],
  createSmsTemplate: [USERROLES.ADMIN],
  createJobTemplate: [USERROLES.ADMIN],
  editEmailTemplate: [USERROLES.ADMIN],
  editJobTemplate: [USERROLES.ADMIN],
  editSmsTemplate: [USERROLES.ADMIN],
  deleteJobTemplate: [USERROLES.ADMIN],
  deleteSmsTemplate: [USERROLES.ADMIN],
  deleteEmailTemplate: [USERROLES.ADMIN],
  addDepartment: [USERROLES.ADMIN],
  editDepartment: [USERROLES.ADMIN],
  deleteDepartment: [USERROLES.ADMIN],
  manageSkills: [USERROLES.ADMIN],
  cvLibCandidates: [USERROLES.ADMIN, USERROLES.RECRUITER, USERROLES.INTERVIEWER],
  mailbox: [USERROLES.ADMIN, USERROLES.RECRUITER],
  addchildCompany: [USERROLES.ADMIN],
  createHiringPlan: [USERROLES.ADMIN, USERROLES.HIRINGMANAGER, USERROLES.RECRUITER],
  assignApprover: [USERROLES.ADMIN, USERROLES.RECRUITER],
  automationAddEvent: [USERROLES.ADMIN, USERROLES.RECRUITER, USERROLES.HIRINGMANAGER],
  modifyCampaigns: [USERROLES.ADMIN, USERROLES.RECRUITER],
  interviewReminder:[USERROLES.ADMIN, USERROLES.RECRUITER],
  viewCalender: [USERROLES.ADMIN, USERROLES.RECRUITER, USERROLES.HIRINGMANAGER, USERROLES.INTERVIEWER],
  viewJobs: [USERROLES.ADMIN, USERROLES.RECRUITER, USERROLES.HIRINGMANAGER, USERROLES.INTERVIEWER],
  viewActivity: [USERROLES.ADMIN, USERROLES.RECRUITER, USERROLES.HIRINGMANAGER, USERROLES.INTERVIEWER],
  viewWizard: [USERROLES.BILLINGADMIN, USERROLES?.ADMIN, USERROLES?.HIRINGMANAGER, USERROLES?.INTERVIEWER, USERROLES?.RECRUITER],
  editSource: [USERROLES.ADMIN,USERROLES.RECRUITER],
  deleteSource: [USERROLES.ADMIN,USERROLES.RECRUITER],
  addSource:[USERROLES.ADMIN,USERROLES.RECRUITER],
  requestRenewal: [USERROLES.BILLINGADMIN],
  subcriptionRedirection: [USERROLES.ADMIN, USERROLES.RECRUITER, USERROLES.BILLINGADMIN],
  companymailsview: [USERROLES.ADMIN]
};

export function MidleWare({
  component: Component,
  allowedRoles,
  noBasicAllowed,
  childUser
}) {
  let { user } = useAuth();
  let currentUserRoles = [];
  if ((user?.accountType === 2 && user?.company?.isParent === 1 && childUser)) {
    return <Navigate to="/" />;
  }
  if ((user?.accountType === 0)) {
    if (noBasicAllowed) {
      return <Navigate to="/" />;
    }
    else {
      return <Outlet />;
    }
  }
  if (user?.accountRoles && user?.accountRoles?.length > 0) {
    currentUserRoles = user?.accountRoles;
    currentUserRoles = currentUserRoles
      ?.map((item) => {
        return item?.role?.title === 'Billing Admin' ? 'Billing' : item?.role?.title;;
      })
      .join(',');
    const access =
      allowedRoles?.length > 0 &&
      allowedRoles?.filter((e) => currentUserRoles.indexOf(e) !== -1);
    return access?.length > 0 ? <Outlet /> : <Navigate to="/" />;
  } else {
    return <Outlet />;
  }
}

export function AllowedFunc(key, user) {
  let currentUserRoles = [];
  if (user?.accountType === 0 || (user?.accountType === 2 && user?.company?.isParent === 1)) {
    return true;
  }
  if (user?.accountRoles && user?.accountRoles?.length > 0) {
    currentUserRoles = user?.accountRoles;
    currentUserRoles = currentUserRoles
      ?.map((item) => {
        return item?.role?.title === 'Billing Admin' ? 'Billing' : item?.role?.title;;
      })
      .join(',');
    const access =
      allowedFunctions[key]?.length > 0 &&
      allowedFunctions[key]?.filter((e) => currentUserRoles.indexOf(e) !== -1);
    return access?.length <= 0;
  } else {
    return false;
  }
}

export function SideMenuAccess(item) {
  const { user } = useAuth();
  let currentUserRoles = [];
  if (item?.eventType === 1) {
    return false;
  }

  if (user?.accountType === 2 && !item?.childUser && user?.company?.isParent === 1) {
    return false;
  }
  if ((user?.accountType === 0)) {
    if (item.nobasicAllowed) {
      return false;
    }
    else { return true }
  }
  if (user?.accountRoles && user?.accountRoles?.length > 0) {
    currentUserRoles = user?.accountRoles;
    currentUserRoles = currentUserRoles
      ?.map((item) => {
        return item?.role?.title === 'Billing Admin' ? 'Billing' : item?.role?.title;
      })
      .join(',');
    return !(
      item?.authorizedRoles?.length > 0 &&
      item?.authorizedRoles?.filter(e => currentUserRoles.indexOf(e) !== -1)
        .length === 0
    );


  } else {
    return true;
  }
}
