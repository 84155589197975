import React from 'react';
import { useTheme } from 'styled-components';

const EyeIcon = ({ size = 20, color='currentColor',notActionIcon=false, ...props }) => {
  const theme = useTheme()
  
  return(
  <svg
    width={size}
    height={size}
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M12.9842 10.4999C12.9842 12.1499 11.6509 13.4833 10.0009 13.4833C8.35091 13.4833 7.01758 12.1499 7.01758 10.4999C7.01758 8.84993 8.35091 7.5166 10.0009 7.5166C11.6509 7.5166 12.9842 8.84993 12.9842 10.4999Z"
      stroke={theme?.dark && !notActionIcon ? theme?.actionsIconColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99987 17.3918C12.9415 17.3918 15.6832 15.6584 17.5915 12.6584C18.3415 11.4834 18.3415 9.50843 17.5915 8.33343C15.6832 5.33343 12.9415 3.6001 9.99987 3.6001C7.0582 3.6001 4.31654 5.33343 2.4082 8.33343C1.6582 9.50843 1.6582 11.4834 2.4082 12.6584C4.31654 15.6584 7.0582 17.3918 9.99987 17.3918Z"
      stroke={theme?.dark && !notActionIcon ? theme?.actionsIconColor : color}
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)};

export default EyeIcon;
