import React from 'react';
import styled from 'styled-components';
import StyledText from '../../../../app/components/styledComponents/StyledText';
import FormPublicRenderControl from './FormPublicRenderControl';
import { ControlTypes } from '../../../../utils/helper';
import { groups } from '../../assets/formUtils';
const FormPublicControlList = ({ isReadOnly, controls = [], assessment, setCurrentIndex, currentIndex, viewType,publicForm }) => {
  const currentControl = controls[currentIndex];
  return (
    <>
      <StyledFontSize>  
        {viewType === 'single' ? (
          <div>
            <div className="col-12" key={`${currentControl?.id}-${currentIndex}`}>
              <div className={`${assessment ? "my-3 my-md-4" : "my-4 my-md-5"}`}>
                {
                  !assessment &&
                  <StyledText as="h6" className="text-uppercase" opacity="0.7">
                    Question {currentIndex + 1}
                  </StyledText>
                }
                <StyledText assessment={assessment} as="h3" className="text-break">
                 {currentControl?.sectionType!==ControlTypes.FILE_UPLOAD && `${currentIndex + 1}. `} {currentControl?.sectionLabel}
                </StyledText>
                {currentControl?.sectionLabel === 'What is your ethnic origin?'  ? (
                      
                      groups?.map((grp, ind) => (
                        <> 
                        <div className="py-2 fw-600 fs-4" key={ind}>{grp.plabel}</div>
                     <FormPublicRenderControl
                  isReadOnly={isReadOnly}
                  assessment={assessment}
                  dei={true}
                  control={{
                    ...currentControl,
                    sectionOptions: {
                      ...currentControl?.sectionOptions,
                      opts: currentControl.sectionOptions?.opts?.filter(obj => obj?.plabel === grp?.plabel)
                    }
                  }
                  }
                  key={currentControl?.id}
                />
                
                </>))) : (
                   <FormPublicRenderControl
                   isReadOnly={isReadOnly}
                   assessment={assessment}
                   control={currentControl}
                   key={currentControl?.id}
                   publicForm={publicForm}
                 />
                )
                
              }
              </div>
            </div>

          </div>) : (
          <>
            {controls?.map((control, index) => (
              <div className="col-12" key={`${control.id}-${index}`}>
                <div className={`${assessment ? "my-3 my-md-4" : "my-4 my-md-5"}`}>
                  {
                    !assessment &&
                    <StyledText as="h6" className="text-uppercase" opacity="0.7">
                      Question {index + 1}
                    </StyledText>
                  }
                  <StyledText assessment={assessment} as="h3" className="text-break">
                    {index + 1}. {control?.sectionLabel}
                  </StyledText>
                  <FormPublicRenderControl
                    isReadOnly={isReadOnly}
                    assessment={assessment}
                    control={control}
                    key={control.id}
                    publicForm={publicForm}
                  />
                </div>
              </div>
            ))}
          </>
        )
        }
      </StyledFontSize>
    </>
  )
}
const StyledFontSize = styled.div`
${(props) => props.theme.md} {
  h3 {
    font-size: 20px;
  }
}
`;
export default FormPublicControlList;
