import styled from 'styled-components';
import StyledButton from '../../app/components/styledComponents/StyledButton';
import { FormLabel } from 'react-bootstrap';
import { STATUSES } from '../../utils/Statuses';

export const Wrap = styled.div``;
export const Image = styled.img``;
export const H1 = styled.h1``;
export const H2 = styled.h2``;
export const H3 = styled.h3``;
export const H4 = styled.h4``;
export const H5 = styled.h5``;
export const H6 = styled.h6``;

export const P = styled.p``;
export const B = styled.b``;
export const I = styled.i``;
export const Span = styled.span``;

export const HR = styled.hr`
  border-top: ${({ borderSize }) => borderSize ?? '1'}px solid
    $shadow-color-dark;
  width: ${({ width }) => width ?? 'auto'};
`;

export const StyledLabel = styled.label`
  font-family: ${({ font }) => font && font};
  font-size: ${({ size }) => size ?? '14px'};
  font-weight: ${({ weight }) => weight ?? '600'};
  color: ${({ color, theme }) => color ?? theme?.headingColor};
  background-color: ${({ bgColor }) => bgColor ?? 'none'};
  letter-spacing: ${({ letterSpacing }) => letterSpacing ?? 'normal'};
  line-height: ${({ lineHeight }) => lineHeight ?? ''};
  word-wrap: break-word;
`;

export const StyledSpan = styled.span`
  font-size: ${({ size }) => size ?? '14px'};
  font-weight: ${({ weight }) => weight ?? '600'};
  color: ${({ color, theme }) => color ?? 'black'};
`;

export const ScrollWrap = styled.div`
  height: ${({ height }) => height ?? '14px'};
  width: ${({ width }) => width ?? '100%'};
  margin-top: 12px;
  overflow: auto;
`;

export const StyledButtonNew = styled(StyledButton)`
  color: ${({ color, theme }) => color ?? theme.blackCardColor};
  : 10px 20px;
  font-size: ${({ size }) => size ?? '14px'};
  font-weight: ${({ weight }) => weight ?? '600'};
  font-family: ${({ fontFamily }) => fontFamily ?? 'Quick Sand'};
  color: ${({ color, theme }) => color ?? theme.body};
  border-radius: 8px;
  border: ${({ borderWidth, $bordercolor, $bordertype }) =>
    `${borderWidth ?? '1'}px ${$bordertype ?? 'solid'} ${
      $bordercolor ?? 'none'
    }`} !important;
  background: ${({ background, theme }) =>
    background ? background : theme.white} !important;
`;

export const StyledTruncateText = styled.label`
  font-family: ${({ font }) => font && font};
  font-size: ${({ size }) => size ?? '14px'};
  font-weight: ${({ weight }) => weight ?? '600'};
  color: ${({ color }) => color ?? 'black'};
  background-color: ${({ bgColor }) => bgColor ?? 'none'};
  word-wrap: break-word;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: ${({ width }) => width ?? '300px'};
  // height: 1.2em;
  white-space: nowrap;
`;

export const StyledDotsLoader = styled.div`
  position: relative;
  width: ${({ size }) => size ?? '10px'};
  height: ${({ size }) => size ?? '10px'};
  border-radius: 5px;
  background-color: ${({ color, theme }) => color ?? theme.primary};
  color: ${({ color, theme }) => color ?? theme.primary};
  animation: dot-flashing 1s infinite linear alternate;
  animation-delay: 0.3s;

  &::before,
  &::after {
    content: '';
    display: inline-block;
    position: absolute;
    top: 0;
  }
  &::before {
    left: -${({ gap }) => gap ?? '15px'};
    width: ${({ size }) => size ?? '10px'};
    height: ${({ size }) => size ?? '10px'};
    border-radius: 5px;
    background-color: ${({ color, theme }) => color ?? theme.primary};
    color: ${({ color, theme }) => color ?? theme.primary};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 0s;
  }
  &::after {
    left: ${({ gap }) => gap ?? '15px'};
    width: ${({ size }) => size ?? '10px'};
    height: ${({ size }) => size ?? '10px'};
    border-radius: 5px;
    background-color: ${({ color, theme }) => color ?? theme.primary};
    color: ${({ color, theme }) => color ?? theme.primary};
    animation: dot-flashing 1s infinite alternate;
    animation-delay: 1s;
  }

  @keyframes dot-flashing {
    0% {
      background-color: ${({ color, theme }) => color ?? theme.primary};
    }
    50%,
    100% {
      background-color: rgba(152, 128, 255, 0.2);
    }
  }
`;

export const StyledStatusTagByVariant = styled(FormLabel)`
  ${({ variant, theme, _children }) => {
    const basicProperties = `
    display: Inline-flex;
  flex-direction: row;
  align-items: center;
  color: ${theme.statusTextColor};
  margin-top:10px;
  border: 1px;
  font-size:12px;
  border-radius: 4px;
    `;
    switch (variant) {
      case 'sent':
        return `
        ${basicProperties}
        background: ${theme.statusTagSent};
        width:fit-content;
        padding:4px 8px;
        color:${theme.statusTextColor};
        `;
      case 'compeleted':
        return `
        ${basicProperties}
        background: ${theme.TagCompletedBg} ;
        width:fit-content;
        padding:4px 8px;
        color:${theme.green};
        `;
      case 'rejected':
        return `
          ${basicProperties}
        background: ${theme.statusTagOverDue};
        width:fit-content;
        padding:4px 8px;
        color:${theme.darkRed};
          `;
      case 'pending':
        return `
              ${basicProperties}
          background:${theme.statusTagAwaiting};
           color:${theme.statusAwaitingColor};
           width:fit-content;
           padding:4px 8px;
              `;
      case 'notSent':
        return `
            ${basicProperties}
          background: ${theme.statusTagNotSent};
          width:fit-content;
          padding:4px 8px;
          color:${theme.statusTextColor};
            `;

      case 'overdue':
        return `
        ${basicProperties}
        background: ${theme.statusTagOverDue};
        width:90px;
        `;
      case 'overdueDate':
        return `
          ${basicProperties}
          background: ${theme.statusTagOverDue};
          padding:4px 8px;
          width:auto;
          `;
      case 'new':
        return `
        padding:4px 8px;
          ${basicProperties}
       background: ${theme.statusTagNew};
       color:${theme.primary};
       width:auto
          `;
      case 'readyToRollOut':
        return `
          ${basicProperties}
       background: ${theme.statusTagNew};
       color:${theme.primary};
       padding:4px 8px;
          `;
      case 'listingCompletedColor':
        return `
              ${basicProperties}
          background:${
            theme?.dark ? theme?.statusTagCompleted : theme.interviewAttend
          };
           color:${theme?.dark ? theme.blue : theme.statusTextColor};
           width:fit-content;
           padding:4px 8px;
              `;
      case 'listingTagNew':
        return `
                      ${basicProperties}
                  background:${
                    theme?.dark ? theme?.statusTagNew : theme.interviewAttend
                  };
                   color:${
                     theme?.dark ? theme?.tagBlueBgColor : theme.statusTextColor
                   };
                   width:fit-content;
                   padding:4px 8px;
                      `;
      default:
        return `
        ${basicProperties}
        `;
    }
  }}
`;

export const StyledTagByStatusId = styled(FormLabel)`
  ${({
    status,
    theme,
    $border,
    $marginX,
    $fontSize,
    $padding,
    $width,
    $height,
    $radius,
  }) => {
    const basicProperties = `
  display: flex;
  align-items: center;
  color: ${theme.statusTextColor};
  border: ${$border ?? ''};
  margin: 0 ${$marginX ?? '10px'};
  font-size: ${$fontSize ?? '12px'};
  padding: ${$padding ?? '4px 7px'};
  border-radius: ${$radius ?? '5px'};
  width: ${$width ?? 'auto'};
  height: ${$height ?? 'auto'};
    `;
    switch (status) {
      case STATUSES.SENT:
        return `
        ${basicProperties}
        background: ${theme.statusTagSent};
        width:fit-content;
        color:${theme.statusTextColor};
        `;
      case STATUSES.DRAFT:
        return `
           ${basicProperties}
            background:${theme.statusTagPending};
            color:${theme.statusTextColor};
            width:fit-content;
           `;
      case STATUSES.COMPLETED:
      case STATUSES.LIVE:
      case STATUSES.ACTIVE:
      case STATUSES.APPROVED:
      case STATUSES.PASS:
        return `
         ${basicProperties}
        background: ${theme.TagCompletedBg} ;
        width:fit-content;
        color:${theme.green};
        `;
      case STATUSES.REJECTED:
      case STATUSES.FAILED:
      case STATUSES.CLOSED:
        return `
         ${basicProperties}
        background: ${theme.statusTagOverDue};
        width:fit-content;
        color:${theme.darkRed};
          `;
      case STATUSES.PENDING:
      case STATUSES.EXPIRED:
        return `
         ${basicProperties}
        background:${theme.statusTagAwaiting};
        color:${theme.statusAwaitingColor};
        width:fit-content;
        `;
      case STATUSES.NEW:
        return `
          ${basicProperties}
       background: ${theme.statusTagNew};
       color:${theme.primary};
       width:auto
          `;
      case STATUSES.COPYWRITING:
        return `
        ${basicProperties}
        background: ${theme.primaryLight};
        color:${theme.primary};
        width:auto
            `;
      case STATUSES.AWAITING_FEEDBACK:
        return `
         ${basicProperties}
          background:${
            theme?.dark ? theme?.statusTagNew : theme.interviewAttend
          };
           color:${theme?.dark ? theme?.tagBlueBgColor : theme.statusTextColor};
          width:fit-content;
         `;

      case 'notSent':
        return `
            ${basicProperties}
          background: ${theme.FormBasicControlGreen};
          width:fit-content;
          color:${theme.statusTextColor};
            `;

      case 'overdue':
        return `
        ${basicProperties}
        background: ${theme.statusTagOverDue};
        width:90px;
        `;
      case 'overdueDate':
        return `
          ${basicProperties}
          background: ${theme.statusTagOverDue};
          width:auto;
          `;

      case 'readyToRollOut':
        return `
          ${basicProperties}
       background: ${theme.statusTagNew};
       color:${theme.primary};
          `;
      case 'primary':
            return `
              ${basicProperties}
           background: ${theme.primary};
           color:${theme.white};
              `;
      case "status_green":
          return `
            ${basicProperties}
                background: ${theme?.dark ?theme.TagCompletedBg : '#6eaa641f'};
                width:fit-content;
                color:${theme?.dark ? theme.green : '#6eaa64'};
                `;
      default:
        return `
        ${basicProperties}
        `;
    }
  }}
`;
