import React from 'react';
import { useTheme } from 'styled-components';

const FormDateButtonIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        rx="4"
        fill={theme?.dark ? theme?.statusAwaitingColor : '#FFF6DA'}
      />
      <path
        d="M9.33331 5.33334V7.33334"
        stroke={theme?.dark ? '#f5e7b1' : '#664700'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.6667 5.33334V7.33334"
        stroke={theme?.dark ? '#f5e7b1' : '#664700'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.33331 10.06H17.6666"
        stroke={theme?.dark ? '#f5e7b1' : '#664700'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18 9.66668V15.3333C18 17.3333 17 18.6667 14.6667 18.6667H9.33333C7 18.6667 6 17.3333 6 15.3333V9.66668C6 7.66668 7 6.33334 9.33333 6.33334H14.6667C17 6.33334 18 7.66668 18 9.66668Z"
        stroke={theme?.dark ? '#f5e7b1' : '#664700'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4632 13.1334H14.4691"
        stroke={theme?.dark ? '#f5e7b1' : '#664700'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4632 15.1334H14.4691"
        stroke={theme?.dark ? '#f5e7b1' : '#664700'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.997 13.1334H12.003"
        stroke={theme?.dark ? '#f5e7b1' : '#664700'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.997 15.1334H12.003"
        stroke={theme?.dark ? '#f5e7b1' : '#664700'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.52956 13.1334H9.53555"
        stroke={theme?.dark ? '#f5e7b1' : '#664700'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.52956 15.1334H9.53555"
        stroke={theme?.dark ? '#f5e7b1' : '#664700'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FormDateButtonIcon;
