import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import useAuth from '../../hooks/useAuth';
import React, { useEffect, useState } from 'react';
import {
  setCredentials,
  useClaimGrantQuery,
  sideMenuState,
  setLicence,
  useGetUserLicenceMutation,
  removeCredentials,
  removeLicence,
} from './authSlice';
import { isApprover } from '../users/creation/assets/utills';

const LoginByCognito = () => {
  const { user } = useAuth();
  const query = new URLSearchParams(window.location.search);
  const [userLicence ,{isLoading: licenceLoading}] = useGetUserLicenceMutation();
  const [licence, setLicences] =useState();
 
  const { data: token, error, isFetching } = useClaimGrantQuery(
    { code: query.get('code') },
    {
      skip: !query.get('code'),
    },
  );

  const dispatch = useDispatch();
  const nav = useNavigate();

  useEffect(() => {
    if (token) {
      dispatch(removeCredentials());
      dispatch(removeLicence());
      dispatch(setCredentials(token));
      dispatch(sideMenuState(false));
      userLicence()
        .then((licence) => {
          if (licence?.data){ 
          dispatch(setLicence({ licence: licence }));
          setLicences(licence);
          }
          else nav(`/logout`);
        })
        .catch((error) => nav(`/logout`));
    }
    return () => {
      setLicences();
    }
  }, [token]);
  

  useEffect(() => {
    if (error) {
      nav(`/`);
    }
  }, [error]);

  if (user && token && !licenceLoading && !isFetching && licence && licence?.data){
    return licence?.data?.accepted === 1 ? (
      <Navigate to={ isApprover(user) ? "/approvers" : "/dashboard"} />
    ) : (
      <Navigate to="/privacy-policy" />
    );
  };
  if (!query.get('code')) {
    return nav('/');
  }

  return 'Loading..';
};

export default LoginByCognito;