import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import styled from 'styled-components';
import EditorToolbar, { formats, getModules } from './Toolbar';

const StyledTextEditor = ({
  title,
  value,
  onChange,
  placeholder,
  id,
  border,
  borderRadius,
  borderRadiusEditor,
  wrapperBorder,
  elementRef,
  isReadOnly,
  borderTop,
  toolbar,
  noToolBar,
  hide,
  borderApp = '',
  minHeight,
  isInvalid,
  overFlow,
  ...props
}) => {
  const getConditionalProps = (props) => {
    if (props?.publicForm) {
      return { ...props };
    }
    return {};
  };
  return (
    <>
      <StyledContainer
        {...getConditionalProps(props)}
        borderApp={borderApp}
        overFlow={overFlow}
        border={border}
        isInvalid={isInvalid}
        borderRadius={borderRadius}
        toolbar={toolbar}
        borderTop={borderTop}
        borderRadiusEditor={borderRadiusEditor}
        minHeight={minHeight}
        wrapperBorder={wrapperBorder}
      >
        <h5 className="m-0 fw-bold">{title}</h5>
        <EditorToolbar id={id} noToolBar={noToolBar} hide={hide} />
        <ReactQuill
          ref={elementRef}
          modules={getModules(id,hide)}
          formats={formats}
          readOnly={isReadOnly}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
        />
      </StyledContainer>
    </>
  );
};

const StyledContainer = styled.div`
  background-color: ${({ theme, publicForm }) =>
    publicForm ? 'transparent' : theme?.dark ? theme?.water : theme.white};
  border: ${({ wrapperBorder }) =>
    wrapperBorder ? wrapperBorder : '1px solid transparent'};
  border-radius: 4px;
  ${({ isValid, theme, isInvalid }) => {
    if (isValid && !isInvalid) {
      return `
      overflow: hidden;
      `;
    }
    if (!isValid && isInvalid) {
      return `
      overflow: hidden;
      border-color: ${theme.danger};
      `;
    }
    return `border-color: transparent`;
  }};
    .ql-editor.ql-blank::before {
        color: var(--bs-calendar-placeholder);
    }
  .quill {
    display: flex;
    flex-direction: column;
  }
  .ql-container {
    color: black;
    
  }

  .ql-container.ql-snow {
      border: 1px solid ${({theme})=>theme?.dark ? theme?.inputBorder : 'transparent'};
    }
  .ql-toolbar {
    border-radius: ${({ borderRadius }) =>
      borderRadius ? borderRadius : '5px 5px 0 0'};
    background-color: ${({ theme, publicForm }) =>
      publicForm ? theme.primary : theme?.dark ? theme?.darkLight : theme.bodyBg} !important;
    border: ${({ border }) => (border ? border : '0 !important')};
    // overflow-x: ${({ overFlow }) => (overFlow ? overFlow : 'auto !important')};
  }

  .ql-snow.ql-toolbar,
  button,
  .ql-stroke {
    display: ${({ toolbar }) => toolbar ?? ''} !important;
    stroke: ${({ theme, publicForm }) =>
      publicForm ? theme?.backgroundColor : theme.backgroundColor || theme?.dark ? theme?.headingColor : theme.textColor} !important;

    &:hover {
      color: ${({ theme }) => theme.primary} !important;
    }
  }

  .ql-fill {
    fill: ${({ theme }) => theme.backgroundColor || theme?.dark ? theme?.headingColor : theme.textColor} !important;
  }

  .ql-editor {
    font-size: 16px !important;
    -webkit-text-size-adjust: 100%;
    font-family: 'Quick Sand';
    border-radius: ${({ borderRadiusEditor }) =>
      borderRadiusEditor ? borderRadiusEditor : '0 0 5px 5px'};
    min-height: ${({ minHeight }) => (minHeight ? minHeight : '150px')};
    font-size: 14px;
    border-bottom: 2px solid
      ${({ theme, border, publicForm }) =>
        border ? border : publicForm ? theme.primary : theme.inputBorder};
    border-right: 2px solid
      ${({ theme, border, publicForm }) =>
        border ? border : publicForm ? theme.primary : theme.inputBorder};
    border-left: 2px solid
      ${({ theme, border, publicForm }) =>
        border ? border : publicForm ? theme.primary : theme.inputBorder};
    max-height: 300px;
    overflow-y: auto;
    border: ${({ borderApp }) => borderApp && borderApp};
    border-top: ${({ theme, borderTop }) =>
      `${borderTop} solid ${theme.bodyBg}` ?? 'none'} !important;
    color: ${({ publicForm, theme }) => publicForm ? theme.primary : theme?.dark ? theme?.headingColor : theme.textColor};
  }
  .ql-editor p {
    margin-bottom: 14px !important;
    color: ${({ publicForm, theme }) => publicForm ? theme.primary : theme?.dark ? theme?.headingColor :  theme.textColor};
  }
`;

export default StyledTextEditor;
