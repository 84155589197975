import React, { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import StyledDot from '../../../app/components/styledComponents/StyledDot';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import OfferAcceptedModal from './OfferAcceptedModal';
import NewAttachmentIcon from '../../../app/components/svgIcons/NewAttachmentIcon';
import StyledToolTip from '../../../app/components/styledComponents/StyledToolTip';
import DownloadIcon2 from '../../../app/components/svgIcons/DownloadIcon2';
import OccyLogoDefault from '../../../app/components/svgIcons/OccyLogoDefault.png';

const JobOfferAcception = ({
  jobOffer,
  setOfferLetter,
  responceLoading, attachmentDescription,
  title,
  description, document
}) => {
  const [acceptOffer, setAcceptOffer] = useState(false);
  const theme = useTheme();
  const filterHtmlRigix = (data) => {
    return <div dangerouslySetInnerHTML={{ __html: data }} />;
  };
  return (
    <Wrap className="d-sm-flex justify-content-sm-center">
      <Wrap className="col-sm col-sm-8 mt-0 mt-sm-5">
        <StyledContainer className="mt-0 mt-md-5 rounded">
          <StyledRoundedBorder className="bg-white">
            <Wrap className="px-5 py-4 rounded-top flex-column d-flex align-items-center align-items-md-start">
              <Wrap className="d-flex justify-content-center w-100 mb-2">
                <img
                  alt="company_logo"
                  src={jobOffer?.jobOfferDetails?.application?.job?.company?.compLOGOSignedUrl || OccyLogoDefault}
                  width="100"
                  height="20%"
                />
              </Wrap>
              <StyledLabel size="24px">{title}</StyledLabel>
              <StyledLabel
                weight="500px"
                color={theme.headingColor}
                className="text-left"
              >
                {filterHtmlRigix(document ? jobOffer?.agreementTpl?.offerAcceptMsg
                  : jobOffer?.jobOfferDetails?.compOfferTpl?.offerAcceptMsg) || ''}
              </StyledLabel>
            </Wrap>
          </StyledRoundedBorder>
          <div className='px-4 py-2'>
            {(document ? jobOffer?.agreementTpl?.documents : jobOffer?.jobOfferDetails?.jobOfferDocuments)?.length > 0 ?
              <StyledPortlet className="my-4">
                <StyledLabel>Attached Documents</StyledLabel>

                <StyledLabel className='d-flex' size='12px' color={theme?.borderColor} weight="500">
                  {attachmentDescription}
                </StyledLabel>
                {(document ? jobOffer?.agreementTpl?.documents : jobOffer?.jobOfferDetails?.jobOfferDocuments)?.map(
                  (item, index) => (
                    <>
                      <Wrap className="d-flex justify-content-between align-items-center mt-3">
                        <Wrap className="d-flex align-items-center gap-2">
                          <NewAttachmentIcon />
                          <StyledLabel weight="500px">
                            {document ? item?.fileOriginalName : item?.media?.fileOriginalName}
                          </StyledLabel>
                        </Wrap>
                        <a
                          className="cursor-pointer text-muted"
                          href={document ? item?.signedUrl : item?.media?.signedUrl}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <StyledToolTip title="Download" childrenClass={'ms-0'}>
                            <DownloadIcon2 />
                          </StyledToolTip>
                        </a>
                      </Wrap>
                      <hr />
                    </>
                  ),
                )}
              </StyledPortlet> : null}

            <StyledPortlet className="d-flex flex-column my-3">
              <StyledLabel className="mb-2">For queries contact</StyledLabel>
              <StyledLabel color={theme.primary} className=" text-capitalize">
                {`${(document ? jobOffer : jobOffer?.jobOfferDetails
                )?.application?.job?.account
                  ?.firstName ?? ''
                  }${' '}${(document ? jobOffer : jobOffer?.jobOfferDetails
                  )?.application?.job?.account
                    ?.lastName ?? ''
                  }`}
              </StyledLabel>
              <Wrap className="d-flex flex-column flex-sm-row align-items-sm-center gap-sm-2">
                <StyledLabel color={theme.lightBoldBlack} weight="500px">
                  {
                    (document ? jobOffer : jobOffer?.jobOfferDetails
                    )?.application?.job?.account
                      ?.email ?? ''}
                </StyledLabel>
                {!responceLoading && jobOffer ? (
                  <StyledDot className=" d-none d-sm-block" />
                ) : null}
                <StyledLabel color={theme.lightBoldBlack} weight="500px">
                  {(document ? jobOffer : jobOffer?.jobOfferDetails
                  )?.application?.job?.account
                    ?.phone ?? ''}
                </StyledLabel>
              </Wrap>
            </StyledPortlet>

            {(document ? jobOffer?.agreementTpl?.templateFingerprint : jobOffer?.jobOfferDetails?.compOfferTpl?.templateFingerprint) &&
              <div className="d-flex">
                <StyledButton
                  onClick={() => setAcceptOffer(true)}
                  type="button"
                  className="ms-auto mb-3 btn"
                >
                  Proceed
                </StyledButton>
              </div>}
          </div>
          <OfferAcceptedModal
            show={acceptOffer}
            onHide={() => setAcceptOffer(false)}
            setOfferLetter={setOfferLetter}
            agreement={document}
          />
        </StyledContainer>
      </Wrap>
    </Wrap >
  )
};

const StyledContainer = styled(Wrap)`
  ${(props) => props.theme.gtSm} {
    background-color: ${({theme})=> theme.inputBorder};
  }
`;

const StyledPortlet = styled(PortletBody)`
  ${(props) => props.theme.gtSm} {
    border: 1px solid ${({theme})=> theme.borderLight};
  }
`;

const StyledRoundedBorder = styled(Wrap)`
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  ${(props) => props.theme.gtSm} {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;


export default JobOfferAcception;
