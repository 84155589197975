import React from 'react';

const ArrowIcon = ({
  size = 18,
  rotate = 'right',
  color = 'currentColor',
  strokeWidth = '1.5',
  ...props
}) => {
  const getRotateDeg = (val) => {
    switch (val) {
      case 'down':
        return '90';
      case 'left':
        return '180';
      case 'up':
        return '270';
      default:
        return '0';
    }
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      style={{ transform: `rotate(${getRotateDeg(rotate)}deg)` }}
      {...props}
    >
      <path
        d="M14.4299 18.0698L20.4999 11.9998L14.4299 5.92982"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
      <path
        d="M3.5 12L20.33 12"
        stroke={color}
        strokeWidth={strokeWidth}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

export default ArrowIcon;
