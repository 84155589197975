import { createSlice } from '@reduxjs/toolkit';
import uniqBy from 'lodash/uniqBy';


const slice = createSlice({
  name: 'jobCreation',
  initialState: {
    notes: [],
    listing: { perPage: 10, page: 1 },
  },
  reducers: {
    setNoteData: (state, { payload: { values } }) => {
      const notes= [...state.notes, ...values]
      state.notes = uniqBy(notes, (note) => note?.id);
    },
    setNotes: (state, { payload: { values } }) => {
      state.notes = [...values];
    },
    resetNoteData: (state) => {
      state.notes = [];
      state.listing = { perPage: 10, page: 1 };
    },
    setListing: (state, { payload: values }) => {
      state.listing = { ...values };
    },
  },
});

export const { setNoteData, resetNoteData, setListing, setNotes } = slice.actions;

export default slice.reducer;

export const getNotesState = (state) => state.notes.notes;
export const getListing=(state)=>state.notes.listing;