import React from 'react';
import { ErrorMessage, FastField } from 'formik';
import { formErrorMessage } from './FormErrorMessage';
import StyledButton from '../styledComponents/StyledButton';
import Dropzone from '../dropzone/Dropzone';

const FormikDropZone = ({
  name,
  hideName,
  label,
  maxChar,
  onDrop,
  setFieldName,
  noContent,
  cont,
  contVar,
  ...props
}) => {
  // const [preOnUpload] = usePreOnUploadMutation();
  return (
    <FastField name={name}>
      {({
        field,
        form: { touched, setFieldValue, submitCount, errors, ...props },
      }) => (
        <>
          <Dropzone
            {...props}
            name={name}
            hideName={hideName}
            isInvalid={submitCount && touched[field.name] && errors[field.name]}
            onDrop={onDrop}
            label={label ? label : 'Drag and drop a CV here'}
            content={
              !noContent && (
                <StyledButton variant={`${contVar ? contVar : 'text'}`} className={`${contVar ? 'mb-3' : ''}`}>{cont ? cont : 'Browse your computer'}</StyledButton>
              )
            }
          />
          <ErrorMessage name={field.name} render={formErrorMessage} />
        </>
      )}
    </FastField>
  );
};

export default React.memo(FormikDropZone);
