import React from 'react';
import CustomDropdown from '../dropdown/CustomDropDown';
// import DropdownItem from 'react-bootstrap/DropdownItem';
import styled, { useTheme } from 'styled-components';
import StyledImage from './StyledImage';
import NotificationBellIcon from '../svgIcons/NotificationBellIcon';
import StyledButton from './StyledButton';
import SettingsIcon from '../svgIcons/SettingsIcon';
import {
  StyledLabel,
  StyledSpan,
  Wrap,
} from '../../../assets/styledComponents/Global';
import StyledDot from './StyledDot';
import moment from 'moment';
import { handleErrorImg } from '../../../utils/helper';
import { useNavigate } from 'react-router';
import { setNotifictionData } from '../../../features/Setting/Notifications/Apis/notificationsDataSlice';
import { useDispatch } from 'react-redux';
import { useMarkNotifAsReadMutation } from '../../../features/Setting/Notifications/Apis/notificationApiSlice';
import useAuth from '../../../hooks/useAuth';
const NotificationDropDown = ({
  toggleClassName,
  menuClassName,
  profile,
  notifications,
  totalUnreadNotifications,
  refetch,
}) => {
  const theme = useTheme();
  const { user } = useAuth();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [markNotifRead] = useMarkNotifAsReadMutation();
  const formattedTime = (date) => {
    return moment(date).format('LT');
  };
  const ViewAlNotifications = () => {
    dispatch(setNotifictionData(refetch));
    navigate('/notifications', {
      state: { notifications, totalUnreadNotifications },
    });
  };
  const markRead = (item) => {
    markNotifRead(item?.id, { skip: !item?.id })
      .unwrap()
      .then((res) => {
        // toast.success(res?.message || 'Marked as read successfully');
        refetch();
      });
  };

  return (
    <ProfileDropDownStyle>
      <CustomDropdown
        toggleClassName={toggleClassName}
        menuClassName={menuClassName}
        dropdownToggle={
          <div
            className={`account-menu ${toggleClassName} ${
              user?.accountType === 2 && user?.company?.isParent === 1
                ? 'd-none'
                : ''
            } position-relative`}
          >
            <div
              className={`account-img   ${!profile?.image ? 'default' : ''}`}
            >
              <NotificationBellIcon />
            </div>
            {notifications?.map((item, index) =>
              item?.read === 0 ? (
                <StyledDot
                  key={index}
                  color={theme?.dark ? theme?.darkDoubleRed :theme.danger}
                  $position="absolute"
                  $top="0px"
                  $right="5px"
                  $border={`2px solid ${theme.white}`}
                  $size="10px"
                />
              ) : null,
            )}
          </div>
        }
        dropdownMenu={
          <Wrap className="dropDown">
            <div className="m-3 d-flex flex-row justify-content-between align-items-center">
              <StyledLabel size="20px">Notifications</StyledLabel>
              <StyledIcon className="feature-pending">
                {' '}
                <SettingsIcon size="17px" />
              </StyledIcon>
            </div>
            {notifications?.length ? (
              <>
                <StyledScrollNoteViewer>
                  {notifications?.map((item, index) => (
                    <div
                      className={`${
                        item.read === 0 ? 'bg-body' : ''
                      } rounded py-2 mb-2 cursor-pointer`}
                      onClick={() => markRead(item)}
                      key={index}
                    >
                      <div
                        className="d-flex mx-3 align-items-center mb-2"
                        key={Math.random()}
                      >
                        <div className="col-2">
                          <StyledImage height={'56px'} width={'56px'}>
                            <img
                              src={item?.notification_icon}
                              alt="img"
                              onError={handleErrorImg}
                            />
                          </StyledImage>
                        </div>
                        <div className="col-10 ps-3 d-flex flex-wrap">
                          {item?.message?.map((data) => (
                            <StyledSpan
                              color={theme.lightBoldBlack}
                              className={`me-1 ${data?.bold ? 'fw-bold' : ''}`}
                              key={Math.random()}
                            >
                              {data?.text}
                            </StyledSpan>
                          ))}
                          <div>
                            <StyledLabel
                              size="14px"
                              color={theme.inputPlaceHolder}
                            >
                              {formattedTime(item?.created_at)}
                            </StyledLabel>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </StyledScrollNoteViewer>

                <Wrap className='bg-transparent p-3'>
                  <StyledButton
                    variant="white"
                    className={`col-12`}
                    onClick={ViewAlNotifications}
                  >
                    View All Notifications
                  </StyledButton>
                </Wrap>
              </>
            ) : (
              <StyledLabel className="text-center w-100">
                No notification yet
              </StyledLabel>
            )}
          </Wrap>
        }
      />
    </ProfileDropDownStyle>
  );
};
NotificationDropDown.defaultProps = {
  toggleClassName: '',
  menuItems: [],
  menuClassName: '',
  profile: {
    firstName: '',
  },
};
const StyledIcon = styled.div`
  background: #efefef;
  padding: 8px;
  border-radius: 50%;
`;
const ProfileDropDownStyle = styled.div`
  .menu-toggle {
    padding-block: 0;
  }
  .dropDown {
    width: 336px;
  }
  .account-menu {
    display: flex;
    align-items: center;
    h6 {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }
  }
  h6 {
    word-break: break-all;
    white-space: pre-wrap;
  }
  .time {
    color: ${({ theme }) => theme.inputPlaceHolder};
  }
  .account-img {
    border-radius: 5px;
    overflow: hidden;
    // margin-right: 8px;

    &.default {
      background-color: ${({ theme }) => theme.defaultImage};
      border-radius: 50%;
      color: ${({ theme }) => theme.white};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`;
const StyledScrollNoteViewer = styled.div`
  max-height: 310px;
  max-width: 520px;
  overflow: auto;
  word-wrap: break-word;
  @-moz-document url-prefix() {
    scrollbar-width:thin;
        scrollbar-color: #c8c8c8 transparent;
      }
`;
export default NotificationDropDown;
