import React from 'react';
import { FastField } from 'formik';
import StyledPublicCheckbox from '../checkbox/StyledPublicCheckbox';

const FormikPublicCheckbox = ({
  name,
  options,
  isReadOnly,
  isRadio,
  assessment,
  dei,
  ...props
}) => {
  return (
    <FastField name={name}>
      {({ field: { value }, form: { setFieldValue } }) => (
        <>
          <div className="d-flex align-items-center flex-wrap">
            {options?.map((option, index) => (
              <StyledPublicCheckbox
                assessment={assessment}
                isRadio={isRadio && isRadio}
                label={option.label}
                name={name}
                dei={dei}
                id={`${name}-${index}-${option.label}`}
                index={index}
                value={value?.includes(option.label)}
                onChange={() => {
                  const newValue = option.label;
                  if (Array.isArray(value) && !isRadio) {
                    const val = value.includes(newValue)
                      ? value.filter((v) => v !== newValue)
                      : [...value, newValue];
                    setFieldValue(name, val?.length ? val : "");
                  } else {
                    setFieldValue(name, [newValue]);
                  }
                }}
                disabled={isReadOnly}
                key={`${name}-${index}-${option.label}`}
              />
            ))}
          </div>
        </>
      )}
    </FastField>
  );
};

export default FormikPublicCheckbox;
