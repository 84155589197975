import React from 'react';
import styled, { useTheme } from 'styled-components';
import { StyledLabel, Wrap } from '../../../../assets/styledComponents/Global';
import StyledDot from '../../../../app/components/styledComponents/StyledDot';
import StyledButton from '../../../../app/components/styledComponents/StyledButton';
import PortletBody from '../../../../app/components/portlet/PortletBody';
import OccyLogoDefault from '../../../../app/components/svgIcons/OccyLogoDefault.png';
import { toast } from 'react-toastify';

const DocumentAgreement = ({ setAgreement, document, responceLoading, acceptRejectDocument, isLoading, refetch,
    token, }) => {
    const theme = useTheme();
    const handleAcceptOfferLetter = (isAccept) => {
        const values = {};
        values.token = token;
        values.agreementStatus = isAccept;
        acceptRejectDocument(values).then((res) => {
            toast.success(res?.data?.message);
            setAgreement(isAccept === 'ACCEPT' ? 'Continue' : '');
            if (isAccept !== 'ACCEPT') {
                refetch();
            }
        }
        );
    };
    const filterHtmlRigix = (text) => <div dangerouslySetInnerHTML={{ __html: text }} />

    return (
        <Wrap className="d-sm-flex justify-content-sm-center">
            <Wrap className="col-sm col-sm-8 mt-0 mt-sm-5">
                <StyledContainer className="mt-0 mt-md-5 rounded">
                    <StyledRoundedBorder className="bg-white">
                        <Wrap className="px-5 py-4 rounded-top flex-column d-flex align-items-center align-items-md-start">
                            <Wrap className="d-flex justify-content-center w-100 mb-2">
                                <img
                                    alt="company_logo"
                                    src={document?.application?.job?.company?.compLOGOSignedUrl || OccyLogoDefault}
                                    width="100"
                                    height="20%"
                                />
                            </Wrap>
                            <StyledLabel size="24px">{document?.agreementTpl?.title ?? 'Non Disclosure'} Agreement</StyledLabel>
                            <StyledLabel
                                weight="500px"
                                color={theme.headingColor}
                                className="text-center d-none"
                            >
                                Please find the details of our {document?.agreementTpl?.title?.toLowerCase() + ' agreement' ?? 'NDA'} below
                            </StyledLabel>
                        </Wrap>
                    </StyledRoundedBorder>

                    <Wrapped className="p-4">



                        <StyledPortlet>
                            <StyledLabel
                                weight="500px"
                                color={theme.textColor}
                                className=" my-md-3"
                            >
                                {filterHtmlRigix(document?.accompanyingMessage) ??
                                    `Thank you for considering a potential partnership with Parallel. We have an NDA to protect any confidential information that may be shared during our discussions. Please review, sign, and return at your earliest convenience. `}
                            </StyledLabel>

                            {document?.statusId === 16
                                ? !responceLoading && document ? (
                                    <StyledWrap className="px-3 px-md-0 py-4 py-md-0 bg-white d-flex gap-2">
                                        <StyledButtonContainer
                                            onClick={() => handleAcceptOfferLetter('ACCEPT')}
                                            disabled={isLoading}
                                        // loading={isLoading}
                                        >
                                            Accept
                                        </StyledButtonContainer>
                                        <StyledButtonContainer
                                            variant="white"
                                            onClick={() => handleAcceptOfferLetter('REJECT')}
                                            disabled={isLoading}
                                        >
                                            Reject
                                        </StyledButtonContainer>
                                    </StyledWrap>

                                ) : null : (
                                    <StyledLabel className="d-flex mt-3 mt-md-0">
                                        Agreement already processed
                                    </StyledLabel>)}
                        </StyledPortlet>

                        <StyledPortlet className="d-flex flex-column mt-3">
                            <StyledLabel className="mb-2">For queries contact</StyledLabel>
                            <StyledLabel color={theme.primary} className=" text-capitalize">
                                {`${document?.application?.job?.account
                                    ?.firstName ?? ''
                                    }${' '}${document?.application?.job?.account
                                        ?.lastName ?? ''
                                    }`}
                            </StyledLabel>
                            <Wrap className="d-flex flex-column flex-sm-row align-items-sm-center gap-sm-2">
                                <StyledLabel color={theme.lightBoldBlack} weight="500px">
                                    {document?.application?.job?.account
                                        ?.email ?? ''}
                                </StyledLabel>
                                {!responceLoading && document ? (
                                    <StyledDot className=" d-none d-sm-block" />
                                ) : null}
                                <StyledLabel color={theme.lightBoldBlack} weight="500px">
                                    {document?.application?.job?.account
                                        ?.phone ?? ''}
                                </StyledLabel>
                            </Wrap>
                        </StyledPortlet>
                    </Wrapped>
                </StyledContainer>
            </Wrap>

        </Wrap >
    )
};

const StyledWrap = styled(Wrap)`
  ${(props) => props.theme.sm} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  z-index: 999;
`;

const StyledButtonContainer = styled(StyledButton)`
  ${(props) => props.theme.sm} {
    width: 100%;
  }
`;

const Wrapped = styled(Wrap)`
  ${(props) => props.theme.sm} {
    margin-bottom: 70px;
  }
`;

const StyledContainer = styled(Wrap)`
  ${(props) => props.theme.gtSm} {
    background-color: ${({theme}) => theme.inputBorder};
  }
`;

const StyledPortlet = styled(PortletBody)`
  ${(props) => props.theme.gtSm} {
    border: 1px solid ${({theme}) => theme.borderLight};
  }
`;

const StyledRoundedBorder = styled(Wrap)`
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  ${(props) => props.theme.gtSm} {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export default DocumentAgreement;
