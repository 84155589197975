import React from 'react';
import { useTheme } from 'styled-components';

const FormEmailIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        rx="4"
        fill={theme?.dark ? theme?.statusTagCompleted : '#D8E6C6'}
        fillOpacity="0.65"
      />
      <path
        d="M9.99998 18.6667H14C17.3333 18.6667 18.6666 17.3333 18.6666 14V10C18.6666 6.66668 17.3333 5.33334 14 5.33334H9.99998C6.66665 5.33334 5.33331 6.66668 5.33331 10V14C5.33331 17.3333 6.66665 18.6667 9.99998 18.6667Z"
        stroke={theme?.dark ? theme?.darkTagGreenTextColor : '#292D32'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.66669 9.92563C10.7667 8.87896 13.2334 8.87896 15.3334 9.92563"
        stroke={theme?.dark ? theme?.darkTagGreenTextColor : '#292D32'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14.8665V9.28647"
        stroke={theme?.dark ? theme?.darkTagGreenTextColor : '#292D32'}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FormEmailIcon;
