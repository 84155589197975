import React from 'react';
import { useTheme } from 'styled-components';

const SwitchCompanyIcon = ({ size = 18 }) => {
  const theme = useTheme();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.46672 12H2.76672C1.81339 12 1.33337 11.52 1.33337 10.5666V2.76668C1.33337 1.81334 1.81339 1.33333 2.76672 1.33333H5.63338C6.58672 1.33333 7.06669 1.81334 7.06669 2.76668V3.99999"
        stroke={theme?.dark ? 'white' : '#585558'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M11.58 5.61332V13.0533C11.58 14.1267 11.0467 14.6667 9.97335 14.6667H6.08C5.00666 14.6667 4.46667 14.1267 4.46667 13.0533V5.61332C4.46667 4.53999 5.00666 4 6.08 4H9.97335C11.0467 4 11.58 4.53999 11.58 5.61332Z"
        stroke={theme?.dark ? 'white' : '#585558'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.93335 3.99999V2.76668C8.93335 1.81334 9.41333 1.33333 10.3667 1.33333H13.2333C14.1867 1.33333 14.6667 1.81334 14.6667 2.76668V10.5666C14.6667 11.52 14.1867 12 13.2333 12H11.58"
        stroke={theme?.dark ? 'white' : '#585558'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66663 7.33333H9.33329"
        stroke={theme?.dark ? 'white' : '#585558'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66663 9.33333H9.33329"
        stroke={theme?.dark ? 'white' : '#585558'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 14.6667V12.6667"
        stroke={theme?.dark ? 'white' : '#585558'}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default SwitchCompanyIcon;
