import React from 'react';
import { useTheme } from 'styled-components';

const FillCandidatesIcon = ({ size = 56 }) => {
  const theme = useTheme();
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 56 56"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="56"
        height="56"
        rx="28"
        fill={theme?.darkLight || '#E1EAF9'}
      />
      <path
        d="M33.53 23.77C33.46 23.76 33.39 23.76 33.32 23.77C31.77 23.72 30.54 22.45 30.54 20.89C30.54 19.3 31.83 18 33.43 18C35.02 18 36.32 19.29 36.32 20.89C36.31 22.45 35.08 23.72 33.53 23.77Z"
        fill={(theme?.dark && theme.chartVacant) || '#204B8C'}
      />
      <path
        d="M36.7901 30.7004C35.6701 31.4504 34.1001 31.7304 32.6501 31.5404C33.0301 30.7204 33.2301 29.8104 33.2401 28.8504C33.2401 27.8504 33.0201 26.9004 32.6001 26.0704C34.0801 25.8704 35.6501 26.1504 36.7801 26.9004C38.3601 27.9404 38.3601 29.6504 36.7901 30.7004Z"
        fill={(theme?.dark && theme.chartVacant) || '#204B8C'}
      />
      <path
        d="M22.4402 23.77C22.5102 23.76 22.5802 23.76 22.6502 23.77C24.2002 23.72 25.4302 22.45 25.4302 20.89C25.4302 19.29 24.1402 18 22.5402 18C20.9502 18 19.6602 19.29 19.6602 20.89C19.6602 22.45 20.8902 23.72 22.4402 23.77Z"
        fill={(theme?.dark && theme.chartVacant) || '#204B8C'}
      />
      <path
        d="M22.5501 28.8496C22.5501 29.8196 22.7601 30.7396 23.1401 31.5696C21.7301 31.7196 20.2601 31.4196 19.1801 30.7096C17.6001 29.6596 17.6001 27.9496 19.1801 26.8996C20.2501 26.1796 21.7601 25.8896 23.1801 26.0496C22.7701 26.8896 22.5501 27.8396 22.5501 28.8496Z"
        fill={(theme?.dark && theme.chartVacant) || '#204B8C'}
      />
      <path
        d="M28.1198 31.87C28.0398 31.86 27.9498 31.86 27.8598 31.87C26.0198 31.81 24.5498 30.3 24.5498 28.44C24.5598 26.54 26.0898 25 27.9998 25C29.8998 25 31.4398 26.54 31.4398 28.44C31.4298 30.3 29.9698 31.81 28.1198 31.87Z"
        fill={(theme?.dark && theme.chartVacant) || '#204B8C'}
      />
      <path
        d="M24.8698 33.9396C23.3598 34.9496 23.3598 36.6096 24.8698 37.6096C26.5898 38.7596 29.4098 38.7596 31.1298 37.6096C32.6398 36.5996 32.6398 34.9396 31.1298 33.9396C29.4198 32.7896 26.5998 32.7896 24.8698 33.9396Z"
        fill={(theme?.dark && theme.chartVacant) || '#204B8C'}
      />
    </svg>
  );
};

export default FillCandidatesIcon;
