import React from 'react';
import styled from 'styled-components';
import LightModeIcon from '../../components/svgIcons/LightModeIcon';
import DarkModeIcon from '../../components/svgIcons/DarkModeIcon';
import theme from '../../../utils/themes';
import { useDispatch } from 'react-redux';
import { getAppMode, setModeState } from '../../../features/auth/authSlice';
import { useSelector } from 'react-redux';

const DarkModeTile = ({sidebarVisible}) => {
  const darkMode = useSelector(getAppMode);
  const dispatch =useDispatch()
  return (
    <DarkModeTileWrap darkMode={darkMode} sidebarVisible={sidebarVisible}>
      <ModeTile
        active={!darkMode}
        borderEnd
        sidebarVisible={sidebarVisible}
        onClick={() => sidebarVisible && dispatch(setModeState(false))}
      >
        <LightModeIcon color="currentColor"  onClick={() => dispatch(setModeState(true))}/>
       {sidebarVisible?  'Light' : '' }
      </ModeTile>
      {sidebarVisible && <BorderLine />}
      <ModeTile
        active={darkMode}
        sidebarVisible={sidebarVisible}
        onClick={() => sidebarVisible && dispatch(setModeState(true))}
      >
        <DarkModeIcon color="currentColor" onClick={() => dispatch(setModeState(false))} />
        {sidebarVisible? 'Dark' : '' }
      </ModeTile>
    </DarkModeTileWrap>
  );
};

const BorderLine = styled.div`
height:100%;
width:1px;
background-color: ${({ darkMode }) =>
    darkMode ? theme?.primaryLight4: theme?.primaryDark };
`

const ModeTile =styled.div`
width: ${({sidebarVisible})=>sidebarVisible? '50%': 'auto'};
display:${({sidebarVisible, active})=>sidebarVisible ? 'flex': active ? '' : 'none'};
align-items: center;
gap:8px;
background-color : ${({active})=> active ? theme?.primaryDark : 'transparent'};
color: ${({active})=> active ? theme?.white : ''};
Padding: 4px 6px;
cursor:pointer;
border-radius : 16px;
`


const DarkModeTileWrap = styled.div`
  height: ${({sidebarVisible})=>sidebarVisible? '36px': 'auto'};
  width: ${({sidebarVisible})=>sidebarVisible? '160.34px': 'fit-content'};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: ${({sidebarVisible})=>sidebarVisible? '20px': '20px'};
  font-Weight: 600;
  gap:4px;
  font-size:14px;
  margin-left: 16px;
  Padding :${({sidebarVisible})=>sidebarVisible ? '8px 4px 8px 4px' : '4px'};
  background-color: ${({ darkMode }) =>
    darkMode ? theme?.primary: theme?.primaryLight4};
`;

export default DarkModeTile;
