import React from 'react';

const RedirectIcon = ({
  size = 16,
}) => {
  return (
<svg width={size} height={size} viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M6.76562 6.31509L10.8656 2.21509" stroke="#585558" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M11.2652 4.21519V1.81519H8.86523" stroke="#585558" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M5.76562 1.81519H4.76562C2.26562 1.81519 1.26562 2.81519 1.26562 5.31519V8.31519C1.26562 10.8152 2.26562 11.8152 4.76562 11.8152H7.76562C10.2656 11.8152 11.2656 10.8152 11.2656 8.31519V7.31519" stroke="#585558" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
</svg>

);
};

export default RedirectIcon;