import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import styled, { useTheme } from 'styled-components';
import useAuth from '../../../hooks/useAuth';
import LogoutIcon from '../../components/svgIcons/LogoutIcon';
import { useDispatch } from 'react-redux';
import DropDownIcon from '../../components/svgIcons/DropDownIcon';
import AddIcon from '../../components/svgIcons/AddIcon';
import SwitchCompanyIcon from '../../components/svgIcons/SwitchCompanyIcon';
import AddCompanyModal from '../../../features/Setting/ChildCompanies/components/AddCompanyModal';
import GearIcon from '../../components/svgIcons/GearIcon';
import TickIcon from '../../components/svgIcons/TickIcon';
import { useGetAllChildCompaniesQuery } from '../../../features/Setting/ChildCompanies/childCompaniesApiSlice';
import { useSelector } from 'react-redux';
import { getPagination, getPaginationPage } from '../../components/pagination/paginationSlice';
import { isArray } from 'lodash';
import { setAppendedToken } from '../../../features/auth/authSlice';
import { occyLs } from '../../../utils/localStorage';
import { setChildCompany } from '../../../features/Setting/ChildCompanies/ChildCompanyDataSlice';

const ChildCompaniesDropdown = ({ profile }) => {
  const loginCompany = occyLs.getObject('account');
  const theme = useTheme();
  const { user } = useAuth();
  const [addCompanyModal, setAddCompanyModal] = useState(false);
  const initialFilters = {
    childCompanies: [],
    perPage: 5,
    page: 1,
    sortedColumn: { path: 'createdAt', order: 'DESC' },
    query: '',
  };
  const listing = {
    ...initialFilters,
    perPage: useSelector(getPagination),
    page: useSelector(getPaginationPage),
  };

  const {
    data: childCompaniesRes,
    refetch: refetchCompanies,
  } = useGetAllChildCompaniesQuery(
    {
      perPage: 50,
      page: listing.page,
      query: listing.query && listing.query,
      statusId: 2,
      //   sort: listing.sortedColumn.path,
      //   sortDir: listing.sortedColumn.order,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setChildCompany({ refetchCompanies }))
  }, [])
  const userMenuItems = [
    // {
    //   name: 'Career Site',
    //   icon: <GlobeUnFilledIcon />,
    //   action: () => {
    //     navigate('/career-site');
    //   },
    // },
    {
      name: 'Switch Company',
      icon: <SwitchCompanyIcon theme={theme} />,
      subItems: [{
        name: 'Logout',
        icon: <LogoutIcon />,
      }],
      action: () => {
      },
    },
    {
      name: 'Settings',
      icon: <GearIcon />,
      action: () => {
        navigate('/settings/company/child/companies');
      },
    },
  ];
  const li = document.querySelectorAll('li.dropdown a');
  const btn = document.querySelector('.nav-btn');
  const nav = document.querySelector('ul.nav');

  btn?.addEventListener('click', e => {
    nav.classList.toggle('toggle');
  })


  li.forEach((each) => {
    if (each.nextElementSibling !== null) {
      each.addEventListener('click', e => {
        if (window.innerWidth < 768) {
          e.target.parentElement.classList.toggle("active");
        }
      })
    }
  })
  let randomColorsComp = [theme.danger, theme.borderColor, theme.tagLightgray, theme.success, theme.interviewsuccess, theme.inputPlaceHolder, theme.tootipBg, theme.lightGreen, theme.funnelIndex3, theme.statusTagSent, theme.stageIconColor, theme.primaryLight2, theme.notesColorOverDue, theme.chartManual, theme.gold, theme.eventColor2, theme.eventGreen, theme.LineChartBorder, theme.darkRed, theme.funnelIndex4, theme.successColorSky, theme.doughplaceholderColor, theme.pyramidInterviewed, theme.bodyBg, theme.chartVacant, theme.scheduleSelfSlotInfoColor, theme.crystal, theme.defaultImage, theme.danger, theme.statusTagCompleted, theme.chartFilled, theme.notesColorCompleted, theme.blue, theme.eventColor1, theme.white, theme.headingColor, theme.inputBorder, theme.inputColor, theme.formSettingCardDesc, theme.primaryDark, theme.dotColor, theme.lightYellow, theme.teamLabelbg, theme.stagesDarkColor, theme.hintBg, theme.primary, theme.lightBoldBlack, theme.creditBg1, theme.notesColorSent, theme.interviewtimebg, theme.orange, theme.tagBlueColor, theme.pyramidOffered, theme.statusTagOverDue, theme.successLight, theme.tagLightYellow, theme.tuna, theme.green, theme.triggerIconColor, theme.dangerFailColor, theme.creditBg2, theme.berylGreen, theme.eventColor5, theme.statusTextColor, theme.noteTags, theme.borderLight, theme.skygreen, theme.LineChartDashes, theme.eventColor3, theme.textColor, theme.doughGraphtooltipTitle, theme.darkBrown, theme.InterviewFormikToolbar, theme.crystal];

  const ls = occyLs.getObject('token')?.split('|').shift();
  const switchAccount = (acc) => {
    if (acc?.isParent === 1) {
      dispatch(setAppendedToken(ls?.split('|')[0]));
    }
    else {
      dispatch(setAppendedToken(ls?.split('|')[0] + '|' + acc?.id));
    }
    navigate('/settings/accounts/profile');
    window.location.reload();
  }
  return (
    <>
      <ProfileDropDownStyle className="d-flex align-items-center">
        <div className={`account-img  cursor-pointer ${!profile?.image ? 'default' : ''} pt-1`} onClick={() => navigate('settings/company/profile')}>
          <StyledChar className='char' color={randomColorsComp[0]}>
            {user?.company?.name?.charAt(0).toUpperCase()}</StyledChar>
        </div>

        <StyledNav>
          <nav>
            <ul className="nav">
              <li className="drodown">
                <a href="#" className='heading'>{user?.company?.name} <DropDownIcon rotate="down" className="ms-1" /></a>
                <ul>
                  {userMenuItems.map((it) => (
                    <li className="dropdown " key={`umi-${Math.random()}`}>
                      <div className='d-flex align-items-center justify-content-between' onClick={() => it.action()}>
                        <a > {it?.icon} <label className='ps-2'>{it?.name}</label>  </a> {it?.subItems && <DropDownIcon rotate="right" className="ms-1" />}</div>
                      {it?.subItems?.length > 0 && <ul>
                        {it?.subItems?.map((itm) => (
                          it?.name === 'Switch Company' ? (<div key={`sc-${Math.random()}`}><><StyledDiv>
                            {isArray(childCompaniesRes?.companies) && childCompaniesRes?.companies?.slice(0, 3)?.map((l, i) => (
                              <div key={`ccr-${Math.random()}`} className={`${user?.company?.id === l?.id && 'pe-none'} d-flex align-items-center list justify-contetn-between`} onClick={() => switchAccount(l)}>
                                <StyledChar className='char' color={randomColorsComp[i]}>
                                  {l.name?.charAt(0).toUpperCase()}</StyledChar><h6>{user?.company?.id === l?.id ? l?.name?.substring(0, 11) + '...' : l?.name} <span className='ps-3'>{user?.company?.id === l?.id && <TickIcon size={'20'} />}</span></h6>
                              </div>
                            ))}
                          </StyledDiv>
                          </>
                            {(isArray(childCompaniesRes?.companies) && childCompaniesRes?.companies?.length > 3) &&
                              <Link className="m-0 text-decoration-none py-0 text-center view-more fw-bold fs-6 text-secondary" to={'/settings/company/child/companies'}> View more</Link>
                            }</div>
                          ) : (
                            <li className="dropdown ">
                              <div className='d-flex align-items-center'>
                                {itm?.icon}<a href="#">{itm?.name}</a></div>
                            </li>
                          )

                        ))}

                        {it?.name === 'Switch Company' && it?.subItems && loginCompany?.company?.isParent === 1 && <li className={`dropdown border-top d-flex  px-2 ${user?.accountType === 2 && user?.company?.isParent === 1 ? 'd-none' : ''}`}>
                          <div className={`d-flex align-items-center`}>
                            <AddIcon /><a href="#" onClick={() => setAddCompanyModal(true)}>Add Company    </a></div>
                        </li>
                        }
                      </ul>}
                    </li>
                  ))}
                </ul>
              </li>

            </ul>
          </nav>
        </StyledNav>

      </ProfileDropDownStyle>
      <AddCompanyModal
        title={'Add Company'}
        show={addCompanyModal}
        onHide={() => {
          setAddCompanyModal(false);
        }}
        editChildCompanyData={undefined}
        editCompanyModal={false}
      />
    </>
  );
};

const StyledDiv = styled.div`
overflow:auto;
height:auto;
padding:5px;
.list{
  padding-top:5px;
  border-radius:5px;
}
.list:hover{
  background:${({ theme }) => theme?.dark ? theme.primary : "#fafafa"};
}
`

const StyledChar = styled.div`
    background-color: ${props => props.color};
    color:white;
    border-radius:50px;
    width: 30px;
    height: 30px;
    display:flex;
    justify-content:center;
    align-items:center;
    margin-bottom:5px;
    margin-right:8px;
    font-size:14px;
`
const StyledNav = styled.div`
.heading{
  font-size:14px;
  font-weight:500;
}
.mobile-nav {
  background: #555;
  color: #fff;
  display: none;
  justify-content: space-between;
  align-items: center;
  height: 35px;
  padding: 20px;
  font-size: 20px;
}

.mobile-nav .nav-btn {
  cursor: pointer;
}

ul {
  list-style: none;
  display: flex;
}

ul li a,
ul li {
  cursor: pointer;
  font-size: 20px;
  text-decoration: none;
}

ul li {
  display: block;
}

ul li a {
  padding: 15px 11px;
  color: ${({theme})=>(theme?.dark &&  'white') || 'black'};
  display: block;
  font-size:16px;
}


ul li ul {
  background: ${({theme})=>theme?.darkLight ||  'white'};
  padding: 5px;
  position: absolute;
  width: 10em;
  display: none;
  border-radius:10px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, 0.15) !important;
}

li ul li a {
  padding: 10px;
  background: fafafa;
  color:${({theme})=> theme?.textColor ||  'black'};
  font-size:14px;
}

li ul li a:hover {
  background: ${({ theme }) => theme?.dark ? theme.primary : "#fafafa"} !important;
}
.view-more:hover{
  color:${({ theme }) => theme.primary};
}

ul li ul li {
  position: relative;
}

ul li ul li ul {
  position: absolute;
  top: 5px;
  left: 100%;
}

li:hover > ul,
li:active > ul {
  display: block;
}

.dropdown {
  position: relative;
  border-radius:5px;
  padding:5px;
  padding-left:0;
}
.dropdown:hover{
  background: ${({ theme }) => theme?.dark ? theme.primary : "#fafafa"} !important;
}
.dropdown > a,
.dropdown > a:hover,
.dropdown.active > a,
.dropdown.active > a:hover {
 content:'\f105';
  color: black;
}
`
const ProfileDropDownStyle = styled.div`
  .menu-toggle {
    padding-block: 0;
  }

  .account-menu {
    display: flex;
    align-items: center;

    h6 {
      font-size: 14px;
      font-weight: 600;
      margin: 0;
    }
  }

  .account-img {
    width: 35px;
    height: 35px;
    border-radius: 5px;
    overflow: hidden;

    &.default {
      background-color: ${({ theme }) => theme.defaultImage};
      border-radius: 50%;
      color: ${({ theme }) => theme.white};
      display: flex;
      align-items: center;
      justify-content: center;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
`;
export default ChildCompaniesDropdown;
