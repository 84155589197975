import React, { useEffect, useMemo, useState } from 'react';
import styled, { ThemeProvider } from 'styled-components';
import StyledFormContainer from '../../../app/components/styledComponents/StyledFormContainer';
import themes from '../../../utils/themes';
import { Col, Container, Row } from 'react-bootstrap';
import StyledImage from '../../../app/components/styledComponents/StyledImage';
import { get, omitBy, size } from 'lodash';
import { Formik, Form } from 'formik';
import StyledText from '../../../app/components/styledComponents/StyledText';
import FormPublicControlList from './components/FormPublicControlList';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import StyledProgressbar from '../../../app/components/styledComponents/StyledProgressbar';
import * as yup from 'yup';
import { createYupSchema } from '../../../utils/yupValidationSchema';
import { useQuery } from '../../../utils/helper';
import {
  useDeclineDeiFormSubmissionMutation,
  useGetPublicDeiFormMutation,
  useGetPublicFormByTokenQuery,
  useSubmitDeiFormMutation,
  useSubmitFormMutation,
} from '../formApiSlice';
import { toast } from 'react-toastify';
import FormPublicErrorView from './components/FormPublicErrorView';
import FormSuccessIcon from '../../../app/components/svgIcons/FormSuccessIcon';
import FormExpiryIcon from '../../../app/components/svgIcons/FormExpiryIcon';
import moment from 'moment';
import DeiFormStarterImage from '../../../app/components/svgIcons/DeiFormStarterImage';
import { formTypes } from '../assets/formUtils';
import DropDownIcon from '../../../app/components/svgIcons/DropDownIcon';
import { STATUSES } from '../../../utils/Statuses';

const PublicForm = ({ formData, isReadOnly }) => {
  const query = useQuery();
  const [isCompleted, setIsCompleted] = useState(false);
  const [formExpired, setFormExpired] = useState(false);
  const [submitForm, { isLoading }] = useSubmitFormMutation();
  const [submitDeiForm, { isLoading: isLoadingDeiSubmission }] =
    useSubmitDeiFormMutation();
  const [getDeiForm, { isLoading: isLoadingDei }] =
    useGetPublicDeiFormMutation();

  const [declineDeiForm, { isLoading: isLoadingDeiDecline }] =
    useDeclineDeiFormSubmissionMutation();

  const [currentIndex, setCurrentIndex] = useState(0);
  const [publicForm, setPublicForm] = useState({});
  const token = query?.get('token');
  let {
    data: _formData,
    error,
    isLoading: formLoading,
  } = useGetPublicFormByTokenQuery(token, {
    skip: !token,
  });

  const [deiForm, setDeiForm] = useState(false);
  const [startDeiForm, setStartDeiForm] = useState(false);

  const onStartDeiForm = async () => {
    const { data } = await getDeiForm(token);
    if (data?.id) {
      setDeiForm(true);
      setStartDeiForm(false);
      setPublicForm({ ...data, viewType: 'single' });
    }
  };

  const onDeclineSubmission = async () => {
    const { data } = await declineDeiForm(token);
    if (data?.message) {
      toast.success(data?.message || 'Form submitted successfully!');
      setStartDeiForm(false);
      setIsCompleted(true);
    }
  };

  const handleNextClick = () => {
    setCurrentIndex((currentIndex + 1) % publicForm?.formQuestions?.length);
  };
  const handlePrevClick = () => {
    setCurrentIndex(
      (currentIndex - 1 + publicForm?.formQuestions?.length) %
      publicForm?.formQuestions?.length,
    );
  };

  useEffect(() => {
    setPublicForm(
      formData
        ? {
          ...formData,
          viewType: formData?.viewType ?? 'single',
        }
        : _formData,
    );
    const createdAt = get(_formData || formData, 'appForm.createdAt');
    const expiredDate = get(
      _formData || formData,
      'appForm.customFormId.refOpts.expireAfter',
    );
    if (
      expiredDate
        ? moment().isAfter(moment(createdAt).add(expiredDate, 'days'))
        : moment().isAfter(moment(createdAt).add(21, 'days'))
    ) {
      setFormExpired(true);
    }
  }, [_formData, formData]);

  const isShowNextPrev = (viewType, currentIndex, length) =>
    (viewType === 'single' && currentIndex !== length - 1) ||
    (isReadOnly && viewType === 'single' && currentIndex !== length);

  const renderFormScreens = (message, publicForm) => {
    if (formLoading) {
      return <>Loading</>;
    } else if (
      (publicForm?.dei && publicForm?.appForm?.isComplete && !isCompleted) ||
      startDeiForm
    ) {
      return (
        <div className="flex-center flex-column pt-md-4 pt-2 text-center">
          <StyledImg >
            <DeiFormStarterImage size="350" className="webSize" />
            <DeiFormStarterImage size="200" className="mobileSize" />
          </StyledImg>
          <StyledFont style={{ display: "flex", flexDirection: "column", alignItems: "center" }}>
            <StyledText as="h1" $fontSize="40px">
              We would like to know you better!
            </StyledText>
            <StyledText as="h4" className="w-50 pt-2">
              At {_formData?.company?.name}, we strive to be an equal opportunity employer.
            </StyledText>
            <StyledText as="h4" className="w-50 pb-2 pb-md-3">
              We have a few questions for you that will help us with this initiative.
            </StyledText>
            <StyledText as="h4" className="py-2">
              Rest assured, the responses will be completely anonymous.
            </StyledText>
          </StyledFont>
          <div className="d-flex gap-2 gap-md-3 pt-md-4 pt-2 ">
            <StyledButton
              type="button"
              className="btn-white"
              onClick={onStartDeiForm}
              loading={isLoadingDei}
              disabled={isLoadingDei}
            >
              Get Started
            </StyledButton>

            <StyledButton
              type="button"
              className="btn-outline-white bg-transparent text-white"
              onClick={onDeclineSubmission}
              loading={isLoadingDeiDecline}
              disabled={isLoadingDeiDecline}
            >
              No, thanks
            </StyledButton>
          </div>

        </div>
      );
    } else if (message === 'You already completed this form' || isCompleted) {
      return (
        <FormPublicErrorView
          heading="That’s all the questions! Thanks!"
          icon={<FormSuccessIcon />}
        />
      );
    } else if (
      message ===
      'This form is expired. Please contact support for more details' ||
      formExpired
    ) {
      return (
        <FormPublicErrorView
          heading="This form has passed the expiry date"
          description="This form cannot be filled in now as it has passed the expiration date. "
          icon={<FormExpiryIcon />}
        />
      );
    } else if (!isReadOnly && !publicForm?.refOpts?.isAccepting) {
      return (
        <FormPublicErrorView
          heading="This form has no longer accepting any responses."
          description="We are no longer accepting any responses. Please try again later."
          icon={<FormExpiryIcon />}
        />
      );
    } else if (publicForm) {
      return (
        <>
          {getLogo(publicForm)}
          {getBanner(publicForm)}
          <StyledFontSize>
            <section className="d-flex justify-content-center py-2 py-md-3 pb-1 pb-md-1">
              <StyledText as="h1">{publicForm?.title || ''}</StyledText>
            </section>
            <section className="d-flex justify-content-center pb-2 ">
              <StyledText as="h3">{publicForm?.introduction || ''}</StyledText>
            </section>
          </StyledFontSize>
          <StyledForm className="public-form">
            <Formik
              initialValues={{ ...(formInitialValues?.values || {}) }}
              onSubmit={handleSubmit}
              validationSchema={
                publicForm?.viewType === 'single' && !isReadOnly
                  ? yup
                    .object()
                    .shape(
                      [publicForm?.formQuestions[currentIndex]]?.reduce(
                        createYupSchema,
                        {},
                      ),
                    )
                  : ''
              }
              enableReinitialize
            >
              {({ handleSubmit, values }) => (
                <Form onSubmit={handleSubmit}>
                  <FormPublicControlList
                    controls={publicForm?.formQuestions || []}
                    isReadOnly={isReadOnly}
                    setCurrentIndex={setCurrentIndex}
                    currentIndex={currentIndex}
                    viewType={publicForm.viewType}
                    publicForm={publicForm}
                  />
                  {isShowNextPrev(
                    publicForm?.viewType,
                    currentIndex,
                    publicForm?.formQuestions?.length,
                  ) && (
                      <StyledWebButton>
                        <div className="d-flex justify-content-between">
                          {currentIndex > 0 && (
                            <StyledButtonForm
                              type="button"
                              className=" bg-transparent arrow-button"
                              variant={'white'}
                              onClick={handlePrevClick}
                            >
                              &lt; Previous
                            </StyledButtonForm>
                          )}
                          {currentIndex < publicForm?.formQuestions?.length - 1 && (
                            <StyledButtonForm variant={'white'} type="submit" className="arrow-button bg-transparent">
                              Next &gt;
                            </StyledButtonForm>
                          )}
                        </div>
                      </StyledWebButton>
                    )}
                  {!isReadOnly && (
                    <>
                      <StyledWebButton>
                        {currentIndex === publicForm?.formQuestions?.length - 1 &&
                          publicForm?.viewType === 'single' && (
                            <StyledContainer className={`d-flex justify-content-between`}>
                              {publicForm.viewType === 'single' && (
                                <StyledButtonForm
                                  type="button"
                                  variant={'white'}
                                  className="arrow-button bg-transparent"
                                  onClick={handlePrevClick}
                                  disabled={isLoadingDeiSubmission}
                                >
                                  &lt; Previous
                                </StyledButtonForm>
                              )}
                              <StyledButtonForm
                                type="submit"
                                className="bg-transparent"
                                variant={'white'}
                                loading={isLoading || isLoadingDeiSubmission}
                                disabled={isLoading || isLoadingDeiSubmission}
                              >
                                Submit Form
                              </StyledButtonForm>
                            </StyledContainer>
                          )}
                      </StyledWebButton>
                      {publicForm.viewType !== 'single' && (
                        <div className={` d-flex justify-content-end`}>
                          <StyledButtonForm
                            type="submit"
                            variant={'white'}
                            className="bg-transparent"
                            loading={isLoading || isLoadingDeiSubmission}
                            disabled={isLoading || isLoadingDeiSubmission}
                          >
                            Submit Form
                          </StyledButtonForm>
                        </div>
                      )}

                      <StyledButtons className="align-items-center">
                        <div className={`flex-grow-1 mr-3`}>
                          <StyledProgressbar
                          background={'transparent'}
                          filledColor={publicForm?.compFormSettingId?.color}
                            value={Math.ceil(
                              (size(
                                omitBy(values, (v) => v == null || v?.length < 1),
                              ) /
                                publicForm?.formQuestions?.length) *
                              100,
                            )}
                          />  </div>
                        <div className="buttons-cirlce">
                          {currentIndex > 0 && (
                            <StyledButtonForm variant={'white'} onClick={handlePrevClick} className=" bg-transparent arrow-button mx-2 border rounded-circle" icon={<DropDownIcon rotate={'left'} size={12} color={`${publicForm?.compFormSettingId?.color ? publicForm?.compFormSettingId?.color : 'white'}`} />}>
                            </StyledButtonForm>
                          )}
                          {currentIndex < publicForm?.formQuestions?.length - 1 && (
                            <StyledButtonForm type="submit" variant={'white'} className=" bg-transparent arrow-button  rounded-circle" icon={<DropDownIcon rotate={'right'} size={12} color={`${publicForm?.compFormSettingId?.color ? publicForm?.compFormSettingId?.color : 'white'}`} />}>
                            </StyledButtonForm>
                          )}
                        </div>
                      </StyledButtons>
                    </>
                  )}
                </Form>
              )}
            </Formik>
          </StyledForm>
        </>
      );
    } else return <></>;
  };

  const [formInitialValues, setFormInitialValues] = useState({});
  useEffect(() => {
    let initValues = {};
    publicForm?.formQuestions?.map((question) => {
      initValues = {
        ...initValues,
        values: {
          ...initValues.values,
          [`q_${question.id}`]: question?.answer || '',
        },
      };
      return question;
    });
    setFormInitialValues({
      ...initValues,
    });
  }, [publicForm?.formQuestions]);
  const theme = useMemo(
    () => ({
      ...themes,
      backgroundColor:
        publicForm?.compFormSetting?.backgroundColor ?? themes.primary,
      primary: publicForm?.compFormSetting?.color ?? themes.white,
      fontFamily: publicForm?.compFormSetting?.fontFamily ?? 'inherit',
      dark:'', darkThemBoxBgColor:'', darkLight:''
    }),
    [publicForm?.compFormSetting],
  );
  const getLogo = (formData) => {
    const logo =
      get(formData, 'compFormSetting.logo.signedUrl') ||
      get(formData, 'logoMedia.signedUrl');
    get(formData, 'company.signedUrl');
    return logo ? (
      <section className="d-flex justify-content-center py-2 py-md-3">
        <StyledImage width={'80px'} height={'auto'} className="rounded-3">
          <img src={logo} alt={publicForm || 'form_logo'} />
        </StyledImage>
      </section>
    ) : (
      <></>
    );
  };

  // const yepSchema = publicForm?.formQuestions?.reduce(createYupSchema, {});

  const getBanner = (formData) => {
    const banner =
      get(formData, 'compFormSetting.banner.signedUrl') ||
      get(formData, 'bannerMedia.signedUrl');
    return banner ? (
      <section className="d-flex justify-content-center py-2 py-md-3">
        <StyledImage width={'100%'} height={'auto'} className="rounded-3">
          <img src={banner} alt="form_banner" />
        </StyledImage>
      </section>
    ) : (
      <></>
    );
  };
  const handleSubmit = async (values) => {
    if (!(currentIndex === publicForm?.formQuestions?.length - 1)) {
      handleNextClick();
    } else {
      const formData = new FormData();
      Object.keys(values)?.map((value) => {
        if (Array.isArray(values[value]) ) {
          values[value]?.map((v) => {
            formData.append(
              `${value}${deiForm ? '' : '[]'}`,
              typeof v === 'object' ? JSON.stringify(v) : v,
            );
            return v;
          });
        } else {
          formData.append(value, values[value]);
        }
        return value;
      });
      if (deiForm) {
        formData.append('formApplicationId', publicForm?.appForm?.id);
        formData.append('customFormId', publicForm?.id);
        formData.append('statusId', publicForm?.appForm?.statusId);
        formData.append('shortCode', publicForm?.shortCode);
        formData.append('formTitle', publicForm?.title);
      }
      const { data } = !deiForm
        ? await submitForm({
          token: query?.get('token') || '',
          formData,
        })
        : await submitDeiForm({
          token: query?.get('token') || '',
          formData,
        });
      if (data?.message) {
        toast.success(data?.message || 'Form submitted successfully!');
        if (publicForm?.dei && formTypes.CANDIDATE && data?.DEIFormResp?.statusId !== STATUSES.DECLINED && data?.DEIFormResp?.statusId !== STATUSES.COMPLETED ) {
          setStartDeiForm(true);
          renderFormScreens(error?.data?.message, publicForm);
        } else {
          setIsCompleted(true);
        }
      }
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <StyledFormContainer
        className="min-vh-100"
        fontFamily={publicForm?.compFormSetting?.fontFamily}
        publicForm
        data-bs-theme='light'
      >
        <Container>
          <Row >
            <Col className="justify-content-center py-3 p-md-4">
              {renderFormScreens(error?.data?.message, publicForm)}
            </Col>
          </Row>
        </Container>
      </StyledFormContainer>
    </ThemeProvider>
  );
};
const StyledFontSize = styled.div`
${(props) => props.theme.md} {
  h1 {
    font-size: 20px;
  }
  h3 {
    font-size: 16px;
  }
}
`;
const StyledFont = styled.div`
${(props) => props.theme.md} {
  h1{
   font-size: 22px;
  }
  h4{
    font-size: 16px;
  }
}

`;
const StyledImg = styled.div`
  .webSize {
    display: block; 
  }

  .mobileSize {
    display: none; 
  }

  ${(props) => props.theme.md} {
    .webSize {
      display: none; 
    }
  
    .mobileSize {
      display: block; 
    }
  }
`;
const StyledContainer = styled.div`
  margin-bottom: 100px;
`;
const StyledButtons = styled.div`
display: flex;
position: fixed;
    bottom: 35px;
    right: 20px;
.buttons-cirlce{
  .iconButton{
    border: 1px solid;
    border-radius: 50%;
  }
  display:none;
  ${(props) => props.theme.md} {
    display: flex !important;
  }
}
`;
const StyledWebButton = styled.div`
 margin-bottom: 100px !important;
${(props) => props.theme.md} {
  .arrow-button{
    display: none;
  }
}
`;
const StyledForm = styled.div`
${(props) => props.theme.md} {
  margin-bottom: 100px !important;
}
`;

const StyledButtonForm = styled(StyledButton)`
background-color: transparent;
border-color: ${({theme}) => theme?.primary} !important;
color: ${({theme}) => theme?.primary} !important;
`
export default PublicForm;