import React from 'react';
import { useState } from 'react';
import { useQuery } from '../../../utils/helper';
import { useEffect } from 'react';
import { useAcceptOrRejectcandidateOfferMutation, useGetcandidateOfferDetailsQuery } from '../../OffersAndAgreements/OffersAndAgreementsApiSlice';
import OfferView from './OfferView';
import OfferAcception from './OfferAcception';
const NonPresignedOfferContainer = () => {
  const [offerLetter, setOfferLetter] = useState('');
  const query = useQuery();
  const token = query?.get('token');
  const { data: jobOffer, refetch, isLoading: responceLoading } = useGetcandidateOfferDetailsQuery(token);
  const [rejectOffer, { isLoading }] = useAcceptOrRejectcandidateOfferMutation();

  useEffect(() => {
    if (offerLetter === '') refetch();
  }, [offerLetter]);

  return  offerLetter === 'Continue' ? (
    <OfferAcception
      jobOffer={jobOffer}
      title='Job Offer'
      description={'Please find the details of our job offer below. We can’t wait for you to join the team'}
      responceLoading={responceLoading}
      rejectOffer={rejectOffer}
      token={token}
      setOfferLetter={setOfferLetter}
      isLoading={isLoading}
    />
  ) : (
    <OfferView
      setOfferLetter={setOfferLetter}
      jobOffer={jobOffer}
      token={token}
      rejectOffer={rejectOffer}
      isLoading={isLoading}
      refetch={refetch}
      offerLetter={offerLetter}
      responceLoading={responceLoading}
    />
  );
};

export default NonPresignedOfferContainer;
