import React, { useEffect } from 'react';
import { ThemeProvider } from 'styled-components';
import themes from '../utils/themes';
import App from '../App';
// import { occyLs } from '../utils/localStorage';
import darkThemes from '../utils/darkThemes';
import { useSelector } from 'react-redux';
import { getAppMode } from '../features/auth/authSlice';
import { SkeletonTheme } from 'react-loading-skeleton';
import { useLocation } from 'react-router-dom';
import { isPublicPage } from '../utils/helper';

const ThemeContext = () => {
  const location = useLocation(); // H
  const darkMode = useSelector(getAppMode);
  useEffect(() => {
    const currentPath = location.pathname;
    document.documentElement.setAttribute(
      'data-theme',
      isPublicPage(currentPath) ? 'light' :darkMode ? 'dark' : 'light',
    );
    document.documentElement.setAttribute(
      'data-bs-theme',
      isPublicPage(currentPath) ? 'light' : darkMode ? 'dark' : 'light',
    );
  }, [darkMode, location.pathname]);

  return (
    <div >
      <SkeletonTheme
        baseColor={
          darkMode &&  !isPublicPage(location?.pathname) ? darkThemes?.skeltonBaseColor : themes?.skeltonBaseColor
        }
        highlightColor={
          darkMode &&  !isPublicPage(location?.pathname) 
            ? darkThemes?.skeltonHighlightColor
            : themes?.skeltonHighlightColor
        }
      >
        <ThemeProvider theme={darkMode &&  !isPublicPage(location?.pathname)  ? darkThemes : themes || {}}>
          <App />
        </ThemeProvider>
      </SkeletonTheme>
    </div>
  );
};

export default ThemeContext;
