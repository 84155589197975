import { uuidv4 } from '../../../utils/helper';
import { fileStatuses } from '../../../utils/FileStatuses';
import PdfIcon from '../../../app/components/svgIcons/PdfIcon';
import MovieIcon from '../../../app/components/svgIcons/MovieIcon';
import ImgIcon from '../../../app/components/svgIcons/ImgIcon';
import DocsIcon from '../../../app/components/svgIcons/DocsIcon';
import { STATUSES } from '../../../utils/Statuses';
import * as Yup from 'yup';

export const phoneRegExp = /^(\+\d{1,3}[- .]?)?\d{10,14}$/;
// jobIds: Yup.array().min(1).of(Yup.string().trim().required('Required')),
export const candidateDefaultValues = {
  id: uuidv4(),
  firstName: '',
  mobileCountryCode: '+44',
  phoneCountryCode: '+44',
  lastName: '',
  email: '',
  phone: '',
  jobId: '',
  mobile: '',
  address: '',
  postcode: '',
  coverLetter: '',
  key: '',
  source: "",
  jhpId:''
};

export const applicationModalTabs = [
  {
    id: 1,
    title: `Overview`,
    active: true,
  },
  {
    id: 2,
    title: 'Communication',
    active: false,
  },
  {
    id: 3,
    title: 'Files',
    active: false,
  },

  {
    id: 4,
    title: 'Evaluations',
    active: false,
  },
  {
    id: 5,
    title: 'Timeline',
    active: false,
  },
  {
    id: 6,
    title: 'Interviews',
    active: false,
  },
  {
    id: 7,
    title: 'Forms',
    active: false,
  },
];

export const viewCandidatesTab = [
  {
    id: 1,
    title: `Overview`,
    active: true,
  },
  {
    id: 2,
    title: 'Jobs',
    active: false,
  },
  {
    id: 3,
    title: 'Communication',
    active: false,
  },
  {
    id: 4,
    title: 'Files',
    active: false,
  },
  {
    id: 5,
    title: 'Timeline',
    active: false,
  },
  {
    id: 6,
    title: 'Evaluations',
    active: false,
    // tabsDisbaled: true,
  },
  {
    id: 7,
    title: 'Forms',
    active: false,
  },
];
export const sortFilters = [
  {
    id: 1,
    label: 'None',
    value: undefined,
  },
  {
    id: 2,
    label: 'Applied - Latest to Oldest',
    value: 'applied-latest-to-oldest',
  },
  {
    id: 3,
    label: 'Applied - Oldest to Latest',
    value: 'applied-oldest-to-latest',
  },
  {
    id: 4,
    label: 'Ratings- Low to High',
    value: 'ratings-low-to-high',
  },
  {
    id: 5,
    label: 'Ratings- High to Low',
    value: 'ratings-high-to-low',
  },
  {
    id: 6,
    label: 'Last Activity',
    value: 'updatedAt',
  },
];

export const kanbanInitialFilters = {
  page: 1,
  perPage: 20,
  query: '',
  coordinates: undefined,
  rating: undefined,
  isNoRating: false,
  //radius: 50,
  lastActivity: undefined,
  appDate: undefined,
  sourcedFrom: undefined,
  sort: undefined,
  declined: 0,
  formStatus: undefined,
  sortedColumn: { path: 'name', order: 'asc' },
};

export const docTypes = [
  {
    label: 'All files',
    value: 'all',
  },
  {
    label: 'CV',
    value: fileStatuses.CV,
  },
  {
    label: 'Cover Letter',
    value: fileStatuses.COVER,
  },
  {
    label: 'Documents',
    value: fileStatuses.DOC,
  },
];

export const fileTypes = [
  {
    label: 'All types',
    value: 'all',
  },
  {
    label: 'DOC',
    value: 'doc',
  },
  {
    label: 'PDF',
    value: 'pdf',
  },
  {
    label: 'MOV',
    value: 'mov',
  },
];

export const fileFilters = {
  type: 'all',
  docType: 'all',
};

export const getQueryByIdObj = (obj) =>
  Object.entries(obj)
    ?.map(([k, v]) => `${k}=${v}`)
    ?.join('&');

export const getAllkeysQuery = (obj) => Object.values(obj).join('/');

export const getFileSize = (size) => {
  let i = size === 0 ? 0 : Math.floor(Math.log(size) / Math.log(1024));
  return (
    (size / Math.pow(1024, i)).toFixed(2) * 1 +
    ' ' +
    ['B', 'kB', 'MB', 'GB', 'TB'][i]
  );
};

export const getIcon = (path) => {
  switch (path) {
    case `${path?.includes('pdf')}`:
      return <PdfIcon />;
    case `${path?.includes('mov')}`:
      return <MovieIcon />;
    case `${path?.includes('img')}`:
      return <ImgIcon />;

    default:
      return <DocsIcon />;
  }
};

export const formTypes = [
  // {
  //   label: 'All Forms',
  // },
  {
    label: 'Candidate Form',
    value: 1,
  },
  // {
  //   label: 'Internal Form',
  //   value: 4,
  // },
];

export const campaignStatuses = [
  {
    label: 'Live',
    value: STATUSES.LIVE,
  },
  {
    label: 'Pending',
    value: STATUSES.PENDING,
  },
  {
    label: 'Closed',
    value: STATUSES.CLOSED,
  },
  {
    label: 'Partially Live',
    value: STATUSES.PARTIALLY_LIVE,
  },
  {
    label: 'Draft',
    value: STATUSES.DRAFT,
  },
  {
    label: 'Scheduled',
    value: STATUSES.SCHEDULED,
  },
  {
    label: 'Suspended',
    value: STATUSES.SUSPENDED,
  },
  {
    label: 'Failed',
    value: STATUSES.FAILED,
  }
];

export const campaignListingSort = [
  {
    id: 1,
    label: 'None',
    value: 'createdAt',
    dir: 'DESC',
    match: 'checked1',
  },
  // {
  //   id: 2,
  //   label: 'Cost (Highest to Lowest)',
  //   value: 'cost',
  //   dir: 'ASC',
  //   match: 'checked2',
  // },
  // {
  //   id: 3,
  //   label: 'Cost (Lowest to Highest)',
  //   value: 'cost',
  //   dir: 'DESC',
  //   match: 'checked3',
  // },
  {
    id: 4,
    label: 'Creation (Newest to Oldest)',
    value: 'createdAt',
    dir: 'DESC',
    match: 'checked4',
  },
  {
    id: 5,
    label: 'Creation (Oldest to Newest)',
    value: 'createdAt',
    dir: 'ASC',
    match: 'checked5',
  },
  {
    id: 6,
    label: 'Name (A to Z)',
    value: 'campaignName',
    dir: 'ASC',
    match: 'checked6',
  },
  {
    id: 7,
    label: 'Name (Z to A)',
    value: 'campaignName',
    dir: 'DESC',
    match: 'checked7',
  },
];
export const rejectionReason = [
  {
    label: 'Poor time keeping',
    value: 1,
  },
  {
    label: 'Appearance',
    value: 2,
  },
];

export const OfferRejectionValidationSchema = Yup.object().shape({
  // reason: Yup.string().required('Required'),
  applicantComment: Yup.string().required('Required'),
  // reject: Yup.boolean().oneOf([true], 'Required'),
});

export const dateSuggestion = [
  {
    id: 1,
    title: '22 Nov 2022 ',
  },
  {
    id: 2,
    title: '30 Nov 2022',
  },
  {
    id: 3,
    title: '15 Nov 2022',
  },
  {
    id: 4,
    title: 'These dates do not work for me',
  },
];

export const datesOffered = [
  {
    id: 1,
    title: '21 Nov 2022',
  },
  {
    id: 2,
    title: '22 Nov 2022',
  },
  {
    id: 3,
    title: '23 Nov 2022',
  },
];
