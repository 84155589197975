import React from 'react';
import styled, { useTheme } from 'styled-components';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import StyledDot from '../../../app/components/styledComponents/StyledDot';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import { getFileNameByFileDescriptor, getPreviewURL, useWindowSize } from '../../../utils/helper';
import { STATUSES } from '../../../utils/Statuses';
import OccyLogoDefault from '../../../app/components/svgIcons/OccyLogoDefault.png';
import { useState } from 'react';
import StyledCheckbox from '../../../app/components/checkbox/StyledCheckbox';
import { getSalaryInformation } from '../../HiringRequests/utils/hiringRequestUtils';
import { toTitleCase } from '../components/hiringListing/hiringUtils';
import DeleteCompanyModal from '../../candidates/creation/components/DeleteCompanyModal';
import NewAttachmentIcon from '../../../app/components/svgIcons/NewAttachmentIcon';
import StyledToolTip from '../../../app/components/styledComponents/StyledToolTip';
import ExportIcon from '../../../app/components/svgIcons/ExportIcon';
import DownloadIcon3 from '../../../app/components/svgIcons/DownloadIcon3';
// import OfferAcceptedModal from './OfferAcceptedModal';
// import Contactus from '../../ReInterviews/components/Contactus';
// import { Link } from 'react-router-dom';

const OfferView = ({
  setOfferLetter,
  jobOffer,
  responceLoading,
  rejectOffer,
  isLoading,
  token,
  refetch,
}) => {
  const theme = useTheme();
  const isSmallScreen = useWindowSize();
  const [state, setState] = useState({
    selectDate: '',
    confirm: false,
  });
  // const [pickDate, setPickDate] = useState('');
  // const [pickDateError, setPickDateError] = useState('');
  const [checkboxError, setCheckboxError] = useState('');
  const [showConfirmationBox, setShowConfirmationBox] = useState(false);
  // const [selectCustomDate, setSelectCustomDate] = useState(false);
  // const [contactUs, setContactUs] = useState(false);

  const handleAcceptOfferLetter = (isAccept = undefined) => {
    if (!state?.confirm) {
      setCheckboxError('Required');
    } else {
      const values = {};
      // values.startDate = state?.selectDate?.offerDate ?? undefined;
      // values.selectedJHPId = state?.selectDate?.jobHiringPlan?.id?? undefined;
      // values.proposedDate = pickDate ? pickDate : undefined;
      values.token = token;
      values.offerStatus = 'ACCEPT';
      //  if (selectCustomDate) values.comments = comment;
      rejectOffer(values).then((res) => {
        if (res?.data?.message === 'Offer accepted') refetch();
      });
    }
  };

  const handleRejectedOffer = () => {
    const values = {};
    values.token = token;
    values.offerStatus = 'REJECT';
    setShowConfirmationBox(false);
    rejectOffer(values).then((res) => {
      if (res?.data?.message === 'Offer rejected') setOfferLetter('');
      refetch();
    });
  };

  const DisplayAttachment = (attachment) => {
    return (
      <Wrap className="d-flex justify-content-between align-items-center mt-3">
        <Wrap className="d-flex align-items-center gap-2">
          <NewAttachmentIcon />
          <StyledLabel weight="500" color={theme.headingColor}>
            {attachment.fileOriginalName || getFileNameByFileDescriptor(attachment.fileDescriptor)}
          </StyledLabel>
        </Wrap>
        <Wrap className="d-flex gap-2">
          {attachment.signedUrl && (
            <StyledToolTip title="View" childrenClass={'ms-0'}>
              <ExportIcon
                className="mx-2 cursor-pointer" // color={theme?.primary}
                onClick={() => {
                  window.open(
                    getPreviewURL(attachment.signedUrl || ''),
                    '_blank',
                  );
                }}
              />
            </StyledToolTip>
          )}
          <a
            className="cursor-pointer text-muted"
            href={attachment.signedUrl}
            target="_blank"
            rel="noreferrer"
          >
            <StyledToolTip title="Download" childrenClass={'ms-0'}>
              <DownloadIcon3 />
            </StyledToolTip>
          </a>
        </Wrap>
      </Wrap>
    );
  };

  // contactUs ? (
  //   <Contactus
  //     setContactUs={setContactUs}
  //     token={token}
  //     rejectOffer={rejectOffer}
  //     setOfferLetter={setOfferLetter}
  //     // contactUs={contactUs}
  //     isLoading={isLoading}
  //   />
  // ) :
  return (
    <Wrap className="d-sm-flex justify-content-sm-center">
      <Wrap className="col-sm col-sm-8 mt-0 mt-sm-5">
        <StyledContainer className="mt-0 mt-md-5 rounded">
          <StyledRoundedBorder className="bg-white">
            <Wrap className="px-5 py-4 rounded-top flex-column d-flex align-items-center align-items-md-start">
              <Wrap className="d-flex justify-content-center w-100 mb-2">
                <img
                  alt="company_logo"
                  src={
                    jobOffer?.document?.company?.compLOGOSignedUrl ||
                    OccyLogoDefault
                  }
                  width="100"
                  height="20%"
                />
              </Wrap>
              <StyledLabel size="24px">Job Offer</StyledLabel>
              <StyledLabel
                weight="500"
                color={theme.lightBoldBlack}
                className="text-center"
              >
                Please find the details of our job offer below. We can’t wait
                for you to join the team
              </StyledLabel>
            </Wrap>
          </StyledRoundedBorder>
       

          <Wrapped className="p-4">
            <StyledPortlet>
              <Wrap className="">
                <Wrap className="d-flex flex-column flex-sm-row gap-sm-2 mb-2">
                  <StyledLabel
                    color={theme.textColor}
                    size={isSmallScreen ? '16px' : '14px'}
                    weight="500px"
                  >
                    Job
                  </StyledLabel>
                  <span className="d-none d-md-block">:</span>
                  <StyledLabel
                    color={theme.primaryDark}
                    className="mt-1 mt-sm-0 cursor-pointer text-decoration-none"
                    // as={Link}
                    // to={getAdvertRedirectUrl(jobOffer?.application?.job?.title, jobOffer?.application?.applicationSourceTracking?.postingId)}
                  >
                    {jobOffer?.application?.job?.title} (
                    {toTitleCase(
                      jobOffer?.application?.applicationSourceTracking
                        ?.jobHiringPlan?.jobType,
                    )}
                    )
                  </StyledLabel>
                </Wrap>
                <hr className="d-sm-none" />
                <Wrap className="d-flex flex-column flex-sm-row gap-sm-2">
                  <StyledLabel
                    size={isSmallScreen ? '16px' : '14px'}
                    weight="500px"
                    color={theme.textColor}
                  >
                    Salary
                  </StyledLabel>
                  <span className="d-none d-md-block">:</span>
                  <StyledLabel
                    color={theme.primaryDark}
                    className="mt-1 mt-sm-0"
                  >
                    {Number(
                      jobOffer?.application?.applicationSourceTracking
                        ?.jobHiringPlan?.salaryFrom,
                    ) > 0
                      ? `${getSalaryInformation(
                          jobOffer?.application?.applicationSourceTracking
                            ?.jobHiringPlan,
                        )}`
                      : 'Competitive/negotiable'}
                  </StyledLabel>
                </Wrap>
                <hr className="d-sm-none" />
              </Wrap>
              <StyledLabel
                weight="500"
                color={theme.textColor}
                className=" my-md-3"
              >
                We are excited to have you onboard. All you need to do now is
                let us know whether you will accept the job and agree a start
                date. We will then let you know the next steps to get you
                started with us.
              </StyledLabel>

              {(jobOffer?.statusId === STATUSES.PASS ||
                jobOffer?.statusId === STATUSES.REJECTED) && (
                <StyledLabel className="d-flex mt-3 mt-md-0">
                  offer already processed
                </StyledLabel>
              )}
            </StyledPortlet>
            {jobOffer?.attachments?.length > 0 || jobOffer?.signedDoc ? (
              <StyledPortlet className="my-4">
                <StyledLabel className="w-100">Attached Documents</StyledLabel>

                <StyledLabel
                  className="mt-2"
                  size="12px"
                  color={theme?.borderColor}
                  weight="500"
                >
                  Download the documents below for future reference
                </StyledLabel>
                {jobOffer?.attachments?.map((attachment, index) => (
                  <>
                    {DisplayAttachment(attachment.item?.media)}
                    <StyledHR className="text-light2" />
                  </>
                ))}
                {jobOffer?.signedDoc ? (
                  <>
                    {DisplayAttachment(jobOffer?.signedDoc)}
                    <StyledHR className="text-light2" />
                  </>
                ) : null}
              </StyledPortlet>
            ) : null}
          
            {/* {jobOffer?.statusId === 16
              && ( */}
            {jobOffer?.statusId !== STATUSES.PASS &&
            jobOffer?.statusId !== STATUSES.REJECTED ? (
              <StyledPortlet className="my-3">
                {/* <StyledLabel weight="500px" className="d-md-none">
                    Choose a date of joining as per your convenience.{' '}
                  </StyledLabel>
                  <StyledLabel className="d-md-block d-none">
                    Select Start Date
                  </StyledLabel> */}

                {/* <Wrap className="mt-3">
                    {jobOffer?.jobOfferHiringPlans?.map(
                      (option, index, array) => (
                        <div
                          className={`py-2 px-3 rounded-3 ${state?.selectDate?.id ===
                            option?.id
                            ? 'bg-lightPrimary'
                            : ''
                            }`}
                          key={`radio-${index}-${option?.id}`}
                        >
                          <StyledRadioButton
                            className={
                              pickDateError ? 'border-danger text-danger' : ''
                            }
                            label={moment(option?.offerDate).format(
                              'DD MMM YYYY',
                            )}
                            id={`${index}-${option?.id}`}
                            value={
                              state?.selectDate?.id ===
                              option?.id
                            }
                            size={12}
                            disabled={false}
                            onChange={() => {
                              setState({ ...state, selectDate: option });
                              setSelectCustomDate(false);
                              setPickDate('');
                              setPickDateError('');
                            }}
                          />
                        </div>
                      ),
                    )}
                    <Wrap
                      className={`px-3 py-2  rounded ${selectCustomDate ? 'bg-lightPrimary' : ''
                        }`}
                    >
                      <StyledRadioButton
                        label="These dates don’t work for me"
                        value={selectCustomDate}
                        size={12}
                        disabled={false}
                        onChange={() => {
                          setState({ ...state, selectDate: '' });
                          setSelectCustomDate(true);
                          setPickDate(selectCustomDate ? new Date() : '');
                        }}
                      />
                    </Wrap>
                  </Wrap> */}

                <hr className="d-none d-sm-block" />

                <Wrap className="d-flex align-items-start align-items-md-center gap-2 mb-4 mt-3 mt-md-0">
                  <StyledCheckbox
                    className={
                      checkboxError
                        ? 'border-danger text-danger mt-1 mt-md-0'
                        : 'mt-1 mt-md-0'
                    }
                    size={16}
                    value={state?.confirm}
                    label="I confirm I will be accepting this job offer and start date
                      and will await confirmation of the next steps."
                    onChange={() => {
                      setState({ ...state, confirm: !state?.confirm });
                      setCheckboxError('');
                    }}
                  />
                </Wrap>
                {/* {
                  selectCustomDate ? (
                    <StyledWrap className="px-3 px-md-0 py-4 py-md-0 bg-white d-flex gap-2">
                      <StyledButtonContainer onClick={() => setContactUs(true)}>
                        Contact Us
                      </StyledButtonContainer>
                    </StyledWrap>
                  ) : (
                    !responceLoading && jobOffer ? ( */}
                <StyledWrap className="px-3 px-md-0 py-4 py-md-0 bg-white d-flex gap-2">
                  <StyledButtonContainer
                    onClick={() => {
                      setState({ ...state, currentProcess: 'Accept' });
                      handleAcceptOfferLetter(true);
                    }}
                    disabled={isLoading}
                    loading={isLoading && state?.currentProcess === 'Accept'}
                  >
                    Accept
                  </StyledButtonContainer>
                  <StyledButtonContainer
                    variant="white"
                    disabled={isLoading}
                    loading={isLoading && state?.currentProcess === 'Reject'}
                    onClick={() => {
                      setState({ ...state, currentProcess: 'Reject' });
                      setShowConfirmationBox(true);
                    }}
                  >
                    Reject
                  </StyledButtonContainer>
                </StyledWrap>

                {/* ) : null

                  )
                  
                  } */}
              </StyledPortlet>
            ) : null}
            {/* )} */}

            <StyledPortlet className="d-flex flex-column my-3">
              <StyledLabel className="mb-2">For queries contact</StyledLabel>
              <StyledLabel
                color={theme.primary}
                className="mt-2 text-capitalize"
              >
                {`${jobOffer?.account?.firstName ?? ''}${' '}${
                  jobOffer?.account?.lastName ?? ''
                }`}
              </StyledLabel>
              <Wrap className="d-flex flex-column flex-sm-row align-items-sm-center gap-sm-2">
                <StyledLabel
                  size={'12px'}
                  color={theme.lightBoldBlack}
                  weight="500"
                >
                  {jobOffer?.account?.email ?? ''}
                </StyledLabel>
                {!responceLoading && jobOffer ? (
                  <StyledDot $size={'4px'} className=" d-none d-sm-block" />
                ) : null}
                <StyledLabel
                  size={'12px'}
                  color={theme.lightBoldBlack}
                  weight="500"
                >
                  {jobOffer?.account?.phone ?? ''}
                </StyledLabel>
              </Wrap>
            </StyledPortlet>
          </Wrapped>
        </StyledContainer>
      </Wrap>
      <DeleteCompanyModal
        show={showConfirmationBox}
        onHide={() => {
          setShowConfirmationBox(false);
        }}
        onSubmit={() => handleRejectedOffer()}
        pipeline
        submitButtonLoading="Rejected"
        title="Rejected Offer"
        description={`Are you sure you want to reject this offer?`}
      />
    </Wrap>
  );
};

const StyledWrap = styled(Wrap)`
  ${(props) => props.theme.sm} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  z-index: 999;
`;

const StyledButtonContainer = styled(StyledButton)`
  ${(props) => props.theme.sm} {
    width: 100%;
  }
`;

const Wrapped = styled(Wrap)`
  ${(props) => props.theme.sm} {
    margin-bottom: 70px;
  }
`;

const StyledContainer = styled(Wrap)`
  ${(props) => props.theme.gtSm} {
    background-color: ${({theme}) => theme.inputBorder};
  }
`;

const StyledPortlet = styled(PortletBody)`
  ${(props) => props.theme.gtSm} {
    border: 1px solid ${({theme}) => theme.borderLight};
  }
`;

const StyledHR = styled.hr`
  border-color: ${({theme}) => theme?.borderColor};
`;

const StyledRoundedBorder = styled(Wrap)`
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  ${(props) => props.theme.gtSm} {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export default OfferView;
