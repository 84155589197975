import React from 'react';

const DotIcon = ({ size = 6,color="#DBD0D9", className, ...props }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 5 6"
    fill="none"
    className={className}
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <rect y="0.5" width="5" height="5" rx="2.5" fill={color} />
  </svg>
);

export default DotIcon;
