import { createSlice } from '@reduxjs/toolkit';
import { occyLs } from '../utils/localStorage';


const isReadOnly = occyLs.getObject('isReadOnly') || false;

const slice = createSlice({
  name: 'isReadOnly',
  initialState: {
    isReadOnly: isReadOnly,
  },
  reducers: {
    setOOOPermission: (state, { payload }) => {
      state.isReadOnly = { payload };
      occyLs.setObject('isReadOnly',  payload );   
    },
  },
});

export const { setOOOPermission } =
  slice.actions;

export default slice.reducer;

export const getOOOPermissionState = (state) => state.readOnly.isReadOnly;
