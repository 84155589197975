import React from 'react';

const LimitedPermissionIcon = ({ size = 15, ...props }) => (
    <svg width={size} height={size} viewBox="0 0 170 141" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_666_29755)">
    <path d="M136.846 129.292C136.846 129.292 136.069 134.974 136.846 136.522C137.623 138.069 141.495 132.65 141.363 129.292C141.232 125.934 137.083 126.974 136.846 129.292Z" fill="url(#paint0_linear_666_29755)"/>
    <path d="M162.671 130.51C162.671 130.51 161.071 138.003 162.283 140.433C163.494 142.863 169.953 135.771 169.749 130.51C169.545 125.249 163.04 126.882 162.664 130.51H162.671Z" fill="url(#paint1_linear_666_29755)"/>
    <path d="M59.5623 56.2793C64.3061 56.1459 67.956 49.0745 67.7146 40.4848C67.4732 31.895 63.4318 25.0398 58.688 25.1731C53.9442 25.3065 50.2944 32.3779 50.5358 40.9676C50.7772 49.5573 54.8185 56.4126 59.5623 56.2793Z" fill="#ED018C"/>
    <path d="M87.232 73.0467C88.8913 73.1586 90.6033 73.185 92.3877 73.1323C93.1844 73.1126 93.9811 73.0731 94.7713 73.0204C116.895 71.5389 134.114 58.2381 133.567 38.5636C133.027 19.3105 115.75 3.96854 94.2314 2.89526C87.0674 5.91756 85.8954 3.19157 85.8954 3.19157C65.6414 5.77928 55.4749 21.727 56.0082 40.7431C56.535 59.555 67.2743 71.8022 87.232 73.0467Z" fill="url(#paint2_linear_666_29755)"/>
    <path d="M87.2327 73.0469C88.892 73.1589 90.6039 73.1852 92.3883 73.1325C93.1851 73.1128 93.9818 73.0733 94.7719 73.0206C83.3412 59.6079 78.0999 46.1689 79.1732 33.046C80.4506 17.4868 90.4262 6.53015 94.232 2.88892C87.0681 5.91121 85.896 3.18522 85.896 3.18522C80.8786 9.16396 74.439 19.2449 73.3328 32.4797C72.2002 45.978 76.8752 59.5948 87.2327 73.0338V73.0469Z" fill="#BA157F"/>
    <path d="M117.574 39.0114C117.804 47.1499 112.563 54.5245 104.286 59.2785C111.503 54.5311 115.974 47.6569 115.763 40.1242C115.381 26.4482 99.6966 15.7878 80.7398 16.3212C74.0697 16.5055 68.4597 18.0397 64.002 20.5484C68.7033 17.3813 74.9388 15.4191 82.5505 15.2084C101.501 14.675 117.185 25.3354 117.567 39.0114H117.574Z" fill="#AD93A4"/>
    <path d="M115.769 40.1242C115.98 47.6503 111.509 54.5311 104.292 59.2786C98.6627 62.5116 91.6304 64.533 83.9529 64.7437C65.0026 65.2771 53.9801 54.485 53.5982 40.809C53.3678 32.631 56.9893 25.2695 63.9886 20.5682C63.9886 20.5616 64.0018 20.555 64.0084 20.555C68.4661 18.0463 74.0761 16.5121 80.7462 16.3278C99.703 15.7944 115.387 26.4548 115.769 40.1308V40.1242Z" fill="url(#paint3_linear_666_29755)"/>
    <path d="M115.769 40.1242C115.98 47.6503 111.509 54.5311 104.292 59.2786C98.6627 62.5116 91.6304 64.533 83.9529 64.7437C65.0026 65.2771 53.9801 54.485 53.5982 40.809C53.3678 32.631 56.9893 25.2695 63.9886 20.5682C63.9886 20.5616 64.0018 20.555 64.0084 20.555C68.4661 18.0463 74.0761 16.5121 80.7462 16.3278C99.703 15.7944 115.387 26.4548 115.769 40.1308V40.1242Z" fill="url(#paint4_linear_666_29755)"/>
    <path d="M129.677 54.3095C134.42 54.1762 138.07 47.1048 137.829 38.515C137.587 29.9253 133.546 23.0701 128.802 23.2034C124.058 23.3367 120.409 30.4082 120.65 38.9979C120.891 47.5876 124.933 54.4429 129.677 54.3095Z" fill="#BA157F"/>
    <path d="M132.549 52.0622C136.635 51.9473 139.779 45.8604 139.571 38.4666C139.363 31.0728 135.882 25.1721 131.797 25.2869C127.711 25.4018 124.567 31.4887 124.775 38.8825C124.983 46.2763 128.463 52.177 132.549 52.0622Z" fill="url(#paint5_linear_666_29755)"/>
    <path d="M67.8717 42.6878C68.2013 39.3914 66.0661 36.4789 63.1026 36.1826C60.139 35.8862 57.4694 38.3183 57.1398 41.6148C56.8102 44.9112 58.9454 47.8237 61.9089 48.12C64.8725 48.4164 67.5421 45.9843 67.8717 42.6878Z" fill="#ED018C"/>
    <path d="M62.0471 40.6603C62.1444 39.6869 61.5164 38.8271 60.6443 38.7399C59.7723 38.6527 58.9864 39.3711 58.8891 40.3445C58.7918 41.3179 59.4198 42.1776 60.2919 42.2648C61.1639 42.352 61.9498 41.6336 62.0471 40.6603Z" fill="white"/>
    <path d="M92.9971 40.914C93.3857 37.0277 90.87 33.5942 87.3782 33.2451C83.8863 32.896 80.7406 35.7633 80.352 39.6496C79.9634 43.5359 82.4791 46.9693 85.971 47.3185C89.4628 47.6676 92.6085 44.8002 92.9971 40.914Z" fill="#ED018C"/>
    <path d="M86.1395 38.5318C86.2542 37.3848 85.5112 36.3713 84.4799 36.2682C83.4486 36.1651 82.5196 37.0114 82.4049 38.1584C82.2902 39.3055 83.0333 40.3189 84.0645 40.4221C85.0958 40.5252 86.0248 39.6789 86.1395 38.5318Z" fill="white"/>
    <path d="M151.47 128.508C139.236 120.462 134.732 118.526 134.732 118.526L132.856 123.958C132.856 123.958 138.663 125.46 139.039 129.391C139.414 133.328 122.742 133.335 111.127 132.953C99.5119 132.577 97.2666 122.839 106.07 109.723L103.634 105.232C103.634 105.232 101.764 111.408 93.52 110.473C91.7685 110.276 90.0038 109.867 88.2919 109.235C81.9378 106.924 76.3476 101.65 75.1623 93.2416C73.9837 84.8397 84.0844 89.653 88.4433 92.0893C89.5561 92.7148 90.2936 93.1823 90.3857 93.235C90.3199 93.0045 88.9701 88.4283 88.4565 80.3557C88.4433 80.2174 88.4367 80.0725 88.4367 79.9343C88.4367 76.5169 91.9463 74.0477 92.1636 73.8963C92.1701 73.8963 92.1767 73.8963 92.1767 73.8963C93.0591 73.6526 93.9414 73.3695 94.7776 73.0337C110.31 71.9933 123.427 65.1323 129.669 54.3205C129.807 54.0834 129.946 53.8398 130.077 53.5962C130.15 53.8201 130.222 54.0374 130.288 54.2612C134.502 67.3776 138.466 78.8083 141.488 81.8372C145.676 86.0184 152.438 95.0326 154.045 104.047C155.658 113.061 166.279 120.146 167.569 126.263C168.853 132.38 163.704 136.561 151.47 128.515V128.508Z" fill="#57A3FF"/>
    <path d="M132.224 60.1871C112.088 62.676 109.376 58.7912 109.007 57.7903C109 57.764 108.994 57.7376 108.987 57.7113C108.981 57.6784 108.974 57.6455 108.961 57.606C108.954 57.5796 108.954 57.5664 108.954 57.5664C107.19 50.0667 104.121 40.2558 99.6833 37.4376C92.2098 32.6967 83.222 33.5395 86.4418 23.5574C86.4418 23.5574 78.2243 18.8956 82.5108 13.5819C86.7908 8.26817 100.928 7.0237 105.115 7.88627C109.297 8.74884 119.417 20.2652 125.376 38.8137C127.687 46.0304 130.005 53.4972 132.224 60.1871Z" fill="#BA157F"/>
    <path d="M88.2919 109.229C81.9378 106.918 76.3476 101.644 75.1623 93.2354C73.9837 84.8336 84.0844 89.6469 88.4433 92.0831C88.5026 92.4255 90.2211 102.862 88.2919 109.229Z" fill="url(#paint6_linear_666_29755)"/>
    <path d="M108.987 57.7051C108.987 57.7051 110.87 68.8856 112.721 72.5137L132.224 60.1809C132.224 60.1809 115.565 60.4113 108.987 57.7051Z" fill="#57A3FF"/>
    <path d="M3.14121 75.9905H50.8395C52.5449 75.9905 53.8683 74.5287 53.7235 72.8299C53.3416 68.3063 51.2477 60.53 41.338 60.6024C40.7059 60.6024 40.1725 60.1284 40.1199 59.4962C39.8697 56.4081 38.2367 48.1511 27.978 48.1511C16.6592 48.1511 13.5645 57.2509 15.4016 63.2099C15.6584 64.0396 14.967 64.8626 14.1044 64.7573C10.1866 64.2832 2.05477 64.3424 0.296702 72.527C-0.0852001 74.3114 1.31072 75.9905 3.14121 75.9905Z" fill="#C2E5FF"/>
    <path d="M40.9294 87.579H68.7227C74.3656 87.579 74.7673 79.1113 69.1375 78.7294C69.0124 78.7228 68.8873 78.7163 68.7556 78.7097C66.7539 78.6241 65.0485 77.2018 64.6271 75.2396C63.7514 71.1572 60.8081 65.2048 51.1157 65.2048C37.1565 65.2048 29.6633 76.438 37.5055 85.979C38.3417 86.9996 39.6059 87.5856 40.9294 87.5856V87.579Z" fill="#E6F5FF"/>
    <path d="M126.114 23.2944H153.907C159.55 23.2944 159.952 14.8267 154.322 14.4448C154.197 14.4382 154.072 14.4316 153.94 14.425C151.938 14.3394 150.233 12.9172 149.812 10.955C148.936 6.87257 145.993 0.920166 136.3 0.920166C122.341 0.920166 114.848 12.1534 122.69 21.6943C123.526 22.7149 124.79 23.3009 126.114 23.3009V23.2944Z" fill="#C2E5FF"/>
    </g>
    <defs>
    <linearGradient id="paint0_linear_666_29755" x1="136.504" y1="131.972" x2="141.37" y2="131.972" gradientUnits="userSpaceOnUse">
    <stop stopColor="#ED018C"/>
    <stop offset="1" stopColor="#C70180"/>
    </linearGradient>
    <linearGradient id="paint1_linear_666_29755" x1="161.835" y1="134.033" x2="169.763" y2="134.033" gradientUnits="userSpaceOnUse">
    <stop stopColor="#ED018C"/>
    <stop offset="1" stopColor="#C70180"/>
    </linearGradient>
    <linearGradient id="paint2_linear_666_29755" x1="132.401" y1="30.2093" x2="56.1788" y2="44.8321" gradientUnits="userSpaceOnUse">
    <stop stopColor="#ED018C"/>
    <stop offset="1" stopColor="#C70180"/>
    </linearGradient>
    <linearGradient id="paint3_linear_666_29755" x1="54.2471" y1="45.9783" x2="114.897" y2="34.3428" gradientUnits="userSpaceOnUse">
    <stop stopColor="white"/>
    <stop offset="1" stopColor="#FFEEFF"/>
    </linearGradient>
    <linearGradient id="paint4_linear_666_29755" x1="92.5254" y1="17.2605" x2="76.0096" y2="64.8469" gradientUnits="userSpaceOnUse">
    <stop stopColor="white"/>
    <stop offset="0.46" stopColor="#FDFCFD"/>
    <stop offset="0.79" stopColor="#F8F2FA"/>
    <stop offset="1" stopColor="#F3E8F7"/>
    </linearGradient>
    <linearGradient id="paint5_linear_666_29755" x1="124.698" y1="40.1201" x2="139.655" y2="37.2506" gradientUnits="userSpaceOnUse">
    <stop stopColor="#ED018C"/>
    <stop offset="1" stopColor="#C70180"/>
    </linearGradient>
    <linearGradient id="paint6_linear_666_29755" x1="75.0702" y1="98.8586" x2="89.2071" y2="98.8586" gradientUnits="userSpaceOnUse">
    <stop stopColor="#ED018C"/>
    <stop offset="1" stopColor="#C70180"/>
    </linearGradient>
    <clipPath id="clip0_666_29755">
    <rect width="169.525" height="140" fill="white" transform="translate(0.237305 0.920166)"/>
    </clipPath>
    </defs>
    </svg>
    
);

export default LimitedPermissionIcon;
