import React from 'react';

const JobsIcon = ({ size = 24, color = 'currentColor', ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 25"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M7.99983 22.895H15.9998C20.0198 22.895 20.7398 21.285 20.9498 19.325L21.6998 11.325C21.9698 8.88502 21.2698 6.89502 16.9998 6.89502H6.99983C2.72983 6.89502 2.02983 8.88502 2.29983 11.325L3.04983 19.325C3.25983 21.285 3.97983 22.895 7.99983 22.895Z"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8 6.89502V6.09502C8 4.32502 8 2.89502 11.2 2.89502H12.8C16 2.89502 16 4.32502 16 6.09502V6.89502"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14 13.895V14.895C14 14.905 14 14.905 14 14.915C14 16.005 13.99 16.895 12 16.895C10.02 16.895 10 16.015 10 14.925V13.895C10 12.895 10 12.895 11 12.895H13C14 12.895 14 12.895 14 13.895Z"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.65 11.895C19.34 13.575 16.7 14.575 14 14.915"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.62012 12.165C4.87012 13.705 7.41012 14.635 10.0001 14.925"
        stroke={color}
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default JobsIcon;
