import React from 'react';
import { useTheme } from 'styled-components';

const MultipleChoiceIcon = ({ size = 16, ...props }) => {
 const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="24" height="24" rx="4" fill={theme?.dark ? theme?.statusTagNew : "#dcf1f9"} fillOpacity="0.65" />
      <path
        d="M18.3334 7.56675C18.3334 8.17341 18.0468 8.71341 17.6068 9.06008C17.2868 9.31341 16.8801 9.46675 16.4334 9.46675C15.3801 9.46675 14.5334 8.62008 14.5334 7.56675C14.5334 7.12008 14.6868 6.72008 14.9401 6.39341H14.9468C15.2868 5.95341 15.8268 5.66675 16.4334 5.66675C17.4868 5.66675 18.3334 6.51341 18.3334 7.56675Z"
        stroke={theme?.dark ? "#688fc6" : "#3D5F91"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46675 7.56675C9.46675 8.62008 8.62008 9.46675 7.56675 9.46675C7.12008 9.46675 6.72008 9.31341 6.39341 9.06008C5.95341 8.71341 5.66675 8.17341 5.66675 7.56675C5.66675 6.51341 6.51341 5.66675 7.56675 5.66675C8.17341 5.66675 8.71341 5.95341 9.06008 6.39341C9.31341 6.72008 9.46675 7.12008 9.46675 7.56675Z"
        stroke={theme?.dark ? "#688fc6" : "#3D5F91"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3334 16.4334C18.3334 17.4868 17.4868 18.3334 16.4334 18.3334C15.8268 18.3334 15.2868 18.0468 14.9468 17.6068H14.9401C14.6868 17.2868 14.5334 16.8801 14.5334 16.4334C14.5334 15.3801 15.3801 14.5334 16.4334 14.5334C16.8801 14.5334 17.2801 14.6868 17.6068 14.9401V14.9468C18.0468 15.2868 18.3334 15.8268 18.3334 16.4334Z"
        stroke={theme?.dark ? "#688fc6" : "#3D5F91"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46675 16.4334C9.46675 16.8801 9.31341 17.2801 9.06008 17.6068C8.71341 18.0534 8.17341 18.3334 7.56675 18.3334C6.51341 18.3334 5.66675 17.4868 5.66675 16.4334C5.66675 15.8268 5.95341 15.2868 6.39341 14.9468V14.9401C6.71341 14.6868 7.12008 14.5334 7.56675 14.5334C8.62008 14.5334 9.46675 15.3801 9.46675 16.4334Z"
        stroke={theme?.dark ? "#688fc6" : "#3D5F91"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3334 12.0001C18.3334 13.0667 18.0734 14.0601 17.6067 14.9401C17.2867 14.6867 16.8801 14.5334 16.4334 14.5334C15.3801 14.5334 14.5334 15.3801 14.5334 16.4334C14.5334 16.8801 14.6867 17.2801 14.9401 17.6067C14.0601 18.0734 13.0667 18.3334 12.0001 18.3334C10.9401 18.3334 9.94008 18.0734 9.06008 17.6067C9.31341 17.2867 9.46675 16.8801 9.46675 16.4334C9.46675 15.3801 8.62008 14.5334 7.56675 14.5334C7.12008 14.5334 6.72008 14.6867 6.39341 14.9401C5.92675 14.0601 5.66675 13.0667 5.66675 12.0001C5.66675 10.9401 5.92675 9.94008 6.39341 9.06008C6.72008 9.31341 7.12008 9.46675 7.56675 9.46675C8.62008 9.46675 9.46675 8.62008 9.46675 7.56675C9.46675 7.12008 9.31341 6.72008 9.06008 6.39341C9.94008 5.92675 10.9401 5.66675 12.0001 5.66675C13.0667 5.66675 14.0601 5.92675 14.9401 6.39341C14.6867 6.71341 14.5334 7.12008 14.5334 7.56675C14.5334 8.62008 15.3801 9.46675 16.4334 9.46675C16.8801 9.46675 17.2801 9.31341 17.6067 9.06008C18.0734 9.94008 18.3334 10.9401 18.3334 12.0001Z"
        stroke={theme?.dark ? theme?.sourceColor : "#5F82B6"}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default MultipleChoiceIcon;
