import React from 'react';
import { ErrorMessage, Field } from 'formik';
import Proptypes from 'prop-types';
import DatePickerComponent from '../datePicker/DatePickerComponent';
import { formErrorMessage } from './FormErrorMessage';
import { isArray } from 'lodash';

const FormikDatePicker = ({ name, ...props }) => (
  <Field name={name}>
    {({
      field: { value },
      form: { setFieldValue, isReadOnly, touched, errors },
    }) => (
      <>
        <DatePickerComponent
          onChange={(date) => {
            setFieldValue(name, date);
          }}
          isReadOnly={isReadOnly}
          date={
            value && !isArray(value)
              ? new Date(value)
              : value && isArray(value)
              ? value
              : 'Select a Date'
          }
          isValid={touched[name] && !errors[name]}
          isInvalid={touched[name] && errors[name]}
          {...props}
        />
        <ErrorMessage name={name} render={formErrorMessage} />
      </>
    )}
  </Field>
);
FormikDatePicker.propTypes = {
  name: Proptypes.string.isRequired,
};
export default React.memo(FormikDatePicker);
