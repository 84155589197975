import { createSlice } from '@reduxjs/toolkit';

const slice = createSlice({
  name: 'mailCreation',
  initialState: {
    composeEmailView: '',
    totalEmailCounts: '',
    mailsLoading:false,
  },
  reducers: {
    setComposeEmailView: (state, { payload: { composeEmail } }) => {
      state.composeEmailView = { composeEmail };
    },
    setTotalEmailCounts: (state, { payload: { emailCounts } }) => {
      state.totalEmailCounts = emailCounts;
    },
    setMailsLoading: (state, { payload: { isLoading } }) => {
      state.mailsLoading = isLoading;
    },
  },
});

export const {
  setComposeEmailView,
  resetComposeEmailView,
  setTotalEmailCounts,
  setMailsLoading,
} = slice.actions;

export default slice.reducer;

export const getMailCreationState = (state) =>
  state.createEmail.composeEmailView;
export const getMailCounts = (state) => state.createEmail.totalEmailCounts;
export const getMailsLoading = (state) => state.createEmail.mailsLoading;
