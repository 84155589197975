import React from 'react';
import Select from 'react-select';
import styled, { useTheme } from 'styled-components';
import StyledColoredTag from '../styledComponents/StyledColoredTag';
import StyledSelectCss from '../styledComponents/StyledSelectCss';
import defaultTheme from '../../../utils/themes';

const StyledSelect = ({
  isMulti,
  options = [],
  value = '',
  placement,
  onChange,
  disabled = false,
  className = '',
  type = '',
  icon,
  ...props
}) => {
  const themes = useTheme();
  let newValue = null;
  if (value && !isMulti) {
    newValue = {
      value,
      label:options.find((o) => o.value === value)?.label || value,
    };
  }
  else newValue=value
  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? themes?.dark? themes.primary : themes.primaryLight
        : provided.backgroundColor,
      borderRadius: props.borderRadius ? 10 : 8,
      padding: '10px 15px',
      color: themes.textColor,
      cursor: 'pointer',
    }),
  };
  const optionalData = (option) => {
    return (
      type === 'iconLabel' ?
        <div className="d-flex gap-2 align-items-center">
          {option?.label?.icon} <label>{option?.label?.text}</label>
        </div> :
        <div className="d-flex justify-content-between">
          <label>{option?.label}</label>
          {option?.data && (
            <StyledColoredTag variant={option?.color}>
              {option?.data}
            </StyledColoredTag>
          )}
        </div>
    );
  };

  return (
    <CustomSelect className={icon && icon ? 'analytics' : ''} publicForm={props.publicForm}>
      {icon && icon}
      <StyledSelectCss>
        <Select
          isMulti={isMulti}
          menuPlacement={placement ? placement : 'auto'}
          isDisabled={disabled}
          options={options?.map((option) => option)}
          getOptionLabel={(option) => optionalData(option)}
          getOptionValue={(option) => option?.label}
          styles={customStyles}
          theme={(theme) => ({
            ...theme,
            borderRadius: props.borderRadius || 10,
            colors: {
              ...theme.colors,
              primary25: themes?.dark && !props.publicForm  ? theme?.primary : themes.lightHover,
              primary50:  themes.primaryLight,
            },
          })}
          value={newValue}
          onChange={onChange}
          className={`async-select ${className} 
          ${props.isValid ? 'is-valid' : ''}
          ${props.isLightPink ? 'is-light-pink' : ''}
          ${props.pay ? ' is-light-pink pay' : ''}
        ${props.application ? 'application' : ''}
        ${props.workFlow ? 'workFlow' : ''}
        ${props.publicForm ? 'publicForm' : ''}
        ${props.analytics ? 'analytics' : ''}
         ${props.isInvalid ? 'is-invalid' : ''} 
         ${disabled ? 'disabled' : ''}`}
          {...props}
        />
      </StyledSelectCss>
    </CustomSelect>
  );
};

const CustomSelect = styled.div`
&.analytics{ 
  >div {
    width:100%;
  }
  display:flex;
  align-items:center;
  cursor:pointer;
  background: ${({ publicForm, theme}) =>  !publicForm ? theme?.darkThemBoxBgColor || defaultTheme.white : defaultTheme.white};
  font-size:14px;
  border: 1px solid ${({theme}) =>theme.borderLight};
  border-radius: 8px;
  width:170px;
  margin-top:5px;
  max-width:170px;
  height:34px;
  padding-block:0px;
  &:hover, &:active, &:focus {
        border: 1px solid ${({theme}) =>theme.inputBorder};
        background: ${({theme, publicForm}) =>theme?.dark && !publicForm? theme?.primary : defaultTheme.primaryLight};
        color:${({theme}) =>theme?.primary};
          box-shadow: none;
        }

}
`

export default StyledSelect;
