import { omit } from 'lodash';
import { apiSlice } from '../../app/services/apiSlice';

const MAIL_URL = 'mail';

export const mailboxApi = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    sendEmail: builder.mutation({
      query: (data) => ({
        url: `${data?.isCompany ? 'company/' : ''}${MAIL_URL}`,
        method: 'POST',
        body: {
          ...omit(data, 'isCompany'),
        },
      }),
    }),
    sendEmailApplication: builder.mutation({
      query: (data) => ({
        url: `applications/contact`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    deleteMailAttachment: builder.mutation({
      query: (params) => ({
        url: `${MAIL_URL}/attachment`,
        method: 'DELETE',
        params,
      }),
    }),

    removeMailsItem: builder.mutation({
      query: (params) => ({
        url: `${MAIL_URL}/${params}`,
        method: 'DELETE',
        // params,
      }),
    }),
    removeCompanyMailsItem: builder.mutation({
      query: (params) => ({
        url: `company/${MAIL_URL}/${params}`,
        method: 'DELETE',
        // params,
      }),
    }),
    getOneThread: builder.mutation({
      query: (data) => ({
        url: `${data?.isCompanyMail ? 'company/' : ''}${MAIL_URL}/${data.id}`,
      }),
    }),

    getAllMails: builder.mutation({
      query: (params) => ({
        url: `${MAIL_URL}`,
        params,
      }),
    }),

    getAllCompanyMails: builder.mutation({
      query: (params) => ({
        url: `company/${MAIL_URL}`,
        params,
      }),
    }),

    preSignedMailUrl: builder.mutation({
      query: (data) => ({
        url: `${MAIL_URL}/preSignedMailAttachment`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),

    getUnread: builder.query({
      query: () => ({
        url: `${MAIL_URL}/unRead`,
      }),
    }),
  }),
});

export const {
  useSendEmailApplicationMutation,
  useGetAllMailsMutation,
  useGetOneThreadMutation,
  useRemoveMailsItemMutation,
  useRemoveCompanyMailsItemMutation,
  usePreSignedMailUrlMutation,
  useSendEmailMutation,
  useDeleteMailAttachmentMutation,
  useGetUnreadQuery,
  useGetAllCompanyMailsMutation,
} = mailboxApi;
