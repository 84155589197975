import { Modal } from 'react-bootstrap';
import React, { Fragment } from 'react';
import LimitedPermissionIcon from '../app/components/svgIcons/LimitedPermissionIcon';
import { StyledLabel, Wrap } from '../assets/styledComponents/Global';
import CrossIcon from '../app/components/svgIcons/CrossIcon';

const LimitedPermissionModal = ({ show, onHide, size }) => {
  return (
    <Modal show={show} backdrop="static" centered onHide={onHide} size={'lg'}>
    <Modal.Body className="d-flex flex-column bg-body rounded-3 align-items-center">
    <Wrap className="d-flex justify-content-end w-100">
        <CrossIcon className="ms-auto cursor-pointer" size={19} onClick={()=>onHide()}/>
      </Wrap>
    <Fragment>
          <LimitedPermissionIcon size={140}/>
          <StyledLabel weight={600} size={'24px'} className="mt-3">
          Limited Permissions!
          </StyledLabel>
          <StyledLabel weight={400} size={'16px'} className='pb-5'>
          While you're out of office, you have read-only access to the platform.
          </StyledLabel>

        </Fragment>
    </Modal.Body>
  </Modal>
  );
};

export default LimitedPermissionModal;
