import React from 'react';
import { useTheme } from 'styled-components';

const FormRadioButtonIcon = () => {
  const theme = useTheme();
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="24"
        height="24"
        rx="4"
        fill={theme?.dark ? theme?.statusTagCompleted : '#D8E6C6'}
        fillOpacity="0.65"
      />
      <path
        d="M18.3333 7.56666C18.3333 8.17332 18.0467 8.71332 17.6067 9.05999C17.2867 9.31332 16.88 9.46666 16.4333 9.46666C15.38 9.46666 14.5333 8.61999 14.5333 7.56666C14.5333 7.11999 14.6867 6.71999 14.94 6.39332H14.9467C15.2867 5.95332 15.8267 5.66666 16.4333 5.66666C17.4867 5.66666 18.3333 6.51332 18.3333 7.56666Z"
        stroke={theme?.dark ? theme?.toastSuccessIconColor : '#497908'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46669 7.56666C9.46669 8.61999 8.62002 9.46666 7.56669 9.46666C7.12002 9.46666 6.72002 9.31332 6.39335 9.05999C5.95335 8.71332 5.66669 8.17332 5.66669 7.56666C5.66669 6.51332 6.51335 5.66666 7.56669 5.66666C8.17335 5.66666 8.71335 5.95332 9.06002 6.39332C9.31335 6.71999 9.46669 7.11999 9.46669 7.56666Z"
        stroke={theme?.dark ? theme?.toastSuccessIconColor : '#497908'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 16.4333C18.3333 17.4867 17.4867 18.3333 16.4333 18.3333C15.8267 18.3333 15.2867 18.0467 14.9467 17.6067H14.94C14.6867 17.2867 14.5333 16.88 14.5333 16.4333C14.5333 15.38 15.38 14.5333 16.4333 14.5333C16.88 14.5333 17.28 14.6867 17.6067 14.94V14.9467C18.0467 15.2867 18.3333 15.8267 18.3333 16.4333Z"
        stroke={theme?.dark ? theme?.toastSuccessIconColor : '#497908'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.46669 16.4333C9.46669 16.88 9.31335 17.28 9.06002 17.6067C8.71335 18.0533 8.17335 18.3333 7.56669 18.3333C6.51335 18.3333 5.66669 17.4867 5.66669 16.4333C5.66669 15.8267 5.95335 15.2867 6.39335 14.9467V14.94C6.71335 14.6867 7.12002 14.5333 7.56669 14.5333C8.62002 14.5333 9.46669 15.38 9.46669 16.4333Z"
        stroke={theme?.dark ? theme?.toastSuccessIconColor : '#497908'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3334 12C18.3334 13.0667 18.0734 14.06 17.6067 14.94C17.2867 14.6867 16.88 14.5333 16.4334 14.5333C15.38 14.5333 14.5334 15.38 14.5334 16.4333C14.5334 16.88 14.6867 17.28 14.94 17.6067C14.06 18.0733 13.0667 18.3333 12 18.3333C10.94 18.3333 9.94002 18.0733 9.06002 17.6067C9.31335 17.2867 9.46669 16.88 9.46669 16.4333C9.46669 15.38 8.62002 14.5333 7.56669 14.5333C7.12002 14.5333 6.72002 14.6867 6.39335 14.94C5.92669 14.06 5.66669 13.0667 5.66669 12C5.66669 10.94 5.92669 9.93999 6.39335 9.05999C6.72002 9.31332 7.12002 9.46666 7.56669 9.46666C8.62002 9.46666 9.46669 8.61999 9.46669 7.56666C9.46669 7.11999 9.31335 6.71999 9.06002 6.39332C9.94002 5.92666 10.94 5.66666 12 5.66666C13.0667 5.66666 14.06 5.92666 14.94 6.39332C14.6867 6.71332 14.5334 7.11999 14.5334 7.56666C14.5334 8.61999 15.38 9.46666 16.4334 9.46666C16.88 9.46666 17.28 9.31332 17.6067 9.05999C18.0734 9.93999 18.3334 10.94 18.3334 12Z"
        stroke={theme?.dark ? theme?.toastSuccessBorderColor : '#77A33C'}
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default FormRadioButtonIcon;
