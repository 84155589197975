import { useEffect } from 'react';
import { useLoginWithCalenderQuery } from './CalenderApiSlice';
import { OC_CALENDAR_TYPE } from '../../calender/assets/calenderutils';
// import {setCredentials, sideMenuState, useLoginWithOutlookQuery } from './authSlice'

const OutlookComponent = () => {
  function paramsToObject(entries) {
    const result = {}
    for (const [key, value] of entries) {
      result[key] = value;
    }
    return result;
  }
  const urlParams = new URLSearchParams(window.location.search);
  const entries = urlParams.entries();
  const params = paramsToObject(entries);
  const { data: token } = useLoginWithCalenderQuery({ code: params.code, state: params.state,provider:OC_CALENDAR_TYPE.OFFICE365  },
    {
      skip: !params.code,
    },);

  useEffect(() => {
    if (token) {
      window.onunload = function (e) { window.opener.onunload(token) }
      window.close();
    }
  }, [token])

  return 'Loading'

};

export default OutlookComponent;