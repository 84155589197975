export const STATUSES = {
  NULL: 0,
  PENDING: 1,
  NEW: 1,
  LIVE: 2,
  SUSPENDED: 3,
  DELETE: 4,
  ACCEPTED: 5,
  DRAFT: 6,
  ARCHIVED: 7,
  DECLINED: 12,
  APPROVED: 14,
  REJECTED: 15,
  ACTIVE: 16,
  INACTIVE: 17,
  FAILED: 19,
  CLOSED: 21,
  SENT: 22,
  COMPLETED: 45,
  AWAITING_FEEDBACK: 41,
  CANCELLED: 36,
  EXPIRED: 63,
  PASS: 55,
  PARSED: 53,
  JOB_TEMPLATE: 52,
  SCHEDULED: 101,
  PARTIALLY_LIVE: 102,
  COPYWRITING: 104,
};
export const JOB_STATUSES = {
  PENDING: 1,
  LIVE: 2,
  DRAFT: 6,
  CLOSED: 21,
  ACTIVE: 16,

};
export const QUALIFY_STATUSES = {
  DISQUALIFY: 1,
}
export const TEMPLATES = {
  NULL: 0,
  draft: 6,
  standard: 7,
  custom: 8,
};
export const MESSAGE_TYPES = {
  SMS: 1,
  EMAIL: 2,
};
export const interviewActions = {
  Rejected: 15,
  Rescheduled: 101,
  Accepted: 107
}
export const EventAttendanceStatus = {
  ACCEPTED: 107,
  TENTATIVELY: 108,
  DECLINED: 109,
  NO_RESPONSE: 110,
}
export const FORM_TYPES = {
  CANDIDATE: 1,
  INTERNAL: 4,
};

export const MEDIA_FILE_TYPES = {
  VIDEO: 1,
  DOCUMENTS: 2,
  ATTACHMENTS: 3,
};

export const JOB_COPY_WRITING = {
  REQUESTED: 'REQUESTED',
  IN_PROGRESS: 'INPROGRESS',
  IN_REVIEW: 'INREVIEW',
  COMPLETED: 'COMPLETED',
  REVISION_REQUESTED: 'REVISIONREQUESTED',
};


export const EVENT_SOURCE = {
  OCCY: "OCCY",
  GOOGLE: "GOOGLE",
  OUTLOOK: "OUTLOOK",
}

export const EVENT_PROVIDER = {
  GOOGLE: "Google Meet",
  OUTLOOK: "Microsoft Office",
}