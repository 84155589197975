import { useEffect } from 'react';
import {useOutlookClaimGrantQuery } from './authSlice'
import { useNavigate } from 'react-router-dom';

const OutlookComponentLogin = () => {
  const query = new URLSearchParams(window.location.search);
  const nav = useNavigate();
  const { data: token, error } = useOutlookClaimGrantQuery({ code: query.get('code') },
    {
      skip: !query.get('code'),
    },);
  useEffect(() => {
    if (token) {
      window.onunload = function (e) { window.opener.onunload(token) }
      window.close();
    }
  }, [token]);

  useEffect(() => {
    if (error) {
      window.onunload = function (e) { window.opener.onunload(error) }
      window.close();
      nav(`/`);
    }
  }, [error]);

  if (!query.get('code')) {
    return nav('/');
  }

  return 'Loading'

};

export default OutlookComponentLogin;