import { createSlice } from '@reduxjs/toolkit';


const slice = createSlice({
    name: 'accounts',
    initialState: {
        eventType: 1
    },
    reducers: {
        setEventType: (state, { payload: values }) => {
            state.eventType = values
        },

    },
});

export const { setEventType } = slice.actions;

export default slice.reducer;

export const getEventType = (state) => state?.accounts?.eventType;