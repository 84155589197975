import { Form, Formik } from 'formik';
import React from 'react';
import { useState } from 'react';
import styled, { useTheme } from 'styled-components';
import FormikTextEditor from '../../../app/components/formikComponents/FormikTextEditor';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import StyledDot from '../../../app/components/styledComponents/StyledDot';
import BackArrowIcon from '../../../app/components/svgIcons/BackArrowIcon';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import { OfferRejectionValidationSchema } from '../assets/kanbanUtill';
import FeedBackModal from './FeedBackModal';
import { useWindowSize } from '../../../utils/helper';
const JobOfferRejection = ({
  rejectOffer,
  token,
  jobOffer,
  isLoading,
  setOfferLetter,
}) => {
  const theme = useTheme();
  const isSmallScreen = useWindowSize();
  const [showFeedback, setShowFeedBack] = useState(false);
  const handleSubmit = (values) => {
    values.token = token;
    values.reject = true;
    rejectOffer(values).then(
      (resp) =>
        resp?.data?.message === 'Offer rejected' && setShowFeedBack(true),
    );
  };

  return (
    <>
      <Wrap className="d-sm-flex justify-content-sm-center ">
        <StyledContainer className="col-sm-9 rounded mt-0 mt-sm-5 mt-md-5">
          {/* <Wrap className="bg-gray rounded-3 "> */}
          <StyledRoundedBorder className="bg-white px-3 py-3 rounded-top flex-column">
            <Wrap className="d-sm-flex align-items-sm-center">
              <BackArrowIcon
                className="d-sm-none mb-2"
                onClick={() => setOfferLetter('')}
              />
              <StyledLabel size="24px" className="ms-4 ms-sm-3">
                Reject Offer
              </StyledLabel>
            </Wrap>
          </StyledRoundedBorder>
          {/* </Wrap> */}
          <Wrap className="p-4">
            <StyledPortlet className="mb-4">
              <Formik
                initialValues={{
                  // reason: '',
                  applicantComment: '',
                  // reject: false,
                }}
                onSubmit={handleSubmit}
                validationSchema={OfferRejectionValidationSchema}
              >
                {({ handleSubmit, values }) => (
                  <Form onSubmit={handleSubmit}>
                    {/* <div className="mb-3">
                      <label htmlFor="reason" className="form-label">
                        Reason for rejection
                      </label>
                      <FormikSelect
                        name="reason"
                        id="reason"
                        options={rejectionReason}
                      />
                    </div> */}
                    <div className="mb-3">
                      <label htmlFor="applicantComment" className="form-label">
                        Comments
                      </label>
                      <FormikTextEditor
                        placeholder="Is there something you would like to add?"
                        name="applicantComment"
                        id="applicantComment"
                        toolbar={isSmallScreen && 'none'}
                        borderTop="2px"
                      />
                    </div>
                    {/* <Wrap className="mb-4">
                      <FormikCheckBox
                        name="reject"
                        label="I would like to be notified about future position openings"
                      />
                    </Wrap> */}
                    <StyledWrap className='bg-white py-4 px-3 p-sm-0'>
                      <StyledButtonContainer
                        type="submit"
                        disabled={isLoading}
                        loading={isLoading}
                      >
                        Submit
                      </StyledButtonContainer>
                    </StyledWrap>

                  </Form>
                )}
              </Formik>
            </StyledPortlet>

            <StyledPortlet className="d-flex flex-column">
              <StyledLabel className="mb-2">For queries contact:</StyledLabel>
              <StyledLabel color={theme.textColor}>
                {`${jobOffer?.jobOfferDetails?.application?.job?.account
                    ?.firstName
                  }${' '}${jobOffer?.jobOfferDetails?.application?.job?.account?.lastName
                  }`}
              </StyledLabel>
              <Wrap className="d-flex flex-column  flex-md-row align-items-md-center gap-md-2 flex-wrap mt-2 mt ms-0">
                <StyledLabel
                  color={theme.lightBoldBlack}
                  size="12px"
                  weight="500px"
                >
                  {jobOffer?.jobOfferDetails?.application?.job?.account?.email}
                </StyledLabel>
                {isSmallScreen ? null : <StyledDot />}
                <StyledLabel
                  color={theme.lightBoldBlack}
                  size="12px"
                  weight="500px"
                >
                  {jobOffer?.jobOfferDetails?.application?.job?.account?.phone} 1234567890
                </StyledLabel>
              </Wrap>
            </StyledPortlet>
          </Wrap>
        </StyledContainer>
      </Wrap>
      <FeedBackModal
        show={showFeedback}
        onHide={() => setShowFeedBack(false)}
        setOfferLetter={setOfferLetter}
        jobOffer={jobOffer}
      />
    </>
  );
};

const StyledContainer = styled(Wrap)`
  ${(props) => props.theme.gtSm} {
    background-color: ${({theme}) => theme.inputBorder};
  }
`;

const StyledWrap = styled(Wrap)`
  ${(props) => props.theme.sm} {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
  }

  z-index: 999;
`;

const StyledButtonContainer = styled(StyledButton)`
  ${(props) => props.theme.sm} {
    width: 100%;
  }
`;

const StyledPortlet = styled(PortletBody)`
  ${(props) => props.theme.gtSm} {
    border: 1px solid ${({theme}) => theme.borderLight};
  }
`;

const StyledRoundedBorder = styled(Wrap)`
  border-bottom-left-radius: 24px;
  border-bottom-right-radius: 24px;

  ${(props) => props.theme.gtSm} {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
`;

export default JobOfferRejection;
