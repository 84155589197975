import DropDownIcon from '../svgIcons/DropDownIcon';
import ReactPaginate from 'react-paginate';
import DropdownItem from 'react-bootstrap/DropdownItem';
import CustomDropdown from '../dropdown/CustomDropDown';
import './_pagination.scss';
import { useDispatch } from 'react-redux';
import { setPagination } from './paginationSlice';
import { valueToLocaleString } from '../../../features/analytics/assets/analyticsUtil';

const PaginationPerPage = ({
  perPage,
  onPerPageChange,
  pageNo,
  onPageChange,
  totalCount,
  access_key,
  paginate
}) => {
  const dispatch = useDispatch();
  const pageSizes = [5, 10, 20, 50];
  return (
    <div className="flex-center justify-content-between justify-content-sm-end flex-wrap gap-3 gap-lg-0">
      <div className="flex-center me-lg-3 pb-md-0">
        <h6 className="m-0 text-muted">
          Showing : {pageNo * perPage - perPage + 1} -{' '}
          {pageNo * perPage > totalCount ? totalCount : pageNo * perPage} of
          <span className="ms-1 me-2">{valueToLocaleString(totalCount)}</span>
        </h6>
        <CustomDropdown
          dropdownToggle={
            <span className="flex-center p-2">
              <span className="me-1 me-lg-2 text-muted">{perPage}</span>
              <DropDownIcon rotate="down" size={'8px'} />
            </span>
          }
          toggleClassName="bg-tagColor p-0 ms-2"
          menuClassName={'page'}
          dropdownMenu={
            <>
              {pageSizes?.map((p) => (
                <DropdownItem key={p} onClick={(perPage,page) => { onPerPageChange(perPage); dispatch(setPagination({ ...paginate, perPage: perPage.target.innerText,reset:false, access_key })); }}>
                  {p}
                </DropdownItem>
              ))}
            </>
          }
        />
      </div>

      <ReactPaginate
        pageCount={Math.ceil(totalCount / perPage) || 1}
        onPageChange={onPageChange}
        forcePage={pageNo - 1 || 0}
        marginPagesDisplayed={1}
        containerClassName="pagination__container"
        pageLinkClassName="pagination__page"
        previousClassName="pagination__page-prev-next"
        nextClassName="pagination__page-prev-next"
        previousLabel={<DropDownIcon rotate="left" size={'13px'} />}
        nextLabel={<DropDownIcon rotate="right" size={'13px'} />}
        activeLinkClassName="pagination__page-active"

      />
    </div>
  );
};

export default PaginationPerPage;
