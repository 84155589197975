import React from 'react';
import { Modal } from 'react-bootstrap';
import ContactUsFeedbackIcon from '../../../app/components/svgIcons/ContactUsFeedbackIcon';
import CrossIcon from '../../../app/components/svgIcons/CrossIcon';
import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import styled from 'styled-components';

const ContactusModal = ({ show, onHide }) => {
  return (
    <ModalWrap
      show={show}
      centered
      onHide={onHide}
      size={'lg'}
      backdrop="static"
      className="bg-black bg-opacity-50"
    >
      <Modal.Header className="d-flex align-items-end justify-content-end border-0 bg-white">
        <CrossIcon onClick={onHide} className="cursor-pointer" />
      </Modal.Header>
      <Modal.Body className="d-flex flex-column rounded">
        <Wrap className="d-flex align-items-center justify-content-center flex-column my-4">
          <ContactUsFeedbackIcon />
          <StyledLabel size="20px" className="mt-5 mb-2 text-center">
            Your message has been submitted{' '}
          </StyledLabel>
          <StyledLabel weight="500px" className="text-center">
            Our hiring team personnel will get in touch with you shortly
          </StyledLabel>

          {/* <StyledButton variant="white" className="mt-4" onClick={onHide}>
            Close
          </StyledButton> */}
        </Wrap>
      </Modal.Body>
    </ModalWrap>
  );
};


const ModalWrap = styled(Modal)`
.modal-content{
background-color : ${({theme}) => theme.bodyBg} !important;
}
.modal-header{
 background-color : ${({theme}) => theme.white} !important;
}
`
export default ContactusModal;
