import React from 'react';

const NewAttachmentIcon = ({ size = 16, color = '#CCB2C8' }) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.668 6.79301H11.7413C10.1613 6.79301 8.87464 5.50634 8.87464 3.92634V1.99967C8.87464 1.63301 8.57464 1.33301 8.20797 1.33301H5.3813C3.32797 1.33301 1.66797 2.66634 1.66797 5.04634V10.953C1.66797 13.333 3.32797 14.6663 5.3813 14.6663H10.6213C12.6746 14.6663 14.3346 13.333 14.3346 10.953V7.45967C14.3346 7.09301 14.0346 6.79301 13.668 6.79301Z"
      fill={color}
    />
    <path
      d="M10.5318 1.47365C10.2585 1.20032 9.78516 1.38699 9.78516 1.76699V4.09365C9.78516 5.06699 10.6118 5.87365 11.6185 5.87365C12.2518 5.88032 13.1318 5.88032 13.8852 5.88032C14.2652 5.88032 14.4652 5.43365 14.1985 5.16699C13.2385 4.20032 11.5185 2.46032 10.5318 1.47365Z"
      fill={color}
    />
  </svg>
);

export default NewAttachmentIcon;
