import React from 'react';

const ContactUsFeedbackIcon = ({ size = 24 , ...props }) => {
  return (
    <svg
    width="203"
    height="160"
    viewBox="0 0 203 160"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_109_3773)">
      <path
        d="M124.6 142.108L119.197 147.102L123.491 139.868C120.108 133.708 114.575 128.385 114.575 128.385C114.575 128.385 103.097 139.427 103.097 148.107C103.097 156.787 108.236 159.818 114.575 159.818C120.915 159.818 126.054 156.787 126.054 148.107C126.054 146.175 125.484 144.126 124.6 142.108Z"
        fill="#F2F2F2"
      />
      <path
        d="M117.807 147.373V147.801C117.782 152.774 116.946 156.654 115.307 159.387C115.284 159.427 115.259 159.465 115.235 159.505L115.052 159.393L114.877 159.283C116.695 156.332 117.339 152.162 117.362 147.824C117.364 147.684 117.366 147.542 117.364 147.4C117.358 145.564 117.246 143.706 117.066 141.902C117.054 141.762 117.039 141.62 117.024 141.478C116.775 139.097 116.417 136.823 116.05 134.846C116.024 134.707 115.997 134.567 115.97 134.429C115.335 131.087 114.687 128.666 114.539 128.13C114.522 128.064 114.512 128.028 114.51 128.02L114.71 127.963L114.712 127.96L114.915 127.903C114.917 127.912 114.953 128.033 115.012 128.257C115.238 129.096 115.824 131.373 116.4 134.376C116.425 134.512 116.453 134.652 116.478 134.791C116.777 136.395 117.071 138.187 117.303 140.068C117.362 140.54 117.414 141.006 117.461 141.466C117.478 141.607 117.493 141.749 117.505 141.889C117.699 143.84 117.8 145.668 117.807 147.373Z"
        fill="white"
      />
      <path
        d="M116.4 134.376C116.259 134.395 116.115 134.414 115.97 134.429C115.574 134.47 115.177 134.491 114.78 134.491C113.087 134.493 111.414 134.114 109.886 133.381C109.797 133.493 109.709 133.605 109.618 133.72C111.226 134.507 112.991 134.916 114.78 134.914C115.204 134.914 115.628 134.891 116.05 134.846C116.193 134.832 116.337 134.813 116.478 134.791C117.645 134.623 118.779 134.278 119.843 133.768C119.752 133.652 119.663 133.537 119.577 133.425C118.57 133.898 117.5 134.218 116.4 134.376Z"
        fill="white"
      />
      <path
        d="M117.461 141.465C117.316 141.474 117.17 141.478 117.025 141.478C116.98 141.48 116.934 141.48 116.89 141.48C115.055 141.48 113.247 141.035 111.62 140.182C109.994 139.329 108.597 138.093 107.549 136.581C107.464 136.706 107.38 136.831 107.298 136.958C108.39 138.488 109.83 139.735 111.498 140.595C113.166 141.455 115.014 141.904 116.89 141.904C116.949 141.904 117.008 141.904 117.067 141.902C117.214 141.9 117.36 141.895 117.505 141.889C119.758 141.773 121.929 141.009 123.76 139.686C123.693 139.561 123.625 139.436 123.555 139.311C121.776 140.61 119.658 141.358 117.461 141.465Z"
        fill="white"
      />
      <path
        d="M117.808 147.373C117.66 147.386 117.512 147.394 117.365 147.4C117.207 147.407 117.048 147.411 116.89 147.411C114.424 147.408 112.026 146.603 110.054 145.117C108.082 143.631 106.642 141.544 105.95 139.167C105.856 139.339 105.761 139.508 105.67 139.68C106.449 142.051 107.953 144.115 109.967 145.579C111.981 147.043 114.404 147.832 116.89 147.835C117.048 147.835 117.207 147.833 117.363 147.824C117.512 147.82 117.66 147.811 117.808 147.801C120.79 147.566 123.573 146.203 125.592 143.986C125.547 143.829 125.497 143.675 125.446 143.518C123.496 145.751 120.756 147.134 117.808 147.373Z"
        fill="white"
      />
      <path
        d="M27.966 56.4598C43.2591 56.4598 55.6566 44.0115 55.6566 28.6557C55.6566 13.2999 43.2591 0.851517 27.966 0.851517C12.6729 0.851517 0.275391 13.2999 0.275391 28.6557C0.275391 44.0115 12.6729 56.4598 27.966 56.4598Z"
        fill="#E4E4E4"
      />
      <path
        d="M27.9661 51.8258C40.7104 51.8258 51.0416 41.4522 51.0416 28.6557C51.0416 15.8592 40.7104 5.48553 27.9661 5.48553C15.2219 5.48553 4.89062 15.8592 4.89062 28.6557C4.89062 41.4522 15.2219 51.8258 27.9661 51.8258Z"
        fill="#4A154B"
      />
      <path
        d="M101.5 56.4598C116.793 56.4598 129.191 44.0115 129.191 28.6557C129.191 13.2999 116.793 0.851517 101.5 0.851517C86.2071 0.851517 73.8096 13.2999 73.8096 28.6557C73.8096 44.0115 86.2071 56.4598 101.5 56.4598Z"
        fill="#E4E4E4"
      />
      <path
        d="M101.5 51.8258C114.245 51.8258 124.576 41.4522 124.576 28.6557C124.576 15.8592 114.245 5.48553 101.5 5.48553C88.7561 5.48553 78.4248 15.8592 78.4248 28.6557C78.4248 41.4522 88.7561 51.8258 101.5 51.8258Z"
        fill="white"
      />
      <path
        d="M101.525 51.8575C114.269 51.8575 124.6 41.4839 124.6 28.6874C124.6 15.8909 114.269 5.51724 101.525 5.51724C88.7805 5.51724 78.4492 15.8909 78.4492 28.6874C78.4492 41.4839 88.7805 51.8575 101.525 51.8575Z"
        fill="#4A154B"
      />
      <path
        d="M175.033 56.4598C190.326 56.4598 202.724 44.0115 202.724 28.6557C202.724 13.2999 190.326 0.851517 175.033 0.851517C159.74 0.851517 147.343 13.2999 147.343 28.6557C147.343 44.0115 159.74 56.4598 175.033 56.4598Z"
        fill="#E4E4E4"
      />
      <path
        d="M175.033 51.8258C187.778 51.8258 198.109 41.4522 198.109 28.6557C198.109 15.8592 187.778 5.48553 175.033 5.48553C162.289 5.48553 151.958 15.8592 151.958 28.6557C151.958 41.4522 162.289 51.8258 175.033 51.8258Z"
        fill="white"
      />
      <path
        d="M175.188 51.8575C187.932 51.8575 198.263 41.4839 198.263 28.6874C198.263 15.8909 187.932 5.51724 175.188 5.51724C162.444 5.51724 152.112 15.8909 152.112 28.6874C152.112 41.4839 162.444 51.8575 175.188 51.8575Z"
        fill="#4A154B"
      />
      <path
        d="M171.216 37.0874C171.174 37.0874 171.133 37.0864 171.092 37.0844C170.727 37.0667 170.37 36.97 170.046 36.8008C169.721 36.6317 169.438 36.3941 169.213 36.1044L165.674 31.5347C165.262 31.0014 165.077 30.3257 165.16 29.6558C165.244 28.986 165.588 28.3767 166.118 27.9616L166.246 27.8621C166.777 27.4482 167.45 27.2629 168.117 27.3466C168.784 27.4303 169.391 27.7762 169.804 28.3086C170.004 28.5662 170.257 28.7774 170.545 28.9279C170.833 29.0783 171.151 29.1644 171.475 29.1803C171.8 29.1962 172.124 29.1415 172.425 29.0199C172.727 28.8983 172.999 28.7128 173.222 28.4759L180.41 20.8578C180.639 20.6151 180.913 20.42 181.217 20.2837C181.521 20.1474 181.849 20.0727 182.182 20.0636C182.515 20.0546 182.846 20.1115 183.157 20.2311C183.468 20.3507 183.753 20.5306 183.995 20.7606L184.112 20.8723C184.6 21.3367 184.885 21.9769 184.903 22.6519C184.921 23.3269 184.671 23.9816 184.209 24.4718L173.056 36.2922C172.819 36.5435 172.534 36.7436 172.217 36.8803C171.901 37.017 171.56 37.0875 171.216 37.0874Z"
        fill="white"
      />
      <path
        d="M96.6599 37.0345C96.6187 37.0345 96.5774 37.0335 96.536 37.0315C96.1711 37.0138 95.8143 36.9171 95.49 36.7479C95.1658 36.5788 94.8819 36.3412 94.6577 36.0515L91.118 31.4818C90.7058 30.9485 90.5212 30.2728 90.6046 29.6029C90.6879 28.9331 91.0325 28.3238 91.5627 27.9087L91.69 27.8092C92.2212 27.3953 92.8942 27.21 93.5614 27.2937C94.2285 27.3774 94.8354 27.7233 95.2488 28.2557C95.4483 28.5133 95.7009 28.7245 95.9892 28.875C96.2776 29.0254 96.5949 29.1115 96.9195 29.1274C97.244 29.1433 97.5682 29.0886 97.8697 28.967C98.1713 28.8454 98.4431 28.6599 98.6666 28.423L105.854 20.8049C106.083 20.5622 106.357 20.3671 106.662 20.2308C106.966 20.0945 107.294 20.0197 107.626 20.0107C107.959 20.0017 108.291 20.0586 108.602 20.1782C108.913 20.2978 109.197 20.4777 109.439 20.7077L109.556 20.8194C110.045 21.2838 110.329 21.924 110.347 22.599C110.365 23.274 110.116 23.9287 109.653 24.4189L98.5003 36.2393C98.2635 36.4906 97.9782 36.6907 97.6618 36.8274C97.3453 36.9641 97.0044 37.0346 96.6599 37.0345Z"
        fill="white"
      />
      <path
        d="M26.0047 37.0345C25.9634 37.0345 25.9221 37.0335 25.8808 37.0315C25.5158 37.0139 25.159 36.9171 24.8348 36.748C24.5106 36.5788 24.2266 36.3413 24.0024 36.0516L20.4627 31.4819C20.0506 30.9486 19.8659 30.2729 19.9493 29.603C20.0327 28.9332 20.3772 28.3239 20.9074 27.9088L21.0348 27.8092C21.566 27.3954 22.2389 27.21 22.9061 27.2937C23.5732 27.3774 24.1801 27.7234 24.5935 28.2558C24.793 28.5133 25.0456 28.7246 25.3339 28.875C25.6223 29.0255 25.9396 29.1116 26.2642 29.1275C26.5888 29.1434 26.9129 29.0887 27.2145 28.9671C27.516 28.8455 27.7879 28.6599 28.0114 28.4231L35.1987 20.805C35.4277 20.5622 35.7022 20.3671 36.0063 20.2309C36.3104 20.0946 36.6383 20.0198 36.9712 20.0108C37.3041 20.0018 37.6355 20.0587 37.9465 20.1783C38.2575 20.2979 38.542 20.4778 38.7838 20.7078L38.9011 20.8194C39.3893 21.2839 39.6738 21.924 39.6919 22.5991C39.7101 23.2741 39.4604 23.9287 38.9979 24.419L27.845 36.2394C27.6083 36.4906 27.3229 36.6907 27.0065 36.8274C26.69 36.9642 26.3492 37.0346 26.0047 37.0345Z"
        fill="white"
      />
      <path
        d="M96.6599 37.0345C96.6187 37.0345 96.5774 37.0335 96.536 37.0315C96.1711 37.0138 95.8143 36.9171 95.49 36.7479C95.1658 36.5788 94.8819 36.3412 94.6577 36.0515L91.118 31.4818C90.7058 30.9485 90.5212 30.2728 90.6046 29.6029C90.6879 28.9331 91.0325 28.3238 91.5627 27.9087L91.69 27.8092C92.2212 27.3953 92.8942 27.21 93.5614 27.2937C94.2285 27.3774 94.8354 27.7233 95.2488 28.2557C95.4483 28.5133 95.7009 28.7245 95.9892 28.875C96.2776 29.0254 96.5949 29.1115 96.9195 29.1274C97.244 29.1433 97.5682 29.0886 97.8697 28.967C98.1713 28.8454 98.4431 28.6599 98.6666 28.423L105.854 20.8049C106.083 20.5622 106.357 20.3671 106.662 20.2308C106.966 20.0945 107.294 20.0197 107.626 20.0107C107.959 20.0017 108.291 20.0586 108.602 20.1782C108.913 20.2978 109.197 20.4777 109.439 20.7077L109.556 20.8194C110.045 21.2838 110.329 21.924 110.347 22.599C110.365 23.274 110.116 23.9287 109.653 24.4189L98.5003 36.2393C98.2635 36.4906 97.9782 36.6907 97.6618 36.8274C97.3453 36.9641 97.0044 37.0346 96.6599 37.0345Z"
        fill="white"
      />
      <path
        d="M89.3471 23.0258H71.1211V7.10986C71.1399 6.12437 71.547 5.18656 72.2533 4.50202C72.9595 3.81747 73.9073 3.44204 74.8888 3.45803H80.9472C85.579 3.45803 89.3471 7.11025 89.3471 11.5994V23.0258Z"
        fill="#2F2E41"
      />
      <path
        d="M96.0944 156.209L92.6345 156.209L90.9883 142.809L96.0949 142.809L96.0944 156.209Z"
        fill="#FFB6B6"
      />
      <path
        d="M96.9769 159.577L85.8203 159.577V159.435C85.8204 158.279 86.2779 157.17 87.0923 156.352C87.9066 155.534 89.0111 155.075 90.1628 155.075H90.163L96.9771 155.075L96.9769 159.577Z"
        fill="#2F2E41"
      />
      <path
        d="M64.2028 156.209L60.7426 156.209L59.0967 142.809L64.2033 142.809L64.2028 156.209Z"
        fill="#FFB6B6"
      />
      <path
        d="M65.0853 159.577L53.9287 159.577V159.435C53.9288 158.279 54.3863 157.17 55.2007 156.352C56.015 155.534 57.1195 155.075 58.2712 155.075H58.2714L65.0855 155.075L65.0853 159.577Z"
        fill="#2F2E41"
      />
      <path
        d="M68.3808 48.5528L66.123 63.0053L87.572 63.2886L84.7498 49.4031L68.3808 48.5528Z"
        fill="#FFB6B6"
      />
      <path
        d="M66.7455 59.4764L59.9141 77.7412L59.0674 153.433H64.853L78.682 92.6187L91.3821 151.846H97.0266L93.0754 85.2508C93.0754 85.2508 94.2406 71.8845 88.2776 63.9972L86.8262 59.7866L66.7455 59.4764Z"
        fill="#544E51"
      />
      <path
        d="M96.0485 73.7147C95.4513 73.5513 94.897 73.2584 94.4248 72.8566C93.9525 72.4549 93.5737 71.954 93.315 71.3893C93.0564 70.8246 92.9242 70.2099 92.9278 69.5884C92.9314 68.9669 93.0706 68.3537 93.3357 67.792L84.2109 37.9211L90.9225 36.5852L99.268 65.865C100.178 66.3747 100.87 67.202 101.214 68.19C101.557 69.178 101.527 70.2582 101.131 71.2257C100.734 72.1933 99.9972 72.9811 99.0606 73.4399C98.1239 73.8986 97.0522 73.9964 96.0485 73.7147H96.0485Z"
        fill="#FFB6B6"
      />
      <path
        d="M83.9722 56.5344L69.1869 56.2478C68.7643 56.2388 68.3563 56.0909 68.0254 55.8268C67.6944 55.5627 67.4589 55.197 67.3547 54.7857C66.5621 51.6346 64.8182 43.4225 65.7432 35.2432C65.9257 33.6587 66.4229 32.1271 67.2054 30.7389C67.9878 29.3507 69.0396 28.1342 70.2984 27.1614C71.5573 26.1885 72.9977 25.4791 74.5345 25.075C76.0712 24.6709 77.6731 24.5805 79.2452 24.8089C80.9037 25.0473 82.4932 25.6351 83.9096 26.5338C85.3259 27.4324 86.5369 28.6216 87.4633 30.0233C88.3898 31.425 89.0105 33.0075 89.285 34.667C89.5594 36.3265 89.4813 38.0256 89.0558 39.6527C87.1947 46.7494 86.1434 51.8265 85.9313 54.7433C85.8998 55.165 85.7318 55.5648 85.4529 55.8816C85.1739 56.1985 84.7993 56.4149 84.3863 56.4979C84.2635 56.5224 84.1386 56.5347 84.0133 56.5348C83.9996 56.5348 83.986 56.5347 83.9722 56.5344Z"
        fill="#E4E4E4"
      />
      <path
        d="M39.0609 30.2962C39.1497 30.4962 39.2227 30.7029 39.2791 30.9145L57.7862 37.2291L61.3623 34.0922L66.7826 38.8926L60.9892 45.0636C60.5212 45.5622 59.9048 45.8948 59.2324 46.0116C58.5601 46.1284 57.8682 46.0231 57.2605 45.7115L37.4568 35.5543C36.7406 35.9954 35.9077 36.2071 35.0688 36.1612C34.2299 36.1154 33.4247 35.8142 32.7604 35.2977C32.0962 34.7812 31.6042 34.0739 31.3501 33.2698C31.0959 32.4657 31.0916 31.603 31.3377 30.7964C31.5838 29.9898 32.0686 29.2775 32.7276 28.7543C33.3867 28.2311 34.1888 27.9218 35.0272 27.8675C35.8656 27.8132 36.7006 28.0165 37.4212 28.4503C38.1417 28.8842 38.7137 29.528 39.0609 30.2962V30.2962Z"
        fill="#FFB6B6"
      />
      <path
        d="M57.8243 36.2221C57.8257 35.9662 57.8821 35.7136 57.9896 35.4816C58.0972 35.2495 58.2533 35.0435 58.4473 34.8776L65.2238 29.0816C66.0814 28.0949 67.2936 27.4899 68.5948 27.3994C69.8959 27.3089 71.1797 27.7403 72.1645 28.5989C73.1494 29.4575 73.7549 30.6733 73.8482 31.9795C73.9415 33.2858 73.5151 34.5759 72.6624 35.5669L67.8368 43.1122C67.699 43.3275 67.5167 43.5105 67.3022 43.6487C67.0876 43.7869 66.846 43.8771 66.5936 43.913C66.3413 43.949 66.0842 43.9299 65.8399 43.8571C65.5955 43.7843 65.3697 43.6595 65.1777 43.4911L58.4311 37.5743C58.239 37.406 58.0854 37.1981 57.9806 36.9647C57.8759 36.7314 57.8226 36.478 57.8243 36.2221Z"
        fill="#E4E4E4"
      />
      <path
        d="M83.8015 45.4875C83.5759 45.3684 83.3786 45.2018 83.223 44.9991C83.0674 44.7965 82.9572 44.5625 82.8998 44.3131L80.8963 35.6042C80.4191 34.3856 80.4427 33.0266 80.9619 31.8254C81.481 30.6242 82.4534 29.6787 83.6658 29.1963C84.8782 28.714 86.2317 28.734 87.4294 29.2522C88.627 29.7703 89.5712 30.7442 90.0548 31.9603L94.5032 39.7359C94.6301 39.9579 94.7079 40.2046 94.7313 40.4595C94.7547 40.7143 94.7231 40.9712 94.6387 41.2127C94.5543 41.4542 94.4191 41.6745 94.2422 41.8588C94.0653 42.043 93.851 42.1869 93.6137 42.2805L85.2763 45.5693C85.0391 45.663 84.7845 45.7042 84.53 45.6901C84.2755 45.676 84.027 45.6069 83.8015 45.4875Z"
        fill="#E4E4E4"
      />
      <path
        d="M90.9748 5.01328C91.6594 3.40407 90.4821 1.35656 88.3453 0.440035C86.2084 -0.476493 83.9212 0.0850257 83.2366 1.69423C82.552 3.30344 83.7293 5.35096 85.8662 6.26749C88.003 7.18402 90.2902 6.62249 90.9748 5.01328Z"
        fill="#2F2E41"
      />
      <path
        d="M84.346 16.9747C86.632 12.7758 85.0951 7.51119 80.9134 5.21586C76.7316 2.92053 71.4885 4.46369 69.2026 8.6626C66.9166 12.8615 68.4535 18.1261 72.6352 20.4215C76.817 22.7168 82.0601 21.1736 84.346 16.9747Z"
        fill="#FFB6B6"
      />
      <path
        d="M81.5078 2.89493H71.6375C70.9139 2.88552 70.2135 3.15116 69.6767 3.63857C69.14 4.12598 68.8067 4.79912 68.7435 5.523C68.718 5.90767 68.7714 6.29352 68.9005 6.65662C69.0295 7.01972 69.2315 7.35234 69.4938 7.63387C69.7561 7.9154 70.0732 8.13984 70.4254 8.29329C70.7776 8.44674 71.1575 8.52592 71.5415 8.52593C72.2851 8.52593 72.9983 8.82256 73.5242 9.35057C74.05 9.87858 74.3455 10.5947 74.3455 11.3414V22.885H89.7675V11.1885C89.7675 10.0993 89.5538 9.02088 89.1387 8.01466C88.7236 7.00844 88.1153 6.09417 87.3483 5.32404C86.5813 4.55392 85.6707 3.94302 84.6686 3.52623C83.6665 3.10944 82.5925 2.89492 81.5078 2.89493Z"
        fill="#2F2E41"
      />
      <path
        d="M130.164 160H23.3312C23.2568 160 23.1855 159.97 23.1329 159.918C23.0803 159.865 23.0508 159.793 23.0508 159.718C23.0508 159.644 23.0803 159.572 23.1329 159.519C23.1855 159.467 23.2568 159.437 23.3312 159.437H130.164C130.238 159.437 130.309 159.467 130.362 159.519C130.415 159.572 130.444 159.644 130.444 159.718C130.444 159.793 130.415 159.865 130.362 159.918C130.309 159.97 130.238 160 130.164 160Z"
        fill="#CACACA"
      />
    </g>
    <defs>
      <clipPath id="clip0_109_3773">
        <rect
          width="202.449"
          height="160"
          fill="white"
          transform="translate(0.275391)"
        />
      </clipPath>
    </defs>
  </svg>

  );
};

export default ContactUsFeedbackIcon;
