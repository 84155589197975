import { createSlice } from '@reduxjs/toolkit';


const slice = createSlice({
    name: 'childCompany',
    initialState: {
        childCompany: {
            refetchCompanies: '',
        }
    },
    reducers: {
        setChildCompany: (state, { payload }) => {
            state.childCompany = { ...state?.childCompany, ...payload };
        },
        resetChildCompany: (state) => {
            state.childCompany = {};
        },
    },
});

export const { setChildCompany, resetChildCompany } = slice?.actions;

export default slice?.reducer;

export const getChildCompanyState = (state) => state?.childCompany?.childCompany;