import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ProgressBar } from 'react-bootstrap';

const StyledProgressbar = ({ value,background, filledColor }) => {
  const [progress, setProgress] = useState(0);
  useEffect(() => {
    setProgress(value);
  }, [value]);

  return (
    <StyledDiv background={background}>
    <ProgressStyle className="white" progress={progress} now={progress} filledColor={filledColor}>
      <span className="progress-left">
        <span className="progress-bar" />
      </span>
      <span className="progress-right">
        <span className="progress-bar" />
      </span>
      <div className="progress-value">{`${progress}%`}</div>
    </ProgressStyle>
    </StyledDiv>
  );
};
const StyledDiv = styled.div`
background: ${(props) => (props?.background ? `${props?.background}` : 'none')};
  width: 65px;
  height: 65px;
    position: fixed;
  bottom: 20px;
  left: 40px;
  border-radius:50%;
`;
const ProgressStyle = styled(ProgressBar)`
  width: 65px;
  height: 65px;
  background: none;
  position: fixed;
  bottom: 20px;
  left: 40px;

  &:after {
    content: '';
    width: 100%;
    height: 100%;
    border-radius: 50%;
    opacity: 0.4;
    border: 5px solid #fff;
    position: absolute;
    top: 0;
    left: 0;
  }

  > span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 5px;
    border-style: solid;
    position: absolute;
    top: 0;
  }

  .progress-left {
    left: 0;

    .progress-bar {
      left: 100%;
      border-top-right-radius: 80px;
      border-bottom-right-radius: 80px;
      border-left: 0;
      transform-origin: center left;
    }
  }

  .progress-right {
    right: 0;

    .progress-bar {
      left: -100%;
      border-top-left-radius: 80px;
      border-bottom-left-radius: 80px;
      border-right: 0;
      transform-origin: center right;
      animation: loading-1 1.8s linear forwards;
    }
  }

  .progress-value {
    width: 85%;
    height: 85%;
    border-radius: 50%;
    font-size: 16px;
    text-align: center;
    position: absolute;
    top: 7.5%;
    left: 7.5%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.white {
    .progress-bar {
      border-color: ${({ theme, filledColor }) => filledColor ?? theme.white};
    }

    .progress-value {
      color: ${({ theme, filledColor }) => filledColor ?? theme.white};
    }

    .progress-left {
      .progress-bar {
        animation: loading-2 1.5s linear forwards 1.8s;
      }
    }
  }

  @keyframes loading-1 {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: ${({ progress }) =>
        `rotate(${
          progress >= 50 ? 180 : Math.ceil(((progress * 2) / 100) * 180)
        }deg)`};
    }
  }
  @keyframes loading-2 {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: ${({ progress }) => {
        if (progress >= 100) {
          return `rotate(180deg)`;
        } else if (progress <= 50) {
          return `rotate(0deg)`;
        } else
          return `rotate(${Math.ceil((((progress - 50) * 2) / 100) * 180)}deg)`;
      }};
    }
  }
`;
export default StyledProgressbar;
