import React from 'react';
import { ErrorMessage, Field } from 'formik';
import Proptypes from 'prop-types';
import StyledSelect from '../select/StyledSelect';
import { formErrorMessage } from './FormErrorMessage';

const FormikSelect = ({ name, options, ...props }) => (
  <Field name={name}>
    {({ field: { value }, form: { setFieldValue, touched, errors } }) => (
      <>
        <StyledSelect
          options={options}
          value={value}
          onChange={(values) =>
            setFieldValue(
              name,
              typeof values?.value === 'undefined'
                ? values?.label
                : values.value,
            )
          }
          isValid={touched[name] && !errors[name]}
          isInvalid={touched[name] && errors[name]}
          {...props}
        />
        <ErrorMessage name={name} render={formErrorMessage} />
      </>
    )}
  </Field>
);
FormikSelect.propTypes = {
  name: Proptypes.string.isRequired,
  options: Proptypes.array.isRequired,
  isMulti: Proptypes.bool,
};

export default React.memo(FormikSelect);
