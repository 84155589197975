import * as Yup from 'yup';
import ClockFillIcon from '../../../app/components/svgIcons/ClockFillIcon';
import FillBatteryIcon from '../../../app/components/svgIcons/FillBatteryIcon';
import InboxIcon from '../../../app/components/svgIcons/InboxIcon';
import LineIcon from '../../../app/components/svgIcons/LineIcon';
import PeopleIcons from '../../../app/components/svgIcons/PeopleIcons';
import moment from 'moment';
import Calender365Icon from '../../../app/components/svgIcons/Calender365Icon';
import AlignIcon from '../../../app/components/svgIcons/AlignIcon';
import MeetIcon from '../../../app/components/svgIcons/MeetIcon';

export const notify = [
  {
    id: 1,
    title: 'Email',
    disabled: false,
  },
  {
    id: 2,
    title: 'SMS',
    disabled: false,
  },
];

export const relativeTime = [
  {
    label: '6 hours',
    value:'6'
  },
  {
    label: '12 hours',
    value:'12'
  },
  {
    label: '24 hours',
    value:'24'
  },
];

export const notifyTemplate = [
  {
    id: 1,
    title: 'Email',
    disabled: false,
  },
  {
    id: 2,
    title: 'SMS',
    disabled: false,
  },
  {
    id: 3,
    title: 'Both',
    disabled: false,
  },
];

export const bulkEmailCategory = [
  {
    id: 1,
    title: 'General Email',
    disabled: false,
  },
  {
    id: 2,
    title: 'Invitation to Apply for a Job',
    disabled: false,
  },

];

export const offerTypes = [
  {
    id: 1,
    title: 'Flexible Dates',
    disabled: false,
  },
  {
    id: 2,
    title: 'Specific Date',
    disabled: false,
  },
];

export const NOTIFICATIONSTATUS = { Email: 1, SMS: 2, Both: 3 };

export const INTERVIEWTYPESTATUS = {
  'In Person': 1,
  'Google Meet': 2,
  'Microsoft Office': 3,
};
export const interviewTypes = [
  {
    label: 'Phone',
    value: 2,
  },
  {
    label: 'Video',
    value: 3,
  },
];

export const interviewTypesInperson = [
  {
    label: 'In Person',
    value: 1,
  },
];

export const InterveiwTypeStatus = ['In Person', 'Phone', 'Video'];

export const invitationTemplateSchema = Yup.object().shape({
  subject: Yup.string().required('Please enter subject'),
  body: Yup.string()
    .required('Required')
    .matches(
      /\[INTERVIEW_INVITE]/i,
      'Could not detect short code for interview invite link. Please add short code [INTERVIEW_INVITE] to send invitation',
    ),
});

export const invitationTemplateSchemaBoth = Yup.object().shape({
  subject: Yup.string().required('Please enter subject'),
  body: Yup.string()
    .required('Required')
    .matches(
      /\[INTERVIEW_INVITE]/i,
      'Could not detect short code for interview invite link. Please add short code [INTERVIEW_INVITE] to send invitation',
    ),
  smsBody: Yup.string()
    .required('Required')
    .matches(
      /\[INTERVIEW_INVITE]/i,
      'Could not detect short code for interview invite link. Please add short code [INTERVIEW_INVITE] to send invitation',
    ),
});

export const invitationTemplateSchemaSms = Yup.object().shape({
  smsBody: Yup.string()
    .required('Required')
    .matches(
      /\[INTERVIEW_INVITE]/i,
      'Could not detect short code for interview invite link. Please add short code [INTERVIEW_INVITE] to send invitation',
    ),
});

export const SLOTSBOOKSTATUS = {
  CONFIRMED: 16,
};

export const scheduleOptions = [
  {
    id: 1,
    title: 'Set specific slot',
    selfSchedule: false,
  },
  {
    id: 2,
    title: 'Send slots to self schedule',
    selfSchedule: true,
  },
];

export const meetingInformationSchema = Yup.object({
  title: Yup.string()
    .required('Required')
    .min(2, 'Minimum 2 characters required'),
  note: Yup.string(),
  type: Yup.object().required('Required'),
  accountIds: Yup.array()
    .min(1, 'Atleast 1 Team Member is Required')
    .required('This field is required.'),
  locationId: Yup.object().when('type', {
    is: (type) => (type?.value === 1 ? true : false), //just an e.g. you can return a function
    then: Yup.object().required('Required'),
  }),
});

export const specificSlotSelection = Yup.object({
  interviewdate: Yup.string().required('Required'),
  duration: Yup.string().required('Required'),
  meetingtimeslot: Yup.string().required('Required').nullable(''),
  timeZone: Yup.string().required('Required'),
});

export const specificSlotSelfSelection = Yup.object({
  daterange: Yup.array(Yup.string().required()).min(1),
  duration: Yup.string().required('Required'),
  slotsLength: Yup.number().required('Required').min(1),
});

export const MeetingOptions = [
  {
    value: 1,
    label: { text: 'In Person', icon: <PeopleIcons /> },
  },
  {
    value: 3,
    label: {
      text: 'Microsoft Office',
      icon: <Calender365Icon size={20} />,
      accessKey: 'OFFICE365',
    },
  },
  {
    value: 2,
    label: {
      text: 'Google Meet',
      icon: <MeetIcon />,
      accessKey: 'GOOGLE_CALENDAR',
    },
  },
];

export const MeetingOptionsAutomation = [
  {
    value: 1,
    label: { text: 'In Person', icon: <PeopleIcons /> },
  },
  {
    value: 3,
    label: {
      text: 'Microsoft Office / In Person',
      icon: <Calender365Icon size={20} />,
      accessKey: 'OFFICE365',
    },
  },
  {
    value: 2,
    label: {
      text: 'Google Meet/ In Person',
      icon: <MeetIcon />,
      accessKey: 'GOOGLE_CALENDAR',
    },
  },
];

export const getMeetingIcon = (value) => {
  let val = MeetingOptions?.find((item) => item?.label?.text === value)?.label
    ?.icon;
  return val;
};

export const MeetingOptionsAccess = ['GOOGLE_CALENDAR'];

export const MeetingDuration = [
  {
    value: 20,
    label: '20 Minutes',
  },
  {
    value: 30,
    label: '30 Minutes',
  },
  {
    value: 45,
    label: '45 Minutes',
  },
  {
    value: 60,
    label: '1 Hour',
  },
  {
    value: 90,
    label: '90 Minutes',
  },
  {
    value: 120,
    label: '2 Hour',
  },
];

export const OfferDuration = [
  {
    value: 6,
    label: 'for the next 6 months',
    query: {
      // statusId: 2,
      startDate: moment()?.startOf('day')?.toISOString(),
      endDate: moment()?.add(6, 'months')?.endOf('day')?.toISOString(),
    },
  },
  {
    value: 12,
    label: 'for the next 12 months',
    query: {
      // statusId: 2,
      startDate: moment()?.startOf('day')?.toISOString(),
      endDate: moment()?.add(12, 'months')?.endOf('day')?.toISOString(),
    },
  },
];

export const Timelines = [
  {
    id: 1,
    label: 'Meeting Information',
    icon: <FillBatteryIcon />,
  },
  {
    id: 2,
    label: 'Time Slot',
    icon: <ClockFillIcon />,
    line: <LineIcon />,
    active: true,
  },
  {
    id: 2,
    label: 'Send Message',
    icon: <InboxIcon />,
    line: <LineIcon />,
    active: true,
  },
];

export const offerTimelines = [
  {
    id: 1,
    label: 'Offer Details',
    icon: <AlignIcon className="mb-1" />,
  },
  {
    id: 2,
    label: 'Slots',
    icon: <ClockFillIcon className="mb-1" />,
    line: <LineIcon />,
    active: true,
  },
  {
    id: 3,
    label: 'Notify Candidate',
    icon: <InboxIcon className="mb-1" />,
    line: <LineIcon />,
    active: true,
  },
];

export const documentTimeline = [
  {
    id: 1,
    label: 'Document Details',
    icon: <AlignIcon className="mb-1" />,
  },
  {
    id: 2,
    label: 'Notify Candidate',
    icon: <InboxIcon className="mb-1" />,
    line: <LineIcon />,
    active: true,
  },
];

export const MeetingTimes = [
  {
    value: 1,
    label: '12:30 - 13:30',
  },
  {
    value: 2,
    label: '12:30 - 13:30',
  },
  {
    value: 3,
    label: '12:30 - 13:30',
  },
];

export const MeetingRoles = [
  {
    value: 'INTERVIEWER',
    label: 'Interviewer',
  },
  {
    value: 'HIRING_MANAGER',
    label: 'Hiring Manager',
  },
  {
    value: 'RECRUITER',
    label: 'Recruiter',
  },
];

export const NotifyRoles = [
  {
    value: 'INTERVIEWER',
    label: 'Interviewer',
    id: '1',
  },
  {
    value: 'HIRING_MANAGER',
    label: 'Hiring Manager',
    id: '2',
  },
  {
    value: 'RECRUITER',
    label: 'Recruiter',
    id: '3',
  },
  {
    value: 'ADMIN',
    label: 'Admin',
    id: '4',
  },
];


export const InterviewReminderType = [
  {
    id: 1,
    title: 'Email',
    value:'EMAIL',
    disabled: false,
  },
  {
    id: 2,
    title: 'SMS',
    value:'SMS',
    disabled: false,
  },
  {
    id: 3,
    title: 'Both',
    value:'BOTH',
    disabled: false,
  },
];


export const INTERVIEWREMINDERTYPES = {
    EMAIL: 'EMAIL',
    SMS: 'SMS',
    BOTH: 'BOTH',
  };