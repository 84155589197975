import React from 'react';
import { Dropdown } from 'react-bootstrap';
import PropTypes from 'prop-types';
import './_dropdown-menu.scss';
import { occyLs } from '../../../utils/localStorage';

const CustomDropdown = ({
  dropdownToggle,
  dropdownMenu,
  toggleClassName,
  align,
  menuClassName,
  menuProps,
  dropDownProps,
  dropDownToggleProps,
  leftMenu,
  ...props
}) => {
  const delegatedAccount = occyLs.getObject('account');
  return (
    <Dropdown
      className={`custom-drop-down ${dropDownProps || ''}`}
      {...dropDownProps}
      {...props}
    >
      <Dropdown.Toggle
        {...dropDownToggleProps}
        className={`menu-toggle  ${toggleClassName || ''}`}
      >
        {dropdownToggle}
      </Dropdown.Toggle>
      <Dropdown.Menu
        align={align ?? "start"}
        {...menuProps}
        className={`m-0 ${(delegatedAccount?.oooAccount && menuClassName === 'ooo')? 'ooo bg-trasparent' :''} menu-dropdown ${leftMenu ? "leftMenu" : ""} ${menuClassName || ''}`}
      >
        {dropdownMenu}
      </Dropdown.Menu>
    </Dropdown>
  );
};
CustomDropdown.propTypes = {
  dropdownToggle: PropTypes.element.isRequired,
  toggleClassName: PropTypes.string,
  dropdowwnMenu: PropTypes.element,
  menuClassName: PropTypes.string,
};
export default CustomDropdown;
