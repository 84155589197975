import React from 'react';
import Proptypes from 'prop-types';
import FormSuccessIcon from '../../../../app/components/svgIcons/FormSuccessIcon';
import styled from 'styled-components';

const FormPublicErrorView = ({
  description,
  heading,
  icon = <FormSuccessIcon />,
}) => (
  <StyledPublicView className="flex-center justify-content-center flex-column text-center">
    <StyledImg >
      <FormSuccessIcon size="300" className="webSize" />
      <FormSuccessIcon size="170" className="mobileSize" />
    </StyledImg>
    <StyledFonts>
      <h1 className="text-white">{heading || ''}</h1>
      <h5 className="text-white opacity-75">{description || ''}</h5>
    </StyledFonts>
  </StyledPublicView>
);

const StyledPublicView = styled.div`
  min-height: 86vh;
`;

FormPublicErrorView.propTypes = {
  description: Proptypes.string.isRequired,
  heading: Proptypes.string.isRequired,
  icon: Proptypes.node.isRequired,
};
const StyledImg = styled.div`
  .webSize {
    display: block; 
  }
  .mobileSize {
    display: none; 
  }
  ${(props) => props.theme.md} {
    .webSize {
      display: none; 
    }
    .mobileSize {
      display: block; 
    }
  }
`;
const StyledFonts = styled.div`
${(props) => props.theme.md} {
h1{
 font-size: 20px;
}
h5{
font-size: 14px;
}
}
`;
export default FormPublicErrorView;
