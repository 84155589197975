import { apiSlice } from '../../app/services/apiSlice';

const JOBS_OFFERS_URL = 'jobs/joboffers/';

export const offersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    createOffer: builder.mutation({
      query: (data) => ({
        url: `${JOBS_OFFERS_URL}createOffer`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    sendDocument: builder.mutation({
      query: (data) => ({
        url: `applicant-employment-agreement/`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    sendInvitationEmail: builder.mutation({
      query: (data) => ({
        url: `jobOffers/invitation`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    sendDocInvitationEmail: builder.mutation({
      query: (data) => ({
        url: `applicant-employment-agreement/invitation`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    sendofferSmsInvitaton: builder.mutation({
      query: (data) => ({
        url: `applications/sms`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    sendJobofferSmsInvitaton: builder.mutation({
      query: (data) => ({
        url: `jobOffers/sms-invitation`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    preSignedOfferAttachmentUrl: builder.mutation({
      query: (data) => ({
        url: `${JOBS_OFFERS_URL}preSignedofferattachment`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    getDocument: builder.query({
      query: (params) => ({
        url: `public/applicant-employment-agreement/details?token=${params}`,
      }),
    }),
    acceptRejectDocument: builder.mutation({
      query: (params) => ({
        url: `public/applicant-employment-agreement/accepted-rejected?token=${params?.token}`,
        method: 'POST',
        body: {
          ...params,
        },
      }),
    }),
    getJobOffer: builder.query({
      query: (params) => ({
        url: `jobOffers/public?token=${params}`,
      }),
    }),
    rejectOfferLetter: builder.mutation({
      query: (params) => ({
        url: `jobOffers/public?token=${params?.token}`,
        method: 'POST',
        body: {
          ...params,
        },
      }),
    }),
    getApplicationOfferStatus: builder.query({
      query: (params) => ({
        url: `${JOBS_OFFERS_URL}getofferletter`,
        params,
      })
    }),
    getApplicationDocuments: builder.query({
      query: (params) => ({
        url: `applicant-employment-agreement/envelope/details`,
        params,
      })
    }),

  }),
});

export const {
  useGetDocumentQuery,
  useGetApplicationDocumentsQuery,
  useAcceptRejectDocumentMutation,
  useSendJobofferSmsInvitatonMutation,
  useSendDocInvitationEmailMutation,
  useCreateOfferMutation,
  useSendDocumentMutation,
  usePreSignedOfferAttachmentUrlMutation,
  // useGetOfferLetterDocumentsQuery,
  useGetJobOfferQuery,
  useRejectOfferLetterMutation,
  useSendInvitationEmailMutation,
  useSendofferSmsInvitatonMutation,
  useGetApplicationOfferStatusQuery,
} = offersApiSlice;
