import { apiSlice } from '../../../../app/services/apiSlice';

const NOTIFICATION_URL = '/notifications';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    addSecondaryEmail: builder.mutation({
      query: (data) => ({
        url: `${NOTIFICATION_URL}/email`,
        method: 'POST',
        body: {
          ...data,
        },
      }),
    }),
    updateNotification: builder.mutation({
      query: (data) => ({
        url: `${NOTIFICATION_URL}/settings/${data?.id}`,
        method: 'PUT',
        body: {
          ...data,
        },
      }),
    }),

    gellAllSettingNotifications: builder.query({
      query: () => `${NOTIFICATION_URL}/settings`,
    }),

    getSecondaryEmail: builder.query({
      query: () => `${NOTIFICATION_URL}/email`,
    }),

    getNotifications: builder.query({
      query: () => `${NOTIFICATION_URL}`,
    }),

    deleteSecondaryEmail: builder.mutation({
      query: () => ({
        url: `${NOTIFICATION_URL}/email`,
        method: 'DELETE',
      }),
    }),
    markAllNotifAsRead: builder.mutation({
      query: (data) => ({
        url: `${NOTIFICATION_URL}/mark-read`,
        method: 'PUT',
      }),
    }),
    markNotifAsRead: builder.mutation({
      query: (id) => ({
        url: `${NOTIFICATION_URL}/read/${id}`,
        method: 'PUT',
      }),
    }),
    getAllNotifications: builder.query({
      query: (params) => ({
        url: `notifications`,
        params,
      }),
    }),
  }),
});

export const {
  useAddSecondaryEmailMutation,
  useUpdateNotificationMutation,
  useMarkAllNotifAsReadMutation,
  useMarkNotifAsReadMutation,
  useGellAllSettingNotificationsQuery,
  useGetNotificationsQuery,
  useGetSecondaryEmailQuery,
  useDeleteSecondaryEmailMutation,
  useGetAllNotificationsQuery
} = extendedApiSlice;
