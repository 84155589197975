import React from 'react';
import FormikInputField from '../../../../app/components/formikComponents/FormikInputField';
import FormikTextEditor from '../../../../app/components/formikComponents/FormikTextEditor';
import FormikDatePicker from '../../../../app/components/formikComponents/FormikDatePicker';
import FormikSelect from '../../../../app/components/formikComponents/FormikSelect';
import { ErrorMessage, useFormikContext } from 'formik';
import { formErrorMessage } from '../../../../app/components/formikComponents/FormErrorMessage';
import FormikPublicCheckbox from '../../../../app/components/formikComponents/FormikPublicCheckbox';
import { ControlTypes } from '../../../../utils/helper';
import styled from 'styled-components';
import FormikDropZone from '../../../../app/components/formikComponents/FormikDropZone';
import { toast } from 'react-toastify';
// import { usePreSignedUrlMutation } from '../../../job/jobApiSlice';
import { usePreSignedPublicFormsUrlMutation } from '../../../careerPage/PublicPageApi';

const FormPublicRenderControl = ({ isReadOnly, control, assessment,dei,publicForm }) => {
  const name = `q_${control?.id}`;
  const formik = useFormikContext();
  const [getPreSignedUrl] = usePreSignedPublicFormsUrlMutation();
  switch (control?.sectionType) {
    case ControlTypes.TEXT_INPUT:
    case ControlTypes.EMAIL:
    case ControlTypes.NUMBER_INPUT:
      return (
        <>
          {
            assessment ?
              <StyledHeight>
                <FormikInputField
                  forms
                  name={name}
                  type={control?.sectionType}
                  rows="4"
                  placeholder='Please enter your response here'
                /> </StyledHeight> :
              <StyledHeight>
                <FormikInputField
                  forms
                  name={name}
                  type={control?.sectionType}
                  as="input"
                  className={`input publicForms`}
                  rows="4"
                  isReadOnly={isReadOnly}
                   placeholder='Please enter your response here'
                />
              </StyledHeight>
          }
        </>
      );
    case ControlTypes.CHECKBOX:
    case ControlTypes.RADIO:
    case ControlTypes.YES_NO:
      return (
        <>
          <StyledHeight>
            <FormikPublicCheckbox
              assessment={assessment}
              name={name}
              dei={dei}
              isRadio={control?.sectionType === ControlTypes.RADIO || control?.sectionType === ControlTypes.YES_NO}
              options={control?.sectionOptions?.opts?.map((o) => ({
                label: o.label,
                value: o.label
              }))}
              isReadOnly={isReadOnly}
            /></StyledHeight>
          <ErrorMessage name={name} render={formErrorMessage} />
        </>
      );
    case ControlTypes.TEXTAREA:
      return (
        <StyledHeight>
          <FormikTextEditor
            className={`${!assessment ? "publicForms" : ""}`}
            publicForm={!assessment}
            name={name}
            isReadOnly={isReadOnly}
            id={`${name}-${control?.sectionOrder || ''}`}
          /></StyledHeight>

      );
    case ControlTypes.DATE:
      return (
        <FormikDatePicker
          isReadOnly={isReadOnly}
          toggleClassName={`form-control date-picker__toggle d-flex justify-content-between align-items-center input ${!assessment ? "publicForm" : ""}`}
          name={name}
        />
      );

    case ControlTypes.DROPDOWN:
      return (
        // <StyledHeight>
        <FormikSelect
          // disabled={isReadOnly}
          publicForm={!assessment}
          name={name}
          options={control?.sectionOptions?.opts?.map((o) => ({
            label: o.label,
            // value: o.label,
            value: isReadOnly ? '' : o.label,
          }))}
        />
        // </StyledHeight>
      );

      

      case ControlTypes.FILE_UPLOAD:


      const onDropUploadFile = (acceptedFiles) => {
        if (!Array.isArray(acceptedFiles) || acceptedFiles.length < 1) return;
        acceptedFiles?.map(async (file) => {
          const { data: responseData } = await getPreSignedUrl({
            fileName: file.name,
            fileType: file.type,
            appId: publicForm?.appForm?.applicationId,
          });
          if (!responseData?.url) {
            toast.error(
              'Could not process the upload. Please make sure the file is valid and the internet connection is stable',
            );
            return;
          }
          await fetch(`${responseData?.url}`, {
            method: responseData?.method,
            headers: {
              Accept: 'application/json',
              'Content-Type': file.type,
            },
            body: file,
          });
          formik.setFieldValue(name, responseData.key);
          return file;
        });
      };
      const { values } = formik;
        return (
          <StyledDropZoneWrap assessment={assessment}>
            <FormikDropZone
              name={name}
              hideName={false}
              label={values[name] ? 'File Uploaded' : 'Drag and drop a CV here'}
              noContent={values[name] && true}
              // type={'career'}
              onDrop={(val) => onDropUploadFile(val)}
            />
          </StyledDropZoneWrap>
        );
    default:
      return <p>{control?.type}</p>;
  }
};
const StyledHeight = styled.div`
${(props) => props.theme.md} {
 max-height: 61vh;
 overflow: auto;
}
`;
const StyledDropZoneWrap = styled.div`
 {
  .dropzone_container{
    background-color: ${({ theme }) => theme.backgroundColor} !important;
    border: 2px dashed ${({ theme, assessment }) => assessment? theme?.inputBorder :theme.primary};
    border-radius:5px;
    padding: 20px;
    svg{
      height:70px ;
      width: 64px;
    }
    .resumeIcon{
      fill:${({ theme, assessment }) => assessment? theme?.secondary : theme.primary};
    }
    button{
    background-color: transparent !important;
    }
    button span{
      color:${({ theme, assessment }) => assessment? theme?.secondary : theme.primary};
    }
  }
}
`;


export default FormPublicRenderControl;
