import React, { useState } from 'react';
import CustomDropdown from '../../app/components/dropdown/CustomDropDown';
import DropdownItem from 'react-bootstrap/DropdownItem';
import StyledButton from '../../app/components/styledComponents/StyledButton';
import ArrowIcon from '../../app/components/svgIcons/ArrowIcon';
import StyledInput from '../../app/components/styledComponents/StyledInput';
import StyledSelect from '../../app/components/select/StyledSelect';
import DatePickerComponent from '../../app/components/datePicker/DatePickerComponent';
import StyledCheckbox from '../../app/components/checkbox/StyledCheckbox';
import StyledRadioButton from '../../app/components/radio/StyledRadioButton';
import PortletHeader from '../../app/components/portlet/PortletHeader';
import StyledTextEditor from '../../app/components/textEditor/StyledTextEditor';

const ComponentsList = () => {
  const [date, setDate] = useState(new Date());
  const [checkboxValue, setCheckboxValue] = useState(false);
  const [radioButtonValue, setRadioButtonValue] = useState(false);
  const [tabs, setTabs] = useState([
    {
      id: 1,
      title: 'Tab1',
      active: true,
    },
    {
      id: 2,
      title: 'Tab2',
      active: false,
    },
    {
      id: 3,
      title: 'Tab3',
      active: false,
    },
    {
      id: 4,
      title: 'Archived',
      active: false,
    },
  ]);
  const onChangeTabs = (tab) => {
    setTabs((prevState) =>
      prevState.map((p) => {
        return p.id === tab
          ? {
              ...p,
              active: true,
            }
          : {
              ...p,
              active: false,
            };
      }),
    );
  };

  const [value, setValue] = useState('');

  return (
    <div className="container">
      <div className="row">
        <section className="col-4">
          <div className="my-2">
            <h3>Inputs</h3>
            <h6>Regular Inputs</h6>
            <StyledInput placeholder="Enter Job title" />
          </div>

          <div className="my-2">
            <h6>Disabled Inputs</h6>
            <StyledInput placeholder="Enter Job title" disabled />
          </div>

          <div className="my-2">
            <h6>Invalid Inputs</h6>
            <StyledInput placeholder="Enter Job title" isInvalid />
          </div>

          <div className="my-2">
            <h6>Valid Inputs</h6>
            <StyledInput placeholder="Enter Job title" isValid />
          </div>

          <div className="my-2">
            <h6>Text Area</h6>
            <StyledInput placeholder="Text area" as="textarea" />
          </div>

          <h3>Select</h3>
          <div className="my-2">
            <h6>Regular Select</h6>
            <StyledSelect
              components={{
                IndicatorSeparator: () => null,
              }}
              options={[
                {
                  value: 'item1',
                  label: 'item1',
                },
                {
                  value: 'item2',
                  label: 'item2',
                },
                {
                  value: 'item3',
                  label: 'item3',
                },
              ]}
              onChange={() => {
                return 0;
              }}
            />
          </div>

          <div className="my-2">
            <h6>Disabled Select</h6>
            <StyledSelect
              disabled
              components={{
                IndicatorSeparator: () => null,
              }}
              options={[
                {
                  value: 'item1',
                  label: 'item1',
                },
                {
                  value: 'item2',
                  label: 'item2',
                },
                {
                  value: 'item3',
                  label: 'item3',
                },
              ]}
              onChange={() => {
                return 0;
              }}
            />
          </div>
          <div className="my-2">
            <h3>Editor</h3>
            <StyledTextEditor
              Title="About Company"
              onChange={(val) => setValue(val)}
              value={value}
            />
          </div>
        </section>
        <section className="col-4">
          <div className="my-2">
            <h6>Dropdown</h6>
            <div className="d-flex">
              <CustomDropdown
                dropdownToggle={<span className="text-grey">More Items</span>}
                dropdownMenu={
                  <>
                    <DropdownItem className="text-grey">Item 1</DropdownItem>
                    <DropdownItem className="text-grey">Item 2</DropdownItem>
                    <DropdownItem className="text-grey">Item 3</DropdownItem>
                  </>
                }
              />
            </div>
          </div>
          <div className="my-2">
            <h6>Date Picker</h6>
            <DatePickerComponent
              onChange={(date) => {
                setDate(date);
              }}
              date={date}
            />
          </div>
          <div className="my-2">
            <h6>Checkbox</h6>
            <StyledCheckbox
              value={checkboxValue}
              onChange={() => setCheckboxValue(!checkboxValue)}
              label="Label"
            />
          </div>

          <div className="my-2">
            <h6>Radio</h6>
            <StyledRadioButton
              value={radioButtonValue}
              onChange={() => setRadioButtonValue(!radioButtonValue)}
              label="Label"
            />
          </div>

          <div className=" mt-3">
            <h3>Tabs</h3>
            <PortletHeader
              tabs={tabs}
              // title="Jobs"
              onChangeTabs={onChangeTabs}
              // subTitle={'(24)'}
            />
          </div>
        </section>
        <section className="col-4">
          <h3>Buttons</h3>
          <div className="my-2">
            <h6>Transparent Button</h6>
            <StyledButton variant={'white'} className="mb-2">
              Button
            </StyledButton>
          </div>
          <div className="my-2">
            <h6>Gray Button</h6>
            <StyledButton variant={'gray'} className="mb-2">
              Button
            </StyledButton>
          </div>
          <div className="my-2">
            <h6>Primary Button</h6>
            <StyledButton className="mb-2">Button</StyledButton>
          </div>
          <div className="my-2">
            <h6>Icon Transparent Button</h6>
            <StyledButton
              variant={'white'}
              className="mb-2"
              icon={<ArrowIcon />}
            >
              Button
            </StyledButton>
          </div>
          <div className="my-2">
            <h6>Icon Primary Button</h6>
            <StyledButton className="mb-2" icon={<ArrowIcon rotate={'left'} />}>
              Button
            </StyledButton>
          </div>
        </section>
      </div>
    </div>
  );
};

export default ComponentsList;
