import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import { Row, Col } from 'react-bootstrap';
import styled from 'styled-components';
import NewArrowIcon from '../../../app/components/svgIcons/NewArrowIcon';
import { getAddressStringByLocation } from '../../job/creation/assets/jobCreationUtill';
import { getAdvertRedirectUrl } from '../../../utils/helper';
const CareerPageTable = ({ CareerPageTableData }) => {
  const timePosted = (time) => {
    return moment(time).fromNow();
  };

  return (
    <>
      {/* <Wrap className='d-none d-md-block'>
      <Row className="justify-content-between align-items-center mt-4">
        <Col>
          <StyledSelect placeholder="Select Location" />
        </Col>
        <Col>
          <StyledSelect placeholder="Select Job Type" />
        </Col>
        <Col>
          <StyledSelect placeholder="Select Role" />
        </Col>
        <Col>
          <StyledSelect placeholder="Select Salary Range" />
        </Col>
      </Row>
      </Wrap> */}
      {CareerPageTableData?.map((publicJob, index) => (
        <>
          <Link
            to={getAdvertRedirectUrl(publicJob?.title, publicJob?.posting_id)}
            className=" text-decoration-none"
          >
            <Row className="mt-4">
              <Col lg={3}>
                <StyledLink className="cursor-pointer"> 
                  <StyledRow className="d-flex gap-2 align-items-center justify-content-between ">
                    <WrapText className="text-primaryIcon">
                      {publicJob?.title}
                    </WrapText>
                    <NewArrowIcon className="d-block d-lg-none" />
                  </StyledRow>
                  <StyledRow>
                    <div className="m-0 d-flex align-items-center  d-none d-md-block">
                      <label className="fs-6 cursor-pointer">
                        {timePosted(publicJob?.createdAt)}
                      </label>
                    </div>
                  </StyledRow>
                </StyledLink>
              </Col>
              <Col
                lg={3}
                className="text-start d-flex flex-column justify-content-center"
              > 
                <h5 className="mb-2 text-lightBlack">{getAddressStringByLocation(publicJob, ['town','postcode'])}</h5>
              </Col>
              {/* <Col
                lg={3}
                className="text-start d-flex flex-column justify-content-center"
              > 
                <h5 className="mb-2 text-lightBlack">{publicJob?.address }</h5>
              </Col> */}
              <Col
                lg={3}
                className="text-start d-flex flex-column justify-content-center"
              >
                <h5 className="mb-2 text-lightBlack">
                  {publicJob?.job_category}{' '}
                </h5>
              </Col>
              <Col
                lg={3}
                className="text-start d-flex flex-column justify-content-center"
              >
                <h5 className="mb-0 text-lightBlack">
                  {' '}
                  {publicJob?.position === 'full_time' && 'Full Time'}{' '}
                  {publicJob?.position === 'part_time' && 'Part Time'}
                  {publicJob?.position === 'MID_SENIOR_LEVEL' && 'Mid Senior Level'}
                  {publicJob?.position === 'ENTRY_LEVEL' && 'Entry Level'}
                  {publicJob?.position === 'MID_LEVEL' && 'Mid Level'}
                  {publicJob?.position === 'OTHER' && 'Other'}
                  {publicJob?.position === 'SENIOR_LEVEL' && 'Senior Level'}
                </h5>
              </Col>
            </Row>
          </Link>
          <hr />
        </>
      ))}
    </>
  );
};
const StyledRow = styled.div`
  color: ${({ theme }) => theme.lightBoldBlack};
`;
const StyledLink = styled.div`
  a {
    text-decoration: none;
  }
`;

const WrapText = styled.h4`
  width: 200px;
  whitespace: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
export default CareerPageTable;
