const DropDownIcon = ({ size = 14, rotate = 'down',color='currentColor', stroke="1.5", transition, ...props }) => {
  const getRotateDeg = (val) => {
    switch (val) {
      case 'left':
        return '90';
      case 'up':
        return '180';
      case 'right':
        return '270';
      default:
        return '0';
    }
  };
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 14 7"
      fill="none"
      style={{ transition: transition ? 'transform 0.2s ease-in-out' : '' , transform: `rotate(${getRotateDeg(rotate)}deg)` }}
      {...props}
    >
      <path
        d="M12.2797 0.966675L7.93306 5.31334C7.41973 5.82668 6.57973 5.82668 6.06639 5.31334L1.71973 0.966675"
        stroke={color}
        strokeWidth={stroke}
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
        fill="none"
      />
    </svg>
  );
};

export default DropDownIcon;
