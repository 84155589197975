import React from 'react';

const ClockFillIcon = ({ size = 20 ,color="currentColor",...props}) => (
  <svg
    width={size}
    height={size}
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M7.00008 1.16675C3.78591 1.16675 1.16675 3.78591 1.16675 7.00008C1.16675 10.2142 3.78591 12.8334 7.00008 12.8334C10.2142 12.8334 12.8334 10.2142 12.8334 7.00008C12.8334 3.78591 10.2142 1.16675 7.00008 1.16675ZM9.53758 9.08258C9.45591 9.22258 9.31008 9.29841 9.15841 9.29841C9.08258 9.29841 9.00675 9.28091 8.93675 9.23425L7.12841 8.15508C6.67925 7.88675 6.34675 7.29758 6.34675 6.77841V4.38675C6.34675 4.14758 6.54508 3.94925 6.78425 3.94925C7.02341 3.94925 7.22175 4.14758 7.22175 4.38675V6.77841C7.22175 6.98841 7.39675 7.29758 7.57758 7.40258L9.38591 8.48175C9.59591 8.60425 9.66591 8.87258 9.53758 9.08258Z"
      fill={color}
    />
  </svg>
);

export default ClockFillIcon;
