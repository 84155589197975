import React from 'react';

const EditIcon = ({ size = 15, color="currentColor", ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 17 17"
    {...props}
  >
    <path
      d="M9.55442 2.9L4.08109 8.69334C3.87442 8.91334 3.67442 9.34667 3.63442 9.64667L3.38775 11.8067C3.30109 12.5867 3.86109 13.12 4.63442 12.9867L6.78109 12.62C7.08109 12.5667 7.50109 12.3467 7.70775 12.12L13.1811 6.32667C14.1278 5.32667 14.5544 4.18667 13.0811 2.79334C11.6144 1.41334 10.5011 1.9 9.55442 2.9Z"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M8.64111 3.86667C8.92778 5.70667 10.4211 7.11334 12.2744 7.3"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
    <path
      d="M2.71436 15.1667H14.7144"
      stroke={color}
      strokeWidth="1.5"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
      fill="none"
    />
  </svg>
);

export default EditIcon;
