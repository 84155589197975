import React from 'react';

const DarkModeIcon = ({color= "white",...props}) => {
  return (
    <svg
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M0.81779 9.40662C1.1209 13.7428 4.80034 17.2707 9.20387 17.4643C12.3108 17.5991 15.0893 16.1509 16.7564 13.8691C17.4468 12.9345 17.0764 12.3114 15.9228 12.5219C15.3587 12.623 14.7778 12.6651 14.1715 12.6398C10.0543 12.4714 6.68637 9.02773 6.66953 4.96099C6.66111 3.86642 6.88844 2.83079 7.30101 1.88777C7.75568 0.843723 7.20839 0.346957 6.15592 0.793204C2.82169 2.19931 0.539938 5.55879 0.81779 9.40662Z"
        fill={color}
        stroke={color}
        strokeWidth="1.26296"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default DarkModeIcon;
