import React from 'react';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import PortletBody from '../../../app/components/portlet/PortletBody';
import StyledButton from '../../../app/components/styledComponents/StyledButton';
import StyledDot from '../../../app/components/styledComponents/StyledDot';
// import OccyLogoBlue from '../../../app/components/svgIcons/OccyLogoBlue';
import OccyLogoBlue1 from '../../../app/components/svgIcons/OccyLogoBlue.png';

import { StyledLabel, Wrap } from '../../../assets/styledComponents/Global';
import useLicence from '../../../hooks/useLicence';
import {
  removeCredentials,
  removeLicence,
  setLicence,
  removeDelegatedUser
} from '../../auth/authSlice';
import {
  CookiePolicyInformation,
  CookiesPolicy,
  GeneralPrivacyPolicy,
  PrivacyPolicyInformation,
  TermsOfUse,
  privacySideBar,
} from '../assets/utills';
import QuestionTile from './Tiles/QuestionTile';
import { useUpdateLicenceInfoMutation } from './tosApiSlice';
import styled, { useTheme } from 'styled-components';
import TickCircleIconOpen from '../../../app/components/svgIcons/TickCircleIconOpen';

const PrivacyPolicyContainer = () => {
  const theme = useTheme();
  const [tab, setTab] = useState(1);
  const { licence } = useLicence();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [updateLicenceInfo, { isLoading }] = useUpdateLicenceInfoMutation();

  useEffect(() => {
    if (licence?.data?.accepted === 1) {
      navigate('/welcome');
    }
  }, [licence]);

  const handleAccept = () => {
    const licenceId = licence?.data?.id;
    updateLicenceInfo(licenceId).then((licence) => {
      if (licence?.data?.accepted === 1) {
        dispatch(setLicence({ licence: licence }));
      } else {
        dispatch(removeCredentials());
        dispatch(removeLicence());
        dispatch(removeDelegatedUser());
        navigate('/');
      }
    });
  };

  const handleReject = () => {
    dispatch(removeCredentials());
    dispatch(removeLicence());
    dispatch(removeDelegatedUser());
    navigate('/');
  };

  const privacyAccept = () => {
    setTab(tab + 1);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }

  return (
    <StyledWrap className="p-4">
      {/* <OccyLogoBlue /> */}
      <img
        src={OccyLogoBlue1}
        alt="img"
        height={45}
        width={170}
      />
      <Wrap className="d-flex mt-3">
        <div className='col-1'></div>
        <StyledSideBar className="col-2 me-3 ms-0">
          {privacySideBar?.map((item, ind) => (
            <StyledItem key={ind} className={`d-flex align-items-center justify-content-between p-3 mb-2 cursor-pointer ${item?.id === tab && 'active'}`}
              onClick={() => setTab(item?.id)}>
              {item?.name}
              {item?.id === tab - 1 &&
                <TickCircleIconOpen fill={theme?.interviewsuccess} color='white' />
              }
            </StyledItem>
          ))}
        </StyledSideBar>

        <Wrap className='d-flex flex-column col-8'>
          <PortletBody className="">
            <StyledLabel size="32px">{tab === 1 ? 'Privacy Policy' : 'Terms & Conditions'}</StyledLabel>
          </PortletBody>
          <PortletBody className=" mt-3 rounded-3">
            <StyledLabel size="24px">{tab === 1 ? 'General Privacy Policy' : 'Terms of Use of occy'} </StyledLabel>
            <Wrap >
              {(tab === 1 ? GeneralPrivacyPolicy : TermsOfUse)?.map((item, ind) => (
                <>
                  <StyledLabel
                    key={ind + item}
                    color={theme?.textColor}
                    className="mt-3"
                    size="16px"
                    weight="500"
                  >
                    {item?.title}
                  </StyledLabel>
                  {item?.data?.map((data, ind) => (
                    <>
                      <Wrap className="d-flex gap-2 ms-2" key={ind}>
                        <StyledDot $size="2px" $padding='3px' className='mt-2' color={theme?.textColor} />
                        <StyledLabel
                          color={theme?.textColor}
                          size="16px"
                          weight="500"
                        >
                          {data}
                        </StyledLabel>
                      </Wrap>
                    </>
                  ))}
                </>
              ))}
            </Wrap>
            {tab === 1 && <QuestionTile Information={PrivacyPolicyInformation} />}

          </PortletBody>
          <PortletBody className="mt-3 rounded-3">
            {tab === 1 &&
              <>
                <StyledLabel size="24px">Cookie Policy</StyledLabel>
                <Wrap>
                  {CookiesPolicy?.map((item, ind) => (
                    <>
                      <StyledLabel
                        key={ind + item}
                        color={theme?.textColor}
                        className="mt-3"
                        size="16px"
                        weight="500"
                      >
                        {item?.title}
                      </StyledLabel>
                      {item?.data?.map((data1, ind) => (
                        <>
                          <Wrap className="d-flex gap-3 ms-3" key={ind}>
                            <StyledDot $size="2px" $padding='3px' className='mt-2' color={theme?.textColor} />
                            <StyledLabel
                              color={theme?.textColor}
                              size="16px"
                              weight="500"
                            >
                              {data1}
                            </StyledLabel>
                          </Wrap>
                        </>
                      ))}
                    </>
                  ))}
                </Wrap>
                <QuestionTile className='mt-2' Information={CookiePolicyInformation} />
              </>}
            <Wrap className="d-flex justify-content-between align-items-center">
              <StyledButton
                variant="text"
                className="ps-0 fw-bold text-secondary"
                onClick={handleReject}
              >
                Close
              </StyledButton>
              <StyledButton
                onClick={() => {
                  tab === 1 ? privacyAccept()
                    : handleAccept()
                }}
                loading={isLoading}
                disabled={isLoading}
              >
                I agree
              </StyledButton>
            </Wrap>
          </PortletBody>
        </Wrap>
      </Wrap >


    </StyledWrap >
  );
};

export default PrivacyPolicyContainer;

const StyledWrap = styled(Wrap)`
background-color:${({theme}) => theme?.bgPink};
min-height:100vh;
`

const StyledSideBar = styled.div`
padding: 20px;
border-radius: 10px;
height:144px;
width:250px;
background-color: ${({theme}) => theme?.white};
@include media-breakpoint-down(sm) {
  padding: 15px;
}`

const StyledItem = styled.div`
font-weight:400;
margin-bottom:10px;
color:${({theme}) => theme?.neutral};

&.active{
  background-color:${({theme}) => theme?.primaryLight};
  font-weight:600;
border-radius:12px;
}

&:hover{
  font-weight:600;
  background-color:${({theme}) => theme?.primaryLight};
  border-radius:12px;
}`