import styled from 'styled-components';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';

const StyledButton = ({ loading, children, icon, endIcon, ...props }) => (
  <ButtonStyled {...props} _children={children}>
    <>
      <span
        className={`spinner spinner-border spinner-border-sm me-2 ${
          !loading ? 'd-none' : 'd-inline-block'
        }`}
        style={{
          transition: 'all 0.3s linear',
        }}
      />
      {icon && !loading && (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: children ? 10 : 0,
          }}
        >
          {icon}
        </span>
      )}
      <span
        style={{
          transition: 'margin-left 0.3s ease-in',
        }}
      >
        {children}
      </span>

      {endIcon && !loading && (
        <span
          style={{
            display: 'flex',
            alignItems: 'center',
            marginRight: children ? 10 : 0,
          }}
        >
          {endIcon}
        </span>
      )}
    </>
  </ButtonStyled>
);

const ButtonStyled = styled(Button)`
  ${({ variant, theme, height, _children, dotted }) => {
    const basicProperties = `
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    ${!_children ? 'padding: 0.57rem;' : ''}
    `;
    switch (variant) {
      case 'white':
        return `
        ${basicProperties}
        color: ${theme.primaryText};
        border: 1px ${dotted ? 'dashed' : 'solid'} ${theme.primaryText};
        max-height: ${height ? height + 'px' : ''};
        background: ${theme?.dark ? 'transparent' : theme.white};
        &:hover{
          background: ${theme.secondary};
          color: #fff !important;
          border: 1px solid ${theme.primaryText};
          box-shadow: none;
          
        }
     

        `;
      case 'light':
        return `
        ${basicProperties}
        color: ${theme.primary};
        background: ${theme?.dark ? theme.primaryLight2 : theme.primaryLight};
        border:2px solid ${theme.inputBorder};
        &:hover{
          color: ${theme.primary};
          border: 2px solid ${theme.primary};
          background: ${theme?.dark ? theme.primaryLight2 : theme.primaryLight};
          box-shadow: none;
          svg {
           fill: ${theme.white}!important;
          }
        }
        `;
      case 'added':
        return `
          ${basicProperties}
          color: ${theme.finch};
          background: ${theme.berylGreen};
          padding-right: 33px;
          padding-left: 32px;
          &:hover{
            color: ${theme.finch};
          background: ${theme.berylGreen};

            box-shadow: none;
            svg {
             fill: ${theme.berylGreen}!important;
            }
          }
          `;
      case 'text':
        return `
        ${basicProperties}
        color: ${theme.inputBody};
        background: transparent;
        &:hover, &:focus, &:active {
         background: transparent;
          color: ${theme.secondary};
          box-shadow: none;
          border-color: transparent!important;
        }
        `;
      case 'icon':
        return `
          ${basicProperties}
          color: ${theme.inputColor};
          background: transparent;
          border: 1px solid ${theme.inputBorder};
          &:hover, &:active, &:focus {
          border: 1px solid ${theme.inputBorder};
          background: transparent;
            color: ${theme.secondary};
            box-shadow: none;
          }
          `;
      case 'gray':
        return `
              ${basicProperties}
              color: ${theme.lightBoldBlack};
              background: ${theme.borderLight};
              &:hover, &:active, &:focus {
              color: ${theme.lightBoldBlack};
              background: ${theme.borderLight};
              }
              `;
      case 'iconBackground':
        return `
              ${basicProperties}
              color: ${theme.inputColor};
              background: ${theme?.dark ? theme?.tableHeaderBg : theme.white};
              border: 1px solid ${theme.inputBorder};
              padding:13px;
              max-height: ${height ? height + 'px' : ''};
              &:hover, &:active, &:focus {
                background: ${theme?.dark ? theme?.primaryLight2 : theme.primary};
                color: ${theme.white};
                box-shadow: none;
              }
              `;
      case 'border':
        return `
              ${basicProperties}
              background: ${theme.white};
              border: 1px solid ${theme.primary};
              padding:13px;
              &:hover, &:active, &:focus {
                border: 2px solid ${theme.primary};
                box-shadow: none;
              }
              `;
     case 'nonActive':
        return `
              ${basicProperties}
              background: ${theme?.dark ? theme?.darkLight :  theme.white};
              border: 2px solid ${theme?.dark ? theme?.darkLight :theme.borderLight};
              color: ${theme?.dark ? theme?.headingColor :theme.inputColor};
              &:hover, &:active, &:focus {
              border: 2px solid ${theme.secondary};
              box-shadow: none;
              }
             `;
    
      case 'PrimaryLightBg':
          return `
                ${basicProperties}
                color: ${theme.primary};
                background: ${theme?.dark ? theme.primaryLight2 : theme.primaryLight};
                border:1px solid ${theme.primary};
                &:hover{
                color: ${theme.primary};
                border: 1px solid ${theme.primary};
                box-shadow: none;
                svg {
                 fill: ${theme.white}!important;
                }
                }
                `;
      default:
        return `
        ${basicProperties}
        `;
    }
  }}
`;

ButtonStyled.propTypes = {
  loading: PropTypes.bool,
  children: PropTypes.element.isRequired,
  icon: PropTypes.element,
};
export default StyledButton;
