import React from 'react';
import { ErrorMessage, Field} from 'formik';
import Proptypes from 'prop-types';
import StyledInput from '../styledComponents/StyledInput';
import { formErrorMessage } from './FormErrorMessage';

const FormikInputField = ({ name, forms, maxChar, index, isReadOnly, isShowError, ...props }) => (
  <Field name={name}>
    {({ field, form: { touched, errors, submitCount, ...rest } }) => (
      <>
        <StyledInput
          {...field}
          value={isReadOnly ? "" : field.value}
          // isValid={submitCount && touched[field.name] && !errors[field.name]}
          isInvalid={submitCount && touched[field.name] && errors[field.name]}
          id={props.id}
          {...props}
        />
        {props.type !== 'number' && maxChar && field && field.value && (
          <code className="text-muted float-right">
            {field.value.length}/{maxChar}
          </code>
        )}
        {submitCount > 0 && !isShowError && (
          <ErrorMessage name={field.name} render={formErrorMessage} />
        )}
      </>
    )}
  </Field>
);

FormikInputField.propTypes = {
  name: Proptypes.string.isRequired,
  placeholder: Proptypes.string,
  type: Proptypes.string,
  as: Proptypes.string,
  rows: Proptypes.string,
};
export default React.memo(FormikInputField);
