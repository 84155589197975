import styled from 'styled-components';
import { ToastContainer } from 'react-toastify';
import theme from '../../../utils/themes';

const StyledToastContainer = styled(ToastContainer)`
  min-width: 35%;
  z-index: 1100 !important;

  .Toastify__toast-theme--colored {
    color: ${theme.textColor} !important;
    font-family: 'Quick Sand';
    font-weight: 600;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    border-radius: 8px;
    padding: 5px 30px;
  }

  .Toastify__toast--success {
    background: ${({theme}) => theme?.toastSuccessBg} !important;
    border: 1px solid ${({theme}) => theme.toastSuccessBorderColor} !important;
    color : ${({theme}) =>theme.toastErrorTextColor} !important;
    .Toastify__progress-bar {
      opacity: 0;
    }

    .Toastify__toast-icon svg{
       fill: ${({theme}) => theme.toastSuccessIconColor} !important;
    }
  }

  .Toastify__toast--error {
    background: ${({theme}) => theme?.toastErrorBg} !important;
    border: 1px solid ${({theme}) =>theme.toastErrorBorderColor} !important;
    color : ${({theme}) =>theme.toastErrorTextColor} !important;
    .Toastify__progress-bar {
      opacity: 0;
    }

    .Toastify__toast-icon svg{
      fill: ${({theme}) => theme.toastErrorIconColor} !important;
    }
  }

  .Toastify__toast--info {
    background: ${theme.primaryLight} !important;
    border: 1px solid ${theme.primaryLight} !important;

    .Toastify__progress-bar {
      background: ${theme.primaryLight2} !important;
    }
  }

  .Toastify__close-button {
    display: none !important;
  }
`;

export default StyledToastContainer;
