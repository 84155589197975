import React from 'react';

const CallIcon = () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M7.36634 9.96659L6.13301 11.1999C5.87301 11.4599 5.45967 11.4599 5.19301 11.2066C5.11967 11.1333 5.04634 11.0666 4.97301 10.9933C4.28634 10.2999 3.66634 9.57325 3.11301 8.81325C2.56634 8.05325 2.12634 7.29325 1.80634 6.53992C1.49301 5.77992 1.33301 5.05325 1.33301 4.35992C1.33301 3.90659 1.41301 3.47325 1.57301 3.07325C1.73301 2.66659 1.98634 2.29325 2.33967 1.95992C2.76634 1.53992 3.23301 1.33325 3.72634 1.33325C3.91301 1.33325 4.09967 1.37325 4.26634 1.45325C4.43967 1.53325 4.59301 1.65325 4.71301 1.82659L6.25968 4.00659C6.37967 4.17325 6.46634 4.32659 6.52634 4.47325C6.58634 4.61325 6.61967 4.75325 6.61967 4.87992C6.61967 5.03992 6.57301 5.19992 6.47967 5.35325C6.39301 5.50659 6.26634 5.66659 6.10634 5.82659L5.59967 6.35325C5.52634 6.42659 5.49301 6.51325 5.49301 6.61992C5.49301 6.67325 5.49967 6.71992 5.51301 6.77325C5.53301 6.82659 5.55301 6.86659 5.56634 6.90659C5.68634 7.12659 5.89301 7.41325 6.18634 7.75992C6.48634 8.10659 6.80634 8.45992 7.15301 8.81325C7.21967 8.87992 7.29301 8.94659 7.35967 9.01325C7.62634 9.27325 7.63301 9.69992 7.36634 9.96659Z" fill="currentColor"/>
  <path d="M14.6471 12.2201C14.6471 12.4068 14.6137 12.6001 14.5471 12.7868C14.5271 12.8401 14.5071 12.8934 14.4804 12.9468C14.3671 13.1868 14.2204 13.4134 14.0271 13.6268C13.7004 13.9868 13.3404 14.2468 12.9337 14.4134C12.9271 14.4134 12.9204 14.4201 12.9137 14.4201C12.5204 14.5801 12.0937 14.6668 11.6337 14.6668C10.9537 14.6668 10.2271 14.5068 9.46039 14.1801C8.69372 13.8534 7.92706 13.4134 7.16706 12.8601C6.90706 12.6668 6.64706 12.4734 6.40039 12.2668L8.58039 10.0868C8.76706 10.2268 8.93372 10.3334 9.07372 10.4068C9.10706 10.4201 9.14706 10.4401 9.19372 10.4601C9.24706 10.4801 9.30039 10.4868 9.36039 10.4868C9.47372 10.4868 9.56039 10.4468 9.63372 10.3734L10.1404 9.87344C10.3071 9.70677 10.4671 9.58011 10.6204 9.50011C10.7737 9.40677 10.9271 9.36011 11.0937 9.36011C11.2204 9.36011 11.3537 9.38677 11.5004 9.44677C11.6471 9.50677 11.8004 9.59344 11.9671 9.70677L14.1737 11.2734C14.3471 11.3934 14.4671 11.5334 14.5404 11.7001C14.6071 11.8668 14.6471 12.0334 14.6471 12.2201Z" fill="currentColor"/>
  </svg>
  
);

export default CallIcon;
