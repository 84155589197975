import React from 'react';

const RestrictedAccess = () => {
 
  return (
    <>
<div className='d-flex justify-content-center'>Access Restricted</div>
    </>
  );
};


export default RestrictedAccess;
