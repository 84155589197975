import React from 'react';
import { useTheme } from 'styled-components';

const RadioButtonIcon = ({ size = '16', active = false, ...props }) => {
  const theme = useTheme();
  
  return (
  <svg
    width={size}
    height={size}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle
      cx="8"
      cy="8"
      r="8"
      fill={active ? theme.secondary : theme.inputBorder}
    />
    <circle cx="8" cy="8" r="6" fill={theme?.white} />
    <circle cx="8" cy="8" r="4" fill={active ? theme.secondary : theme.white} />
  </svg>
)};

export default RadioButtonIcon;
