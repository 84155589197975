import { apiSlice } from '../../../app/services/apiSlice';

export const extendedApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    updateLicenceInfo: builder.mutation({
      query: (id) => ({
        url: `/company/licence/${id}/accept`,
        method: 'PUT',
      }),
    }),
    getCompanyDetails: builder.query({
      query: (token) => ({
        url: `/public/candidates/mail/details`,
        params:{token}
      }),
    }),
  updateUnsubscribtion: builder.mutation({
    query: (token) => ({
      url: `/public/candidates/unsubscribe`,
      method: 'POST',
      body:{token:token}
    }),
  }),
}),
});

export const { useUpdateLicenceInfoMutation, useUpdateUnsubscribtionMutation,useGetCompanyDetailsQuery} = extendedApiSlice;
